import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions, ResponseType } from "@angular/http";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { URLSearchParams } from "@angular/http";


import "rxjs/add/operator/toPromise";
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/map";
import {
  product_obj,
  userstorefront_obj,
  m_product_obj,
  order_status,
  order_obj,
  tags,
  inmemoryCell,
  customer_obj,
  productinventory_set,
  Inventory,
  ProductTag
} from "./models";
import { m_customers_obj } from "./models";
import { overrideProvider } from "@angular/core/src/view";
import { element } from "protractor";

import { Observable, timer, pipe, throwError, BehaviorSubject } from 'rxjs';
import { map, switchMap, debounce, debounceTime, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material";
//import { CustomersComponent } from './customers/customers.component';
import { environment } from '../environments/environment';

@Injectable()
export class DataService {
  CustId: any;
  Sfname: any;
  objs: any;
  multiBoolean: any;

  inmemoryData: inmemoryCell[] = []

  productEdited = false
  customerGetFlag = false;

  pageNumber = 0;

  constructor(private http: Http, private httpClient: HttpClient, private _snackBar: MatSnackBar,) { }

  //Mainsite
  //baseurl = 'https://store-api.primetime.company/';

  //Devlopment
  // baseurl = 'https://primetime-api.analogyplus.com/';

  //Test-stie
  // baseurl = "https://store-api.analogyplus.com/";

  //Prod-2

  // baseurl = 'https://storev1-api.primetime.company/';


  //Dev

  // baseurl ='https://storedev-api.primetime.company/'
  // storeUrl = 'https://storedev.primetime.company'

  // prod v2
  // baseurl ='https://storev2-api.primetime.company/'
  // storeUrl = 'https://storev2.primetime.company/'

  baseurl = environment.baseurl
  storeUrl = environment.storeUrl


  private subBoolShowHideSource = new Subject<boolean>();

  subBoolShowHide$ = this.subBoolShowHideSource.asObservable();

  selected_customer: m_customers_obj;

  customer_list: m_customers_obj[] = [];

  selected_storefronts: userstorefront_obj[] = [];

  selected_storefront: userstorefront_obj;

  protected_selected_storefronts: userstorefront_obj[] = [];

  selected_product: m_product_obj;

  customerListState = 'list';
  productListState = 'list';

  users_id: any;
  sfData: any;

  orderstatuscollection: order_status[] = [
    {
      value: "0",
      viewvalue: "Order received"
    },
    {
      value: "1",
      viewvalue: "Order processing"
    },
    {
      value: "2",
      viewvalue: "Order dispatch"
    },
    {
      value: "3",
      viewvalue: "Order delivered"
    }
  ];

  sfDeleteId = new BehaviorSubject<number>(0);
  sfDeleteId$ = this.sfDeleteId.asObservable();

  copiedSizes$ = new BehaviorSubject<any>([]);
  copiedSizesObs$ = this.copiedSizes$.asObservable();

  customerUpdated$ = new BehaviorSubject<m_customers_obj>(null);
  deleteSfFromProductsCatalogue(id) {
    console.log(id);
    this.sfDeleteId.next(id)
  }

  getNativeWindow() {
    return window;
  }

  setCustomer_List(data: m_customers_obj[]) {
    this.customer_list = data;
  }

  getCustomer_List() {
    return this.customer_list;
  }

  appendCustomer_List(obj: m_customers_obj) {


    let index = this.inmemoryData.findIndex(element => element.datatype === 'customer-store');

    if (index > -1) {
      this.inmemoryData[index].multicollectionarry[0].unshift(obj);
    }



  }

  appendProduct_List(obj: product_obj) {
    // console.log( this.inmemoryData[0].multicollectionarry[0]);


    // console.log(obj);
    let index = this.inmemoryData.findIndex(element => element.datatype === 'product');

    if (index > -1) {
      console.log(this.inmemoryData[index].multicollectionarry[0]);
      this.inmemoryData[index].multicollectionarry[0].unshift(obj);
      console.log(this.inmemoryData[index].multicollectionarry[0]);

    }



  }


  appendStoreObjInCustomer(obj: userstorefront_obj) {

    let index = this.inmemoryData.findIndex(element => element.datatype === 'customer-store');

    if (index > -1) {
      let numberofPage = this.inmemoryData[index].multicollectionarry.length;

      if (numberofPage === 1) {

        this.inmemoryData[index].multicollectionarry[0].forEach(element => {

          if (element['id'] === obj.user) {

            element['user_storefront'].unshift(obj);
          }
        });
      }
    }

  }


  appendProductObjinStore(obj: m_product_obj, customerid, storefrontid) {

    // console.log(obj);
    // console.log(this.inmemoryData);
    let index = this.inmemoryData.findIndex(element => element.datatype === 'customer-store');
    console.log(index);

    if (index > -1) {
      let numberofPage = this.inmemoryData[index].multicollectionarry.length;
      console.log(numberofPage);




      if (numberofPage === 1) {

        console.log(this.inmemoryData[index].multicollectionarry[0]);

        this.inmemoryData[index].multicollectionarry[0].forEach(element => {
          console.log(element);


          if (element['id'] === customerid) {

            element['user_storefront'].forEach(elementx => {

              if (elementx['id'] === storefrontid) {
                console.log(elementx['id'], storefrontid);
                elementx['storefront_product'] += 1
                elementx['storefront_product_total'] += 1
                console.log(elementx['storefront_product'])
                return
              }

            });
          }
        });
      }
    }

  }

  removeProductObjFromStore(pid, sfid, uid) {

    let index = this.inmemoryData.findIndex(element => element.datatype === 'customer-store');

    if (index > -1) {
      let numberofPage = this.inmemoryData[index].multicollectionarry.length;

      if (numberofPage === 1) {

        this.inmemoryData[index].multicollectionarry[0].forEach(element => {

          if (element['id'] === uid) {

            element['user_storefront'].forEach(elementx => {
              console.log(elementx);

              if (elementx['id'] === sfid) {

                let indexofp = elementx['storefront_product'].findIndex((elemenzzt) => {
                  return elemenzzt.id === pid;
                });

                elementx['storefront_product'].splice(indexofp, 1);
                elementx['storefront_product_total'].splice(indexofp, 1);

              }

            });
          }
        });
      }
    }

  }

  setSelectedCustomer(data: m_customers_obj) {
    this.selected_customer = data;
  }

  getSelectedCustomer() {
    return this.selected_customer;
  }

  getCustomerId(id) {
    this.CustId = id;
    console.log(this.CustId, "heId");
  }
  getMultiSF_Obj(obj: any, multiBoolean: any) {
    this.objs = obj;
    this.multiBoolean = multiBoolean;
    console.log(this.objs);
  }

  showHide(eventValue: boolean) {
    this.subBoolShowHideSource.next(eventValue);
    console.log("show hide service " + eventValue);
  }

  getCustId(id: any) {
    this.users_id = id;
  }

  setSfname(name: any) {
    this.Sfname = name;
    console.log(this.Sfname, "ajaj");
  }

  creatCustomer(
    Cusername: any,
    Cname: any,
    Ctype: any,
    Ctelephone: any,
    Caddress: any,
    Ccity: any,
    Cstate: any,
    Cpostal: any,
    Cdesignation: any,
    Cemail: any,
    Cpassword: any,
    Cstatus: any,
    Custlogo: any,
    Ccontact: any,
    shipping: any,
    ClubData: any,
    tax: any
  ) {
    console.log(ClubData);
    console.log(Cemail, "see");
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    //let array1;
    //array1 = [{ "subfolder_name": subfolder, "file_upload": list }];
    //console.log(array1);
    let formData: FormData = new FormData();
    formData.append("username", Cusername);
    formData.append("name", Cname);
    formData.append("email", Cemail);
    formData.append("address_street", Caddress);
    formData.append("address_city", Ccity);
    formData.append("address_state", Cstate);
    formData.append("postal_code", Cpostal);
    formData.append("phone_number", Ctelephone);
    formData.append("designation", Cdesignation);
    formData.append("status", Cstatus);
    formData.append("shipping_instructions", shipping);
    formData.append("user_type", Ctype);
    formData.append("username", Cusername);
    formData.append("sales_tax", tax);
    if (Custlogo) {
      formData.append("logo", Custlogo);
    }

    formData.append("password_text", Cpassword);
    formData.append("password", Cpassword);
    formData.append("user_sub_type", ClubData);

    //headers.append('enctype', 'multipart/form-data');
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/users/", formData, options)
        .subscribe(
          success => {
            //console.log('success');
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }


  //getuserdetail

  getUserDetail() {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    const options = new RequestOptions({ headers: headers });


    return this.http.get(this.baseurl + "api/v1/userdetailv1/", options);
  }

  addcustomer(obj: any, img: any, isClubs: boolean, allEmailid: any[], create: boolean) {
    // console.log(clubs)
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    let formData: FormData = new FormData();
    formData.append("username", obj.username);
    formData.append("name", obj.name);
    formData.append("email", obj.email);
    formData.append("secondary_emails", allEmailid.toString());

    formData.append("address_street", obj.address_street);
    formData.append("address_city", obj.address_city);
    formData.append("address_state", obj.address_state);
    formData.append("postal_code", obj.postal_code);
    formData.append("phone_number", obj.phone_number);
    formData.append("status", obj.status);
    formData.append("shipping_instructions", obj.shipping_instructions);
    formData.append("user_type", obj.user_type);
    formData.append("username", obj.username);
    formData.append("sales_tax", obj.sales_tax);

    formData.append('contact_name', obj.contact_name);
    formData.append('contact_title', obj.contact_title);
    formData.append('entity_type', "customer");


    if (obj.shipping_cost === null) {
      formData.append("shipping_cost", '0');
    } else {
      formData.append("shipping_cost", obj.shipping_cost);

    }




    formData.append("is_international_shipping", obj["is_international_shipping"]);

    formData.append("shipping_cost_international", obj["shipping_cost_international"] === null ? "0" : obj.shipping_cost_international);



    if (img != undefined) {
      formData.append("logo", img);
    }
    console.log(img);
    formData.append("password_text", obj.password_text);

    if (isClubs) {

      let objname = [];
      obj.club.forEach(element => {

        if (element.name !== '') {
          objname.push(element.name)
        }

      });

      console.log(objname)
      formData.append("user_sub_type", objname.toString());
    }
    else {
      formData.append("user_sub_type", '');


    }

    let api = ""
    if (create) {
      api = "api/v1/users/"
      if (this.draftCustomer) {
        formData.append('draft_id', obj.draft_id);

      }

    } else {
      api = "api/v1/storefront/draft/"

    }
    console.log(api);




    //var returnobj = new Promise((resolve, reject) => {
    return this.http
      .post(this.baseurl + api, formData, requestOptions)
    //     .subscribe(
    //       data => {
    //         returnobj = data.json();
    //         resolve(returnobj);
    //       },
    //       error => {
    //         console.log("error1");
    //         //resolve(returnobj);
    //       },
    //       () => {
    //         // console.log('completed');
    //       }
    //     );
    // });
    // return returnobj;
  }

  // getCustomerDraft2(){

  //   const headers = new HttpHeaders({
  //     'Authorization': 'JWT ' +  sessionStorage.getItem("token")
  //   });



  //     return this.httpClient.get(this.baseurl + "api/v1/storefront/draft/",{ headers: headers }).pipe(
  //       map((data: any) => data.results),
  //       catchError(error => {
  //         return throwError('something went wrong')
  //       })

  //     )


  // }

  draftCustomer: any;
  draftStorefront: any;
  getCustomerDraft() {

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.get(this.baseurl + "api/v1/storefront/draft/", { headers: headers }).pipe(
        map((data: any) => data.results),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }

  updateStorefrontDraft(obj, draft_id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    obj['entity_type'] = "storefront";

    return this.httpClient
      .patch(this.baseurl + "api/v1/storefront/draft/" + draft_id + "/", obj, {
        headers: headers
      });

  }

  updateCustomerDraft(draft_id, obj, s_emailIds) {
    let headers = new Headers({
      Authorization: "JWT " + window.sessionStorage.getItem("token")
    });
    let formData: FormData = new FormData();
    formData.append("username", obj["username"]);
    formData.append("name", obj["name"]);
    formData.append("email", obj["email"]);
    formData.append("secondary_emails", s_emailIds.toString());
    formData.append("address_street", obj["address_street"]);
    formData.append("address_city", obj["address_city"]);
    formData.append("address_state", obj["address_state"]);
    formData.append("postal_code", obj["postal_code"]);
    formData.append("phone_number", obj["phone_number"]);
    formData.append("designation", obj["designation"]);
    formData.append("status", obj["status"]);
    formData.append("user_type", obj["user_type"]);
    formData.append("shipping_instructions", obj["shipping_instructions"]);
    formData.append('entity_type', "customer");


    if (obj["shipping_cost"] === null) {
      formData.append("shipping_cost", '0');
    } else {
      formData.append("shipping_cost", obj["shipping_cost"]);
    }

    formData.append("is_international_shipping", obj["is_international_shipping"]);
    formData.append("shipping_cost_international", obj["shipping_cost_international"]);
    formData.append("user_sub_type", obj["user_sub_type"]);
    formData.append("sales_tax", obj["sales_tax"]);
    formData.append("contact_name", obj["contact_name"]);
    formData.append("contact_title", obj["contact_title"]);

    if (typeof obj["logo"] === "string") {
      console.log("no image uploaded");
    } else {
      formData.append("logo", obj["logo"]);
    }

    //headers.append('enctype', 'multipart/form-data');

    return this.http
      .patch(this.baseurl + "api/v1/storefront/draft/" + draft_id + "/", formData, {
        headers: headers
      });
  }

  updatesCustomers(cust_id: any, obj: any) {
    let headers = new Headers({
      Authorization: "JWT " + window.sessionStorage.getItem("token")
    });
    let formData: FormData = new FormData();
    formData.append("username", obj["username"]);
    formData.append("name", obj["name"]);
    formData.append("email", obj["email"]);
    formData.append("secondary_emails", obj["secondary_emails"]);
    formData.append("address_street", obj["address_street"]);
    formData.append("address_city", obj["address_city"]);
    formData.append("address_state", obj["address_state"]);
    formData.append("postal_code", obj["postal_code"]);
    formData.append("phone_number", obj["phone_number"]);
    formData.append("designation", obj["designation"]);
    formData.append("status", obj["status"]);
    formData.append("user_type", obj["user_type"]);
    formData.append("shipping_instructions", obj["shipping_instructions"]);

    if (obj["shipping_cost"] === null) {
      formData.append("shipping_cost", '0');
    } else {
      formData.append("shipping_cost", obj["shipping_cost"]);
    }

    formData.append("is_international_shipping", obj["is_international_shipping"]);
    formData.append("shipping_cost_international", obj["shipping_cost_international"]);
    formData.append("user_sub_type", obj["user_sub_type"]);
    formData.append("sales_tax", obj["sales_tax"]);
    formData.append("contact_name", obj["contact_name"]);
    formData.append("contact_title", obj["contact_title"]);

    if (typeof obj["logo"] === "string" || obj["logo"] == undefined) {
      console.log("no image uploaded");
    } else {
      formData.append("logo", obj["logo"]);
    }



    //headers.append('enctype', 'multipart/form-data');

    return this.http
      .patch(this.baseurl + "api/v1/userdetail/" + cust_id + "/", formData, {
        headers: headers
      });

  }

  createSf_v2(obj: any, create: boolean) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    obj['entity_type'] = "storefront";


    let api = ""
    if (create) {
      api = "api/v1/storefront/"
    } else {
      api = "api/v1/storefront/draft/"
    }

    return this.httpClient.post(this.baseurl + api, obj, { headers: headers });
  }

  //Create StoreFront

  creatStoreFront(
    SFname: any,
    SFcolor: any,
    SFtitlecolor: any,
    SFdateStart: any,
    SFdateEnd: any,
    SFurlHint: any,
    Productlogo: any,
    user_id: any,
    SFstatus: any,
    net30Pay: any,
    onlinePay: any
  ) {
    // console.log("Do Login");
    //console.log(SFdateStart, 'see');
    let headers = new Headers();
    //headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();
    formData.append("name", SFname);
    formData.append("banner_color_code", SFcolor);
    formData.append("title_color", SFtitlecolor);
    // formData.append("online_payment", onlinePayment);
    if (Productlogo) {
      formData.append("banner", Productlogo);
    }
    formData.append("slug", SFurlHint);
    formData.append("user", user_id);
    formData.append("status", SFstatus);
    formData.append("online_payment", onlinePay);
    formData.append("net30", net30Pay);



    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/storefront/", formData, options)
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Add url in SF

  addUrlSf(SFurlHint: any) {
    let headers = new Headers();
    //headers.append('Content-Type', 'application/json')
    //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });

    return this.http.get(
      this.baseurl + "api/v1/storefront/detailv2/" + SFurlHint + "/",
      options
    );
  }
  newSFdata(newdata) {
    this.sfData = newdata;
  }

  // Update Storefront

  updateStoreFront(obj) {
    //console.log(SFdateEnd, 'date');
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();
    formData.append("name", obj["name"]);
    formData.append("banner_color_code", obj["banner_color_code"]);
    formData.append("title_color", obj["title_color"]);
    formData.append("desc_background_color", obj["desc_background_color"]);
    formData.append("desc_text_color", obj["desc_text_color"]);
    formData.append("tags", JSON.stringify(obj.tags));
    formData.append("slug", obj["slug"]);
    formData.append("user", obj["user"]);
    formData.append("net30", obj["net30"]);
    formData.append("passcode", obj["passcode"]);

    if (obj['location']) {
      formData.append("location", obj["location"]);
    } else {
      formData.append("location", '');

    }
    if (obj['location']) {
      formData.append("chain_name", obj["chain_name"]);
    }
    else {
      formData.append("chain_name", '');

    }

    formData.append("online_payment", obj["online_payment"]);
    formData.append("description", obj["description"]);
    formData.append("status", obj["status"]);
    formData.append("free_shipping_amount", obj["free_shipping_amount"]);
    formData.append("auto_fill", obj["auto_fill"]);
    formData.append("show_banner_images", obj["show_banner_images"]);
    formData.append("auto_fill_data", JSON.stringify(obj["auto_fill_data"]));
    formData.append("show_customer_name", obj["show_customer_name"]);
    formData.append("archive_message", obj["archive_message"]);




    var returnobj = new Promise((resolve, reject) => {
      this.http
        .patch(
          this.baseurl + "api/v1/storefront/detail/" + obj["id"] + "/",
          formData,
          options
        )
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  updateStoreFrontNoImg(
    SFname: any,
    SFcolor: any,
    SFtitlecolor: any,
    SFdateStart: any,
    SFdateEnd: any,
    SFurlHint: any,
    user_id: any,
    SFstatus: any,
    product_id: any,
    net30Pay: any,
    onlinePay: any
  ) {
    // console.log(SFdateEnd, 'date');
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();
    formData.append("name", SFname);
    formData.append("banner_color_code", SFcolor);
    formData.append("title_color", SFtitlecolor);
    formData.append("online_payment", onlinePay);
    formData.append("net30", net30Pay);
    //formData.append("online_payment", onlinePayment);

    // Start Date
    // if (SFdateStart == 'null') {
    //   console.log('blank date');
    // }
    // else {
    //   formData.append("startdate", SFdateStart);
    // }
    // End Date
    // if (SFdateEnd == 'null') {
    //   console.log('blank date');
    // }
    // else {
    //   formData.append("enddate", SFdateEnd);
    // }

    // formData.append("enddate", SFdateEnd);
    formData.append("slug", SFurlHint);
    formData.append("user", user_id);
    formData.append("status", SFstatus);

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .patch(
          this.baseurl + "api/v1/storefront/detail/" + product_id + "/",
          formData,
          options
        )
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  imgs: any = [];

  UpdateImages(files: any[], product_id: number) {
    let formData: FormData = new FormData();
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    formData.append("product_id", product_id.toString());

    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      let cont = index + 1;
      formData.append("image_" + cont, element);


    }

    return this.http.post(this.baseurl + "api/v1/product/", formData);
  }

  UpdateOnlyImgPrd(
    Prd_logo: any,
    image1: any,
    image2: any,
    image3: any,
    image4: any,
    image5: any,
    edit_product_id: any
  ) {
    console.log(
      Prd_logo,
      image1,
      image2,
      image3,
      image4,
      image5,
      edit_product_id,
      "Update Img"
    );

    let formData: FormData = new FormData();
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    formData.append("product_id", edit_product_id);
    if (Prd_logo == undefined) {
      console.log("no image logo");
    } else {
      formData.append("image_1", Prd_logo);
    }

    // img1
    if (image1 == undefined) {
      console.log("no image1");
    } else {
      formData.append("image_2", image1);
    }
    // img2
    if (image2 == undefined) {
      console.log("no image2");
    } else {
      formData.append("image_3", image2);
    }
    // img3
    if (image3 == undefined) {
      console.log("no image3");
    } else {
      formData.append("image_4", image3);
    }
    // img4
    if (image4 == undefined) {
      console.log("no image4");
    } else {
      formData.append("image_5", image4);
    }
    // img5
    if (image5 == undefined) {
      console.log("no image5");
    } else {
      formData.append("image_6", image5);
    }

    var returnobj = new Promise((resolve, reject) => {
      this.http.post(this.baseurl + "api/v1/product/", formData).subscribe(
        success => {
          returnobj = success.json();
          resolve(returnobj);
        },
        error => {
          console.log("error");
          //resolve(returnobj);
          //alert('Incorrect Username or Password');
        },
        () => {
          //console.log('completed');
        }
      );
    });
    return returnobj;
  }

  deleteInvRow(invid) {

    console.log(invid);
    // return
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    var obj = { "is_active": false }

    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.patch(this.baseurl + "api/v1/productinv/" + invid + "/", obj, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }

  copyStorefront(obj) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });



    let promise = new Promise<userstorefront_obj[]>((resolve, reject) => {
      return this.httpClient.post(this.baseurl + "api/v1/storefront/clone/", obj, { headers: headers }).pipe(
        map((data: userstorefront_obj[]) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }


  addNewSizeChartToStyle(id, obj) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();
    formData.append('size_chart', obj.size_chart)


    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.put(this.baseurl + `api/v1/product/sizechart/${id}/`, formData, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise
  }

  addInvImage(invrowid, img) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();

    formData.append("product_inv", invrowid)
    formData.append("image", img)




    formData.forEach((value, key) => {
      console.log(key + " " + value)
    });



    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.post(this.baseurl + "api/v1/product/invimage/", formData, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }

  deleteImage(imgId) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.delete(this.baseurl + "api/v1/product/invimage/" + imgId + "/", { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }

  deleteProductPatch(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    // let obj = {deleted:true};



    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.delete(this.baseurl + "api/v1/productv3/" + id + "/", { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise

  }
  deleteBundle(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.delete(this.baseurl + "api/v1/bundle/" + id + "/", { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise

  }
  ArrayToString(arr: any[]): string {

    var strReturn = ""
    arr.forEach(el => {
      strReturn += el + ','
    })


    return ""
  }

  updateNewInventory(id: number, obj: Inventory[], sfid: any) {
    console.log(id);
    console.log(obj);


    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();
    var inventory_obj = {};
    var inventory_items: any = [];

    obj.forEach((inv) => {
      inventory_obj = { tag: inv.tag, color: this.camelString(inv.color), size: this.camelString(inv.size), quantity: inv.quantity, price: inv.price };

      inv.files.forEach((file, index) => {
        let cont = index + 1;
        formData.append(inv.tag + '_' + this.camelString(inv.color) + '_' + this.camelString(inv.size) + '_' + cont, file)
      })
      console.log(inventory_obj);
      inventory_items.push(inventory_obj);

    })


    console.log(inventory_items);



    formData.append("inventory_items", JSON.stringify(inventory_items));
    formData.append("storefront", sfid);


    formData.forEach((value, key) => {
      console.log(key + " " + value)
    });
    console.log(JSON.stringify(formData));

    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.patch(this.baseurl + "api/v1/productv3/" + id + "/", formData, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise



  }

  getProductTagList() {

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let promise = new Promise<ProductTag[]>((resolve, reject) => {
      return this.httpClient.get(this.baseurl + "api/v1/product/tags/", { headers: headers }).pipe(
        map((data: any) => data.results),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise

  }
  getProductSpecialTagList() {

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let promise = new Promise<ProductTag[]>((resolve, reject) => {
      return this.httpClient.get(this.baseurl + "api/v1/product/special_tags/", { headers: headers }).pipe(
        map((data: any) => data.results),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise

  }
  getBundleCategoryList(){
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let promise = new Promise<any[]>((resolve, reject) => {
      return this.httpClient.get(this.baseurl + "api/v1/bundle/category/", { headers: headers }).pipe(
        map((data: any) => data.results),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }
  addBundleCategory(category){
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let obj = { name: category }

    return this.httpClient.post(this.baseurl + "api/v1/bundle/category/", obj, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }
  removeBundleCategory(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    return this.httpClient.delete(this.baseurl + `api/v1/bundle/category/${id}/`, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }
  addProductTag(tag) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let obj = { name: tag }

    return this.httpClient.post(this.baseurl + "api/v1/product/tags/", obj, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }

  removeProductTag(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    return this.httpClient.delete(this.baseurl + `api/v1/product/tags/${id}/`, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }
  addProductSpecialag(tag) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    let obj = { name: tag }

    return this.httpClient.post(this.baseurl + "api/v1/product/special_tags/", obj, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }

  removeProductSpecialTag(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    return this.httpClient.delete(this.baseurl + `api/v1/product/special_tags/${id}/`, { headers: headers }).pipe(
      map((res: any) => res)
    )
  }

  transform(value: string): string {
    let first = value.substr(0, 1).toUpperCase();
    return first + value.substr(1);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  camelString(val: string): string {
    const colorArray = val.split(',').filter(c => c != "");
    console.log(colorArray);
    var col = ""
    colorArray.forEach(c => {
      col += this.transform(c.trim()) + ","
      console.log(c);
    })

    console.log(col.slice(0, -1));

    return col.slice(0, -1)
  }

  removeSpace(val: string): string {
    const arr = val.split(',').filter(s => s != "");
    var str = ""

    arr.forEach(element => {
      str += element.trim() + ","
    })


    return str.slice(0, -1);
  }

  makeColorStr(colA: any[]): string {

    var col = ""
    colA.forEach(c => {
      col += this.capitalizeFirstLetter(c.trim()) + ","
      console.log(c);
    })

    console.log(col.slice(0, -1));

    return col.slice(0, -1)

  }

  makeSizeStr(sizeA: any[]): string {
    var size = ""
    sizeA.forEach(c => {
      size += c.trim().toUpperCase(); + ","
      console.log(c);
    })

    console.log(size.slice(0, -1));

    return size.slice(0, -1)
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000
    });
  }

  addInvCombination(obj) {
    console.log(obj);
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });
    const formData: FormData = new FormData();

    formData.append("product", obj.id);
    formData.append("tag", obj.tag.name);
    formData.append("color", this.capitalizeFirstLetter(obj.color));
    formData.append("size", obj.size);
    formData.append("storefront", obj.storefront);
    formData.append("quantity", obj.quantity);
    formData.append("price", obj.price);

    obj.files.forEach((file, index) => {
      let cont = index + 1;

      formData.append(obj.tag.name + '_' + this.capitalizeFirstLetter(obj.color) + '_' + obj.size + '_' + cont, file)

    });
    formData.forEach((value, key) => {
      console.log(key + " " + value)
    });
    let promise = new Promise<productinventory_set>((resolve, reject) => {
      return this.httpClient.post(this.baseurl + "api/v1/productinv/ ", formData, { headers: headers }).pipe(
        tap(data => console.log(data)),
        map((data: productinventory_set) => data),
        catchError(error => {
          console.log(error.error.non_field_errors[0]);
          return throwError(error.error.non_field_errors[0])
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise


  }

  isAtLeastOneChecked(array: any[], property: string): boolean {
    return array.some(item => item[property]);
  }

  createMultipleProduct(boj: product_obj) {
    console.log(boj, 'test');

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    // "Authorization",
    //   "JWT" + " " + sessionStorage.getItem("token")
    const formData: FormData = new FormData();
    formData.append("title", boj.title);
    formData.append("categories", "" + boj.categories);
    formData.append("code", boj.code);
    formData.append("color", this.makeColorStr(boj.colorArray));
    formData.append("description", boj.description);
    formData.append("product_background_color", boj.product_background_color);
    formData.append("product_text_color", boj.product_text_color);
    formData.append("special_tags", JSON.stringify(boj.specialTags));
    formData.append("multiple_colors", "true");
    formData.append("price", boj.price);
    formData.append("size", boj.sizeArray.toString());
    formData.append("storefront", boj.storefront);
    // formData.append("size_chart", boj.size_chart);
    if (boj.is_featured) {
      formData.append("is_featured", "True");
    }
    else {
      formData.append("is_featured", "False");

    }

    var tagsArr = [];

    boj.typeArray.forEach(type => {

      tagsArr.push(type);
    })
    formData.append('tags', JSON.stringify(tagsArr))

    var inventory_obj = {};
    var inventory_items: any = [];

    boj.productinventory_set.forEach((inv, i) => {
      inventory_obj = { tag: inv.tag, color: inv.color, size: inv.size, quantity: inv.quantity, price: inv.price };

      inv.files.forEach((file, index) => {
        let cont = index + 1;
        formData.append(inv.tag + '_' + inv.color + '_' + inv.size + '_' + cont, file)
      })
      console.log(inventory_obj);
      inventory_items.push(inventory_obj);

    })
    console.log(inventory_items);
    formData.append("inventory_items", JSON.stringify(inventory_items));

    boj.size_chart.forEach(item => {
      // if(item.chart){
      console.log(item.style, item.chart);
      formData.append(item.style.name, item.chart)
      // }
    })


    formData.forEach((value, key) => {
      console.log(key + " " + value)
    });




    // for (let index = 0; index < boj.files.length; index++) {
    //   const element = boj.files[index];
    //   let cont = index + 1;
    //   formData.append("image_" + cont, element);
    // }



    return this.httpClient.post<any>(this.baseurl + "api/v1/product/inv/", formData, { headers: headers });
  }

  updateProduct2(id: number, obj: any) {
    console.log(id, obj);

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();

    Object.keys(obj).forEach(key => {
      formData.append(key, obj[key]);
    });
    formData.append('special_tags', JSON.stringify(obj.special_tags))
    formData.append('tags', JSON.stringify(obj.tags))
    if (obj.size_chart) {
      formData.append('size_chart', obj.size_chart);
    }

    let promise = new Promise<m_product_obj>((resolve, reject) => {
      return this.httpClient.patch(this.baseurl + "api/v1/productv3/" + id + "/", formData, { headers: headers }).pipe(
        map((data: m_product_obj) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }
  updateInventory(id: number, obj: any) {
    console.log(id, obj);

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    let promise = new Promise<productinventory_set>((resolve, reject) => {
      return this.httpClient.patch(this.baseurl + "api/v1/productinv/" + id + "/", obj, { headers: headers }).pipe(
        map((data: productinventory_set) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }

  createProduct(
    Pname: any,
    pcode: any,
    Psize: any,
    Pdescription: any,
    Ptype: any,
    Pcolor: any,
    SF_id: any,
    price: any,
    availColr: any,
    Prd_logo: any,
    image1: any,
    image2: any,
    image3: any,
    image4: any,
    image5: any
  ) {
    console.log(availColr, "see image");
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let formData: FormData = new FormData();
    formData.append("title", Pname);
    formData.append("code", pcode);
    formData.append("description", Pdescription);
    formData.append("categories", Ptype);
    formData.append("color", Pcolor);
    formData.append("storefront", SF_id);
    formData.append("price", price);
    formData.append("size", Psize);
    formData.append("multiple_colors", availColr);
    // images Logo
    if (Prd_logo == undefined) {
      console.log("no image logo");
    } else {
      formData.append("image_1", Prd_logo);
    }

    // img1
    if (image1 == undefined) {
      console.log("no image1");
    } else {
      formData.append("image_2", image1);
    }
    // img2
    if (image2 == undefined) {
      console.log("no image2");
    } else {
      formData.append("image_3", image2);
    }
    // img3
    if (image3 == undefined) {
      console.log("no image3");
    } else {
      formData.append("image_4", image3);
    }
    // img4
    if (image4 == undefined) {
      console.log("no image4");
    } else {
      formData.append("image_5", image4);
    }
    // img5
    if (image5 == undefined) {
      console.log("no image5");
    } else {
      formData.append("image_6", image5);
    }
    if (Pdescription == undefined) {
      Pdescription = "";
    }

    var returnobj = new Promise((resolve, reject) => {
      this.http.post(this.baseurl + "api/v1/product/", formData).subscribe(
        success => {
          returnobj = success.json();
          resolve(returnobj);
        },
        error => {
          console.log("error");
          //resolve(returnobj);
          //alert('Incorrect Username or Password');
        },
        () => {
          //console.log('completed');
        }
      );
    });
    return returnobj;
  }

  // Update Products images
  updateProduct_images(img_id: any, ProductUImg: any) {
    console.log(img_id, "date");
    console.log(ProductUImg, "date");
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();
    formData.append("image", ProductUImg);
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .patch(
          this.baseurl + "api/v1/product/image/" + img_id + "/",
          formData,
          options
        )
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  deleteImageFromProduct(imageid: number) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    return this.http.delete(
      this.baseurl + "api/v1/product/image/" + imageid + "/",
      options
    );
  }

  updateProduct(obj: any, product_id: number) {
    //console.log(edit_product_id, 'see id');
    //console.log(Pname, 'see name');
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    return this.http.patch(
      this.baseurl + "api/v1/product/detail/" + product_id + "/",
      obj,
      { headers: headers }
    );
  }

  updateBundle(obj: any, bundle_id: number) {
    //console.log(edit_product_id, 'see id');
    //console.log(Pname, 'see name');
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    

    return this.http.patch(
      this.baseurl + "api/v1/bundle/" + bundle_id + "/",
      obj,
      { headers: headers }
    );
  }

  // Delete Product
  deleteProduct(edit_product_id: any) {
    console.log(edit_product_id, "see id");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .delete(
          this.baseurl + "api/v1/product/detail/" + edit_product_id + "/",
          options
        )
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // User StoreFront
  userStoreFront(user_id: any, product_id: any, storefront_id: any) {
    // console.log("Do Login");
    let body = {
      user: user_id,
      product: storefront_id,
      storefront: product_id
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    // headers.append("Authorization", "JWT" + " " + localStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/user_storefront/", body, {
          headers: headers
        })
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Customer order
  custorder(user: any, obj: any, sf_id: any) {
    let body = {
      data: obj,
      user: user,
      storefront: sf_id
    };
    console.log(user, obj, "user id");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/cart_create/", body, options)
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Get User Order details
  getCustOrder(userid: any) {
    console.log(userid, "id services");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl + "api/v1/cart_details/user/?user_id=" + userid,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Customers List
  getCustomers(page: any) {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    console.log(sessionStorage.getItem("token"), "token");
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http.get(this.baseurl + "api/v1/list/?" + page, options).subscribe(
        data => {
          returnobj = data.json();
          resolve(returnobj);
        },
        error => {
          console.log("error1");
          //resolve(returnobj);
        },
        () => {
          // console.log('completed');
        }
      );
    });
    return returnobj;
  }

  // Get Customers details to edit it,on customer Id

  editCustomer(cust_id: any) {
    console.log(cust_id, "see id");
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    //formData.append("folder_name",fold_name)
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/userdetail/" + cust_id + "/", options)
        .subscribe(
          success => {
            //console.log('success');
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  getStoreFront(cust_id: String) {
    console.log(cust_id, "see id");
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    //formData.append("folder_name",fold_name)

    // console.log(searchstring)

    return this.http.get(
      this.baseurl + "api/v1/user_strorefront_v2/?user_id=" + cust_id,
      options
    );
  }

  // Get storefront details to edit it,on customer Id
  editStorefront(store_id: any) {
    console.log(store_id, "see id");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();
    //formData.append("folder_name",fold_name)
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .patch(
          this.baseurl + "api/v1/storefront/detail/" + store_id + "/",
          options
        )
        .subscribe(
          success => {
            //console.log('success');
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  sortCustlist(status_selected: any) {
    console.log(status_selected);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/list/?status=" + status_selected, options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }
  sortProductType(prodct_type: any, SF_id: any) {
    console.log(prodct_type);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl +
          "api/v1/product/?category=" +
          prodct_type +
          "&storefront_id=" +
          SF_id,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  clothProduct(SF_id: any) {
    var prodct_type = "Clothing";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl +
          "api/v1/product/?category=" +
          prodct_type +
          "&storefront_id=" +
          SF_id,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');image_1
          }
        );
    });
    return returnobj;
  }

  accessoriesProduct(SF_id: any) {
    var prodct_type = "Accessories";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl +
          "api/v1/product/?category=" +
          prodct_type +
          "&storefront_id=" +
          SF_id,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }
  paperProduct(SF_id: any) {
    var prodct_type = "Paper";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl +
          "api/v1/product/?category=" +
          prodct_type +
          "&storefront_id=" +
          SF_id,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  addBundle(obj,img){
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();
    formData.append('title', obj.title)
    formData.append('code', obj.code)
    formData.append('description', obj.description)
    formData.append('price', obj.price)
    formData.append('storefront', obj.storefront)
    formData.append('bundle_items', JSON.stringify(obj.bundle_items))
    if (img != undefined) {
    formData.append('preview_image',img )
    }
 

    return this.httpClient.post<any>(this.baseurl + "api/v1/bundle/inv/", formData, { headers: headers });

  }

  getProduct(storesfront_id: any) {
    console.log(storesfront_id);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl + "api/v1/storefront/detail/" + storesfront_id + "/",
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  login(username: any, password: any) {
    // console.log("Do Login");
    let body = {
      username: username,
      password: password
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    // headers.append("Authorization", "JWT" + " " + localStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "rest-auth/login/", body, { headers: headers })
        .subscribe(
          success => {
            returnobj = success.json();
            resolve(returnobj);
          },
          error => {
            alert("Incorrect Username Or Password");
            console.log("error");
            //resolve(returnobj);
            //alert('Incorrect Username or Password');
          },
          () => {
            //console.log('completed');
          }
        );
    });
    return returnobj;
  }

  veriftyURl(SFurlHint: any,cid:any) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();
    formData.append("storefront_url", SFurlHint);
    formData.append("customer_id", cid);
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/storefront/slug/", formData, options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  payStripe(obj: any) {
    console.log(obj, "obj");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/orders/", obj, { headers: headers })
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  getOrders() {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    //console.log( sessionStorage.getItem('token'),'token');
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http.get(this.baseurl + "api/v1/orderlist/", options).subscribe(
        data => {
          returnobj = data.json();
          resolve(returnobj);
        },
        error => {
          console.log("error1");
          //resolve(returnobj);
        },
        () => {
          // console.log('completed');
        }
      );
    });
    return returnobj;
  }

  getOrdersV2() {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseurl + "api/v1/orderlist/", options);
  }

  // Get storefront names
  getSFname() {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    //console.log( sessionStorage.getItem('token'),'token');
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/storefrontnames/", options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  filterOrders() {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    // requestOptions.search = params;
    requestOptions.headers = headers;

    console.log(requestOptions);

    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/orderfilter/", requestOptions)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  postTrackdetails(
    couriertype: any,
    order_status: any,
    estimatedD: any,
    informCust: any,
    trackid: any,
    exptedD: any,
    delievryNotes: any,
    thiProdelivered: any,
    order_id: any
  ) {
    console.log(order_status, "stat");
    console.log(order_id, "ids");
    let body;

    if (order_status == 1) {
      console.log(order_status, "second");
      body = {
        status: order_status,
        delivery_date: estimatedD,
        inform_customer: informCust,
        order: order_id
      };
    }
    if (order_status == 2) {
      body = {
        status: order_status,
        inform_customer: informCust,
        delivery_service: couriertype,
        departure: exptedD,
        notes: delievryNotes,
        tracking_id: trackid,
        order: order_id
      };
    }
    if (order_status == 3) {
      body = {
        status: order_status,
        inform_customer: informCust,
        is_delivered: true,
        order: order_id
      };
    }

    console.log(body, "hello");
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/shipment/", body, { headers: headers })
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Update Or patch track

  patchTrackdetails(
    couriertype: any,
    order_status: any,
    estimatedD: any,
    informCust: any,
    trackid: any,
    exptedD: any,
    delievryNotes: any,
    thiProdelivered: any,
    order_id: any
  ) {
    console.log(informCust, "stat");
    console.log(order_id, "ids");
    let body;

    if (order_status == 1) {
      console.log(order_status, "second");
      body = {
        status: order_status,
        delivery_date: estimatedD,
        inform_customer: informCust,
        order: order_id
      };
    }
    if (order_status == 2) {
      body = {
        status: order_status,
        inform_customer: informCust,

        delivery_service: couriertype,
        departure: exptedD,
        notes: delievryNotes,
        tracking_id: trackid,
        order: order_id
      };
    }
    if (order_status == 3) {
      body = {
        status: order_status,
        inform_customer: informCust,
        is_delivered: true,
        order: order_id
      };
    }

    console.log(body, "hello");
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .patch(
          this.baseurl + "api/v1/shipment/detail/" + order_id + "/",
          body,
          options
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  getTrackdetails(order_id) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    //console.log( sessionStorage.getItem('token'),'token');
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/shipment/detail/" + order_id + "/", options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //  resolve(returnobj);
            //  return error;
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  sendemail(order_id, obj) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();
    formData.append("order_id", order_id);
    formData.append("receipt", obj);
    //formData.append("order_file", obj);
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/ordermail/", formData, options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  reportsOrder(cust_id) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(
          this.baseurl + "api/v1/orderfilter/?customer_id=" + cust_id + "",
          requestOptions
        )
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  filterSf(params: URLSearchParams) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.search = params;
    requestOptions.headers = headers;
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/orderfilter/", requestOptions)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }
  filterSatus(params: URLSearchParams) {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.search = params;
    requestOptions.headers = headers;

    // let headers = new Headers();
    //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    // let requestOptions = new RequestOptions();
    // requestOptions.headers = headers;
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/orderfilter/", requestOptions)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // For Excel filter response

  excelReponseFilter(params: URLSearchParams) {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.search = params;
    requestOptions.headers = headers;

    // let headers = new Headers();
    //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    // let requestOptions = new RequestOptions();
    // requestOptions.headers = headers;
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/orderproducts/", requestOptions)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }
  BulkstatusUpdate(ids: any[], status: any) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();

    let body = {
      order_id: ids,
      status: status
    };
    return this.http.post(this.baseurl + "api/v1/orderupdate/", body, options);
  }

  // Customer (Product Purchaser) Registration
  CustRegistration(username: any, email: any, pwd: any) {
    console.log(username, email, pwd);
    let headers = new Headers();
    // headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();
    // formData.append("order_id", ids);
    // formData.append("status", status);
    let body = {
      username: username,
      email: email,
      password_text: pwd
    };
    //formData.append("order_file", obj);
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/usercreate/", body, options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  // Customer Login (Product Purchaser)

  CustLogin(pwd: any, email: any) {
    console.log(email, pwd);
    let headers = new Headers();
    // headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    let options = new RequestOptions({ headers: headers });
    let formData: FormData = new FormData();
    // formData.append("order_id", ids);
    // formData.append("status", status);
    let body = {
      email: email,
      password_text: pwd
    };
    //formData.append("order_file", obj);
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .post(this.baseurl + "api/v1/customervalidate/", body, options)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  getTags() {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http.get(this.baseurl + "api/v1/tagslist/", options).subscribe(
        data => {
          returnobj = data.json();
          resolve(returnobj);
        },
        error => {
          console.log("error");
          //resolve(returnobj);
        },
        () => {
          // console.log('completed');
        }
      );
    });
    return returnobj;
  }

  addTag(tags: string) {
    console.log(tags);
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      name: tags
    };

    return this.http.post(this.baseurl + "api/v1/tagslist/", body, options);
  }

  //  Add tags to the order id
  postTagsOrder(orderId: any[], tags: any[]) {
    console.log(orderId, tags);
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      order_ids: orderId,
      tag_ids: tags
    };
    //formData.append("order_file", obj);

    return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
  }

  //  Add Archive to the order id
  addArchive(orderId: any[], archives: any) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      archive_order_ids: orderId,
      is_archived: archives
    };

    return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
  }

  // Create Chain

  createChain(obj: any) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      storefront_data: obj
    };

    return this.http.post(
      this.baseurl + "api/v1/multiple_storefront/",
      body,
      options
    );
  }

  //  for filter customer and storefront ids
  get_Custom_SF_Data() {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    var returnobj = new Promise((resolve, reject) => {
      this.http.get(this.baseurl + "api/v1/userstorefront/", options).subscribe(
        data => {
          returnobj = data.json();
          resolve(returnobj);
        },
        error => {
          console.log("error");
          //resolve(returnobj);
        },
        () => {
          // console.log('completed');
        }
      );
    });
    return returnobj;
  }

  OrdersFilters(params: URLSearchParams) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.search = params;
    requestOptions.headers = headers;

    // let headers = new Headers();
    //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
    // let requestOptions = new RequestOptions();
    // requestOptions.headers = headers;
    var returnobj = new Promise((resolve, reject) => {
      this.http
        .get(this.baseurl + "api/v1/orderfilter/", requestOptions)
        .subscribe(
          data => {
            returnobj = data.json();
            resolve(returnobj);
          },
          error => {
            console.log("error1");
            //resolve(returnobj);
          },
          () => {
            // console.log('completed');
          }
        );
    });
    return returnobj;
  }

  orderFilterV2(params: any) {
    // tslint:disable-next-line: deprecation
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    // tslint:disable-next-line: deprecation
    const requestOptions = new RequestOptions();
    requestOptions.params = params;
    requestOptions.headers = headers;

    return this.http.get(this.baseurl + "api/v1/orderfilter/", requestOptions);
  }

  getOrdersReport(obj) {

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token"),
      'Content-Type': "text/plain"
    });

    const body = new HttpParams({ fromObject: obj })


    const httpOptions = {
      headers: headers,
      params: body,
      responseType: 'blob' as 'text'
    };

    console.log(body);


    return this.httpClient.get(this.baseurl + "api/v1/ordercsv/", httpOptions);


  }

  getAllStoreFronts() {

    return this.http.get(this.baseurl + "api/v1/userstorefront/");
  }

  getCommon(url: string) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseurl + url, options);
  }

  getCommonWithNextPage(url: string, pagenumber) {
    let params = new URLSearchParams();
    params.append("page", pagenumber);

    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    options.search = params;
    options.headers = headers;
    return this.http.get(this.baseurl + url, options);
  }

  getSingleCustomer(id: string) {
    let params = new URLSearchParams();
    params.append("cust_id", id);

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.search = params;
    requestOptions.headers = headers;
    return this.http.get(this.baseurl + "api/v1/userlist/", requestOptions);
  }

  getPasscodeprostorefront(passcode: string) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.post(
      this.baseurl + "api/v1/storefront/login/",
      { passcode: passcode },
      requestOptions
    );
  }

  deleteSffromProduct(obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.post(
      this.baseurl + "api/v1/storefront/product/delete/",
      obj,
      requestOptions
    );
  }

  addSftoProduct(obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.post(
      this.baseurl + "api/v1/storefront/product/",
      obj,
      requestOptions
    );
  }

  toggleProductStatus(obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.post(
      this.baseurl + "api/v1/storefront/product/",
      obj,
      requestOptions
    );
  }
  toggleBundleStatus(bundleId, obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    // return true
    return this.http.patch(
      this.baseurl + "api/v1/bundle/" + bundleId + "/",
      obj,
      requestOptions
    );
  }

  toggleProductInStoreStatus(obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.post(
      this.baseurl + "api/v1/storefront/product/active/",
      obj,
      requestOptions
    );
  }

  toggleStoreStatus(store_id: string, obj: any) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.patch(
      this.baseurl + "api/v1/storefront/detail/" + store_id + "/",
      obj,
      requestOptions
    );
  }
  
 
  getSingleProduct(product_id: string) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.get(
      this.baseurl + "api/v1/productv3/" + product_id + "/",
      requestOptions
    );
  }
  getSingleBundle(bundle_id: string) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.get(
      this.baseurl + "api/v1/bundle/" + bundle_id + "/",
      requestOptions
    );
  }

  updateSingleBundle(id,obj){
    console.log(id, obj);

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });

    const formData: FormData = new FormData();
    formData.append('title', obj.title)
    formData.append('code', obj.code)
    formData.append('description', obj.description)
    formData.append('price', obj.price)
    // formData.append('storefront', obj.storefront)
    formData.append('bundle_items', JSON.stringify(obj.bundle_items))

    console.log(typeof obj.preview_image); 
    if(typeof obj.preview_image != 'string'){
      formData.append('preview_image', obj.preview_image)

    }

    
    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.patch(this.baseurl + "api/v1/bundle/" + id + "/", formData, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }

  getSingleStore(store_id: string) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );

    let requestOptions = new RequestOptions();
    requestOptions.headers = headers;
    return this.http.get(
      this.baseurl + "api/v1/storefront/detail/" + store_id + "/",
      requestOptions
    );
  }

  removeTag(tag, order) {
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      order_ids: order,
      tag_ids_remove: tag
    };

    return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
  }

  //Sort Common Function

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      let val1 = parseFloat(a[property])
        ? parseFloat(a[property])
        : parseFloat(a[property]) === 0
          ? 0
          : a[property];
      let val2 = parseFloat(b[property])
        ? parseFloat(b[property])
        : parseFloat(b[property]) === 0
          ? 0
          : b[property];
      var result = val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
      return result * sortOrder;
    };
  }




  //Delete


  deleteCustomer(id: string) {

    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    let body = {
      deleted_v1: true
    };

    return this.http.patch(this.baseurl + "api/v1/userdetail/" + id + "/", body, options);

  }


  deleteStore(id: number) {

    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    let options = new RequestOptions({ headers: headers });
    // let body = {
    //   deleted: true
    // };

    return this.http.delete(this.baseurl + "api/v1/storefront/delete/" + id + "/", options);

  }


  createCoupon(obj) {
    const headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    // tslint:disable-next-line: deprecation
    const options = new RequestOptions({ headers: headers });


    return this.http.post(this.baseurl + "api/v1/coupon/", obj, options);
  }

  updateCoupon(obj, id) {


    const headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    // tslint:disable-next-line: deprecation
    const options = new RequestOptions({ headers: headers });


    return this.http.patch(this.baseurl + `api/v1/coupondetail/${id}/`, obj, options);
  }




  couponNameValidityCheck(coupon_name) {
    // debounce
    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    const options = new RequestOptions({ headers: headers });





    return this.http.get(this.baseurl + "api/v1/couponname/?coupon_name=" + coupon_name, options);

  }


  getCoupons() {

    let headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseurl + 'api/v1/coupon/', options);

  }



  patchCoupon(couponId, obj) {


    // tslint:disable-next-line: deprecation
    const headers = new Headers();
    headers.append(
      "Authorization",
      "JWT" + " " + sessionStorage.getItem("token")
    );
    // tslint:disable-next-line: deprecation
    const options = new RequestOptions({ headers: headers });


    return this.http.patch(this.baseurl + `api/v1/coupondetail/${couponId}/`, obj, options);
  }

  getInventory(prodId) {

    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    let promise = new Promise<m_product_obj>((resolve, reject) => {
      return this.httpClient.get(this.baseurl + `api/v1/productv3/${prodId}/`, { headers: headers }).pipe(
        map((data: m_product_obj) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject(err);
          }
        )
    })

    return promise

  }

  uploadBannerImages(sfId, image: any) {

    let formData: FormData = new FormData();

    formData.append("storefront", sfId);
    formData.append("image", image);



    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.post(this.baseurl + "api/v1/storefront/banner/ ", formData, { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }

  removeBannerImage(id) {
    const headers = new HttpHeaders({
      'Authorization': 'JWT ' + sessionStorage.getItem("token")
    });


    let promise = new Promise<any>((resolve, reject) => {
      return this.httpClient.delete(this.baseurl + "api/v1/storefront/banner/" + id + "/", { headers: headers }).pipe(
        map((data: any) => data),
        catchError(error => {
          return throwError('something went wrong')
        })

      )
        .toPromise().then(
          res => {
            console.log(res);
            resolve(res);
          },
          err => {
            console.log(err);

            reject();
          }
        )
    })

    return promise
  }
  isImage(type: string): boolean {
    return type.startsWith('image/');
  }

  isPDF(type: string): boolean {
    return type === 'application/pdf';
  }

  isImageFromBackend(filePath: string): boolean {
    return filePath.toLowerCase().endsWith('.jpg') ||
      filePath.toLowerCase().endsWith('.jpeg') ||
      filePath.toLowerCase().endsWith('.png');
  }

  isPdfFromBackend(filePath: string): boolean {
    return filePath.toLowerCase().endsWith('.pdf');
  }



}
