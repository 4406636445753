import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms';
import { HttpModule } from '@angular/http';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BrowserXhr } from '@angular/http';
import { NgProgressModule, NgProgressBrowserXhr } from 'ngx-progressbar';
import {
  AuthGuardService as AuthGuard,
  AuthGuardService
} from './auth-guard.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PageComponent } from './page404/page.component';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatTreeModule
} from '@angular/material';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DataService } from './data.service';
import { DatePipe } from '@angular/common';
import { CustomerPortalComponent } from './customer-portal/customer-portal.component';
import { ProductsComponent } from './products/products.component';

import { MultiSfComponent } from './multi-sf/multi-sf.component';
import { MatFormFieldModule } from '@angular/material';
import { ClickOutsideModule } from 'ng4-click-outside';

import { Dashboradv2Component } from './dashborad/dashboardv2.component';

import { AddcustomerComponent } from './addcustomer/addcustomer.component';
import { AddstorefrontComponent } from './addstorefront/addstorefront.component';
import { ProductCatComponent } from './product-cat/product-cat.component';
import { DialogComponent } from './dialog/dialog.component';
import { ViewstorefrontComponent } from './viewstorefront/viewstorefront.component';
import { ProtectedStorefrontPreviewComponent } from './protected-storefront-preview/protected-storefront-preview.component';
import { StoresViewComponent } from './stores-view/stores-view.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { EditStorefrontComponent } from './edit-storefront/edit-storefront.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditBundleComponent } from './edit-bundle/edit-bundle.component';
import { Ordersv2Component } from './ordersv2/ordersv2.component';
import { LearnComponent } from './learn/learn.component';
import { from } from 'rxjs/observable/from';
import { CustomerStoreViewComponent } from './customer-store-view/customer-store-view.component';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MultipleProdctsComponent, CopyUrl } from './multiple-prodcts/multiple-prodcts.component';
import {PizzaPartyComponent} from './multiple-prodcts/multiple-prodcts.component';
import { UsersettingComponent } from './usersetting/usersetting.component';

// pipes

import { ProductNamePipe } from './product-name.pipe';
import { ProductId } from './product-name.pipe';
import { UtilityComponent } from './utility/utility.component';
import { ProductNewComponent } from './product-new/product-new.component';
import { ProductNewEditComponent } from './product-new-edit/product-new-edit.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryCardComponent } from './inventory/inventory-card/inventory-card.component';
import { InventoryTableComponent } from './inventory-table/inventory-table.component';
import {NgxImageCompressService} from 'ngx-image-compress';
import { DraftComponent } from './draft/draft.component';
import { BundlesComponent } from './bundles/bundles.component';
import { AddbundleComponent } from './addbundle/addbundle.component';



@NgModule({
  declarations: [
    AppComponent,
    Dashboradv2Component,
    LoginComponent,
    CustomerPortalComponent,
    ProductsComponent,
    MultiSfComponent,
    AddcustomerComponent,
    AddstorefrontComponent,
    ProductCatComponent,
    DialogComponent,
    ViewstorefrontComponent,
    ProtectedStorefrontPreviewComponent,
    ProductNamePipe,
    ProductId,
    StoresViewComponent,
    PaginatorComponent,
    EditCustomerComponent,
    EditStorefrontComponent,
    EditProductComponent,
    EditBundleComponent,
    Ordersv2Component,
    LearnComponent,
    PageComponent,
    BreadcrumbComponent,
    CustomerStoreViewComponent,
    MultipleProdctsComponent,
    PizzaPartyComponent,
    CopyUrl,

    UsersettingComponent,

    UtilityComponent,

    ProductNewComponent,

    ProductNewEditComponent,

    InventoryComponent,

    InventoryCardComponent,

    InventoryTableComponent,

    DraftComponent,

    BundlesComponent,

    AddbundleComponent
  ],

  entryComponents: [DialogComponent, PizzaPartyComponent, CopyUrl],
  imports: [
    ClickOutsideModule,
    Ng2SearchPipeModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatStepperModule,
    RouterModule,
    ReactiveFormsModule,
    NgProgressModule,
    FormsModule,
    HttpModule,
    MatFormFieldModule,
    HttpClientModule,
    
  ],
  providers: [
    DataService,
    DatePipe,
    AuthGuardService,
    CustomerPortalComponent,
    NgxImageCompressService,
    { provide: BrowserXhr, useClass: NgProgressBrowserXhr }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
