/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-new.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./product-new.component";
import * as i3 from "@angular/forms";
var styles_ProductNewComponent = [i0.styles];
var RenderType_ProductNewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductNewComponent, data: {} });
export { RenderType_ProductNewComponent as RenderType_ProductNewComponent };
export function View_ProductNewComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ProductNewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-new", [], null, null, null, View_ProductNewComponent_0, RenderType_ProductNewComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProductNewComponent, [i3.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductNewComponentNgFactory = i1.ɵccf("app-product-new", i2.ProductNewComponent, View_ProductNewComponent_Host_0, {}, {}, []);
export { ProductNewComponentNgFactory as ProductNewComponentNgFactory };
