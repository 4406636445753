import { Component, OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()

@Component({
  selector: 'app-customer-portal',
  templateUrl: './customer-portal.component.html',
  styleUrls: ['./customer-portal.component.css']
})
export class CustomerPortalComponent implements OnInit {

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  constructor() { }

  excelDataArray = [];

  createExcel(order) {

    this.excelDataArray = [];

    order.forEach(element => {





      element.purchase_set.forEach(x => {


        this.addToexcelArray(element, x);



      });



    });



    console.log(this.excelDataArray);


    this.exportAsExcelFile(this.excelDataArray, "Reports");
  }



  addToexcelArray(element, x) {


    let obj = {};

    let billing_address = '';

    if (element.purchase_user.is_shipping_address) {

      billing_address = element.purchase_user.address_s + ", " + element.purchase_user.address_country_s + ", " + element.purchase_user.address_city_s + ", " + element.purchase_user.address_state_s + ", " + element.purchase_user.postal_code_s;
    }
    else{
      billing_address = '';
    }

    obj = {

      ORDER_ID: element.id,
      CREATED_AT: element.created_v1,
      PURCHASER_NAME: element.purchase_user.first_name + " " + element.purchase_user.last_name,
      PURCHASER_PERSONALIZED_NAME: element.purchase_user.personalized_name,
      PURCHASER_EMAIL: element.purchase_user.email,
      PURCHASER_NUMBER: element.purchase_user.phone_number,
      PURCHASER_SHIPPINGPHONE_NUMBER: element.purchase_user.phone_number,
      PURCHASER_COMPANY_NAME: element.purchase_user.company_name,
      PLAYER_FIRST_NAME : element.purchase_user.player_first_name,
      PLAYER_LAST_NAME : element.purchase_user.player_last_name,
      CLASS_NAME: element.purchase_user.class_name,
      TEAM_NAME: element.purchase_user.team_name,
      CLUB_NAME: element.purchase_user.club_name,
      PURCHASER_SHIPPING_ADDRESS: element.purchase_user.address + ", " + element.purchase_user.address_country + ", " + element.purchase_user.address_city + ", " + element.purchase_user.address_state + ", " + element.purchase_user.postal_code,
      PURCHASER_PROPERTY:element.purchase_user.property_name,
      PURCHASER_BILLING_ADDRESS: billing_address,
      STORE_FRONT_OWNER: element.storefront_owner,
      STORE_FRONT_NAME: element.storefront,
      BUNDLE_TITLE: x.bundle_title,
      BUNDLE_CODE: x.bundle_code,
      PRODUCT_TITLE: x.title,
      PRODUCT_CODE: x.code,
      PRODUCT_SIZE: x.size,
      PRODUCT_COLOR: x.color,
      PRODUCT_STYLE: x.tag,
      PRODUCT_CATEGORY: x.category_name,
      PRODUCT_QUANTITY: x.quantity,
      PRODUCT_UNIT_PRICE: x.amount / x.quantity,
      SHIPPING_INSTRUCTIONS: element.purchase_user.shipping_instructions,
      PO: element.purchase_user.post_order,
      ORDER_STATUS: element.order_tracking,
      PAYMENT_STATUS: element.payment_status,

    };

    console.log(obj);

    this.excelDataArray.push(obj);

  }



  createStampsExcel(order) {
    this.excelDataArray = [];
    order.forEach(element => {

        this.addToexcelArrayStamps(element);

    });
    console.log(this.excelDataArray);
    this.exportAsExcelFile(this.excelDataArray, "Stamps Data");
  }

  addToexcelArrayStamps(element){


    // var custdetails = '';
    // if(element.purchase_user.is_shipping_address){



    // custdetails = element.purchase_user.name + "\n" + element.purchase_user.address_s + " " +  element.purchase_user.address_city_s+"," + " " + element.purchase_user.address_state_s + "," + " " +element.purchase_user.postal_code_s + "\n"
    // + element.purchase_user.address_country_s + "\n"
    // + element.purchase_user.email + "\n";
    // }else{
    const  custdetails = element.purchase_user.name + "\n" + element.purchase_user.address+ " " +  element.purchase_user.address_city + "," + " " + element.purchase_user.address_state + "," + " " +element.purchase_user.postal_code + "\n"
      + element.purchase_user.address_country + "\n"
      + element.purchase_user.email + "\n";
   // }

    this.excelDataArray.push({ "SHIIPING_DATA":custdetails,"ORDER_ID":element.id});

  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
