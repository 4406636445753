import { OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
var ProtectedStorefrontPreviewComponent = /** @class */ (function () {
    function ProtectedStorefrontPreviewComponent(dataservice, router) {
        this.dataservice = dataservice;
        this.router = router;
        this.selected_storefronts = [];
        this.selected_storefronts = this.dataservice.protected_selected_storefronts;
        if (this.selected_storefronts.length == 0) {
            this.router.navigateByUrl('/login/customer');
        }
        else {
            this.singlesfobj = this.selected_storefronts[0];
            console.log(this.selected_storefronts);
        }
    }
    ProtectedStorefrontPreviewComponent.prototype.ngOnInit = function () {
    };
    // Prveiew page
    ProtectedStorefrontPreviewComponent.prototype.preview = function (i) {
        console.log("yo");
        this.selected_storefronts[i].slug;
        this.router.navigateByUrl('/' + this.selected_storefronts[i].slug + '');
    };
    return ProtectedStorefrontPreviewComponent;
}());
export { ProtectedStorefrontPreviewComponent };
