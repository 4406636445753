import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";

@Component({
  selector: "app-usersetting",
  templateUrl: "./usersetting.component.html",
  styleUrls: ["./usersetting.component.css"]
})
export class UsersettingComponent implements OnInit {
  constructor(private dataobj: DataService) {}

  ngOnInit() {
    this.dataobj.getUserDetail().subscribe(
      data => {
        console.log(data.json());
      },
      error => {
        console.log(error);
      }
    );
  }
}
