<div class="main">
  <mat-toolbar style="justify-content: space-between;">
    <h3 class="page-heading">Products</h3>
    <!-- <app-paginator [url]="url" (data)="onData($event)"></app-paginator> -->

    <!-- This fills the remaining space of the current row -->
    <!-- <span class="example-fill-remaining-space"></span> -->

    <div>
      <input class="input-style" placeholder="Search" [(ngModel)]="searchText" value=""
        (keyup)="sortDataList($event.target.value)" />
      <mat-form-field style="margin-right: 20px">
        <mat-label style="font-size: 16px">Sort By</mat-label>
        <mat-select [(ngModel)]="selectedSort" style="font-size: 16px">
          <mat-option *ngFor="let item of sortParams" [value]="item.value" (click)="sortDataDropDown()">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup"
        [value]="selectedVal" (change)="onValChange(group.value)">
        <mat-button-toggle value="grid">
          <mat-icon class="example-icon">view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
          <mat-icon class="example-icon">view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </mat-toolbar>
  <div class="mess">
    <!-- start grid -->

    <div class="cards-div" *ngIf="selectedVal === 'grid'">
      <div class="product-cat-grid">
        <div *ngFor="
            let item of (product_collection | productName: searchText);
            let i = index
          ">

          <mat-card class="product-card">
            <mat-card-header>

              <mat-icon class="edit-btn icon-black" (click)="editProd(item)" matTooltip="Edit"
                [matTooltipPosition]="'above'">
                edit
              </mat-icon>


              <mat-card-title class="titles font-fmly">{{
                item.title
                }}</mat-card-title>

              <mat-card-subtitle>{{ item.category_name }}</mat-card-subtitle>
            </mat-card-header>
            <div class="image-tag" style="background-size: cover; border-radius: 6px;background-size: contain;"
              [style.background-image]="getImage2(item.image)"></div>
            <mat-card-content> 

              <div class="details">
                <span class="detail-title">Description : </span>
                <span>{{ item.description }}</span>
              </div>

              <!-- StoreFront -->
              <span *ngIf="item.storefront_product.length > 0" class="detail-title">
                StoreFronts :
              </span>
              <!-- [ngStyle]="itemx.status == 'active'? {} : {'background-color':'#999999','opacity':'0.5'}" -->

              <div class="chipss">
                <mat-chip-list #chipList id="style-3">
                  <mat-chip *ngFor="let itemx of item.storefront_product"
                    (removed)="removeSf(itemx.name, itemx.id, item.title, item.id ,itemx.user_id)"
                    style="flex: none;align-self: center;">
                    {{ itemx.name }}
                    <button style="background-color: transparent;border:none;display: flex;
                justify-content: center;" (click)="previewStore(itemx,item,$event)">
                      <mat-icon style="width: 20px; height: 20px;font-size: 20px; margin: 0 5px;"
                        class="product-card-table-i product-card-table-delete-i" matTooltip="Preview store"
                        [matTooltipPosition]="'above'">
                        store
                      </mat-icon>
                    </button>
                    <div [ngClass]="itemx.is_active? 'activeInStore' : 'inActiveInStore'"
                      [matTooltip]="itemx.is_active ? 'Available in store' : 'Unavailable in store'"
                      [matTooltipPosition]="'above'"></div>

                    <mat-icon class="icon-black" (click)="goToStorefront(itemx.id)" matTooltip="Edit"
                      [matTooltipPosition]="'above'">
                      edit
                    </mat-icon>

                    <mat-icon matTooltip="Remove product from store" [matTooltipPosition]="'above'" matChipRemove>cancel
                    </mat-icon>


                  </mat-chip>
                </mat-chip-list>
              </div>

              <p class="details">
                <span class="detail-title">Color:</span> {{ item.color }}
              </p>
              <p class="details">
                <span class="detail-title">Size:</span> {{ item.size }}
              </p>
              <p class="details">
                <span class="detail-title">Price:</span> ${{ item.price }}
              </p>
              <p class="details">
                <span class="detail-title">Featured: </span>
                <mat-checkbox [checked]="item.is_featured" (change)="onChange($event, i, item)"></mat-checkbox>
              </p>
              <h5 style="display: flex;justify-content: start;align-items: center; gap: 5px;cursor: pointer;"
                (click)="showInventory(item.id)">
                <mat-icon>inventory</mat-icon>
                <p style="margin: 0;">Check Inventory</p>
              </h5>



              <p class="price"></p>
            </mat-card-content>
            <mat-card-actions class="text-center flex space-evenly align-items" style="height: 63px;">

              <div (click)="openAddDialog(item)" matTooltip="Add To Storefronts" [matTooltipPosition]="'above'"
                class="product-card-grid-icon">
                <mat-icon class="product-card-grid-icon-store">
                  store
                </mat-icon>
                <mat-icon class="product-card-grid-icon-plus">
                  exposure_plus_1
                </mat-icon>
              </div>
              <div>

                <p class="details" *ngIf="item.active">Status: Active</p>
                <p class="details" *ngIf="!item.active">Status: Inactive</p>


                <mat-slide-toggle color="primary" [(ngModel)]="item.active" (change)="onToggle($event,item.id)">
                </mat-slide-toggle>

                <!-- <mat-icon
                  mat-raised-button
                  *ngIf="item.active"
                  (click)="openArchiveDialog(item.id, false)"
                  class="actives"
                  matTooltip="Deactivate"
                  [matTooltipPosition]="'above'"
                >
                  block
                </mat-icon>
                <mat-icon
                  *ngIf="!item.active"
                  (click)="toggleProductstaus(item.id, true)"
                  matTooltip="Activate"
                  [matTooltipPosition]="'above'"
                  class="product-card-grid-icon-activate"
                >
                  check_circle
                </mat-icon> -->

              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <!-- End grid -->

    <div class="" *ngIf="selectedVal === 'list'">
      <table class="table-list table-striped " matSort (matSortChange)="sortData($event)">
        <thead>
          <tr class="row-header font-fmly">
            <th class="" mat-sort-header="id"><b>Id</b></th>
            <th class="" mat-sort-header="image"><b>Image</b></th>
            <th class="" mat-sort-header="title">Title</th>
            <th class="" mat-sort-header="category_name">Category</th>
            <th class="" mat-sort-header="code">Code</th>
            <th class="">Color</th>

            <th class="">Size</th>
            <th class="" mat-sort-header="price">Price</th>
            <th class="" mat-sort-header="is_featured">Featured</th>

            <th class="" mat-sort-header="active">Status</th>
            <th class="" mat-sort-header="sfs">Storefronts</th>
            <th class="" mat-sort-header="asfs">Add Storefronts</th>
            <th class="" mat-sort-header="dsf">Delete</th>

            <th></th>
            <th></th>
          </tr>
        </thead>
        <tr *ngFor="let item of (product_collection | productName: searchText);let i = index" style="height: 80px;">




          <td (click)="editProd(item)">{{ item.id }}</td>

          <td (click)="editProd(item)">
            <img  style="height: 50px; width: 50px;object-fit: cover;border-radius: 50%;"
              src="{{item.image}}"
              onError="this.src='assets/images/deaault_store_img.png';" alt="">
            <!-- <img *ngIf="item.productinventory_set[0].productinventoryimage_set.length === 0" style="height: 50px; width: 50px;object-fit: cover;border-radius: 50%;"
            src="assets/images/deaault_store_img.png"
              onError="this.src='assets/images/deaault_store_img.png';" alt=""> -->
          </td>

          <!-- <td (click)="editProd(item)" *ngIf="item.productinventory_set.length === 0">
            <img style="height: 50px; width: 50px;object-fit: cover;border-radius: 50%;"
              src="assets/images/deaault_store_img.png" alt="">
          </td> -->

          <td matTooltip="Edit {{item.title}}" [matTooltipPosition]="'above'" style="cursor: pointer;"
            (click)="editProd(item)">{{ item.title }}</td>

          <td (click)="editProd(item)">{{ item.category_name }}</td>
          <td (click)="editProd(item)">{{ item.code }}</td>
          <td (click)="colorDialogue(item.color)">{{ item.color }}</td>

          <td [matTooltip]="item.size.length > 6 ? 'Click to see size list' :''" [matTooltipPosition]="'above'" (click)="sizeDialogue(item.size)">{{ (item.size.length>10)? (item.size |
            slice:0:20)+'..':(item.size) }}
            <!-- <mat-icon (click)="sizeDialogue(item.size)" style="margin: 0 5px;
            font-size: 20px;
            color: #8c8c8c !important;position: absolute;top: 10px;right: 0;">info</mat-icon> -->
          </td>
          <td (click)="editProd(item)">{{ item.price }}</td>
          <td>
            <mat-checkbox [checked]="item.is_featured" (change)="onChange($event, i, item)"></mat-checkbox>
          </td>

          <td>
            <mat-slide-toggle [matTooltip]="item.active ? 'Inactive' : 'Active'" color="primary"
              [(ngModel)]="item.active" (change)="onToggle($event,item.id)">
            </mat-slide-toggle>
          </td>
          <!-- <td *ngIf="!item.active">
            <mat-slide-toggle color="primary" [(ngModel)]="item.active" (change)="onToggle($event,item.id)">
            </mat-slide-toggle>
          </td> -->

          <td style="max-width: 200px;">
            <mat-chip-list #chipList id="style-3">
              <mat-chip style="flex: none;align-self: center;cursor: pointer;" [matTooltip]="itemx.user"
                [matTooltipPosition]="'above'" *ngFor="let itemx of item.storefront_product"
                (removed)="removeSf(itemx.name, itemx.id, item.title, item.id)">
                {{ itemx.name }}

                <button style="background-color: transparent;border:none;display: flex;
                justify-content: center;" (click)="previewStore(itemx,item,$event)">
                  <mat-icon style="width: 20px;
                  height: 20px;
                  font-size: 20px;
                  margin: 0 5px;" class="product-card-table-i product-card-table-delete-i" matTooltip="Preview store"
                    [matTooltipPosition]="'above'">
                    store
                  </mat-icon>
                </button>
                <div [ngClass]="itemx.is_active? 'activeInStore' : 'inActiveInStore'"
                  [matTooltip]="itemx.is_active ? 'Available in store' : 'Unavailable in store'"
                  [matTooltipPosition]="'above'"></div>

                <mat-icon class="icon-black" (click)="goToStorefront(itemx.id)" matTooltip="Edit"
                  [matTooltipPosition]="'above'">
                  edit
                </mat-icon>

                <mat-icon matTooltip="Remove product from store" [matTooltipPosition]="'above'" matChipRemove>cancel
                </mat-icon>
              </mat-chip>
            </mat-chip-list>
          </td>

          <td>

            <div style="justify-content: space-evenly;
          display: flex; align-items: center">
              <div style="height: 35px;" (click)="openAddDialog(item)" matTooltip="Add To Storefronts"
                [matTooltipPosition]="'above'" class="product-card-table-icon">
                <mat-icon class="product-card-table-icon-store">
                  store
                </mat-icon>
                <mat-icon class="product-card-table-icon-plus">
                  exposure_plus_1
                </mat-icon>
              </div>


              <!-- <div style="height: 35px;">
                <mat-icon
                  mat-raised-button
                  *ngIf="item.active"
                  (click)="openArchiveDialog(item.id, false)"
                  class="actives"
                  matTooltip="Deactivate"
                  [matTooltipPosition]="'above'"
                >
                  block
                </mat-icon>
                <mat-icon
                  *ngIf="!item.active"
                  (click)="toggleProductstaus(item.id, true)"
                  matTooltip="Activate"
                  [matTooltipPosition]="'above'"
                  class="product-card-grid-icon-activate"
                >
                  check_circle
                </mat-icon>
              </div> -->
            </div>
          </td>

          <td>
            <!-- <button  (click)="deleteProduct($event,item.id)"  mat-raised-button  aria-label="Example icon button with a delete icon">
              <mat-icon>delete</mat-icon>
            </button> -->
            <mat-icon (click)="deleteProduct($event,item.id,i)" class="product-card-table-i product-card-table-delete-i"
              matTooltip="Delete" [matTooltipPosition]="'above'">
              delete
            </mat-icon>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-sm-60 text-center paginat">
      <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
    </div>

    <button mat-fab color="primary" class="btn-cust" routerLink="/addproducts" matTooltip="Add Product"
      [matTooltipPosition]="'left'">
      <mat-icon class="icons">add</mat-icon>
    </button>
  </div>
</div>