var product_obj = /** @class */ (function () {
    function product_obj() {
        this.product_background_color = '#f4f4f4';
        this.product_text_color = '#161616';
        this.colorArray = [];
        this.sizeArray = [];
        this.typeArray = [];
        this.specialTags = [];
        this.size_chart = [];
    }
    return product_obj;
}());
export { product_obj };
export var Sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
var customer_obj = /** @class */ (function () {
    function customer_obj() {
    }
    return customer_obj;
}());
export { customer_obj };
var storefront_obj = /** @class */ (function () {
    function storefront_obj() {
    }
    return storefront_obj;
}());
export { storefront_obj };
var tags = /** @class */ (function () {
    function tags() {
    }
    return tags;
}());
export { tags };
var purchase_user = /** @class */ (function () {
    function purchase_user() {
    }
    return purchase_user;
}());
export { purchase_user };
var purchase_set_obj = /** @class */ (function () {
    function purchase_set_obj() {
    }
    return purchase_set_obj;
}());
export { purchase_set_obj };
var order_obj = /** @class */ (function () {
    function order_obj() {
    }
    return order_obj;
}());
export { order_obj };
var product_image_obj = /** @class */ (function () {
    function product_image_obj() {
    }
    return product_image_obj;
}());
export { product_image_obj };
var m_bundle_obj = /** @class */ (function () {
    function m_bundle_obj() {
        this.product_background_color = '#f4f4f4';
        this.product_text_color = '#161616';
    }
    return m_bundle_obj;
}());
export { m_bundle_obj };
var m_product_obj = /** @class */ (function () {
    function m_product_obj() {
        this.product_background_color = '#f4f4f4';
        this.product_text_color = '#161616';
        this.tags = [];
        this.special_tags = [];
    }
    return m_product_obj;
}());
export { m_product_obj };
var p_sf = /** @class */ (function () {
    function p_sf() {
    }
    return p_sf;
}());
export { p_sf };
var userstorefront_obj = /** @class */ (function () {
    function userstorefront_obj() {
    }
    return userstorefront_obj;
}());
export { userstorefront_obj };
var StoreFrontBanner = /** @class */ (function () {
    function StoreFrontBanner() {
    }
    return StoreFrontBanner;
}());
export { StoreFrontBanner };
var m_customers_obj = /** @class */ (function () {
    function m_customers_obj() {
    }
    return m_customers_obj;
}());
export { m_customers_obj };
var order_status = /** @class */ (function () {
    function order_status() {
    }
    return order_status;
}());
export { order_status };
// tslint:disable-next-line: class-name
var searchparams = /** @class */ (function () {
    function searchparams() {
    }
    return searchparams;
}());
export { searchparams };
var quickdate = /** @class */ (function () {
    function quickdate() {
        this.id = 0;
        this.key = '';
        this.value = '';
        this.viewvalue = '';
    }
    return quickdate;
}());
export { quickdate };
var inmemoryCell = /** @class */ (function () {
    function inmemoryCell() {
        this.multicollectionarry = [];
    }
    return inmemoryCell;
}());
export { inmemoryCell };
//order
var OrderUnit = /** @class */ (function () {
    function OrderUnit() {
    }
    return OrderUnit;
}());
export { OrderUnit };
var Scq = /** @class */ (function () {
    function Scq() {
    }
    return Scq;
}());
export { Scq };
var Coupon = /** @class */ (function () {
    function Coupon() {
    }
    return Coupon;
}());
export { Coupon };
var product_obj_new = /** @class */ (function () {
    function product_obj_new() {
    }
    return product_obj_new;
}());
export { product_obj_new };
