import { OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { m_customers_obj, userstorefront_obj } from '../models';
import { FormControl, Validators } from '@angular/forms';
var storefront_collection = /** @class */ (function () {
    function storefront_collection() {
    }
    return storefront_collection;
}());
export { storefront_collection };
var MultiSfComponent = /** @class */ (function () {
    function MultiSfComponent(dataservice, route, dialog, router, _location) {
        this.dataservice = dataservice;
        this.route = route;
        this.dialog = dialog;
        this.router = router;
        this._location = _location;
        this.responseStoreFronts = [];
        this.isPasscode = false;
        // chain_name: string = '';
        this.chain_name = new FormControl('', [
            Validators.required,
            Validators.maxLength(25)
        ]);
        this.storefront_count = new FormControl('', [
            Validators.required,
            Validators.max(10),
            Validators.min(2)
        ]);
        this.passcode = new FormControl('', [Validators.required]);
        // storefront_count: any;
        this.SFcolor = '#3f51b5';
        this.SFtitlecolor = '#ffffff';
        this.status = 'active';
        this.onlinePay = true;
        this.net30Pay = false;
        this.UpdateBtn = true;
        this.MultiForm = false;
        this.arra1 = [];
        this.loc = [];
        this.locations = [];
        this.template_value = 0;
        this.arryNet30 = [];
        this.arryOnline = [];
        this.description = '';
        this.cust_id = '';
        this.customername = '';
        this.colletionObj = [];
    }
    MultiSfComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataservice.showHide(true);
        this.UpdateBtn = false;
        if (!this.dataservice.selected_customer) {
            //  this.route.navigateByUrl('/customers');
            this.route.params.subscribe(function (params) {
                _this.cust_id = params['cust_id'];
                _this.getSingleCustomer(_this.cust_id);
            });
        }
        else {
            // this.showForm = true;
            this.cust_id = this.dataservice.selected_customer.id;
            this.customername = this.dataservice.selected_customer.name;
        }
        this.storefront_count.valueChanges.subscribe(function (term) {
            _this.radioValueChange();
        });
    };
    MultiSfComponent.prototype.getSingleCustomer = function (id) {
        var _this = this;
        this.dataservice.getSingleCustomer(id).subscribe(function (data) {
            console.log(data.json());
            var result = data.json();
            var selectedCustomer = result['results'][0];
            _this.dataservice.setSelectedCustomer(selectedCustomer);
            _this.cust_id = selectedCustomer.id;
            _this.customername = selectedCustomer.name;
            console.log(_this.dataservice.getSelectedCustomer());
            // this.showForm = true
        }, function (err) {
            console.log(err);
        });
    };
    MultiSfComponent.prototype.cancel = function () {
        this._location.back();
    };
    MultiSfComponent.prototype.radioValueChange = function () {
        this.colletionObj = [];
        for (var index = 0; index < this.storefront_count.value; index++) {
            var obj = new storefront_collection();
            obj.title_color = '#3f51b5';
            obj.banner_color_code = '#ffffff';
            obj.net30 = false;
            obj.online_payment = false;
            obj.location = '';
            obj.name = '';
            obj.status = 'active';
            obj.passcode = '';
            obj.description = '';
            this.colletionObj.push(obj);
        }
        console.log(this.colletionObj);
    };
    MultiSfComponent.prototype.create_chain = function () {
        var _this = this;
        if (this.storefront_count.invalid || this.chain_name.invalid) {
            this.storefront_count.markAsTouched();
            this.chain_name.markAsTouched();
            return;
        }
        if (this.isPasscode) {
            if (this.passcode.invalid) {
                this.passcode.markAsTouched();
                return;
            }
        }
        this.multiBoolean = 0;
        if (this.template_value === 2) {
            for (var index = 0; index < this.colletionObj.length; index++) {
                this.colletionObj[index].name =
                    this.chain_name.value + ' ' + this.colletionObj[index].location;
                this.colletionObj[index].passcode = this.passcode.value;
            }
        }
        else {
            for (var index = 0; index < this.colletionObj.length; index++) {
                this.colletionObj[index].name =
                    this.chain_name.value + ' ' + this.colletionObj[index].location;
                this.colletionObj[index].banner_color_code = this.SFcolor;
                this.colletionObj[index].title_color = this.SFtitlecolor;
                this.colletionObj[index].net30 = this.net30Pay;
                this.colletionObj[index].online_payment = this.onlinePay;
                this.colletionObj[index].passcode = this.passcode.value;
                this.colletionObj[index].description = this.description;
            }
        }
        console.log(this.colletionObj);
        var chainObj = [
            {
                user_id: this.cust_id,
                chain_name: this.chain_name.value,
                storefront_count: this.storefront_count.value,
                storefronts: this.colletionObj
            }
        ];
        if (this.checkforAddress(this.colletionObj)) {
            console.log('request no');
            return;
        }
        console.log('request yes');
        this.dataservice.createChain(chainObj).subscribe(function (res) {
            console.log(res.json());
            _this.responseStoreFronts = res.json();
            _this.responseStoreFronts.forEach(function (element) {
                _this.dataservice.appendStoreObjInCustomer(element);
            });
            _this.arra1 = [];
            // alert('Chain Created');
            _this.dataservice.customer_list = [];
            _this.openDialog();
            // this.customer.UpdateSfList();
            // this.cancel();
        });
    };
    MultiSfComponent.prototype.add_location = function () {
        this.loc = new Array(this.storefront_count.value);
        console.log(this.loc, 'jhg');
    };
    MultiSfComponent.prototype.checkforAddress = function (colletionObj) {
        var obj = false;
        colletionObj.forEach(function (element) {
            if (element.location.length === 0) {
                obj = true;
                return;
            }
        });
        if (obj) {
            this.openErrorDialog();
        }
        return obj;
    };
    // // Next Step click process (add product)
    // //  Save chain obj
    // nextStep()
    // { this.customer.setMultiProduct();
    //   this.customer.productShow();
    //   if (this.template_value == 2) {
    //     for (let index = 0; index < this.colletionObj.length; index++) {
    //       this.colletionObj[index].name = this.chain_name + " " + this.colletionObj[index].location;
    //     }
    //   }
    //   else {
    //     for (let index = 0; index < this.colletionObj.length; index++) {
    //       this.colletionObj[index].name = this.chain_name + " " + this.colletionObj[index].location;
    //       this.colletionObj[index].banner_color_code = this.SFcolor
    //       this.colletionObj[index].title_color = this.SFtitlecolor
    //       this.colletionObj[index].net30 = this.net30Pay;
    //       this.colletionObj[index].online_payment = this.onlinePay;
    //     }
    //   }
    //   console.log(this.colletionObj);
    //   let chainObj = [{
    //     "user_id": this.custId,
    //     "chain_name": this.chain_name,
    //     "storefront_count": this.storefront_count,
    //     "storefronts": this.colletionObj,
    //   }]
    //  // this.objs = chainObj;
    //  this.dataservice.getMultiSF_Obj(chainObj,this.multiBoolean);
    //  this.cancel();
    // }
    // // Cancel form
    // cancel() {
    //   // this.customer.showMultipleForm = 1;
    //   // this.customer.multiBtn = 1;
    // }
    // File CSV read
    MultiSfComponent.prototype.onFileLoad = function (fileLoadedEvent) {
        var textFromFileLoaded = fileLoadedEvent.target.result;
        this.csvContent = textFromFileLoaded;
        console.log(JSON.parse(this.csvContent));
    };
    MultiSfComponent.prototype.onFileSelect = function (input) {
        var files = input.files;
        var content = this.csvContent;
        if (files && files.length) {
            /*
             console.log("Filename: " + files[0].name);
             console.log("Type: " + files[0].type);
             console.log("Size: " + files[0].size + " bytes");
             */
            var fileToRead = files[0];
            var fileReader = new FileReader();
            fileReader.onload = this.onFileLoad;
            fileReader.readAsText(fileToRead, 'UTF-8');
        }
    };
    MultiSfComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Success',
                content: 'Storefront Group ' + this.chain_name.value + ' has been created.',
                type: 'addproduct'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result == 'no') {
                console.log('The dialog was closed', result);
                _this.router.navigateByUrl('/');
            }
            if (result == 'yes') {
                _this.dataservice.selected_storefronts = _this.responseStoreFronts;
                _this.router.navigateByUrl('/addproducts');
            }
        });
    };
    MultiSfComponent.prototype.openErrorDialog = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Error',
                content: 'Addresses of stores cannot be empty.',
                type: 'confirmation'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    MultiSfComponent.prototype.returnRandomString = function (length) {
        var passcode = Math.random()
            .toString(36)
            .substr(2, length);
        if (this.isPasscode) {
            this.passcode.setValue(passcode);
        }
        else {
            this.passcode.setValue('');
        }
    };
    return MultiSfComponent;
}());
export { MultiSfComponent };
