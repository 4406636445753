<div class="main" *ngIf="showForm">
  <app-breadcrumb></app-breadcrumb>

  <mat-card class="cards">
    <br />
    <mat-toolbar class="subtitle font-fmly">Customer Details
    </mat-toolbar>
    <div style="margin: 20px 0;" *ngIf="customer_obj.user_storefront.length > 0">
      <mat-form-field appearance="fill">
        <mat-label>Go To Storefront</mat-label>
        <mat-select [(ngModel)]="selectedStorefront" (selectionChange)="sfSelected($event.value)">
          <mat-option *ngFor="let sf of customer_obj.user_storefront" [value]="sf.id">
            {{sf.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Customer Name" [(ngModel)]="customer_obj.name" required />
    </mat-form-field>
    <br />
    <br />
    <div style="display: flex;">
      <mat-form-field class="example-full-width widths" style="display: block;">
        <mat-select placeholder="Customer Type" [(ngModel)]="customer_obj.user_type">
          <mat-option *ngFor="let type of types" [value]="type.value" required>
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button (click)="addClubRow()"  mat-mini-fab color="primary" style="margin: 0 10px;"
        matTooltip="Add Location" [matTooltipPosition]="'above'">
        <mat-icon class="icons">add</mat-icon>
      </button>
    </div>

    <!-- Enter Location -->
    <div class="" >
      <div *ngFor="let item of teams; let i = index;trackBy: customTrackBy">
        <mat-form-field class="example-full-width widths">
          <input matInput placeholder="Enter Location" [(ngModel)]="teams[i]" />
        </mat-form-field>
        <button  (click)="removeClubRow(i)" mat-mini-fab color="warn" style="margin: 0 10px;"
            matTooltip="Add Location" [matTooltipPosition]="'above'">
            <mat-icon class="icons">delete</mat-icon>
          </button>
      </div>


      <!-- <mat-form-field >
            <input matInput placeholder="Enter Location 1"  [(ngModel)]="teams[0]" />
          </mat-form-field><br>
          <mat-form-field >
            <input matInput placeholder="Enter Location 2"  [(ngModel)]="teams[1]" />
          </mat-form-field><br>
          <mat-form-field >
            <input matInput placeholder="Enter Location 3"  [(ngModel)]="teams[2]" />
          </mat-form-field><br>
          <mat-form-field >
            <input matInput placeholder="Enter Location 4"  [(ngModel)]="teams[3]" />
          </mat-form-field><br>
          <mat-form-field >
            <input matInput placeholder="Enter Location 5"  [(ngModel)]="teams[4]" />
          </mat-form-field> -->


      <br />
      <br />
    </div>
    <!--  -->
    <!--  -->
    <mat-form-field class="example-full-width" style="width:250px;">
      <span matPrefix>+1 &nbsp;</span>
      <input type="tel" matInput placeholder="Telephone" [(ngModel)]="customer_obj.phone_number" />
    </mat-form-field>
    <table class="example-full-width " cellspacing="0">
      <tr>
        <td>
          <mat-form-field>
            <input matInput placeholder="Customer Logo" value="" [(ngModel)]="customer_obj.logo" disabled />
          </mat-form-field>
        </td>
        <td>
          <label color="primary" for="file-upload" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i> Browse..</label>
          <input id="file-upload" type="file" (change)="fileupload($event)" />
        </td>
      </tr>
    </table>

    <br />
    <!---->
    <mat-toolbar class="subtitle font-fmly">Customer Address</mat-toolbar>
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Street Address" [(ngModel)]="customer_obj.address_street" />
    </mat-form-field>
    <table class="example-full-width" cellspacing="0">
      <tr>
        <td>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="City" [(ngModel)]="customer_obj.address_city" />
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width">
            <mat-select placeholder="Select State" [(ngModel)]="customer_obj.address_state">
              <mat-option [value]="item.value" *ngFor="let item of states">
                {{ item.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Zip Code" value="" [(ngModel)]="customer_obj.postal_code" />
          </mat-form-field>
        </td>
      </tr>
    </table>

    <br />
    <br />

    <!---->
    <mat-toolbar class="subtitle font-fmly">Customer Contact</mat-toolbar>
    <br />
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Contact Name" [(ngModel)]="customer_obj.contact_name" />
    </mat-form-field>
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Contact Title" [(ngModel)]="customer_obj.contact_title" />
    </mat-form-field>
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Primary Contact Email" [(ngModel)]="customer_obj.email" />
    </mat-form-field>
    <mat-form-field class="example-full-width widths">

      <mat-chip-list #chipList aria-label="Secondary Email Id">
        <mat-chip *ngFor="let emailid of allEmailId" [selectable]="selectable" [removable]="removable"
          (removed)="remove(emailid)">
          {{emailid}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Secondary Emails" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addSecondaryEmail($event)">

      </mat-chip-list>

    </mat-form-field>

    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Contact User Name" [(ngModel)]="customer_obj.username" />
    </mat-form-field>
    <mat-form-field class="example-full-width widths">
      <input type="text" matInput placeholder="Contact Password" [(ngModel)]="customer_obj.password_text" />
    </mat-form-field>
    <br />
    <br />
    <!---->
    <mat-toolbar class="subtitle font-fmly">Customer Status</mat-toolbar>
    <br />
    <br />
    <mat-form-field class="example-full-width widths">
      <mat-select placeholder="Select Status" [(ngModel)]="customer_obj.status">
        <mat-option [value]="item.value" *ngFor="let item of status">
          {{ item.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <br />
    <mat-toolbar class="subtitle font-fmly">Instructions</mat-toolbar>
    <br />
    <mat-form-field class="example-full-width widths">
      <textarea matInput placeholder="Shipping Instruction" [(ngModel)]="customer_obj.shipping_instructions"></textarea>
    </mat-form-field>
    <br />
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Service Fee" [(ngModel)]="customer_obj.shipping_cost" />
      <mat-icon matSuffix>attach_money</mat-icon>

    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width widths">
      <input matInput placeholder="Sales Tax" [(ngModel)]="customer_obj.sales_tax" />

      <span matSuffix><b>%</b></span>

    </mat-form-field>

    <br />
    <mat-checkbox class="example-margin" [(ngModel)]="customer_obj.is_international_shipping">Enable international
      shipping</mat-checkbox>
    <br>
    <mat-form-field class="example-full-width widths" *ngIf="customer_obj.is_international_shipping">
      <input matInput placeholder="International Service Fee" [(ngModel)]="customer_obj.shipping_cost_international" />
      <mat-icon matSuffix>attach_money</mat-icon>
    </mat-form-field>


    <div class="btn-div text-center">
      <button mat-raised-button color="primary" class="btn-update" (click)="update()" style="margin-right: 10px;">
        Update
      </button>
      <button mat-raised-button color="primary" class="btn-update" (click)="cancel()">
        Cancel
      </button>
    </div>
    <!---->
    <div></div>
  </mat-card>
</div>
