import { Component, OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DialogComponent } from '../dialog/dialog.component';
import { m_customers_obj, storefront_obj, userstorefront_obj } from '../models';
import { FormControl, Validators } from '@angular/forms';

export class storefront_collection {
  location: string;
  banner_color_code: string;
  title_color: string;
  online_payment: boolean;
  net30: boolean;
  name: string;
  status: string;
  passcode: string;
  description: string;
}

@Component({
  selector: 'app-multi-sf',
  templateUrl: './multi-sf.component.html',
  styleUrls: ['./multi-sf.component.css']
})
export class MultiSfComponent implements OnInit {
  objs: any;

  responseStoreFronts: userstorefront_obj[] = [];

  constructor(
    private dataservice: DataService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private _location: Location
  ) { }
  isPasscode = false;
  SFname: any;
  // chain_name: string = '';

  chain_name = new FormControl('', [
    Validators.required,
    Validators.maxLength(25)
  ]);
  storefront_count = new FormControl('', [
    Validators.required,
    Validators.max(10),
    Validators.min(2)
  ]);

  passcode = new FormControl('', [Validators.required]);

  // storefront_count: any;
  SFcolor = '#3f51b5';
  SFtitlecolor = '#ffffff';
  status = 'active';
  SFstatus: any;
  custId: any;
  onlinePay = true;
  net30Pay = false;
  UpdateBtn = true;
  SFurlHint: any;
  checkUrl: any;
  MultiForm = false;
  url: string;
  arra1 = [];
  loc = [];
  locations: any = [];
  template_value = 0;
  arryNet30: any = [];
  arryOnline: any = [];
  csvContent: string;
  multiBoolean: any;
  description = '';
  cust_id = '';
  customername = '';

  colletionObj: storefront_collection[] = [];

  ngOnInit() {
    this.dataservice.showHide(true);
    this.UpdateBtn = false;
    if (!this.dataservice.selected_customer) {
      //  this.route.navigateByUrl('/customers');

      this.route.params.subscribe(params => {
        this.cust_id = params['cust_id'];

        this.getSingleCustomer(this.cust_id);
      });
    } else {
      // this.showForm = true;
      this.cust_id = this.dataservice.selected_customer.id;
      this.customername = this.dataservice.selected_customer.name;
    }

    this.storefront_count.valueChanges.subscribe(term => {
      this.radioValueChange();
    });
  }

  getSingleCustomer(id: string) {
    this.dataservice.getSingleCustomer(id).subscribe(
      data => {
        console.log(data.json());
        const result = data.json();
        const selectedCustomer = <m_customers_obj>result['results'][0];
        this.dataservice.setSelectedCustomer(selectedCustomer);
        this.cust_id = selectedCustomer.id;
        this.customername = selectedCustomer.name;

        console.log(this.dataservice.getSelectedCustomer());
        // this.showForm = true
      },
      err => {
        console.log(err);
      }
    );
  }
  cancel() {
    this._location.back();
  }

  radioValueChange() {
    this.colletionObj = [];

    for (let index = 0; index < this.storefront_count.value; index++) {
      const obj = new storefront_collection();

      obj.title_color = '#3f51b5';
      obj.banner_color_code = '#ffffff';
      obj.net30 = false;
      obj.online_payment = false;
      obj.location = '';
      obj.name = '';
      obj.status = 'active';
      obj.passcode = '';
      obj.description = '';

      this.colletionObj.push(obj);
    }

    console.log(this.colletionObj);
  }

  create_chain() {
    if (this.storefront_count.invalid || this.chain_name.invalid) {
      this.storefront_count.markAsTouched();
      this.chain_name.markAsTouched();
      return;
    }

    if (this.isPasscode) {
      if (this.passcode.invalid) {
        this.passcode.markAsTouched();
        return;
      }
    }

    this.multiBoolean = 0;
    if (this.template_value === 2) {
      for (let index = 0; index < this.colletionObj.length; index++) {
        this.colletionObj[index].name =
          this.chain_name.value + ' ' + this.colletionObj[index].location;
        this.colletionObj[index].passcode = this.passcode.value;
      }
    } else {
      for (let index = 0; index < this.colletionObj.length; index++) {
        this.colletionObj[index].name =
          this.chain_name.value + ' ' + this.colletionObj[index].location;
        this.colletionObj[index].banner_color_code = this.SFcolor;
        this.colletionObj[index].title_color = this.SFtitlecolor;
        this.colletionObj[index].net30 = this.net30Pay;
        this.colletionObj[index].online_payment = this.onlinePay;
        this.colletionObj[index].passcode = this.passcode.value;
        this.colletionObj[index].description = this.description;
      }
    }

    console.log(this.colletionObj);

    const chainObj = [
      {
        user_id: this.cust_id,
        chain_name: this.chain_name.value,
        storefront_count: this.storefront_count.value,
        storefronts: this.colletionObj
      }
    ];

    if (this.checkforAddress(this.colletionObj)) {
      console.log('request no');

      return;
    }

    console.log('request yes');


    this.dataservice.createChain(chainObj).subscribe(res => {
      console.log(res.json());

      this.responseStoreFronts = res.json();

      this.responseStoreFronts.forEach(element => {
        this.dataservice.appendStoreObjInCustomer(element);
      });
      this.arra1 = [];
      // alert('Chain Created');
      this.dataservice.customer_list = [];
      this.openDialog();
      // this.customer.UpdateSfList();
      // this.cancel();
    });
  }

  add_location() {
    this.loc = new Array(this.storefront_count.value);
    console.log(this.loc, 'jhg');
  }

  checkforAddress(colletionObj: storefront_collection[]): boolean {
    let obj = false;
    colletionObj.forEach(element => {
      if (element.location.length === 0) {
        obj = true;
        return;
      }
    });

    if (obj) {
      this.openErrorDialog();
    }

    return obj;
  }

  // // Next Step click process (add product)
  // //  Save chain obj
  // nextStep()
  // { this.customer.setMultiProduct();
  //   this.customer.productShow();
  //   if (this.template_value == 2) {
  //     for (let index = 0; index < this.colletionObj.length; index++) {

  //       this.colletionObj[index].name = this.chain_name + " " + this.colletionObj[index].location;
  //     }
  //   }
  //   else {
  //     for (let index = 0; index < this.colletionObj.length; index++) {

  //       this.colletionObj[index].name = this.chain_name + " " + this.colletionObj[index].location;
  //       this.colletionObj[index].banner_color_code = this.SFcolor
  //       this.colletionObj[index].title_color = this.SFtitlecolor
  //       this.colletionObj[index].net30 = this.net30Pay;
  //       this.colletionObj[index].online_payment = this.onlinePay;

  //     }

  //   }

  //   console.log(this.colletionObj);

  //   let chainObj = [{
  //     "user_id": this.custId,
  //     "chain_name": this.chain_name,
  //     "storefront_count": this.storefront_count,
  //     "storefronts": this.colletionObj,
  //   }]
  //  // this.objs = chainObj;
  //  this.dataservice.getMultiSF_Obj(chainObj,this.multiBoolean);
  //  this.cancel();
  // }

  // // Cancel form

  // cancel() {
  //   // this.customer.showMultipleForm = 1;
  //   // this.customer.multiBtn = 1;
  // }

  // File CSV read

  onFileLoad(fileLoadedEvent) {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.csvContent = textFromFileLoaded;
    console.log(JSON.parse(this.csvContent));
  }

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    let content = this.csvContent;

    if (files && files.length) {
      /*
       console.log("Filename: " + files[0].name);
       console.log("Type: " + files[0].type);
       console.log("Size: " + files[0].size + " bytes");
       */

      const fileToRead = files[0];

      const fileReader = new FileReader();
      fileReader.onload = this.onFileLoad;

      fileReader.readAsText(fileToRead, 'UTF-8');
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Success',
        content:
          'Storefront Group ' + this.chain_name.value + ' has been created.',
        type: 'addproduct'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'no') {
        console.log('The dialog was closed', result);

        this.router.navigateByUrl('/');
      }

      if (result == 'yes') {
        this.dataservice.selected_storefronts = this.responseStoreFronts;
        this.router.navigateByUrl('/addproducts');
      }
    });
  }

  openErrorDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Error',
        content: 'Addresses of stores cannot be empty.',
        type: 'confirmation'
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  returnRandomString(length: number) {
    const passcode = Math.random()
      .toString(36)
      .substr(2, length);

      if (this.isPasscode) {
    this.passcode.setValue(passcode);
      } else {
        this.passcode.setValue('');
      }
  }
}
