import { Component, OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { RouterModule, Routes, NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { m_customers_obj, userstorefront_obj, customer_obj } from '../models';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-viewstorefront',
  templateUrl: './viewstorefront.component.html',
  styleUrls: ['./viewstorefront.component.css']
})
export class ViewstorefrontComponent implements OnInit {
  constructor(
    private dataservice: DataService,
    private router: ActivatedRoute,
    private route: Router,
    private dialog: MatDialog
  ) {
    this.router.params.subscribe(params => {
      this.customerId = params['cust_id'];
    });
  }
  customer_obj: m_customers_obj;
  storefrontlist: userstorefront_obj[] = [];
  showForm = false;
  customerId: string;
  ngOnInit() {
    this.dataservice.showHide(true);
    console.log("yo");

    this.customer_obj = this.dataservice.getSelectedCustomer();

    if (this.dataservice.getSelectedCustomer()) {
      this.showForm = true;

      if (this.customer_obj) {
        this.storefrontlist = this.customer_obj.user_storefront;
      }
    } else {
      this.router.params.subscribe(params => {
        this.customerId = params['cust_id'];
        this.getSingleCustomer(params['cust_id']);
      });
    }
  }

  getSingleCustomer(id: string) {
    this.dataservice.getSingleCustomer(id).subscribe(
      data => {
        let result = data.json();
        let selectedCustomer = <m_customers_obj>result['results'][0];
        this.dataservice.setSelectedCustomer(selectedCustomer);

        this.customer_obj = this.dataservice.getSelectedCustomer();

        this.storefrontlist = this.customer_obj.user_storefront;
        this.showForm = true;
      },
      err => {
        console.log(err);
      }
    );
  }

  gotoAddStoreFront() {
    this.route.navigateByUrl('/addstorefront/' + this.customerId);
  }

  gotoAddProduct(item: userstorefront_obj) {
    this.dataservice.selected_storefronts = [];

    this.dataservice.selected_storefronts.push(item);

    this.route.navigateByUrl('/addproducts/' + this.customerId);
  }

  Preview
  prev(i: any, listItem) {
    let slug = this.storefrontlist[i].slug;
    console.log(slug);
    if (!slug) {
      alert('No Url Added');
      return;
    }
    // let status = ;
    if (this.customer_obj['status'].toLocaleLowerCase() == 'archive') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Archived customer',
          content: `Customer status is archived, press okay to view store`,
          type: 'confirmation'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          console.log("", result)
          if (result === 'okay') {

            slug = slug + "/?is_admin=true";

            this.openStoreFront(this.customer_obj.name_slug, slug);

          }
        }

      });
    } else if (listItem.status.toLocaleLowerCase() == 'archive') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Archived store',
          content: `Store is archived, press okay to view store`,
          type: 'confirmation'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          console.log("", result)
          if (result === 'okay') {

            slug = slug + "/?is_admin=true";

            this.openStoreFront(this.customer_obj.name_slug,slug);

          }
        }

      });
    } else {
      this.openStoreFront(this.customer_obj.name_slug,slug);
    }
  }





  openStoreFront(customer,url) {
    window.open(
      `${this.dataservice.storeUrl}/${customer}/${url}`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800'
    );
  }

  // edit storefront
  edit(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;
    this.route.navigateByUrl(
      '/liststorefronts/editstore/' + this.customerId + '/' + item.id
    );
  }

  // Get storefront Name

  toggleStoreFrontStatus(
    item: userstorefront_obj,
    status: string,
    index: number
  ) {
    this.openArchiveDialog(item, status, index);
  }

  openArchiveDialog(
    item: userstorefront_obj,
    status: string,
    index: number
  ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Archive',
        content: 'Are you sure, you want to archive store ' + item.name + '?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        if (result == 'yes') {
          this.toggleStoreStatusData(item, status, index);
        }
      }

      // this.animal = result;
    });
  }

  toggleStoreStatusData(item: userstorefront_obj, statuss, index) {
    this.dataservice
      .toggleStoreStatus(item.id.toString(), { status: statuss })
      .subscribe(
        data => {
          this.storefrontlist[index].status = statuss;

          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }
}
