import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { userstorefront_obj } from '../models';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatSnackBar, MatTable, MatTableDataSource } from '@angular/material';
import { DataService } from '../data.service';
import { CompressImageService } from '../compress.service';
import { DialogComponent } from '../dialog/dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-addbundle',
  templateUrl: './addbundle.component.html',
  styleUrls: ['./addbundle.component.css']
})
export class AddbundleComponent implements OnInit {

  selectedstorefronts: userstorefront_obj[] = [];
  formGroup: FormGroup;
  bundleCategories = []
  products = [];
  previewImage: any;
  selectedCategories = [];
  displayedColumns = ['title', 'Count', 'products'];
  storefrontId = 0;
  progressStatus = 'Adding products ....';
  showProgress = false;
  prevnum = 0;
  isLoading = false;
  statusArr: {
    title: string;
    status: string;
  }[] = [];
  constructor(private dataservice: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private compressImage: CompressImageService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();
    this.getBundleCategoryList();

  }

  fileupload(event) {
    var files = event.srcElement.files;
    if (files) {
      this.previewImage = files[0];
      const obj = this.formGroup.get('previewImage');
      obj.setValue(this.previewImage.name);
      obj.disable();

    }
  }

  initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      bundleName: ['', Validators.required],
      desc: [''],
      code: ['', Validators.required],
      price: ['', Validators.required],
      storefront: ['', Validators.required],
      category_count: ['', Validators.required],
      previewImage: ['', Validators.required]
    });
  }

  getBundleCategoryList() {
    this.dataservice.getBundleCategoryList().then(
      res => {
        console.log(res);
        this.bundleCategories = res;
      }
    )
  }


  onSelectCategories(event: any): void {
    this.selectedCategories = event.value;
    console.log(this.selectedCategories);

  }

  onSelectProducts(e, i) {
    console.log(e.value, i);
    let selectedProducts = e.value
    let index = this.selectedCategories.findIndex(item => item.id === i)
    console.log(index);

    this.selectedCategories[index]['products'] = selectedProducts;

    // this.selectedCategories[index]['category_count'] = this.formGroup.get('category_count').value


  }
  onCategoryCountChange(id) {
    console.log(id);

    this.selectedCategories[id]['category_count'] = this.formGroup.get('category_count').value
  }
  // addProductsToCategory(categoryId){this.formGroup.get(controlName)?.value;
  //   console.log(categoryId);

  // }

  addBundle() {

    // First, define the bundleItems array
    let bundleItems = [];

    // Then, iterate through the categories
    this.selectedCategories.forEach(category => {
      // Iterate through the products of each category
      category.products.forEach(product => {
        // Create a bundleItem object and push it to the bundleItems array
        bundleItems.push({
          bundle_category: category.id,
          product: product.id,
          category_count: category.category_count
        });
      });
    });

    // Now bundleItems contains the desired structure
    // console.log(bundleItems);
    // console.log(this.formGroup.value);

    let obj = {
      'title': this.formGroup.get('bundleName').value,
      'code': this.formGroup.get('code').value,
      'description': this.formGroup.get('desc').value,
      'price': this.formGroup.get('price').value,
      'storefront': this.storefrontId,
      'bundle_items': bundleItems,
    }
    this.statusArr.push({ title: this.formGroup.get('bundleName').value, status: 'In progress' });
    console.log(obj);
    this.dataservice.addBundle(obj,this.previewImage).subscribe(
      val => {
        
        let i = 0;
        this.showProgress = true;
        this.isLoading = true
        console.log(val);
        val.forEach(element => {
          this.dataservice.appendProduct_List(element);

        });
        this.statusArr[i].status = "Done";
        this.isLoading = false
        this.progressStatus = "Products added successfully";
      }
    )
  }


  openAddDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Select Storefronts",
        content: this.selectedstorefronts,
        type: "selectstorefront"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      if (result.length > 0) {
        // let temp = this.selectedstorefronts;
        // let temp2 = result;

        this.selectedstorefronts = result;
        this.storefrontId = this.selectedstorefronts[0].id
        this.dataservice.getProduct(this.selectedstorefronts[0].id).then((product: any) => {
          console.log(product.storefront_product);
          this.products = product.storefront_product
          console.log(this.products);
        })

      }
    });
  }

  remove(index) {
    this.selectedstorefronts.splice(index, 1);

  }

}
