import { OnInit } from '@angular/core';
var InventoryCardComponent = /** @class */ (function () {
    function InventoryCardComponent() {
    }
    InventoryCardComponent.prototype.ngOnInit = function () {
        console.log(this.inventory);
    };
    InventoryCardComponent.prototype.onImgError = function (event) {
        event.target.src = "../src/assets/images/no-image.jpg";
    };
    return InventoryCardComponent;
}());
export { InventoryCardComponent };
