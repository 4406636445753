import { OnInit } from '@angular/core';
import { m_product_obj, userstorefront_obj, p_sf, m_customers_obj } from '../models';
import { DataService } from './../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { Router } from '@angular/router';
import { PaginatorComponent } from '../paginator/paginator.component';
var ProductCatComponent = /** @class */ (function () {
    function ProductCatComponent(dataservice, _sanitizer, dialog, route) {
        this.dataservice = dataservice;
        this._sanitizer = _sanitizer;
        this.dialog = dialog;
        this.route = route;
        this.product_collection = [];
        this.dataSource = new MatTableDataSource([]);
        this.displayedColumns = [
            'id',
            'image',
            'title',
            'category_name',
            'code',
            'color',
            'size',
            'price',
            'active',
            'sfs',
            'asfs',
            'dsf'
            // "btn2"
        ];
        this.sortParams = [
            {
                viewValue: 'Title',
                value: 'title',
                ascending: null
            },
            {
                viewValue: 'Price',
                value: 'price'
            },
            {
                viewValue: 'Category',
                value: 'category_name'
            },
            {
                viewValue: 'Latest',
                value: 'latest'
            }
        ];
        this.selectedstorefronts = [];
        // url = 'api/v1/productsf/';
        this.url = 'api/v1/productv3/';
        this.urlindex = 0;
    }
    ProductCatComponent.prototype.onValChange = function (val) {
        this.selectedVal = val;
        this.dataservice.productListState = val;
    };
    ProductCatComponent.prototype.ngOnInit = function () {
        this.selectedVal = this.dataservice.productListState;
        this.selectedSort = 'latest';
        this.dataservice.showHide(true);
    };
    ProductCatComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    ProductCatComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        console.log('after view init');
        this.subscription = this.dataservice.sfDeleteId$.subscribe(function (id) {
            console.log(id);
            if (id != 0) {
                _this.removeSfAllProducts(id);
            }
        });
    };
    ProductCatComponent.prototype.onData = function ($event) {
        console.log($event);
        this.product_collection = $event;
        this.dataSource = new MatTableDataSource(this.product_collection);
    };
    ProductCatComponent.prototype.deleteProduct = function (event, prodId, index) {
        var _this = this;
        event.stopPropagation();
        console.log(prodId);
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete',
                content: 'Are you sure, you want to delete this product?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result == "yes") {
                console.log("delete it");
                _this.dataservice.deleteProductPatch(prodId).then(function (response) {
                    console.log(response);
                    console.log(index);
                    _this.product_collection.splice(index, 1);
                    _this.updateProductInCustomer();
                }, function (err) {
                    console.log(err);
                });
            }
        });
    };
    ProductCatComponent.prototype.goToStorefront = function (sfid) {
        console.log(sfid);
        this.route.navigate(['/editstore/' + sfid]);
    };
    ProductCatComponent.prototype.showInventory = function (prodId) {
        // console.log(prodId);
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete',
                content: prodId,
                type: 'showInventory'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
            }
            // this.animal = result;
        });
    };
    ProductCatComponent.prototype.sortDataList = function (sort) {
        if (!this.dataSource)
            return;
        this.dataSource.filter = sort.trim().toLowerCase();
    };
    ProductCatComponent.prototype.editProd = function (item) {
        this.dataservice.selected_product = item;
        this.route.navigate(['/editproduct/' + item.id + '']);
    };
    ProductCatComponent.prototype.getImage = function (objx) {
        var obj = objx;
        if (obj.length > 0) {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + obj[0].image + ")");
        }
        else {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + 'assets/images/no-image.jpg' + ")");
        }
    };
    ProductCatComponent.prototype.getImage2 = function (objx) {
        // let obj: productinventory_set[] = objx;
        // console.log(obj);
        if (objx != null) {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + objx + ")");
        }
        else {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + 'assets/images/no-image.jpg' + ")");
        }
    };
    ProductCatComponent.prototype.removeSf = function (sfname, sfid, pname, pid, uid) {
        this.openDeleteDialog(sfname, sfid, pname, pid, uid);
    };
    ProductCatComponent.prototype.openDeleteDialog = function (sfname, sfid, pname, pid, uid) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete',
                content: 'Are you sure, you want to delete product ' +
                    pname +
                    ' from storefront ' +
                    sfname +
                    ' ?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
                if (result == 'yes') {
                    _this.deleteSf(sfid, pid, uid);
                }
            }
            // this.animal = result;
        });
    };
    ProductCatComponent.prototype.deleteSf = function (sfid, pid, uid) {
        var _this = this;
        console.log(sfid, pid);
        var obj = { product: pid, storefront: sfid };
        this.dataservice.deleteSffromProduct(obj).subscribe(function (data) {
            console.log(data.json());
            // this.dataservice.removeProductObjFromStore(pid,sfid,uid);
            _this.updateProductInCustomer();
            _this.removeSfFromStatic(sfid, pid);
        }, function (error) {
            console.log(error);
        });
    };
    ProductCatComponent.prototype.removeSfAllProducts = function (sfid) {
        console.log('calling');
        for (var index = 0; index < this.product_collection.length; index++) {
            for (var indexx = 0; indexx < this.product_collection[index].storefront_product.length; indexx++) {
                if (this.product_collection[index].storefront_product[indexx].id ===
                    sfid) {
                    this.product_collection[index].storefront_product.splice(indexx, 1);
                    console.log(this.product_collection[index].storefront_product);
                    break;
                }
            }
        }
    };
    ProductCatComponent.prototype.removeSfFromStatic = function (sfid, pid) {
        for (var index = 0; index < this.product_collection.length; index++) {
            if (this.product_collection[index].id === pid) {
                for (var indexx = 0; indexx < this.product_collection[index].storefront_product.length; indexx++) {
                    if (this.product_collection[index].storefront_product[indexx].id ===
                        sfid) {
                        this.product_collection[index].storefront_product.splice(indexx, 1);
                        console.log(this.product_collection[index].storefront_product);
                        break;
                    }
                }
            }
        }
    };
    ProductCatComponent.prototype.openAddDialog = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Select Storefronts',
                content: [],
                type: 'selectstorefront'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            _this.selectedstorefronts = result;
            if (_this.selectedstorefronts.length > 0) {
                _this.addPtoSf(item);
            }
        });
    };
    ProductCatComponent.prototype.addPtoSf = function (item) {
        var _this = this;
        console.log(item);
        this.selectedstorefronts.forEach(function (element) {
            var index = item.storefront_product.findIndex(function (elementx) {
                return (elementx.id === element.id);
            });
            if (index === -1) {
                _this.addPtoSfDatas(item.id, element.name, element.id, item, element.user, element.id, element.status);
            }
        });
    };
    ProductCatComponent.prototype.addPtoSfDatas = function (pid, sfname, sfid, a, b, c, status) {
        var _this = this;
        var obj = { product: pid, storefront: sfid };
        this.dataservice.addSftoProduct(obj).subscribe(function (data) {
            console.log(data.json());
            _this.updateProductInCustomer();
            _this.paginatorComponent.url = _this.url;
            _this.paginatorComponent.initialCall();
            // this.addPtoSfStatic(sfid, sfname, pid,status);
        }, function (error) {
            console.log(error);
        });
    };
    ProductCatComponent.prototype.addPtoSfStatic = function (sfid, sfname, pid, status) {
        for (var index = 0; index < this.product_collection.length; index++) {
            if (this.product_collection[index].id === pid) {
                var obj = { id: sfid, name: sfname, is_active: true, status: status };
                this.product_collection[index].storefront_product.unshift(obj);
                break;
            }
        }
    };
    ProductCatComponent.prototype.updateProductInCustomer = function () {
        if (length > 0) {
            this.urlindex = this.dataservice.inmemoryData.findIndex(function (element) { return element.url === "api/v1/userlist/"; });
            this.dataservice.customerGetFlag = true;
        }
    };
    ProductCatComponent.prototype.toggleProductstaus = function (pid, status) {
        var _this = this;
        var obj = { product: pid, is_active: status };
        this.dataservice.toggleProductStatus(obj).subscribe(function (data) {
            console.log(data);
            _this.dataservice.productEdited = true;
            _this.updateProductInCustomer();
            _this.toggleProductStatusStatic(pid, status);
        }, function (error) {
        });
    };
    ProductCatComponent.prototype.toggleProductStatusStatic = function (pid, status) {
        for (var index = 0; index < this.product_collection.length; index++) {
            if (this.product_collection[index].id === pid) {
                this.product_collection[index].active = status;
            }
        }
    };
    ProductCatComponent.prototype.onToggle = function (evt, id) {
        console.log(evt.checked, id);
        this.toggleProductstaus(id, evt.checked);
    };
    ProductCatComponent.prototype.openArchiveDialog = function (pid, status) {
        var _this = this;
        var obj = this.product_collection.find(function (items) { return items.id === pid; });
        var storestring = [];
        obj.storefront_product.forEach(function (element) {
            storestring.push(element.name);
        });
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Archive',
                content: 'Are you sure you want to archive product ' +
                    obj.title +
                    ', by doing so the product will not be listed in ' +
                    storestring.toString(),
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
                if (result == 'yes') {
                    _this.toggleProductstaus(pid, status);
                }
            }
            // this.animal = result;
        });
    };
    ProductCatComponent.prototype.sortDataDropDown = function () {
        var _this = this;
        console.log(this.selectedSort);
        if (this.selectedSort == "latest") {
            this.dataservice.getCommon(this.url).subscribe(function (res) {
                console.log(res.json());
                var obj = res.json();
                _this.product_collection = obj['results'];
            });
        }
        else {
            var bool = void 0;
            if (this.selectedSort === this.asedesSort) {
                bool = false;
            }
            else {
                bool = true;
            }
            this.asedesSort = this.selectedSort;
            if (bool) {
                this.product_collection.sort(this.dataservice.dynamicSort(this.selectedSort));
            }
            else {
                this.product_collection
                    .sort(this.dataservice.dynamicSort(this.selectedSort))
                    .reverse();
                this.asedesSort = '';
            }
        }
        console.log(this.product_collection);
    };
    ProductCatComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.product_collection.slice();
        if (!sort.active || sort.direction === '') {
            this.product_collection = data;
            return;
        }
        this.product_collection = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'id':
                    return _this.compare(a.id, b.id, isAsc);
                case 'title':
                    return _this.compare(a.title, b.title, isAsc);
                case 'sfs':
                    return _this.compare(a.storefront_product.length, b.storefront_product.length, isAsc);
                case 'price':
                    return _this.compare(a.price, b.price, isAsc);
                case 'status':
                    return _this.compare(a.active, b.active, isAsc);
                case 'category_name':
                    return _this.compare(a.category_name, b.category_name, isAsc);
                case 'code':
                    return _this.compare(a.code, b.code, isAsc);
                // case 'protein': return compare(a.protein, b.protein, isAsc);
                default:
                    return 0;
            }
        });
    };
    ProductCatComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    //make featured 
    ProductCatComponent.prototype.onChange = function (event, index, item) {
        console.log(index, event, item);
        this.toggleProductFeaturedstaus(event.checked, item);
    };
    ProductCatComponent.prototype.toggleProductFeaturedstaus = function (status, item) {
        var obj = { is_featured: status };
        this.dataservice.updateProduct(obj, item.id).subscribe(function (data) {
            console.log(data);
            item.is_featured = status;
        }, function (error) { });
    };
    ProductCatComponent.prototype.colorDialogue = function (color) {
        console.log(color);
        var colArr = color.split(",");
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Color Options',
                content: colArr,
                type: 'colorsizeinfo'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
            }
        });
    };
    ProductCatComponent.prototype.sizeDialogue = function (size) {
        console.log(size);
        var colArr = size.split(",");
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Color Options',
                content: colArr,
                type: 'colorsizeinfo'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
            }
        });
    };
    ProductCatComponent.prototype.previewStore = function (item, customer, evt) {
        if (evt) {
            evt.stopPropagation();
        }
        var slug = item.slug;
        console.log(item.slug, customer);
        if (!slug) {
            alert('No Url Added');
            return;
        }
        else {
            this.openStoreFront(item.name_slug, slug);
        }
        // else{
        //   if (item.status.toLocaleLowerCase() === 'archive') {
        //       const dialogRef = this.dialog.open(DialogComponent, {
        //         data: {
        //           title: 'Archived store',
        //           content: `Store is archived, press okay to view store`,
        //           type: 'confirmation'
        //         }
        //       });
        //       dialogRef.afterClosed().subscribe(result => {
        //         if (result) {
        //           console.log("", result)
        //           if (result === 'okay') {
        //             slug = item.slug + "/?is_admin=true";
        //             this.openStoreFront(slug);
        //           }
        //         }
        //       });
        //     }else{
        //     }
        // }
        // if (customer.status.toLowerCase() === 'archive') {
        //   const dialogRef = this.dialog.open(DialogComponent, {
        //     data: {
        //       title: 'Archived customer',
        //       content: `Customer status is archived, press okay to view store`,
        //       type: 'confirmation'
        //     }
        //   });
        //   dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       console.log("", result)
        //       if (result === 'okay') {
        //         slug = item.slug + "/?is_admin=true";
        //         this.openStoreFront(slug);
        //       }
        //     }
        //   });
        // }
        // else if (item.status.toLocaleLowerCase() === 'archive') {
        //   const dialogRef = this.dialog.open(DialogComponent, {
        //     data: {
        //       title: 'Archived store',
        //       content: `Store is archived, press okay to view store`,
        //       type: 'confirmation'
        //     }
        //   });
        //   dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //       console.log("", result)
        //       if (result === 'okay') {
        //         slug = item.slug + "/?is_admin=true";
        //         this.openStoreFront(slug);
        //       }
        //     }
        //   });
        // } else {
        //   this.openStoreFront(slug);
        // }
    };
    ProductCatComponent.prototype.openStoreFront = function (customer, url) {
        window.open(this.dataservice.storeUrl + "/" + customer + "/" + url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800');
    };
    return ProductCatComponent;
}());
export { ProductCatComponent };
