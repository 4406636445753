import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
var BreadcrumbComponent = /** @class */ (function () {
    function BreadcrumbComponent(route, router) {
        var _this = this;
        this.route = route;
        this.router = router;
        var copyUrl = Object.assign([], this.route.routeConfig["data"]["url"]);
        this.logUrlPath = copyUrl.map(function (url) {
            var deleteUrl = Object.assign({}, url);
            if (deleteUrl["isId"]) {
                var index = 2;
                index = _this.router["url"].split("/").findIndex(function (val) { return /-/.test(val); });
                deleteUrl["url"] =
                    deleteUrl["url"] + _this.router["url"].split("/")[index];
                return deleteUrl;
            }
            else
                return deleteUrl;
        });
    }
    BreadcrumbComponent.prototype.ngOnInit = function () { };
    return BreadcrumbComponent;
}());
export { BreadcrumbComponent };
