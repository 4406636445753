import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange, MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
@Component({
  selector: 'app-edit-bundle',
  templateUrl: './edit-bundle.component.html',
  styleUrls: ['./edit-bundle.component.css'],
})
export class EditBundleComponent implements OnInit {

  bundleId: string;
  bundleObj: any;
  displayedColumns = ['title', 'Count', 'products'];
  formGroup: FormGroup;
  bundleCategories = []
  bundleCategoriesControl = new FormControl()
  bundleProductsControls: FormArray;
  category_count = new FormControl()
  categoryNamesObj: any;
  products = [];


  constructor(private dialog:MatDialog,private route: ActivatedRoute, private router: Router, private dataservice: DataService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.bundleId = params['bundle_id'];
    });

    console.log(this.bundleId);
    this.getBunlde(this.bundleId)
  }

  getBundleCategoryList() {
    this.dataservice.getBundleCategoryList().then(
      res => {
        console.log(res);
        this.bundleCategories = res;
        console.log(this.categoryNamesObj);

        const selected = this.bundleCategories.filter(category =>
          this.categoryNamesObj.some(obj => obj.name.trim().toLowerCase() === category.name.trim().toLowerCase())
        );

        this.bundleCategoriesControl.setValue(selected);


      }
    )
  }


  getBunlde(id) {
    this.dataservice.getSingleBundle(id).subscribe(bundle => {
      console.log(bundle.json());
      this.bundleObj = bundle.json();
      this.initializeForm();
      this.categoryNamesObj = this.getCategoryNames(this.bundleObj.bundleinventory_set)


      this.getBundleCategoryList();
      this.getProductsOfSf()

    })
  }



  getProductsOfSf() {
    this.dataservice.getProduct(this.bundleObj.storefront).then((product: any) => {
      console.log(product.storefront_product);
      this.products = product.storefront_product
      console.log(this.products);
      this.setProductsInCatObject()

    })
  }

  setProductsInCatObject() {
    this.bundleProductsControls = this.formBuilder.array([]);

    this.categoryNamesObj.forEach(element => {
      const selected = this.products.filter(prod =>
        element.products.some(product =>
          product.title.trim().toLowerCase() === prod.title.trim().toLowerCase()
        )
      );
      this.bundleProductsControls.push(this.formBuilder.control(selected));

    });

    console.log(this.bundleProductsControls.value);
  }

  getFormControl(index: number): FormControl {
    return this.bundleProductsControls.at(index) as FormControl;
  }

  onSelectProducts(e, i) {
    console.log(e.value, i);
    let selectedProducts = e.value
    let index = this.categoryNamesObj.findIndex(item => item.id === i)
    this.categoryNamesObj[index]['products'] = selectedProducts;
  }

  onCategoryCountChange(id) {
    console.log(id);

    this.categoryNamesObj[id]['count'] = this.category_count.value
  }

  // getCategoryNames(bundleInventorySet: any[]): string[] {
  //   const categoryNames = [];
  //   const category_set = [];
  //   for (const item of bundleInventorySet) {
  //     const categoryName = item.category_name;
  //     if (!category_set.includes(categoryName)) {
  //       category_set.push(categoryName)
  //       categoryNames.push({ name: categoryName, count: item.category_count});
  //     }

  //   }
  //   console.log(categoryNames);
  //   return categoryNames;
  // }

  getCategoryNames(bundleInventorySet: any[]): any[] {
    const categoryNames = [];
    const category_set = new Map();

    for (const item of bundleInventorySet) {
      const catId = item.bundle_category
      const categoryName = item.category_name;
      const categoryCount = item.category_count;
      const product = item.product_data;  // Assuming this is the product data you want to include

      if (!category_set.has(categoryName)) {
        category_set.set(categoryName, { id: catId, name: categoryName, count: categoryCount, products: [product] });
      } else {
        const existingCategory = category_set.get(categoryName);
        existingCategory.products.push(product);
      }
    }

    category_set.forEach((value) => categoryNames.push(value));



    console.log(categoryNames);
    return categoryNames;
  }

  onSelectCategories(event: MatSelectChange) {
    console.log(event.value);

    // this.selectedCategories = event.value;

    //   this.bundleCategoriesControl.setValue(selectedCategories);
    let selectedCategories = event.value;

    selectedCategories.forEach(cat => {
      let categoryExists = this.categoryNamesObj.some(element => element.id === cat.id);

      if (!categoryExists) {
        let newobj = {
          id: cat.id,
          name: cat.name,
          count: 1,
          products: []
        };
        this.categoryNamesObj = [...this.categoryNamesObj, newobj]
      } else {
        // console.log('remove');
      }
    });

    // this.categoryNamesObj = this.categoryNamesObj.filter(cat => {
    //   return selectedCategories.some(element => element.id === cat.id);
    // });
    this.updateCategoriesAndProducts(selectedCategories)
    // let newCategoryNamesObj = [];

    // this.categoryNamesObj.forEach((cat) => {
    //   let categoryExists = selectedCategories.some(element => element.id === cat.id);

    //   if (categoryExists) {
    //     newCategoryNamesObj.push(cat);
    //   } else {
    //     console.log('remove');
    //   }
    // });

    // this.categoryNamesObj = newCategoryNamesObj;

    console.log(this.categoryNamesObj);


  }

  updateCategoriesAndProducts(selectedCategories) {
    let newCategoryNamesObj = [];
    let newBundleProductsControls = this.formBuilder.array([]);

    this.categoryNamesObj.forEach((cat) => {
      let categoryExists = selectedCategories.some(element => element.id === cat.id);

      if (categoryExists) {
        newCategoryNamesObj.push(cat);

        const selected = this.products.filter(prod =>
          cat.products.some(product =>
            product.title.trim().toLowerCase() === prod.title.trim().toLowerCase()
          )
        );
        newBundleProductsControls.push(this.formBuilder.control(selected));
      } else {
        console.log('remove');
      }
    });

    this.categoryNamesObj = newCategoryNamesObj;
    this.bundleProductsControls = newBundleProductsControls;

    console.log(this.bundleProductsControls.value);
  }

  imagePreview: string | ArrayBuffer | null = null;
  fileupload(event) {
    const file = event.target.files[0];
    if (file) {
      this.formGroup.patchValue({ preview_image: file });
      this.formGroup.get('preview_image').updateValueAndValidity();

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  resetImage() {
   
    this.formGroup.patchValue({ preview_image: this.bundleObj.preview_image });
    this.formGroup.get('preview_image').updateValueAndValidity();
    this.imagePreview = this.formGroup.get('preview_image').value
    console.log(this.formGroup.get('preview_image').value);
  }

  initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      title: [this.bundleObj.title, Validators.required],
      description: [this.bundleObj.description],
      code: [this.bundleObj.code, Validators.required],
      price: [this.bundleObj.price, Validators.required],
      preview_image: [this.bundleObj.preview_image, Validators.required]
    });
  }
  isLoading = false

  showMsgDialog(title,msg,type) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: title,
        content: msg,
        type: type,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      location.reload()
    });
  }
  updateBundle() {
    // First, define the bundleItems array
    let bundleItems = [];

    // Then, iterate through the categories
    this.categoryNamesObj.forEach(category => {
      // Iterate through the products of each category
      category.products.forEach(product => {
        // Create a bundleItem object and push it to the bundleItems array
        bundleItems.push({
          bundle_category: category.id,
          product: product.id,
          category_count: category.count
        });
      });
    });

    let obj = {
      'title': this.formGroup.get('title').value,
      'code': this.formGroup.get('code').value,
      'description': this.formGroup.get('description').value,
      'price': this.formGroup.get('price').value,
      'bundle_items': bundleItems,
      'preview_image': this.formGroup.get('preview_image').value,
    }

    
    console.log(obj);
    this.isLoading = true
     
    this.dataservice.updateSingleBundle(this.bundleId, obj).then((result) => {
      this.isLoading = false
      this.showMsgDialog('Success','Bundle has been successfully updated.','confirmation')

    }).catch((error) => {
      this.isLoading = false
      this.showMsgDialog('error','Something went wrong!','error')

    })
  }
}
