import { OnInit } from "@angular/core";
import { DataService } from "./../data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "../app.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(dataservice, router, appcomp, route) {
        var _this = this;
        this.dataservice = dataservice;
        this.router = router;
        this.appcomp = appcomp;
        this.route = route;
        this.type = "";
        this.showDrpdwn = false;
        this.route.params.subscribe(function (params) {
            _this.type = params["login_type"];
            console.log(_this.type);
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.setNavbaroff();
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        // if (this.username = '') {
        //   alert('Please Enter Username');
        //   return;
        // }
        // if (this.password = '') {
        //   alert('Please Enter Password');
        //   return;
        // }
        //console.log('hello');
        //console.log(this.username);
        //console.log(this.password);
        this.dataservice.login(this.username, this.password).then(function (res) {
            _this.user_details = res;
            _this.setNavbarOn();
            //console.log(this.user_details);
            //this.dataservice.users_id = this.user_details.user.pk;
            _this.userid = _this.user_details.token;
            sessionStorage.setItem("token", _this.userid);
            var data = sessionStorage.getItem("token");
            // console.log(data, 'data');
            _this.router.navigateByUrl("/customers");
        });
    };
    // this will removes the navbar and sidebar (hides)
    LoginComponent.prototype.setNavbaroff = function () {
        this.appcomp.navbar_set = 1;
        this.appcomp.width = 0;
        this.appcomp.left = 0;
        this.appcomp.displaytype = 'none';
    };
    LoginComponent.prototype.setNavbarOn = function () {
        this.appcomp.navbar_set = 0;
        this.appcomp.width = 50;
        this.appcomp.left = 50;
        this.appcomp.displaytype = 'block';
    };
    LoginComponent.prototype.custlogin = function () {
        var _this = this;
        // if (this.password.length === 8) {
        this.dataservice
            .getPasscodeprostorefront(this.password)
            .subscribe(function (data) {
            console.log(data.json());
            _this.dataservice.protected_selected_storefronts = data.json();
            _this.selectedStoreFront = _this.dataservice.protected_selected_storefronts[0];
            _this.showDrpdwn = true;
            //  this.router.navigateByUrl('/storelist_protected')
        });
        // }
    };
    LoginComponent.prototype.onSelect = function (item) {
        console.log(item);
    };
    LoginComponent.prototype.launchStore = function () {
        this.prev(this.selectedStoreFront.slug + '/' + this.password);
    };
    LoginComponent.prototype.prev = function (slug) {
        console.log(slug);
        if (!slug) {
            alert("No Url Added");
            return;
        }
        console.log(slug, "url");
        window.open(slug, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800");
    };
    return LoginComponent;
}());
export { LoginComponent };
