import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, EventEmitter } from '@angular/core';
var PaginatorComponent = /** @class */ (function () {
    function PaginatorComponent(dataservice, route, router) {
        this.dataservice = dataservice;
        this.route = route;
        this.router = router;
        this.urlindex = 0;
        this.indexs = 0;
        this.data = new EventEmitter();
        this.multicollectionarry = [];
        this.collectionarry = [];
    }
    PaginatorComponent.prototype.ngOnInit = function () {
        this.lookupInmemoryData();
    };
    PaginatorComponent.prototype.lookupInmemoryData = function () {
        // if (this.dataservice.customerGetFlag && this.dataservice.customerListState == "grid"){
        //   console.log(this.dataservice.productEdited,this.dataservice.customerGetFlag);
        var _this = this;
        //   this.initialCall();
        // }else{
        var length = this.dataservice.inmemoryData.length;
        if (length >= 0) {
            this.urlindex = this.dataservice.inmemoryData.findIndex(function (element) { return element.url === _this.url; });
            if (this.urlindex > -1) {
                this.data.emit(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0]);
            }
            else {
                this.initialCall();
            }
        }
        else {
            this.initialCall();
        }
        // }
    };
    PaginatorComponent.prototype.initialCall = function () {
        var _this = this;
        var obj = {
            url: this.url,
            multicollectionarry: [],
            datatype: this.getDataType(this.url)
        };
        this.dataservice.inmemoryData.push(obj);
        this.dataservice.getCommon(this.url).subscribe(function (data) {
            console.log(data.json());
            _this.urlindex = _this.dataservice.inmemoryData.findIndex(function (element) { return element.url === _this.url; });
            _this.paginatingFunc(data.json().results, data.json().count, _this.urlindex);
        }, function (error) {
            console.log(error);
        });
    };
    PaginatorComponent.prototype.paginatingFunc = function (result, numberofentry, urlindex) {
        console.log(numberofentry, result.length);
        var numberdividefactor = 0;
        if (result.length <= numberofentry) {
            numberdividefactor = result.length;
        }
        else {
            numberdividefactor = numberofentry;
        }
        var numberofpage = numberofentry / numberdividefactor;
        console.log(numberofpage);
        if (numberofpage > 1) {
            for (var index = 0; index < Math.trunc(numberofpage) + 1; index++) {
                var emptycell = [];
                this.dataservice.inmemoryData[urlindex].multicollectionarry.push(emptycell);
            }
            this.dataservice.inmemoryData[urlindex].multicollectionarry[0] = result;
            this.data.emit(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
            console.log(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
        }
        else {
            this.dataservice.inmemoryData[urlindex].multicollectionarry[0] = result;
            this.data.emit(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
            console.log(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
        }
    };
    PaginatorComponent.prototype.paginate = function (pagenumber) {
        var _this = this;
        this.indexs = pagenumber;
        this.dataservice.pageNumber = pagenumber;
        console.log("pagenumber", this.dataservice.pageNumber);
        this.urlindex = this.dataservice.inmemoryData.findIndex(function (element) { return element.url === _this.url; });
        if (this.dataservice.inmemoryData[this.urlindex].multicollectionarry[pagenumber].length > 0) {
            this.data.emit(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[pagenumber]);
        }
        else {
            this.getNextResult(pagenumber + 1, this.urlindex);
        }
    };
    PaginatorComponent.prototype.getNextResult = function (pagenumber, urlindex) {
        var _this = this;
        this.dataservice.getCommonWithNextPage(this.url, pagenumber).subscribe(function (data) {
            // this.multicollectionarry[pagenumber - 1] = data.json().results;
            _this.dataservice.inmemoryData[urlindex].multicollectionarry[pagenumber - 1] = data.json().results;
            _this.data.emit(_this.dataservice.inmemoryData[urlindex].multicollectionarry[pagenumber - 1]);
        }, function (error) {
            console.log(error);
        });
    };
    PaginatorComponent.prototype.getDataType = function (url) {
        switch (url) {
            case 'api/v1/userlist/':
                return 'customer-store';
            case 'api/v1/productsf/':
                return 'product';
            case 'api/v1/productv3/':
                return 'product';
            case 'api/v1/productinv/':
                return 'inventory';
            default:
                return '';
        }
    };
    return PaginatorComponent;
}());
export { PaginatorComponent };
