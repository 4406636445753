export class product_obj {
  description: string;
  code: string;
  title: string;
  product_background_color:string = '#f4f4f4';
  product_text_color:string = '#161616';
  multiple_colors: boolean;
  price: string;
  color: string;
  size: string;
  colorArray:string[] = [];
  sizeArray:string[] = []; 
  typeArray:ProductTag[] = [];
  specialTags:ProductTag[] = [];
  categories: number;
  imagearry: any[];
  files: any[];
  storefront: string;
  is_featured: boolean;
  size_chart?:SizeChart[] = [];
  productinventory_set:Inventory[];
}

export interface SizeChart {
  style?: any;
  chart?:any;
  charturl?:any;
}
export let Sizes = ['XXS','XS','S','M','L','XL','2XL','3XL','4XL','5XL']
export interface ProductTag {
  id:number
	name:string
  // isActive:boolean
}

export class customer_obj {
  id: string;
  name: string;
  username: string;
  user_storefront: storefront_obj[];
  ischecked: boolean;
  isshown: boolean;
}

export class storefront_obj {
  id: string;
  chain_name: string;
  name: string;
  ischecked: boolean;
}

export class tags {
  id: string;
  name: string;
  slug: string;
}

export class purchase_user {
  address: string;
  address_city: string;
  address_state: string;
  address_street: string;
  class_name: string;
  club_name: string;
  designation: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  name: string;
  parent_first_name: string;
  parent_last_name: string;
  phone_number: string;
  postal_code: string;
  purchase_order: string;
  team_name: string;
  username: string;
}

export class purchase_set_obj {
  amount: number;
  code: string;
  color: string;
  purchaser: string;
  quantity: number;
  size: string;
  storefront: string;
  title: string;
}

export class order_obj {
  created: string;
  created_v1: string;
  customer_name: string;
  id: number;
  is_archived: boolean;
  order_tracking: string;
  payment_status: boolean;
  purchase_set: purchase_set_obj[];
  purchase_user: purchase_user;
  storefront: string;
  storefront_owner: string;
  sub_total: string;
  tags: tags[];
  total_amount: number;
  is_checked: boolean;
}

export class product_image_obj {
  id: number;
  image: string;
  product: number;
  color:string;
}

export class m_bundle_obj {
  id: number;
  title: string;
  description: string;
  product_background_color:string = '#f4f4f4';
  product_text_color:string = '#161616';
  code: string;
  price: number;
  active: boolean;
  is_featured: boolean;
}

export class m_product_obj {
  id: number;
  category_name: string;
  product_images: product_image_obj[];
  title: string;
  description: string;
  product_background_color:string = '#f4f4f4';
  product_text_color:string = '#161616';
  code: string;
  price: number;
  size: string;
  image:string;
  color: string;
  active: boolean;
  global_active: boolean;
  multiple_colors: boolean;
  is_featured: boolean;
  sales_tax: number;
  storefront: number;
  size_chart:any;
  categories: number;
  preview_image: string;
  storefront_product: p_sf[];
  productinventory_set:productinventory_set[];
  productsizechart_set:SizeChartConsume[];
  tags:ProductTag[] = [];
  special_tags:ProductTag[] = [];
}

export interface SizeChartConsume{
  id:number;
  style:string
  size_chart:string
}

export class p_sf {
  name: string;
  id: number;
  user?: string;
  user_id?: string;
  is_active?:boolean;
  status?:string;
  tags?:ProductTag[];
}

export class userstorefront_obj {
  id: number;
  storefront_product: m_product_obj[];
	storefrontbanner_set:StoreFrontBanner[];
  storefront_order: order_obj[];
  startdate: string;
  enddate: string;
  user_v1: string;
  name: string;
  chain_name: string;
  banner: string;
  banner_color_code: string;
  desc_background_color: string;
  desc_text_color: string;
  tags: any[];
  title_color: string;
  status: string;
  location: string;
  code: string;
  size: string;
  storefront_url: string;
  slug: string;
  name_slug:string;
  storefront_url_hint: string;
  online_payment: boolean;
  net30: boolean;
  cod: boolean;
  created_at: string;
  updated_at: string;
  user: string;
  ischecked: boolean;
  passcode: string;
  description: string;
  free_shipping_amount: number;
  auto_fill: boolean;
  show_banner_images:boolean;
  auto_fill_data:[];
  show_customer_name:boolean;
  archive_message:string;

}

export class StoreFrontBanner {
	id:number;
	image:string;
}

export class m_customers_obj {
  id: string;
  name: string;
  name_slug:string;
  username: string;
  user_storefront: userstorefront_obj[];
  password_text: string;
  phone_number: string;
  contact_name: string;
  address_street: string;
  address_city: string;
  address_state: string;
  postal_code: string;
  user_type: string;
  user_sub_type: string[];
  designation: string;
  status: string;
  contact_title: string;
  email: string;
  secondary_emails: string;
  logo: string;
  shipping_instructions: string;
  shipping_cost:number;
  sales_tax: number;
  ischecked: boolean;
  shipping_cost_international: number;
  is_international_shipping: boolean;

}

export class order_status {
  value: string;
  viewvalue: string;
}

// tslint:disable-next-line: class-name
export class searchparams {
  puchaser_name: string;
  customer_collection: m_customers_obj[];
  storefront_collection: userstorefront_obj[];
  order_status: order_status[];
  custom_date: string;
  order_date: any;
  order_id:string;
  is_archive: string;
  tags: tags[];
}

export class quickdate {
  id: number = 0;
  key: string = '';
  value: string = '';
  viewvalue: string = '';
}

export class inmemoryCell {
  url: string;
  datatype: string;
  multicollectionarry: any[][] = [];
}


//order

export class OrderUnit {

  id: number;
  title: string;
  product_images: product_image_obj[];
  scq: [Scq];
  sub_total: number;
  unit_price: string;
  code: string;
  description: string;

}

export class Scq {
  color: string;
  quantity: number;
  size: string;

}


export class Coupon {
  allowed_usage_count: number;
  count: number;
  coupon_code: any;
  coupon_id: string;
  coupon_name: string;
  coupon_type: string;
  coupon_valid: boolean;
  created_at: string;
  data: [];
  discount_value: string;
  expiry_date: string;
  percent_discount_value: string;
  transaction: any;
  updated_at: string;
  validity_type: any;
  is_active:boolean;
}
export class product_obj_new {
  description: string;
  code: string;
  title: string;
  multiple_colors: boolean;
  price: string;
  color: string;
  size: string;
  categories: number;
  imagearry: any[];
  files: any[];
  storefront: string;
  is_featured: boolean;
  inventory_items:Inventory[];
}
export interface Inventory {
  tag?:string;
  color:string
  size:string
  quantity:number
  price:number
  files?:any[]
  imageArr?:any[]
  isChecked:boolean
}

export interface productinventory_set {
  id:number
  product_name?:string
  color:string
  size:string
  tag:string
  quantity:number
  price:any
  files?:any[]
  imageArr?:any[]
  productinventoryimage_set:any[]
}