import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { inmemoryCell } from '../models';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
  
})
export class PaginatorComponent implements OnInit {


  @Input() url: string;

  urlindex: number = 0;


  indexs = 0;

  @Output() data = new EventEmitter<any>();


  multicollectionarry: any[][] = [];

  collectionarry: any[] = []





  constructor(public dataservice: DataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {


    this.lookupInmemoryData();


  }


  lookupInmemoryData() {
    
    // if (this.dataservice.customerGetFlag && this.dataservice.customerListState == "grid"){
    //   console.log(this.dataservice.productEdited,this.dataservice.customerGetFlag);

    //   this.initialCall();

    // }else{
      const length = this.dataservice.inmemoryData.length;

      if (length >= 0) {
  
        this.urlindex = this.dataservice.inmemoryData.findIndex(element => element.url === this.url);
  
        if (this.urlindex > -1) {
          this.data.emit(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0]);
        }
        else {
          this.initialCall();
        }
  
        
  
      }
      else {
        this.initialCall();
      }
    // }


    



  }



  initialCall() {

    let obj: inmemoryCell = {
      url : this.url,
      multicollectionarry: [],
      datatype : this.getDataType(this.url)
    };

    
    this.dataservice.inmemoryData.push(obj);
    this.dataservice.getCommon(this.url).subscribe(data => {
      


      console.log(data.json()); 
     

      this.urlindex = this.dataservice.inmemoryData.findIndex(element => element.url === this.url);


      this.paginatingFunc(data.json().results, data.json().count, this.urlindex);


    },
      error => {
        console.log(error);
      }
    )
  }
 


  paginatingFunc(result, numberofentry, urlindex) {
    console.log(numberofentry,result.length);

    let numberdividefactor = 0
    if (result.length <= numberofentry) {

      numberdividefactor = result.length;
    }
    else {
      numberdividefactor = numberofentry
    }





    const numberofpage = numberofentry / numberdividefactor;



    console.log(numberofpage);


    if (numberofpage > 1) {

      for (let index = 0; index < Math.trunc(numberofpage) + 1; index++) {

        const emptycell: any[] = [];
        

        this.dataservice.inmemoryData[urlindex].multicollectionarry.push(emptycell);

      }


      this.dataservice.inmemoryData[urlindex].multicollectionarry[0] = result;


      this.data.emit(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
      console.log(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]); 

    } 
    else {

      this.dataservice.inmemoryData[urlindex].multicollectionarry[0] = result;


      this.data.emit(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]);
      console.log(this.dataservice.inmemoryData[urlindex].multicollectionarry[0]); 


    }


  }



  paginate(pagenumber) {

    this.indexs = pagenumber;
    this.dataservice.pageNumber = pagenumber;

    console.log("pagenumber",this.dataservice.pageNumber);


    this.urlindex = this.dataservice.inmemoryData.findIndex(element => element.url === this.url);




    if (this.dataservice.inmemoryData[this.urlindex].multicollectionarry[pagenumber].length > 0) {

      this.data.emit(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[pagenumber]);
    }
    else {
      this.getNextResult(pagenumber + 1, this.urlindex)

    }

  }



  getNextResult(pagenumber, urlindex) {

    this.dataservice.getCommonWithNextPage(this.url, pagenumber).subscribe(data => {



      // this.multicollectionarry[pagenumber - 1] = data.json().results;

      this.dataservice.inmemoryData[urlindex].multicollectionarry[pagenumber - 1] = data.json().results;


      this.data.emit(this.dataservice.inmemoryData[urlindex].multicollectionarry[pagenumber - 1]);

    },
      error => {

        console.log(error);

      }
    )

  }





  getDataType(url) {



    switch (url) {
      case 'api/v1/userlist/':

        return 'customer-store';

      case 'api/v1/productsf/':

        return 'product';

      case 'api/v1/productv3/' :
        return 'product'

      case 'api/v1/productinv/' :
        return 'inventory'

      default:

        return ''
    }
  }





}


