import { OnInit } from "@angular/core";
import { DataService } from "../data.service";
var UsersettingComponent = /** @class */ (function () {
    function UsersettingComponent(dataobj) {
        this.dataobj = dataobj;
    }
    UsersettingComponent.prototype.ngOnInit = function () {
        this.dataobj.getUserDetail().subscribe(function (data) {
            console.log(data.json());
        }, function (error) {
            console.log(error);
        });
    };
    return UsersettingComponent;
}());
export { UsersettingComponent };
