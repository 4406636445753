import { ProductNewComponent } from './product-new/product-new.component';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CustomerPortalComponent } from './customer-portal/customer-portal.component';
import { ProductsComponent } from './products/products.component';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
// v2
import { Dashboradv2Component } from './dashborad/dashboardv2.component';
// addcustomer
import { AddcustomerComponent } from './addcustomer/addcustomer.component';
import { ProductCatComponent } from './product-cat/product-cat.component';
import { AddstorefrontComponent } from './addstorefront/addstorefront.component';
import { ViewstorefrontComponent } from './viewstorefront/viewstorefront.component';
import { MultiSfComponent } from './multi-sf/multi-sf.component';
import { ProtectedStorefrontPreviewComponent } from './protected-storefront-preview/protected-storefront-preview.component';
import { StoresViewComponent } from './stores-view/stores-view.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { EditStorefrontComponent } from './edit-storefront/edit-storefront.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditBundleComponent } from './edit-bundle/edit-bundle.component';
import { LearnComponent } from './learn/learn.component';
// ordersv2
import { Ordersv2Component } from './ordersv2/ordersv2.component';
import { CustomerStoreViewComponent } from './customer-store-view/customer-store-view.component';
import { MultipleProdctsComponent } from './multiple-prodcts/multiple-prodcts.component';
import { UsersettingComponent } from './usersetting/usersetting.component';
import { UtilityComponent } from './utility/utility.component';
// inventory 
import { InventoryComponent } from './inventory/inventory.component';
import { DraftComponent } from './draft/draft.component';
import { BundlesComponent } from './bundles/bundles.component';
import { AddbundleComponent } from './addbundle/addbundle.component';
var customerBreadcrumb = { label: 'Customer', url: '/customers' };
var listStoreFrontsBreadcrumb = {
    label: 'List Store Fronts',
    url: '/liststorefronts/',
    isId: true
};
var productBreadcrumb = { label: 'Products', url: '/productcatalogue' };
var bundleBreadcrumb = { label: 'Bundles', url: '/bundles' };
var inventoryBreadcrumb = { label: 'Inventory', url: '/inventtory' };
var ɵ0 = {
    url: [customerBreadcrumb, { label: 'Add Customer', url: '' }]
}, ɵ1 = {
    url: [productBreadcrumb, { label: 'Edit Product', url: '' }]
}, ɵ2 = {
    url: [productBreadcrumb, { label: 'Edit Bundle', url: '' }]
}, ɵ3 = {
    url: [customerBreadcrumb, { label: 'Edit Store', url: '' }]
}, ɵ4 = {
    url: [customerBreadcrumb, { label: 'Edit Store', url: '' }]
}, ɵ5 = {
    url: [
        customerBreadcrumb,
        listStoreFrontsBreadcrumb,
        { label: 'Edit Store', url: '' }
    ]
}, ɵ6 = { url: [customerBreadcrumb, { label: 'Edit Customer', url: '' }] }, ɵ7 = {
    url: [
        customerBreadcrumb,
        listStoreFrontsBreadcrumb,
        { label: 'Add Store Front', url: '' }
    ]
}, ɵ8 = {
    url: [
        customerBreadcrumb,
        listStoreFrontsBreadcrumb,
        { label: 'Add Store Front', url: '/addstorefront/', isId: true },
        { label: 'Add Multiple store Front', url: '' }
    ]
}, ɵ9 = {
    url: [customerBreadcrumb, { label: 'List Store Fronts', url: '' }]
}, ɵ10 = {
    url: [
        customerBreadcrumb,
        listStoreFrontsBreadcrumb,
        { label: 'Add Products', url: '' }
    ]
}, ɵ11 = {
    url: [productBreadcrumb, { label: 'Add Product', url: '' }]
}, ɵ12 = {
    url: [bundleBreadcrumb, { label: 'Add Bundle', url: '' }]
}, ɵ13 = {
    url: [productBreadcrumb, { label: 'Add Product', url: '' }]
};
var routes = [
    {
        path: 'addcustomer',
        component: AddcustomerComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: 'inventory',
        component: InventoryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'usersetting',
        component: UsersettingComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'utility',
        component: UtilityComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'draft',
        component: DraftComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'learn',
        component: LearnComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'editproduct/:prod_id',
        component: EditProductComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'editbundle/:bundle_id',
        component: EditBundleComponent,
        canActivate: [AuthGuard],
        data: ɵ2
    },
    {
        path: 'editstore/:storefront_id',
        component: EditStorefrontComponent,
        canActivate: [AuthGuard],
        data: ɵ3
    },
    {
        path: 'editstore/listproducts/:storefront_id',
        component: EditStorefrontComponent,
        canActivate: [AuthGuard],
        data: ɵ4
    },
    {
        path: 'liststorefronts/editstore/:cust_id/:storefront_id',
        component: EditStorefrontComponent,
        canActivate: [AuthGuard],
        data: ɵ5
    },
    {
        path: 'editcustomer/:cust_id',
        component: EditCustomerComponent,
        canActivate: [AuthGuard],
        data: ɵ6
    },
    {
        path: 'addstorefront/:cust_id',
        component: AddstorefrontComponent,
        canActivate: [AuthGuard],
        data: ɵ7
    },
    // {
    //   path: "liststorefronts/addstorefront/:cust_id",
    //   component: AddstorefrontComponent,
    //   canActivate: [AuthGuard],
    //   data: {
    //     url: [
    //       { label: "Customer", url: "/customers" },
    //       { label: "List Store Fronts", url: "/liststorefronts/", isId: true },
    //       { label: "Add Store Front", url: "" }
    //     ]
    //   }
    // },
    {
        path: 'addmultiplestorefront/:cust_id',
        component: MultiSfComponent,
        canActivate: [AuthGuard],
        data: ɵ8
    },
    {
        path: 'liststorefronts/:cust_id',
        component: ViewstorefrontComponent,
        canActivate: [AuthGuard],
        data: ɵ9
    },
    {
        path: 'addproducts/:cust_id',
        component: MultipleProdctsComponent,
        canActivate: [AuthGuard],
        data: ɵ10
    },
    {
        path: 'productcatalogue',
        component: ProductCatComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'bundles',
        component: BundlesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        component: Dashboradv2Component,
        canActivate: [AuthGuard]
    },
    {
        path: 'stores',
        component: StoresViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login/:login_type',
        component: LoginComponent
    },
    {
        path: 'storelist_protected',
        component: ProtectedStorefrontPreviewComponent
    },
    {
        path: 'customers',
        component: CustomerStoreViewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'addproducts',
        component: MultipleProdctsComponent,
        canActivate: [AuthGuard],
        data: ɵ11
    },
    {
        path: 'addbundle',
        component: AddbundleComponent,
        canActivate: [AuthGuard],
        data: ɵ12
    },
    {
        path: 'addproduct',
        component: ProductNewComponent,
        canActivate: [AuthGuard],
        data: ɵ13
    },
    {
        path: 'orders',
        component: Ordersv2Component
    },
    {
        path: 'orders/:cust_id',
        component: Ordersv2Component
    },
    {
        path: 'customersprotal',
        component: CustomerPortalComponent
    },
    {
        path: 'productlist/:id/:user',
        component: ProductsComponent
    },
    {
        path: '',
        redirectTo: '/customers',
        pathMatch: 'full'
    },
    {
        path: 'customersprotal',
        component: CustomerPortalComponent
    },
    {
        path: 'productlist/:id/:user',
        component: ProductsComponent
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
