import { Component, OnInit } from '@angular/core';
import { DataService } from './../data.service';


// import { Transform } from 'stream';

@Component({
  selector: 'app-dashboradv2',
  templateUrl: './dashboardv2.component.html',
  styleUrls: ['./dashboardv2.component.css']
})
export class Dashboradv2Component implements OnInit {

    constructor(private dataservice: DataService) {
        this.dataservice.showHide(true)
      }

    ngOnInit(){}
}