import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule, Router } from "@angular/router";
import { Observable } from "rxjs/Rx";
// import { CustomersComponent } from './customers/customers.component';
import { DataService } from "./data.service";
import { Subscription } from "rxjs/Subscription";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { DialogComponent } from "./dialog/dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.componentv2.html",
  styleUrls: ["./app.componentv2.css"]
})
export class AppComponent {
  urlname: any;
  list = false;

  subscription: Subscription;

  commonApp: any;
  val = 0;
  urls: any;
  width = 50;
  left = 50;
  padding = 15;
  left_nav = 0;
  navbar_set = 0;
  displaytype = 'block';

  constructor(
    private dataservice: DataService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.dataservice.subBoolShowHide$.subscribe(
      (data: boolean) => {
        this.commonApp = data;
      },
      error => console.log(error),
      () => console.log("winner winner chicken dinner")
    );
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      this.urlname = event["url"];
      // console.log(this.urlname);
      // if(this.urlname == '/dashboard'){
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else if(this.urlname == '/customers')
      // {
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else if(this.urlname == '/customfilter'){
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else if(this.urlname == '/login'){
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else if(this.urlname == '/customerlist'){
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else if(this.urlname == '/multiProducts'){
      //   this.urls = true;
      //   console.log(this.urls)
      // }
      // else{
      //   this.urls = false;
      //   console.log(this.urls)
      // }
      // console.log(this.urlname);
    });
    // console.log("Hello from appComponent")

    // this.commonApp = this.dataservice.commonApp ;
  }

  onClickOutside() {
    this.width = 50;
    this.left = 50;
    this.padding = 15;
    this.left_nav = 0;
    // console.log(this.padding,'15')
    //console.log(this.padding,'15')
  }

  showMenu() {
    this.width = this.width == 250 ? 50 : 250;
    this.left = this.left == 250 ? 50 : 250;
    this.padding = this.padding == 32 ? 15 : 32;
    this.left_nav = this.left_nav = 250 ? 0 : 250;
    //console.log(this.padding,'32')
  }

  customer_reload() {
    window.location.reload();
  }
  logout() {
    this.navbar_set = 1;
    sessionStorage.removeItem("token");
    this.commonApp = "";
    this.val = 1;
    this.router.navigateByUrl("/login/admin");
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Logout", content: "Are you sure?", type: "question" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      if (result) {
        if (result == "yes") {
          this.logout();
        }
      }

      // this.animal = result;
    });
  }


}
