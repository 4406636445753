import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChild } from '@angular/core';

import { m_product_obj, userstorefront_obj, p_sf, product_obj, Inventory, productinventory_set, m_customers_obj } from '../models';
import { product_image_obj } from '../models';

import { DataService } from './../data.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from '@angular/platform-browser';
import { MatDialog, Sort, MatTableDataSource } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { isNgTemplate } from '@angular/compiler';
import { RouterModule, Routes, Router } from '@angular/router';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
  selector: 'app-product-cat',
  templateUrl: './product-cat.component.html',
  styleUrls: ['./product-cat.component.css']
})
export class ProductCatComponent implements OnInit {
  product_collection: m_product_obj[] = [];
  subscription: Subscription;
  public selectedVal: string;
  dataSource = new MatTableDataSource([]);
  @ViewChild(PaginatorComponent) paginatorComponent: PaginatorComponent;

  searchText;
  displayedColumns: string[] = [
    'id',
    'image',
    'title',
    'category_name',
    'code',
    'color',
    'size',
    'price',
    'active',
    'sfs',
    'asfs',
    'dsf'

    // "btn2"
  ];

  selectedSort;
  asedesSort;
  sortParams = [
    {
      viewValue: 'Title',
      value: 'title',
      ascending: null
    },
    {
      viewValue: 'Price',
      value: 'price'
    },
    {
      viewValue: 'Category',
      value: 'category_name'
    },
    {
      viewValue: 'Latest',
      value: 'latest'
    }
  ];


  

  selectedstorefronts: userstorefront_obj[] = [];

  constructor(
    private dataservice: DataService,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private route: Router
  ) { }

  public onValChange(val: string) {
    this.selectedVal = val;
    this.dataservice.productListState = val
  }

  // url = 'api/v1/productsf/';
  url = 'api/v1/productv3/';

  ngOnInit() {
    this.selectedVal = this.dataservice.productListState;
    this.selectedSort = 'latest';

    this.dataservice.showHide(true);



  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    console.log('after view init');
    this.subscription = this.dataservice.sfDeleteId$.subscribe(id => {
      console.log(id);

      if (id != 0){
        this.removeSfAllProducts(id);
      }
      
    })
  }

  onData($event) {
    console.log($event);
    this.product_collection = $event;
    this.dataSource = new MatTableDataSource(this.product_collection);
  }

  deleteProduct(event, prodId, index) {
    event.stopPropagation();
    console.log(prodId);
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete',
        content:
          'Are you sure, you want to delete this product?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result == "yes") {
        console.log("delete it");
        this.dataservice.deleteProductPatch(prodId).then(
          response => {
            console.log(response);
            console.log(index);
            this.product_collection.splice(index, 1)
            this.updateProductInCustomer();

          },
          (err) => {
            console.log(err);

          }
        )

      }


    });


  }

  goToStorefront(sfid) {
    console.log(sfid);
    this.route.navigate(['/editstore/' + sfid])
  }

  showInventory(prodId) {
    // console.log(prodId);
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete',
        content: prodId,
        type: 'showInventory'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        
      }

      // this.animal = result;
    });

  }

  sortDataList(sort: String) {
    if (!this.dataSource) return;
    this.dataSource.filter = sort.trim().toLowerCase();
  }

  editProd(item: m_product_obj) {
    this.dataservice.selected_product = item;

    this.route.navigate(['/editproduct/' + item.id + '']);
  }

  getImage(objx: product_image_obj[]) {
    let obj: product_image_obj[] = objx;

    if (obj.length > 0) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${obj[0].image})`);
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(
        `url(${'assets/images/no-image.jpg'})`
      );
    }
  }
  getImage2(objx:string) {
    // let obj: productinventory_set[] = objx;

    // console.log(obj);

    if (objx != null) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${objx})`);
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(
        `url(${'assets/images/no-image.jpg'})`
      );
    }
  }

  removeSf(sfname, sfid, pname, pid, uid) {
    this.openDeleteDialog(sfname, sfid, pname, pid, uid);
  }

  openDeleteDialog(sfname, sfid, pname, pid, uid): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete',
        content:
          'Are you sure, you want to delete product ' +
          pname +
          ' from storefront ' +
          sfname +
          ' ?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        if (result == 'yes') {
          this.deleteSf(sfid, pid, uid);
        }
      }

      // this.animal = result;
    });
  }

  deleteSf(sfid, pid, uid) {
    console.log(sfid, pid);

    let obj = { product: pid, storefront: sfid };

    this.dataservice.deleteSffromProduct(obj).subscribe(
      data => {
        console.log(data.json());
        // this.dataservice.removeProductObjFromStore(pid,sfid,uid);
        this.updateProductInCustomer();
        this.removeSfFromStatic(sfid, pid);

      },
      error => {
        console.log(error);
      }
    );
  }

  removeSfAllProducts(sfid) {
    console.log('calling');
    for (let index = 0; index < this.product_collection.length; index++) {
      for (
        let indexx = 0;
        indexx < this.product_collection[index].storefront_product.length;
        indexx++
      ) {
        if (
          this.product_collection[index].storefront_product[indexx].id ===
          sfid
        ) {
          this.product_collection[index].storefront_product.splice(indexx, 1);
          console.log(this.product_collection[index].storefront_product);
          break;
        }
      } 

    }
  }

  removeSfFromStatic(sfid, pid) {
    for (let index = 0; index < this.product_collection.length; index++) {
      if (this.product_collection[index].id === pid) {
        for (
          let indexx = 0;
          indexx < this.product_collection[index].storefront_product.length;
          indexx++
        ) {
          if (
            this.product_collection[index].storefront_product[indexx].id ===
            sfid
          ) {
            this.product_collection[index].storefront_product.splice(indexx, 1);
            console.log(this.product_collection[index].storefront_product);
            break;
          }
        }
      }
    }
  }

  openAddDialog(item: m_product_obj): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Select Storefronts',
        content: [],
        type: 'selectstorefront'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      this.selectedstorefronts = result;

      if (this.selectedstorefronts.length > 0) {
        this.addPtoSf(item);
      }
    });
  }

  addPtoSf(item: m_product_obj) {

    console.log(item);
    this.selectedstorefronts.forEach(element => {


      let index = item.storefront_product.findIndex((elementx) => {

        return (elementx.id === element.id)
      })

      if (index === -1) {
        this.addPtoSfDatas(item.id, element.name, element.id, item, element.user, element.id, element.status);
      }


    });
  }

  addPtoSfDatas(pid, sfname, sfid, a, b, c, status) {
    let obj = { product: pid, storefront: sfid };

    this.dataservice.addSftoProduct(obj).subscribe(
      data => {
        console.log(data.json());
        this.updateProductInCustomer();

        this.paginatorComponent.url = this.url
        this.paginatorComponent.initialCall();

        // this.addPtoSfStatic(sfid, sfname, pid,status);


      },
      error => {
        console.log(error);
      }
    );
  }

  addPtoSfStatic(sfid, sfname, pid, status) {
    for (let index = 0; index < this.product_collection.length; index++) {
      if (this.product_collection[index].id === pid) {
        let obj: p_sf = { id: sfid, name: sfname, is_active: true, status: status };

        this.product_collection[index].storefront_product.unshift(obj);

        break;
      }
    }
  }

  urlindex = 0

  updateProductInCustomer() {

    if (length > 0) {
      this.urlindex = this.dataservice.inmemoryData.findIndex(element => element.url === "api/v1/userlist/");
      this.dataservice.customerGetFlag = true;
    }

  }

  toggleProductstaus(pid, status) {
    let obj = { product: pid, is_active: status };
    this.dataservice.toggleProductStatus(obj).subscribe(
      data => {
        console.log(data);

        this.dataservice.productEdited = true;
        this.updateProductInCustomer();

        this.toggleProductStatusStatic(pid, status);
      },
      error => {

      }
    );
  }

  toggleProductStatusStatic(pid, status) {
    for (let index = 0; index < this.product_collection.length; index++) {
      if (this.product_collection[index].id === pid) {
        this.product_collection[index].active = status;
      }
    }
  }

  onToggle(evt, id) {

    console.log(evt.checked, id);
    this.toggleProductstaus(id, evt.checked)
  }

  openArchiveDialog(pid, status): void {
    let obj = this.product_collection.find(items => items.id === pid);

    let storestring: string[] = [];
    obj.storefront_product.forEach(element => {
      storestring.push(element.name);
    });

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Archive',
        content:
          'Are you sure you want to archive product ' +
          obj.title +
          ', by doing so the product will not be listed in ' +
          storestring.toString(),
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        if (result == 'yes') {
          this.toggleProductstaus(pid, status);
        }
      }

      // this.animal = result;
    });
  }

  sortDataDropDown() {
    console.log(this.selectedSort);
    if (this.selectedSort == "latest") {
      this.dataservice.getCommon(this.url).subscribe(res => {
        console.log(res.json());
        let obj = res.json();
        this.product_collection = obj['results'];
      })

    } else {
      let bool;
      if (this.selectedSort === this.asedesSort) {
        bool = false;
      } else {
        bool = true;
      }
      this.asedesSort = this.selectedSort;
      if (bool) {
        this.product_collection.sort(
          this.dataservice.dynamicSort(this.selectedSort)
        );
      } else {
        this.product_collection
          .sort(this.dataservice.dynamicSort(this.selectedSort))
          .reverse();
        this.asedesSort = '';
      }
    }

    console.log(this.product_collection);
  }

  sortData(sort: Sort) {
    const data = this.product_collection.slice();
    if (!sort.active || sort.direction === '') {
      this.product_collection = data;
      return;
    }

    this.product_collection = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'title':
          return this.compare(a.title, b.title, isAsc);
        case 'sfs':
          return this.compare(
            a.storefront_product.length,
            b.storefront_product.length,
            isAsc
          );
        case 'price':
          return this.compare(a.price, b.price, isAsc);
        case 'status':
          return this.compare(a.active, b.active, isAsc);
        case 'category_name':
          return this.compare(a.category_name, b.category_name, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);

        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | boolean,
    b: number | string | boolean,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  //make featured 

  onChange(event, index, item) {


    console.log(index, event, item);
    this.toggleProductFeaturedstaus(event.checked, item);

  }

  toggleProductFeaturedstaus(status, item) {
    let obj = { is_featured: status };
    this.dataservice.updateProduct(obj, item.id).subscribe(
      data => {
        console.log(data);
        item.is_featured = status;

      },
      error => { }
    );
  }

  colorDialogue(color) {
    console.log(color);

    let colArr = color.split(",")

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Color Options',
        content: colArr,
        type: 'colorsizeinfo'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {

      }
    });

  }
  sizeDialogue(size) {
    console.log(size);
    let colArr = size.split(",")

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Color Options',
        content: colArr,
        type: 'colorsizeinfo'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {

      }
    });

  }

  previewStore(item: userstorefront_obj, customer: m_customers_obj, evt) {
    if (evt) {
      evt.stopPropagation()

    }
    let slug = item.slug;
    console.log(item.slug,customer);
    if (!slug) {
      alert('No Url Added');
      return;
    } else {
      this.openStoreFront(item.name_slug, slug);
    }

    // else{
    //   if (item.status.toLocaleLowerCase() === 'archive') {
    //       const dialogRef = this.dialog.open(DialogComponent, {
    //         data: {
    //           title: 'Archived store',
    //           content: `Store is archived, press okay to view store`,
    //           type: 'confirmation'
    //         }
    //       });

    //       dialogRef.afterClosed().subscribe(result => {
    //         if (result) {

    //           console.log("", result)
    //           if (result === 'okay') {

    //             slug = item.slug + "/?is_admin=true";

    //             this.openStoreFront(slug);

    //           }
    //         }

    //       });
    //     }else{


    //     }

    // }

    // if (customer.status.toLowerCase() === 'archive') {
    //   const dialogRef = this.dialog.open(DialogComponent, {
    //     data: {
    //       title: 'Archived customer',
    //       content: `Customer status is archived, press okay to view store`,
    //       type: 'confirmation'
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {

    //       console.log("", result)
    //       if (result === 'okay') {

    //         slug = item.slug + "/?is_admin=true";

    //         this.openStoreFront(slug);

    //       }
    //     }

    //   });
    // }

    // else if (item.status.toLocaleLowerCase() === 'archive') {
    //   const dialogRef = this.dialog.open(DialogComponent, {
    //     data: {
    //       title: 'Archived store',
    //       content: `Store is archived, press okay to view store`,
    //       type: 'confirmation'
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {

    //       console.log("", result)
    //       if (result === 'okay') {

    //         slug = item.slug + "/?is_admin=true";

    //         this.openStoreFront(slug);

    //       }
    //     }

    //   });
    // } else {

    //   this.openStoreFront(slug);
    // }
  }

  openStoreFront(customer, url) {
    window.open(
      `${this.dataservice.storeUrl}/${customer}/${url}`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800'
    );
  }

}
