import { purchase_user } from './../models';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { order_obj, searchparams, tags, userstorefront_obj } from '../models';
import { MatDialog, MatTableDataSource, Sort } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { CustomerPortalComponent } from '../customer-portal/customer-portal.component';

import { MatSnackBar } from '@angular/material';
import { CopyUrl } from '../multiple-prodcts/multiple-prodcts.component';


export class param_m {
  param_key: string;
  param_view_value: string;
  param_value: any;
}

@Component({
  selector: 'app-ordersv2',
  templateUrl: './ordersv2.component.html',
  styleUrls: ['./ordersv2.component.css']
})
export class Ordersv2Component implements OnInit {
  selectedTab = 0;
  cust_details: any;
  order_id: any;
  customername: any;
  exptedD: any;
  delievryNotes = '';
  Cemail: any;
  informCust = false;
  hasData: boolean = false;
  order_status = '0';
  trackid = '';
  estimatedD: any;
  Prodelivered = false;
  couriertype: any;
  order_collection: order_obj[] = [];
  checksbox = false;
  obj = {};

  selected_orders: order_obj[] = [];

  // searchparam: searchparams;

  searchparam: searchparams = {
    puchaser_name: '',
    storefront_collection: [],
    customer_collection: [],
    order_status: [],
    custom_date: '',
    order_date: '',
    is_archive: '',
    order_id: '',
    tags: []
  };

  matlist: param_m[] = [];

  datasource;

  constructor(
    private dataservice: DataService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private excel: CustomerPortalComponent,
    private snackbar: MatSnackBar


  ) { }

  sortData(sort: Sort) {
    const data = this.order_collection.slice();
    if (!sort.active || sort.direction === '') {
      this.order_collection = data;
      return;
    }

    console.log(this.order_collection);
    this.order_collection = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'customer_name':
          return this.compare(a.customer_name, b.customer_name, isAsc);
        case 'total_amount':
          return this.compare(a.total_amount, b.total_amount, isAsc);
        case 'created_v1':
          return this.compare(
            new Date(a.created_v1).getTime(),
            new Date(b.created_v1).getTime(),
            isAsc
          );
        case 'storefront':
          return this.compare(a.storefront, b.storefront, isAsc);
        case 'status':
          return this.compare(a.order_tracking, b.order_tracking, isAsc);
        case 'payment_mode':
          return this.compare(a.payment_status, b.payment_status, isAsc);

        default:
          return 0;
      }
    });
  }

  compare(
    a: number | string | boolean,
    b: number | string | boolean,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnInit() {
    //  this.dataservice.orderFilterV2().subscribe(data => {

    //  },
    //  error => {
    //    console.log(error)
    //  })

    this.route.params.subscribe(params => {
      let custid: string = params['cust_id'];
      if (custid) {
        if (custid.length > 10) {
          this.dataservice.getSingleCustomer(custid).subscribe(
            data => {
              this.searchparam.customer_collection = data.json().results;
              this.createMatlist();
            },
            error => {
              console.log(error);
            }
          );
        } else {
          if (this.dataservice.selected_storefront) {
            this.searchparam.storefront_collection.push(
              this.dataservice.selected_storefront
            );
            this.createMatlist();
          } else {
            this.dataservice.getSingleStore(custid).subscribe(
              data => {
                this.searchparam.storefront_collection.push(data.json());
                this.createMatlist();
              },
              error => {
                console.log(error);
              }
            );
          }
        }
      }
    });
  }

  openFilters(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Filters', content: '', type: 'filter' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'no') {
        this.searchparam = result;
        this.createMatlist();
      }
    });
  }

  createMatlist() {
    this.matlist = [];

    if (this.searchparam.puchaser_name) {
      let parm = new param_m();

      parm.param_key = 'purchaser_name';
      parm.param_view_value =
        'Customer name: ' + this.searchparam.puchaser_name;
      parm.param_value = this.searchparam.puchaser_name;

      this.matlist.push(parm);
    }

    if (this.searchparam.is_archive === 'true') {
      let parm = new param_m();

      parm.param_key = 'is_archived';
      parm.param_view_value = 'Show archived orders: ' + 'True';
      parm.param_value = 1;

      this.matlist.push(parm);
    }
    if (this.searchparam.is_archive === 'false') {
      let parm = new param_m();

      parm.param_key = 'is_archived';
      parm.param_view_value = 'Show archived orders: ' + 'False';
      parm.param_value = 0;

      this.matlist.push(parm);
    }

    if (this.searchparam.order_date) {
      let parm = new param_m();

      parm.param_key = 'order_date';
      parm.param_view_value = '' + this.searchparam.order_date['key'];
      parm.param_value = this.searchparam.order_date['value'];

      this.matlist.push(parm);
    }

    this.searchparam.customer_collection.forEach(element => {
      let parm = new param_m();

      parm.param_key = 'customer_id';
      parm.param_view_value = 'Owner: ' + element.name;
      parm.param_value = element.id;

      this.matlist.push(parm);
    });

    this.searchparam.tags.forEach(element => {
      let parm = new param_m();

      parm.param_key = 'tags';
      parm.param_view_value = 'tag: ' + element.name;
      parm.param_value = element.id;

      this.matlist.push(parm);
    });

    this.searchparam.storefront_collection.forEach(element => {
      let parm = new param_m();

      parm.param_key = 'store_id';
      parm.param_view_value = 'Storefront: ' + element.name;
      parm.param_value = element.id;

      this.matlist.push(parm);
    });

    this.searchparam.order_status.forEach(element => {
      let parm = new param_m();

      parm.param_key = 'order_status';
      parm.param_view_value = 'Order status: ' + element.viewvalue;
      parm.param_value = element.value;

      this.matlist.push(parm);
    });

    if (this.searchparam.custom_date) {
      let parm = new param_m();

      parm.param_key = 'custom_date';
      parm.param_view_value = 'Order date: ' + this.searchparam.custom_date;
      parm.param_value = this.searchparam.custom_date;

      this.matlist.push(parm);
    }

    if (this.searchparam.order_id) {
      let parm = new param_m();

      parm.param_key = 'order_id';
      parm.param_view_value = 'Order Id: ' + this.searchparam.order_id;
      parm.param_value = this.searchparam.order_id;

      this.matlist.push(parm);
    }

    this.getData();
  }

  createSearchParams() {
    this.obj = {};

    this.matlist.forEach(element => {
      if (this.obj.hasOwnProperty(element.param_key)) {
        if (this.obj[element.param_key]) {
          this.obj[element.param_key] =
            this.obj[element.param_key] + ',' + element.param_value;
        }
      } else {
        this.obj[element.param_key] = element.param_value;
      }
    });

    return this.obj;
  }

  remove(i) {
    this.matlist.splice(i, 1);

    this.getData();

    // this.createSearchParams();
  }

  getData() {
    // tslint:disable-next-line: curly
    if (this.matlist.length === 0) return;

    this.multicollectionarry = [];

    this.dataservice.orderFilterV2(this.createSearchParams()).subscribe(
      data => {
        this.paginatingFunc(data.json().results, data.json().count);
      },
      error => {
        console.log(error);
      }
    );
  }

  multicollectionarry: order_obj[][] = [];
  indexs = 0;

  paginatingFunc(result, numberofentry) {

    console.log(result);
    let numberdividefactor = 0;
    if (result.length <= numberofentry) {
      numberdividefactor = result.length;
    } else {
      numberdividefactor = numberofentry;
    }

    const numberofpage = numberofentry / numberdividefactor;

    if (numberofpage > 1) {
      for (let index = 0; index < Math.trunc(numberofpage) + 1; index++) {
        let storefront_collection: any[] = [];

        this.multicollectionarry.push(storefront_collection);
      }

      this.multicollectionarry[0] = result;

      this.order_collection = this.multicollectionarry[0];
    } else {
      this.order_collection = result;
      console.log(this.order_collection);
    }
  }

  paginate(pagenumber) {
    this.indexs = pagenumber;

    if (this.multicollectionarry[pagenumber].length > 0) {
      this.order_collection = this.multicollectionarry[pagenumber];
    } else {
      this.getNextResult(pagenumber + 1);
    }
  }

  getNextResult(pagenumber) {
    const obj = this.createSearchParams();

    obj['page'] = pagenumber;

    this.dataservice.orderFilterV2(obj).subscribe(
      data => {
        this.multicollectionarry[pagenumber - 1] = data.json().results;

        this.order_collection = this.multicollectionarry[pagenumber - 1];
        console.log(this.order_collection);
      },
      error => {
        console.log(error);
      }
    );
  }

  bundlemainthing: any[][] = []

  transformedData: { name: string, products: any[] }[] = [];


  transformData(jsonData: any[]): { name: string, products: any[] }[] {
    
    const transformedDataMap: { name: string, products: any[] }[] = [];

    jsonData.forEach(item => {
        const categoryName = item.category_name;
        const productData = item;

        // Check if category already exists in transformedDataMap
        let existingCategory = transformedDataMap.find(category => category.name === categoryName);

        if (!existingCategory) {
            // If category doesn't exist, create a new entry
            existingCategory = { name: categoryName, products: [] };
            transformedDataMap.push(existingCategory);
        }

        // Push product_data into the products array
        existingCategory.products.push(productData);
    });

    
    return transformedDataMap;
}



  getdetails(i: any) {
    this.selectedTab = 0;
    this.cust_details = this.order_collection[i];

    // this.cust_details.bundles.forEach(element => {
    //   this.bundlemainthing.push(this.transformData(element.bundleinventory_set))
    // });

    console.log(this.bundlemainthing);
    this.order_id = this.cust_details.id;
    console.log(this.cust_details);

    if (this.cust_details.shipping_cost === null) {

      this.cust_details.shipping_cost = 0;
    }



    this.getTrackDetails();
    var parentname = this.cust_details.purchase_user.parent_first_name;
    var class_name = this.cust_details.purchase_user.class_name;
    var purchase_order = this.cust_details.purchase_user.purchase_order;
    this.customername = this.cust_details.purchase_user.name;
    this.Cemail = this.cust_details.purchase_user.email;
  }





  getTrackDetails() {
    let abc = '';
    this.hasData = false;

    this.dataservice.getTrackdetails(this.order_id).then(res => {
      this.estimatedD = res['delivery_date_v1'];
      this.order_status = res['status'];
      this.exptedD = res['departure_v1'];
      console.log(this.exptedD);
      this.delievryNotes = res['notes'];
      this.informCust = res['inform_customer'];
      this.trackid = res['tracking_id'];
      this.couriertype = res['delivery_service'];
      abc = res['status'];
      if (abc) {
        this.hasData = true;
      } else {
        this.hasData = false;
      }
    });
  }

  onTabchange($event) {
    this.selectedTab = $event;
  }

  get currentOrderStatus() {
    switch (this.order_status) {
      case '0':
        return 'received';
      case '1':
        return 'processed';
      case '2':
        return 'dispached';
      case '3':
        return 'delivered';

      default:
        break;
    }
  }

  close() {
    this.order_status = this.couriertype = this.estimatedD = this.trackid = this.delievryNotes =
      '';
    this.order_status = '0';
    this.getData();
  }
  changeOrderStatus($event) {
    this.order_status = $event.target.value;
  }

  ctype = '';
  otherService = '';

  courierType($event) {
    if ($event.target.value != 'other') {
      this.couriertype = $event.target.value;
    } else {
      this.couriertype = this.otherService;

    }
  }

  otherCourierChange($event) {
    this.couriertype = $event;

  }

  downloadPdf() {
    var data = document.getElementById('contentToConvert');

    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);


      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }




      let textfilename = 'receipt_' + this.order_id + '.pdf';

      //var obj = pdf.output('blob');

      pdf.save(textfilename); // Generated PDF
    });
  }

  sendemail() {
    var data = document.getElementById('contentToConvert');

    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      let textfilename = 'receipt_' + this.order_id + '.pdf';

      var obj = pdf.output('blob');

      this.dataservice.sendemail(this.order_id, obj).then(res => { });
      //pdf.save(textfilename); // Generated PDF
    });
  }

  inform($event) {
    this.informCust = $event.checked;
  }

  notes($event) {
    this.note1 = $event.checked;
  }

  note1 = false;
  form1() {
    console.log('form1');
  }

  // save status event
  saveState() {
    console.log(this.estimatedD);
    if (!this.hasData) {
      // this.estimatedD = new Date(this.estimatedD);
      // this.exptedD = new Date(this.exptedD);

      this.dataservice
        .postTrackdetails(
          this.couriertype,
          this.order_status,
          new Date(this.estimatedD),
          this.informCust,
          this.trackid,
          new Date(this.exptedD),
          this.delievryNotes,
          this.Prodelivered,
          this.order_id
        )
        .then(res => {
          console.log(res);
        });
    } else {
      //this.informCust = this.informCust['checked'];
      // this.estimatedD = new Date(this.estimatedD);
      // this.exptedD = new Date(this.exptedD);

      this.dataservice
        .patchTrackdetails(
          this.couriertype,
          this.order_status,
          new Date(this.estimatedD),
          this.informCust,
          this.trackid,
          new Date(this.exptedD),
          this.delievryNotes,
          this.Prodelivered,
          this.order_id
        )
        .then(res => {
          console.log(res);
        });
    }
  }

  selectedMode = '';

  selectionMode(btn: number) {
    switch (btn) {
      case 0:
        this.createExcel();
        // console.log(this.createSearchParams());
        // this.dataservice.getOrdersReport(this.createSearchParams()).subscribe(
        //   res => {
        //     console.log("report success");

        //     var FileSaver = require('file-saver');
        //     var blob = new Blob([res as BlobPart], { type: "text/plain;charset=utf-8" });
        //     FileSaver.saveAs(blob, "report.csv");
        //   }
        // )
        break;
      case 1:
        this.selectedMode = 'Bulk Update Orders';
        this.toggleSelectionMode();

        break;
      case 2:
        this.selectedMode = 'Add Tag to Orders';
        this.toggleSelectionMode();

        break;
      case 3:
        this.selectedMode = 'Archive Orders';
        this.toggleSelectionMode();

        break;

      case 4:
        this.createStampsExcel();
        break;

      default:
        break;
    }
  }

  toggleSelectionMode() {
    this.checksbox = this.checksbox ? false : true;

    if (this.checksbox) {
      this.selected_orders = [];
    }
  }

  selectItems(i, $event) {
    console.log(i, $event);
  }

  createExcel() {
    this.excel.createExcel(this.order_collection);
  }

  createStampsExcel() {
    this.excel.createStampsExcel(this.order_collection);
  }

  onCheck(item: order_obj) { }

  cancelEdit() {
    this.checksbox = false;
    this.selected_orders = [];
  }

  doEdit() {
    switch (this.selectedMode) {
      case 'Bulk Update Orders':
        this.bulkUpdate();

        break;

      case 'Add Tag to Orders':
        this.tagOrders();
        break;
      case 'Archive Orders':
        this.archiveOrders();
        break;

      default:
        break;
    }
  }

  //bulkupdate
  bulkUpdate() {
    this.openBulkUpdate();
  }

  openBulkUpdate(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Select status',
        content:
          'Do you wish to update status for orders  ' +
          this.getIds().toString() +
          '  to ',
        type: 'bulkupdate'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result !== 'no') {
          this.updateOrderStatus(this.getIds(), result);
        }
      }
    });
  }

  updateOrderStatus(ids, status) {
    this.dataservice.BulkstatusUpdate(ids, status).subscribe(
      data => {
        this.updateStatusStatic(status);
      },
      error => {
        console.log(error);
      }
    );
  }

  updateStatusStatic(status: number) {
    for (let index = 0; index < this.order_collection.length; index++) {
      if (this.order_collection[index].is_checked) {
        this.order_collection[
          index
        ].order_tracking = this.dataservice.orderstatuscollection[
          status
        ].viewvalue;
      }
    }

    //this.deselectAll();

    this.checksbox = false;
  }

  //archive
  archiveOrders() {
    this.openOrderArchive();
  }

  openOrderArchive(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Archive/Unarchive',
        content:
          'Do you wish to Archive/Unarchive orders    ' +
          this.getIds().toString(),
        type: 'archive'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'no') {
        let state = result ? 1 : 0;
        this.updateArchiveStatus(this.getIds(), state);
      }
    });
  }

  updateArchiveStatus(ids, status) {
    this.dataservice.addArchive(ids, status).subscribe(
      data => {
        this.updateArchiveStatic(status);
      },
      error => {
        console.log(error);
      }
    );
  }

  updateArchiveStatic(resukt: number) {
    for (let index = 0; index < this.order_collection.length; index++) {
      if (this.order_collection[index].is_checked) {
        if (resukt === 1) {
          this.order_collection[index].is_archived = true;
        } else {
          this.order_collection[index].is_archived = false;
        }
      }
    }

    //this.deselectAll();

    this.checksbox = false;
  }

  //addtag

  tagOrders() {
    this.openTagOrders();
  }

  openTagOrders(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Select tags',
        content: 'Add tag to orders ' + this.getIds().toString(),
        type: 'tags'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.length > 0) {
        let tags: tags[] = result;

        let arr = [];
        tags.forEach(element => {
          arr.push(element.id);
        });

        this.addTagsToOrders(arr, tags);
      }
    });
  }

  addTagsToOrders(tags, tagc: tags[]) {
    this.dataservice.postTagsOrder(this.getIds(), tags).subscribe(
      data => {
        this.checksbox = false;

        this.addTagsToOrderstatic(tagc);
      },
      error => { }
    );
  }

  addTagsToOrderstatic(tagx: tags[]) {
    for (let index = 0; index < this.order_collection.length; index++) {
      if (this.order_collection[index].is_checked) {
        tagx.forEach(element => {
          this.order_collection[index].tags.push(element);
        });
      }
    }
  }

  isSelected = false;
  allchecked = false;

  selectAll() {
    var value = this.allchecked ? false : true;
    this.order_collection.forEach(
      order => {
        order.is_checked = value
      }
    )
    this.getIds();
  }

  getIds() {
    let arr = [];
    this.order_collection.forEach(element => {
      if (element.is_checked) {
        arr.push(element.id);
      }
    });

    if (arr.length > 0) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }

    return arr;
  }

  deselectAll() {
    for (let index = 0; index < this.order_collection.length; index++) {
      this.order_collection[index].is_checked = false;
    }
    this.getIds();
  }

  //removeTag

  removeTag(tag: tags, order: order_obj) {
    this.openRemoveTag(tag, order);
  }

  openRemoveTag(tag: tags, order: order_obj): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Remove Tag',
        content:
          'Do you wish to remove tag ' +
          tag.name +
          ' from order ' +
          order.id +
          ' ?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'yes') {
          this.removeTagData(tag, order);
        }
      }
    });
  }

  removeTagData(tag: tags, order: order_obj) {
    let tagx = [];
    let orderx = [];

    tagx.push(tag.id);
    orderx.push(order.id);

    this.dataservice.removeTag(tagx, orderx).subscribe(
      data => {
        this.removeTagFromLocal(tag, order);
      },
      error => {
        console.log(error);
      }
    );
  }

  removeTagFromLocal(tag: tags, order: order_obj) {
    const arryindex = this.order_collection.indexOf(order);

    const tagindex = this.order_collection[arryindex].tags.indexOf(tag);

    this.order_collection[arryindex].tags.splice(tagindex, 1);
  }


  copyUrlToClipboard() {

    var custdetails = '';
    // if(this.cust_details.purchase_user.is_shipping_address){

    // custdetails = this.cust_details.purchase_user.name.trim() + "\n" + this.cust_details.purchase_user.address_s.trim() + " " +  this.cust_details.purchase_user.address_city_s.trim()+"," + " " + this.cust_details.purchase_user.address_state_s.trim() + "," + " " +this.cust_details.purchase_user.postal_code_s.trim() + "\n"
    // + this.cust_details.purchase_user.address_country_s.trim() + "\n"
    // + this.cust_details.purchase_user.email.trim() + "\n";
    // }else{
    custdetails = this.cust_details.purchase_user.name.trim() + "\n" + this.cust_details.purchase_user.address.trim() + " " + this.cust_details.purchase_user.address_city.trim() + "," + " " + this.cust_details.purchase_user.address_state.trim() + "," + " " + this.cust_details.purchase_user.postal_code.trim() + "\n"
      + this.cust_details.purchase_user.address_country.trim() + "\n"
      + this.cust_details.purchase_user.email.trim() + "\n";

    //  }
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (custdetails));
      e.preventDefault();
      this.openSnackBar();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  openSnackBar() {
    this.snackbar.openFromComponent(CopyUrl, {
      duration: 1500,
      horizontalPosition: "end",
      verticalPosition: "bottom"
    });
  }
}
function saveAs(blob: Blob, arg1: string) {
  throw new Error('Function not implemented.');
}

