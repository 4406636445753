import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
var InventoryComponent = /** @class */ (function () {
    function InventoryComponent(cd) {
        this.cd = cd;
        this.url = 'api/v1/productinv/';
        this.inventory_set = [];
        this.displayedColumns = ['id', 'product_name', 'color', 'size', 'quantity'];
    }
    InventoryComponent.prototype.ngOnInit = function () {
        console.log("onit");
        this.selectedVal = "grid";
    };
    InventoryComponent.prototype.onValChange = function (val) {
        this.selectedVal = val;
    };
    InventoryComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.inventory_set.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }
        this.sortedData = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'id': return _this.compare(a.id, b.id, isAsc);
                case 'product_name': return _this.compare(a.product_name, b.product_name, isAsc);
                case 'color': return _this.compare(a.color, b.color, isAsc);
                case 'size': return _this.compare(a.size, b.size, isAsc);
                case 'quantity': return _this.compare(a.quantity, b.quantity, isAsc);
                default: return 0;
            }
        });
    };
    InventoryComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    InventoryComponent.prototype.onData = function ($event) {
        console.log("ond");
        // console.log($event);
        this.inventory_set = $event;
        this.sortedData = this.inventory_set;
        this.dataSource = new MatTableDataSource(this.inventory_set);
        this.dataSource.sort = this.sort;
        console.log(this.inventory_set);
        // this.dataSource = new MatTableDataSource(this.product_collection);
    };
    return InventoryComponent;
}());
export { InventoryComponent };
