import { productinventory_set } from './../models';
// import { Component, OnInit } from '@angular/core';
import { Component, Inject, OnInit } from "@angular/core";
import { DataService } from "./../data.service";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";

import { COMMA, ENTER, O } from "@angular/cdk/keycodes";
import { ElementRef, ViewChild } from "@angular/core";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatAutocomplete,
  MatSnackBar
} from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

export interface DialogData {
  title: string;
  content: any;
  type: string;
}

// @Component({
//   selector: 'app-dialog',
//   templateUrl: './dialog.component.html',
//   styleUrls: ['./dialog.component.css']
// })
// export class DialogComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
  m_customers_obj,
  userstorefront_obj,
  order_status,
  searchparams,
  quickdate,
  tags,
  OrderUnit
} from "../models";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { element } from "protractor";

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "./dialog.component.html"
})
export class DialogComponent {
  selectedOwnerCol = new FormControl();
  selectedTags = new FormControl();

  order_date = new FormControl();
  is_archive = new FormControl(false);

  dates: string[] = ["Last 7 days", "Last 15 days", "Last 30 days"];

  quickdates: quickdate[] = [
    { id: 0, key: "Last 7 days", value: "", viewvalue: "" },
    { id: 1, key: "Last 15 days", value: "", viewvalue: "" },
    { id: 2, key: "Last 30 days", value: "", viewvalue: "" }
  ];

  quickdate: quickdate;

  selectedStoreCol = new FormControl();
  selectedOrderStatus = new FormControl();
  date = new FormControl();

  orderstatuscollection: order_status[] = [
    {
      value: "0",
      viewvalue: "Order Received"
    },
    {
      value: "1",
      viewvalue: "Order Processing"
    },
    {
      value: "2",
      viewvalue: "Order Dispatch"
    },
    {
      value: "3",
      viewvalue: "Order Delivered"
    }
  ];

  customercollection: m_customers_obj[] = [];
  selectedstorefronts: userstorefront_obj[] = [];

  selectedCustomer: m_customers_obj;

  orderId = '';

  searchparams: searchparams = {
    puchaser_name: "",
    storefront_collection: [],
    customer_collection: [],
    order_status: [],
    custom_date: "",
    order_date: "",
    is_archive: "",
    order_id: "",
    tags: []
  };

  searchText: string;

  reorderData: [OrderUnit][] = [];

  allColorChecked = false;
  allSizeChecked = false;
  allTagsChecked = false;
  selectedColors = [];
  selectedSizes = [];
  selectedStyleTags = [];
  mainInvArray = [];

  uniqueColorArray = [];
  uniqueTagsArray = [];
  uniqueSizeArray = [];


  constructor(
    private dataservice: DataService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private datepipe: DatePipe,
    private fb: FormBuilder,

  ) {
    // console.log(data);


    if (data.type === "selectstorefront") {
      this.getStoreFrontData(data.content);
    }

    if (data.type === "selectcustomer") {
      this.getStoreFrontData(data.content);
    }
    if (data.type === "filter") {
      this.getStoreFrontData(data.content);
      this.getTags();
    }
    if (data.type === "tags") {
      this.getTags();
    }
    if (data.type === "reorder") {
      this.reorderData = data.content as any;

      console.log(this.reorderData);
    }



    if (data.type === "colorAndsizeOptions") {
      console.log(data);
      let content = data.content
      this.uniqueColorArray = content.ca
      this.uniqueSizeArray = content.sa
      this.uniqueTagsArray = content.ta

      return

      this.uniqueColorArray = _.uniqBy(data.content, 'color')


      this.uniqueColorArray.forEach(color => {
        var arr = []
        data.content.forEach((element) => {
          if (element.color == color.color) {
            console.log(element.size);
            arr.push({ size: element.size, isChecked: false })
          }
        });
        var inv = { color: color.color, isChecked: false, size: arr }
        // console.log(inv);
        this.mainInvArray.push(inv);
      })
      console.log(this.mainInvArray);

    }

    if (data.type === "showInventory") {
      console.log(data.content);
      this.getSingleInventory(data.content);
    }
    if (data.type === "copystorefront") {
      console.log(data.content);


      this.getCustomerList();

    }
    if(data.type === 'addcombination'){
      this.comboForm.get('tag').valueChanges.subscribe(value => {
        console.log('Selected season:', value);
        // Perform any other actions based on the selected season
      });
      this.comboForm.get('size').valueChanges.subscribe(value => {
        console.log('Selected season:', value);
        // Perform any other actions based on the selected season
      });
    }


  }

  selectColor(index: number, isChecked: boolean) {
    this.selectedColors[index] = isChecked;
    this.allColorChecked = this.selectedColors.every(color => color);
  }
  selectAllColors() {
    for (let i = 0; i < this.uniqueColorArray.length; i++) {
      this.selectedColors[i] = this.allColorChecked;
    }
  }



  selectAllSizes() {
    for (let i = 0; i < this.uniqueSizeArray.length; i++) {
      this.selectedSizes[i] = this.allSizeChecked;
    }
  }

  selectAllTags() {
    for (const tag of this.uniqueTagsArray) {
      tag.isChecked = this.allTagsChecked;
    }

  }
  // yag
  selectTag(index: number, isChecked: boolean) {
    this.uniqueTagsArray[index].isChecked = isChecked;
    this.allTagsChecked = this.uniqueTagsArray.every(tag => tag.isChecked);
  }
  // size

  selectSize(index: number, isChecked: boolean) {
    this.selectedSizes[index] = isChecked;
    this.allSizeChecked = this.selectedSizes.every(size => size);
  }



  getSelectedItems() {
    const selectedTags = this.uniqueTagsArray.filter(tag => tag.isChecked).map(tag => tag.name);
    const selectedColors = this.uniqueColorArray.filter((_, index) => this.selectedColors[index]);
    const selectedSizes = this.uniqueSizeArray.filter((_, index) => this.selectedSizes[index]);

    console.log("Selected tags:", selectedTags);
    console.log("Selected colors:", selectedColors);
    console.log("Selected sizes:", selectedSizes);
    var obj = { tags: selectedTags, colors: selectedColors, sizes: selectedSizes }
    return obj;

  }
  confirmClick() {
    
    console.log("hii");
    if (!this.dataservice.isAtLeastOneChecked(this.uniqueTagsArray, 'isChecked') &&
      !this.dataservice.isAtLeastOneChecked(this.selectedColors, '') &&
      !this.dataservice.isAtLeastOneChecked(this.selectedSizes, '')) {
      alert("Please select at least one tag, color, and size.");
      return;
    }

    // this.mainInvArray = _.filter(this.mainInvArray, function (o) { return o.isChecked; })
    // this.mainInvArray.forEach((color) => {
    //   color.size.forEach(element => {
    //     if (element.isChecked) {
    //       var inv = { color: color.color, size: element.size }
    //       arr.push(inv)
    //     }

    //   });
    // })

    this.dialogRef.close(this.getSelectedItems());
    console.log(this.getSelectedItems());

  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  getTags() {
    this.dataservice.getCommon("api/v1/tagslist/").subscribe(
      data => {
        this.allTags = data.json().results;
      },
      error => { }
    );
  }

  calculateTotal(data: [OrderUnit]): number {

    let total = 0;
    data.forEach(element => {

      total = element.sub_total + total;
    });
    return total;
  }

  getCustomerList() {
    this.dataservice.getAllStoreFronts().subscribe(data => {
      let obj = data.json();
      let filCusts = obj["results"]


      this.customercollection = filCusts

      console.log(this.customercollection);
    });
  }

  getStoreFrontData(datax: any) {
    console.log(datax);
    if (this.dataservice.customer_list.length === 0) {
      this.dataservice.getAllStoreFronts().subscribe(data => {
        let obj = data.json();
        this.customercollection = obj["results"];

        this.dataservice.customer_list = this.customercollection;

        // this.consumeSelectedOptions(datax);

        console.log(this.customercollection);
        if (datax.length != 0) {
          this.consumeSelectedOptions(datax as userstorefront_obj[]);

        }
      });
    } else {
      this.customercollection = this.dataservice.customer_list;
      this.consumeSelectedOptions(datax as userstorefront_obj[]);
    }
  }

  consumeSelectedOptions(data: userstorefront_obj[]) {
    if (this.data.type == "filter") {
      if (data.length === 0) {
        return;
      }
    }

    this.selectedstorefronts = [];
    this.unCheckEverything();

    console.log(data.length, "hello");

    data.forEach(elementx => {
      let index = this.customercollection.findIndex(element => {
        console.log(element.id, elementx.user);
        return element.id === elementx.user;
      });

      console.log(index);

      let userstoreindex = this.customercollection[
        index
      ].user_storefront.findIndex(element => {
        return element.id === elementx.id;
      });

      this.customercollection[index].user_storefront[
        userstoreindex
      ].ischecked = true;
      this.selectedstorefronts.push(
        this.customercollection[index].user_storefront[userstoreindex]
      );
    });
  }

  unCheckEverything() {
    this.customercollection.forEach(element => {
      element.ischecked = false;
      element.user_storefront.forEach(element => {
        element.ischecked = false;
      });
    });
  }

  updateStoreFronts() {
    console.log(this.customercollection);
    this.getSelectedStoreFronts();
  }

  selectCustomerStoreFront(id) {
    let pos = this.customercollection.findIndex(element => {
      return element.id == id;
    });

    for (
      let index = 0;
      index < this.customercollection[pos].user_storefront.length;
      index++
    ) {
      this.customercollection[pos].user_storefront[
        index
      ].ischecked = this.customercollection[pos].ischecked;
    }
    this.getSelectedStoreFronts();
  }

  selectedStoreFront: userstorefront_obj;

  getSelectedStoreFronts() {
    this.selectedstorefronts = [];
    this.selectedstorefronts.push(this.selectedStoreFront)

    return
    this.customercollection.forEach(element => {
      element.user_storefront.forEach(obj => {
        console.log();
        // if (obj.ischecked) {
        //   this.selectedstorefronts.push(obj);
        // }
      });
    });

    console.log(this.selectedstorefronts);
  }

  checkData() {
    // console.log(this.selectedOwnerCol.value,this.selectedStoreCol.value,this.selectedOrderStatus.value);

    // console.log(this.searchText);

    this.searchparams.puchaser_name = this.searchText;

    this.searchparams.order_id = this.orderId;
    if (this.selectedOwnerCol.value !== null) {
      this.searchparams.customer_collection = this.selectedOwnerCol.value;
    }

    if (this.selectedStoreCol.value !== null) {
      this.searchparams.storefront_collection = this.selectedStoreCol.value;
    }

    if (this.selectedOrderStatus.value !== null) {
      this.searchparams.order_status = this.selectedOrderStatus.value;
    }

    if (this.selectedTags.value !== null) {
      this.searchparams.tags = this.selectedTags.value;
    }

    if (this.date.value) {
      this.searchparams.custom_date = this.datepipe.transform(
        this.date.value,
        "dd/MM/yyyy"
      );
    }

    console.log(this.is_archive);
    if (this.is_archive.value) {
      this.searchparams.is_archive = "true";
    } else {
      if (this.is_archive.touched) {
        this.searchparams.is_archive = "false";
      }
    }

    if (this.order_date.value) {
      switch (this.order_date.value["id"]) {
        case 0:
          console.log(this.getDateSub(7));

          this.quickdate = this.quickdates[0];
          this.quickdate.value = this.getDateSub(7);

          this.searchparams.order_date = this.quickdate;

          break;
        case 1:
          this.quickdate = this.quickdates[1];
          this.quickdate.value = this.getDateSub(15);

          this.searchparams.order_date = this.quickdate;

          break;

        case 2:
          this.quickdate = this.quickdates[2];
          this.quickdate.value = this.getDateSub(30);

          this.searchparams.order_date = this.quickdate;

          break;

        default:
          break;
      }
    }

    console.log(this.searchparams);
  }

  getDateSub(days: number) {
    // Days you want to subtract
    const date = new Date();
    const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

    return (
      this.datepipe.transform(last, "dd/MM/yyyy") +
      "," +
      this.datepipe.transform(date, "dd/MM/yyyy")
    );
  }

  //add Tags

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<tags[]>;
  tags: tags[] = [];
  allTags: tags[] = [];

  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || "").trim()) {
        this.addTag(value.trim(), input);
      }

      // Reset the input value
    }
  }

  remove(fruit: tags): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.value);
    this.tagCtrl.setValue(null);

    console.log(this.tags);
  }

  addTag(tag: string, input) {
    this.dataservice.addTag(tag).subscribe(
      data => {
        console.log();
        this.tags.push(data.json());

        if (input) {
          input.value = "";
        }

        this.tagCtrl.setValue(null);
      },
      error => {
        console.log(error);
      }
    );
  }

  comboForm = this.fb.group({
    id: this.data.content.id,
    storefront:[this.data.content.storefront, Validators.required],
    tag: ['', Validators.required],
    color: ['', Validators.required],
    size: ['', Validators.required],
    quantity: ['', Validators.required],
    price: ['', Validators.required],
    files: [[], Validators.required],
  });
  ImageArray = [];

  choseImages(evt) {
    console.log();
    for (let file of evt.target.files) {
      this.data.content.files.push(file);

      let reader = new FileReader();
      reader.onload = (e: any) => {
        let obj = {
          name: file.name,
          url: e.target.result
        };

        this.ImageArray.push(obj);
      };
      reader.readAsDataURL(file);
    }


    this.comboForm.get('files').setValue(this.data.content.files);
  }

  removeImage(i) {
    this.ImageArray.splice(i, 1);
    this.data.content.files.splice(i, 1);
    this.comboForm.get('files').setValue([]);

  }
  isLoading = false;
  SaveCombination() {
    console.log(this.comboForm.value);

    if (this.comboForm.valid) {
      this.isLoading = true;

      this.dataservice.addInvCombination(this.comboForm.value).then(res => {
        this.dataservice.openSnackBar('Combination added', 'close');
        this.isLoading = false;

        this.dialogRef.close(res);
      })
        .catch(err => {
          console.log(err);
          this.dataservice.openSnackBar(err, 'close');
          this.isLoading = false;

          this.dialogRef.close();

        })
    } else {
      this.dataservice.openSnackBar('Please fill out all the fields', 'close');


    }

  }

  ProductInventory: productinventory_set[] = [];

  getSingleInventory(prodId) {
    console.log(prodId);

    this.dataservice.getInventory(prodId).then(res => {
      console.log(res);
      this.ProductInventory = res.productinventory_set;
    })


  }
}
