import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { userstorefront_obj } from '../models';

@Component({
  selector: 'app-protected-storefront-preview',
  templateUrl: './protected-storefront-preview.component.html',
  styleUrls: ['./protected-storefront-preview.component.css']
})
export class ProtectedStorefrontPreviewComponent implements OnInit {

  selected_storefronts : userstorefront_obj [] = []
  singlesfobj : userstorefront_obj;

  constructor(private dataservice : DataService , private router : Router) {


    this.selected_storefronts = this.dataservice.protected_selected_storefronts

    if(this.selected_storefronts.length == 0 ){
      this.router.navigateByUrl('/login/customer')
    }
    else{
      this.singlesfobj = this.selected_storefronts[0];
      console.log(this.selected_storefronts)
    }

           

   }
   

  ngOnInit() {
  }

  // Prveiew page
  preview(i:any){
    console.log("yo");
    this.selected_storefronts[i].slug;
    this.router.navigateByUrl('/'+ this.selected_storefronts[i].slug + '');
  }
}
