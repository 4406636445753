import { Pipe, PipeTransform } from '@angular/core';
import { m_product_obj } from './models';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   return null;
  // }


  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.title.toLowerCase().includes(searchText);
    });
  }

}



@Pipe({
  name: 'productId'
})
export class ProductId implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   return null;
  // }


  transform(array: any, key: any): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    // array.sort((a: any, b: any) => {
    //   if (a[field] > b[field]) {
    //     return -1;
    //   } else if (a[field] < b[field]) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });

    console.log("from pipe", key);

    if (key.field === 'is_featured') {


      array.sort(function (x, y) {
        return y[key.field] - x[key.field];
      });


    }




    if (key.field === 'id') {
      array.sort((a: any, b: any) => {

        if (a[key.field] > b[key.field]) {
          return -1;
        } else if (a[key.field] < b[key.field]) {
          return 1;
        } else {
          return 0;
        }
      });
    }


    if (key.field === 'code') {
      array.sort((a: any, b: any) => {

        let ax = a[key.field].match(/\d/g);
        let bx = b[key.field].match(/\d/g);

        if (ax != null) {
          ax = ax.join('');
        } else {
          ax = 0;
        }

        if (bx != null) {
          bx = bx.join('');
        } else {
          bx = 0;
        }


        console.log(ax, bx);
        if (ax < bx) {
          return -1;
        } else if (ax > bx) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    if (key.field === 'price' && key.filter === 'h2l') {
      array.sort((a: any, b: any) => {
        if (Number(a[key.field]) > Number(b[key.field])) {
          return -1;
        } else if (Number(a[key.field]) < Number(b[key.field])) {
          return 1;
        } else {
          return 0;
        }
      });
    }


    if (key.field === 'price' && key.filter === 'l2h') {
      array.sort((a: any, b: any) => {
        if (Number(a[key.field]) < Number(b[key.field])) {
          return -1;
        } else if (Number(a[key.field]) > Number(b[key.field])) {
          return 1;
        } else {
          return 0;
        }
      });
    }


    console.log(array);
    if(key.field === 'code'){


   


      const temp = [];
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const ax = element[key.field].match(/\d/g);

        if(ax == null){

          temp.push(element);
            array.splice(index,1);
        }
      }


      temp.forEach(element => {
        array.push(element);
      });




      return array;

    }else{
    return array;
    }
  }

}