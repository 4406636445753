import { OnInit } from '@angular/core';
import { DataService } from './../data.service';
// import { Transform } from 'stream';
var Dashboradv2Component = /** @class */ (function () {
    function Dashboradv2Component(dataservice) {
        this.dataservice = dataservice;
        this.dataservice.showHide(true);
    }
    Dashboradv2Component.prototype.ngOnInit = function () { };
    return Dashboradv2Component;
}());
export { Dashboradv2Component };
