/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumb.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./breadcrumb.component";
var styles_BreadcrumbComponent = [i0.styles];
var RenderType_BreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
function View_BreadcrumbComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(7, null, ["", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, _v.parent.context.$implicit.url); _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_4); }); }
function View_BreadcrumbComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_0); }); }
function View_BreadcrumbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.last; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.last; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logUrlPath; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i1.ɵdid(1, 114688, null, 0, i4.BreadcrumbComponent, [i2.ActivatedRoute, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbComponentNgFactory = i1.ɵccf("app-breadcrumb", i4.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, {}, {}, []);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
