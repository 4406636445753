import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataService } from './../data.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatChipInputEvent, MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { ENTER, COMMA, SPACE, TAB } from '@angular/cdk/keycodes';
var AddcustomerComponent = /** @class */ (function () {
    function AddcustomerComponent(_formBuilder, dataservice, route, dialog, _location) {
        this._formBuilder = _formBuilder;
        this.dataservice = dataservice;
        this.route = route;
        this.dialog = dialog;
        this._location = _location;
        this.isLinear = false;
        this.tax = 8.75;
        this.get_Ctype = false;
        this.Cstatus = 'Active';
        // teams: {
        //   title: string;
        // }[];
        this.clubs = [];
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.separatorKeysCodes = [ENTER, COMMA, SPACE, TAB];
        this.allEmailId = [];
        this.type = [
            { value: 'school', viewValue: 'Schools', showteam: false },
            { value: 'business', viewValue: 'Business', showteam: false },
            { value: 'corporate', viewValue: 'Corporate', showteam: false },
            { value: 'restaurant', viewValue: 'Restaurant', showteam: false },
            { value: 'sports', viewValue: 'Sports', showteam: true },
            // { value: 'volleyball', viewValue: 'Volleyball', showteam: true },
            // { value: 'baseball', viewValue: 'Baseball', showteam: true },
            { value: 'other', viewValue: 'Other', showteam: false }
        ];
        this.states = [
            { value: 'AL', viewValue: ' AL' },
            { value: 'AK', viewValue: 'AK' },
            { value: 'AZ', viewValue: 'AZ' },
            { value: 'AR', viewValue: 'AR' },
            { value: 'CA', viewValue: 'CA' },
            { value: 'CO', viewValue: 'CO' },
            { value: 'CT', viewValue: 'CT' },
            { value: 'DE', viewValue: 'DE' },
            { value: 'FL', viewValue: 'FL' },
            { value: 'GA', viewValue: 'GA' },
            { value: 'HI', viewValue: 'HI' },
            { value: 'ID', viewValue: 'ID' },
            { value: 'IL', viewValue: 'IL' },
            { value: 'IN', viewValue: 'IN' },
            { value: 'IA', viewValue: 'IA' },
            { value: 'KY', viewValue: 'KY' },
            { value: 'LA', viewValue: 'LA' },
            { value: 'ME', viewValue: 'ME' },
            { value: 'MD', viewValue: 'MD' },
            { value: 'MA', viewValue: 'MA' },
            { value: 'MI', viewValue: 'MI' },
            { value: 'MN', viewValue: 'MN' },
            { value: 'MS', viewValue: 'MS' },
            { value: 'MO', viewValue: 'MO' },
            { value: 'MT', viewValue: 'MT' },
            { value: 'NE', viewValue: 'NE' },
            { value: 'NV', viewValue: 'NV' },
            { value: 'NH', viewValue: 'NH' },
            { value: 'NJ', viewValue: 'NJ' },
            { value: 'NM', viewValue: 'NM' },
            { value: 'NY', viewValue: 'NY' },
            { value: 'NC', viewValue: 'NC' },
            { value: 'ND', viewValue: 'ND' },
            { value: 'OH', viewValue: 'OH' },
            { value: 'OK', viewValue: 'OK' },
            { value: 'OR', viewValue: 'OR' },
            { value: 'PA', viewValue: 'PA' },
            { value: 'RI', viewValue: 'RI' },
            { value: 'SC', viewValue: 'SC' },
            { value: 'SD', viewValue: 'SD' },
            { value: 'TN', viewValue: 'TN' },
            { value: 'TX', viewValue: 'TX' },
            { value: 'UT', viewValue: 'UT' },
            { value: 'VT', viewValue: 'VT' },
            { value: 'VA', viewValue: 'VA' },
            { value: 'WA', viewValue: 'WA' },
            { value: 'WV', viewValue: 'WV' },
            { value: 'WI', viewValue: 'WI' },
            { value: 'WY', viewValue: 'WY' },
        ];
        this.status = [
            { value: 'Potential', viewValue: 'Potential' },
            { value: 'Active', viewValue: 'Active' },
            { value: 'Archive', viewValue: 'Archive' }
        ];
    }
    AddcustomerComponent.prototype.ngOnInit = function () {
        this.customerDetails = this._formBuilder.group({
            name: ['', Validators.required],
            user_type: ['', Validators.required],
            logo: [''],
            phone_number: ['', Validators.pattern('[- +()0-9]+')],
            address_street: [''],
            address_city: [''],
            address_state: [''],
            postal_code: [''],
            contact_name: [''],
            contact_title: [''],
            username: [''],
            password_text: [''],
            status: [''],
            shipping_instructions: [''],
            sales_tax: [0],
            shipping_cost: [0],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            s_email: [''],
            is_international_shipping: [false],
            shipping_cost_international: [0],
            club: this._formBuilder.array([])
        });
        if (this.dataservice.draftCustomer) {
            console.log(this.dataservice.draftCustomer);
            console.log(this.dataservice.draftCustomer.data.secondary_emails);
            var draft_id = this.dataservice.draftCustomer.id;
            this.customerDetails.patchValue(this.dataservice.draftCustomer.data);
            this.customerDetails.addControl('draft_id', new FormControl(draft_id));
            console.log(this.customerDetails.value);
            if (this.dataservice.draftCustomer.data.secondary_emails == "undefined" || this.dataservice.draftCustomer.data.secondary_emails == "") {
            }
            else {
                this.allEmailId = this.dataservice.draftCustomer.data.secondary_emails.split(',');
            }
        }
    };
    AddcustomerComponent.prototype.ngOnDestroy = function () {
        console.log("des");
        this.dataservice.draftCustomer = null;
    };
    AddcustomerComponent.prototype.goToDraft = function () {
        this.route.navigate(['/draft']);
    };
    AddcustomerComponent.prototype.createItem = function () {
        return this._formBuilder.group({
            name: ''
        });
    };
    AddcustomerComponent.prototype.addSecondaryEmail = function (event) {
        var input = event.input;
        var value = event.value;
        // Add our fruit
        if ((value || '').trim() && this.validateEmail(value)) {
            this.allEmailId.push(value.trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.customerDetails.controls['s_email'].setValue(null);
    };
    AddcustomerComponent.prototype.validateEmail = function (email) {
        // tslint:disable-next-line: max-line-length
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    AddcustomerComponent.prototype.remove = function (fruit) {
        var index = this.allEmailId.indexOf(fruit);
        if (index >= 0) {
            this.allEmailId.splice(index, 1);
        }
    };
    AddcustomerComponent.prototype.saveCust = function (create) {
        var _this = this;
        console.log(this.customerDetails.value);
        console.log(this.Custlogo);
        if (this.Custlogo != undefined) {
            console.log('logo');
        }
        else {
            console.log('no logo');
        }
        if (this.customerDetails.valid) {
            // let draft_id = this.dataservice.draftCustomer.id
            this.dataservice.addcustomer(this.customerDetails.value, this.Custlogo, this.get_Ctype, this.allEmailId, create).subscribe(function (res) {
                console.log(res);
                _this.returnCustomer = res.json();
                console.log(_this.returnCustomer);
                _this.dataservice.appendCustomer_List(_this.returnCustomer);
                _this.openDialog();
            }, function (error) {
                var keys = Object.keys(error.json());
                var errorarry = [];
                keys.forEach(function (element) {
                    //  console.log(error.json()[element]);
                    var obj = {
                        title: element,
                        desc: error.json()[element].toString()
                    };
                    errorarry.push(obj);
                });
                console.log(errorarry);
                var errorconent = '';
                errorarry.forEach(function (element) {
                    errorconent += "<p><b style=\"text-transform: capitalize;\">" + element.title + "</b> " + element.desc + "</p>";
                });
                _this.openDialogError('Error', errorconent);
            });
        }
        else {
            this.openDialogError('Error', 'Please fill out all fields marked by *');
        }
    };
    AddcustomerComponent.prototype.saveAsDraftCust = function (draft) {
        var _this = this;
        if (this.customerDetails.valid) {
            this.dataservice.addcustomer(this.customerDetails.value, this.Custlogo, this.get_Ctype, this.allEmailId, draft).subscribe(function (res) {
                console.log(res);
                _this.openActionDialogue('Success', 'Customer is succesfully saved as a draft!', 'Go to Draft');
            }, function (error) {
                var keys = Object.keys(error.json());
                var errorarry = [];
                keys.forEach(function (element) {
                    //  console.log(error.json()[element]);
                    var obj = {
                        title: element,
                        desc: error.json()[element].toString()
                    };
                    errorarry.push(obj);
                });
                console.log(errorarry);
                var errorconent = '';
                errorarry.forEach(function (element) {
                    errorconent += "<p><b style=\"text-transform: capitalize;\">" + element.title + "</b> " + element.desc + "</p>";
                });
                _this.openDialogError('Error', errorconent);
            });
        }
        else {
            this.openDialogError('Error', 'Please fill out all fields marked by *');
        }
    };
    AddcustomerComponent.prototype.updateDraft = function () {
        var _this = this;
        console.log("update");
        console.log(this.customerDetails.value, this.allEmailId);
        if (this.customerDetails.valid) {
            this.dataservice.updateCustomerDraft(this.dataservice.draftCustomer.id, this.customerDetails.value, this.allEmailId).subscribe(function (res) {
                console.log(res);
                _this.openDialogError('Success', 'Customer draft is succesfully updated!');
            });
        }
        else {
            this.openDialogError('Error', 'Please fill out all fields marked by *');
        }
    };
    //Create Customer Logo
    AddcustomerComponent.prototype.fileupload = function (event) {
        // console.log(event);
        //console.log('got it file');
        var files = event.srcElement.files;
        if (files) {
            this.Custlogo = files[0];
            var obj = this.customerDetails.get('logo');
            obj.setValue(this.Custlogo.name);
            obj.disable();
        }
    };
    AddcustomerComponent.prototype.addClubRow = function () {
        this.addClub();
    };
    AddcustomerComponent.prototype.removeClubRow = function (index) {
        this.teams.removeAt(index);
    };
    AddcustomerComponent.prototype.addClub = function () {
        this.teams = this.customerDetails.get('club');
        this.teams.push(this.createItem());
    };
    AddcustomerComponent.prototype.addItem = function () {
        this.teams = this.customerDetails.get('club');
        if (this.teams.length === 0) {
            for (var index = 0; index < 5; index++) {
                this.teams.push(this.createItem());
            }
        }
    };
    AddcustomerComponent.prototype.getType = function () {
        console.log(this.customerDetails.value.user_type);
        this.get_Ctype = true;
        // this.addItem();
        // if (this.customerDetails.value.user_type === 'baseball' || this.customerDetails.value.user_type === 'volleyball') {
        //   this.get_Ctype = true;
        //   this.addItem();
        // }
        // else {
        //   this.get_Ctype = false;
        // }
    };
    AddcustomerComponent.prototype.cancel = function () {
        this._location.back();
        // this.route.navigate(['/customers']);
    };
    AddcustomerComponent.prototype.openDialogError = function (title, content) {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: title, content: content, type: 'error' }
        });
    };
    AddcustomerComponent.prototype.openActionDialogue = function (title, content, action) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: title, content: { content: content, action: action }, type: 'action' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("go to draft now");
            _this.route.navigate(['/draft']);
        });
    };
    AddcustomerComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Success", content: "Customer <b>" + this.customerDetails.value.name + "</b> is created.", type: 'addstore' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result == 'no') {
                console.log('The dialog was closed', result);
                _this.route.navigateByUrl('/customers');
            }
            if (result === 'yes') {
                _this.dataservice.setSelectedCustomer(_this.returnCustomer);
                _this.route.navigateByUrl('/addstorefront/' + _this.returnCustomer.id);
            }
        });
    };
    return AddcustomerComponent;
}());
export { AddcustomerComponent };
