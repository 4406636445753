<div class="main" *ngIf="showForm">
  <app-breadcrumb></app-breadcrumb>
  <mat-tab-group  [selectedIndex]="selectedIndex">
    <mat-tab label="Edit Store - {{ store_obj.name }}">
      <mat-card class="cards">
        <mat-toolbar class="subtitle font-fmly">
          Edit Store - {{ store_obj.name }} </mat-toolbar><br />
        <mat-form-field class="widths">
          <input matInput placeholder="Storefront Name" [(ngModel)]="store_obj.name" required />
        </mat-form-field>

    <mat-form-field  class="widths">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="..." [(ngModel)]="store_obj.description"></textarea>
    </mat-form-field>
        <mat-form-field class="widths" *ngIf="store_obj.chain_name">
          <input matInput placeholder="Chain Name" [(ngModel)]="store_obj.chain_name" required />
        </mat-form-field>
        <mat-form-field class="widths" *ngIf="store_obj.location">
          <input matInput placeholder="Location" [(ngModel)]="store_obj.location" required />
        </mat-form-field>
        <br />
        <mat-checkbox class="example-margin" [(ngModel)]="checked" (change)="togglePascode($event)">Password Protected
        </mat-checkbox>
        <br />
        <mat-form-field class="widths" *ngIf="checked">
          <input matInput placeholder="Passcode" [(ngModel)]="passcode" />
        </mat-form-field>
        <table>
          <tr class="color-tab">
            <td>Storefront Banner Color:</td>
            <td>
              <input type="color" placeholder="Storefront Banner Color" [(ngModel)]="store_obj.banner_color_code" />
            </td>
             
          </tr>
          <tr class="color-tab">
            <td>Storefront Title Color :</td>
            <td> 
              <input type="color" placeholder="Storefront Title Color" [(ngModel)]="store_obj.title_color" />
            </td>
          </tr> 
        
          <tr class="color-tab">
            <td>Description Background Color:</td>
            <td>
              <input type="color" placeholder="Description Background Color" [(ngModel)]="store_obj.desc_background_color" />
            </td>
          </tr>
          <tr class="color-tab">
            <td>Description Text Color :</td>
            <td>
              <input type="color" placeholder="Description Text Color" [(ngModel)]="store_obj.desc_text_color" />
            </td>
          </tr>
        </table>
        <br>
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Store Style Items</mat-label>
          <mat-select [(ngModel)]="selectedTags" multiple  [compareWith]="compareFn" (selectionChange)="ontagschange($event)">
            <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <br>
    <div style="margin-bottom: 10px;">
      <mat-checkbox  [(ngModel)]="store_obj.show_banner_images" >Show banner images on store front</mat-checkbox>
      <br>
      <label color="primary" for="file-upload" class="custom-file-upload">
        <i class="fa fa-cloud-upload" style="color: white !important;"></i> Upload Images</label>
      <input id="file-upload" (change)="fileupload($event)" type="file" multiple style="display: none;"/>
    </div>
    <div class="row " style="overflow-x: auto;margin: 0 10px;width: 50%;display: flex;">
      <div style="margin:5px;" class="" *ngFor="let item of store_obj.storefrontbanner_set; let imgin = index">
        <div style="position: relative;">
          <img [src]="item.image" width="100" height="100" class="img-p" style="object-fit: cover;"/>
          <mat-icon class="icons"
            style="position: absolute;top: 0px;right: 0px; color: white;cursor: pointer;background-color: gray;
            border-radius: 50%;"
            (click)="removeBannerImage(item.id,imgin)" class="">

            clear
          </mat-icon>
        </div>

      </div>
    </div>

        <div style="margin-bottom: 10px;">
          <mat-checkbox  [(ngModel)]="store_obj.show_customer_name" >Show customer name on store front</mat-checkbox>
        </div>


        <div style="margin-bottom: 10px;">
          <mat-checkbox  [(ngModel)]="auto_fill" >Auto fill address data on checkout</mat-checkbox>

          <input type="file" id="input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  [class.hidden]="!auto_fill" />

          <div>

            <div [class.hidden]="!auto_fill" style="margin-top: 10px;">
              <li *ngFor="let item of xlsErrors; let i=index" style="padding-left: 0px !important;">Error  <span style="color: red;margin-right: 5px;margin-left: 5px;">{{item.column}}</span>   value {{item.error}} at row {{item.row}}  </li>

             </div>
          <button *ngIf="xlsData.length > 0" style="margin-top: 10px;" mat-raised-button color="primary"  (click)="showRecords = !showRecords"  >
            {{showRecords?"Hide Records":"Show Records"}}
          </button>


        <div >
          <table class="table" *ngIf="showRecords">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">PROPERTY_NAME</th>
                <th scope="col">ADDRESS</th>
                <th scope="col">COUNTRY</th>
                <th scope="col">CITY</th>
                <th scope="col">STATE</th>
                <th scope="col">ZIPCODE</th>
                <th scope="col">SHIPPING_INSTRUCTIONS</th>


              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of xlsData; let i=index">
                <th scope="row">{{i+1}}</th>
                <td>{{item.PROPERTY_NAME}}</td>
                <td>{{item.ADDRESS}}</td>
                <td>{{item.COUNTRY}}</td>
                <td>{{item.CITY}}</td>
                <td>{{item.STATE}}</td>
                <td>{{item.ZIPCODE}}</td>
                <td>{{item.SHIPPING_INSTRUCTIONS}}</td>


              </tr>

            </tbody>

          </table>

        </div>
        </div>

        </div>
        <mat-form-field class="widths"  >
          <input matInput placeholder="Enable Free Shipping Threshold Amount" [(ngModel)]="store_obj.free_shipping_amount" type="number" />
          <mat-icon matSuffix>attach_money</mat-icon>
        </mat-form-field>
        <br />
        <mat-form-field class="example-full-width widths">
          <mat-select placeholder="Storefront Status" [(ngModel)]="store_obj.status">
            <mat-option *ngFor="let item of storefront_status" [value]="item.value">
              {{ item.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field class="widths">
          <mat-label>Archive Message</mat-label>
          <textarea matInput placeholder="Custom message to show when storefront status is archived" [(ngModel)]="store_obj.archive_message"></textarea>
        </mat-form-field>
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td>
              <mat-form-field>
                <input matInput placeholder="Storefront URL Hint" pattern="[a-zA-Z ]*" [(ngModel)]="store_obj.slug" />
              </mat-form-field>
            </td>
            <td>
              <button mat-button class="genereteurl" (click)="generateUrl()">
                Generate URL
              </button>
            </td>
          </tr>
        </table>

        <br />
        <mat-checkbox [(ngModel)]="store_obj.online_payment">Online</mat-checkbox>
        <mat-checkbox [(ngModel)]="store_obj.net30" style="margin-left:20px">Net30</mat-checkbox>
        <br />
        <br />
        <mat-form-field class="widths">
          <input matInput placeholder="Storefront URL Hint" value="" [(ngModel)]="store_obj.slug" disabled />
        </mat-form-field>
        <div class="text-center">
          <button mat-raised-button color="primary" class="btn-update" [disabled]="!store_obj.name" (click)="update()"
            [disabled]="store_obj.name">
            Update
          </button>
          <button style ="margin-left: 20px;" mat-raised-button color="primary" class="btn-update" (click)="cancel()">
            Cancel
          </button>
        </div>
      </mat-card>
    </mat-tab>
    <mat-tab  label="Edit Products">
      <mat-card *ngIf="store_obj.storefront_product.length > 0" class="cards">
        <mat-toolbar> Edit Products - {{ store_obj.name }} </mat-toolbar>

        <div>
          <table class="table-list" >
            <thead>
              <tr class="row-header font-fmly">
                <th class="margin-r" ><b>Id</b></th>
                <th class="margin-r" >Title</th>
                <th class="margin-r" >Category</th>
                <th class="margin-r" >Code</th>
                <th class="margin-r" >Color</th>
                <th class="margin-r"  style="width: 22%;">Size</th>
                <th class="margin-r" >Price</th>
                <th class="margin-r" >Featured</th>
                <th class="margin-r"  style="width: 11%;">Available in store</th>
                <th class="margin-r" >Global Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <!-- | productId:sortByVar -->
            <tr style="cursor: pointer;" [ngClass]="!item.global_active ? 'globalInActive' :''"   *ngFor="let item of store_obj.storefront_product ; let i=index ">
              <td  >{{ item.id }}</td>
              <td  >{{ item.title }}</td>

              <td  >{{ item.category_name }}</td>
              <td  >{{ item.code }}</td>
              <td  >{{ item.color }}</td>
              <td [matTooltip]="item.size.length > 6 ? 'Click to see size list' :''" [matTooltipPosition]="'above'"  (click)="sizeDialogue(item.size)" >{{ (item.size.length>10)? (item.size |
                slice:0:20)+'..':(item.size) }}</td>
              <td  >{{ item.price }}</td>
              <td><mat-checkbox [checked]="item.is_featured" (change)="onChange($event, i, item)"></mat-checkbox></td>



              <td *ngIf="item.active">
                <mat-slide-toggle [matTooltip]="item.active ? 'Deactivate' : 'Active'" color="primary" [(ngModel)]="item.active"
                 (change)="!item.active ? toggleProductStatusData(item, store_obj, false):toggleProductStatusData(item, store_obj, true)">
                </mat-slide-toggle>
              </td>
              <td *ngIf="!item.active">
                <mat-slide-toggle [matTooltip]="item.active ? 'Deactivate' : 'Active'" color="primary" [(ngModel)]="item.active"
                 (change)="!item.active ? toggleProductStatusData(item, store_obj, false):toggleProductStatusData(item, store_obj, true)">
                </mat-slide-toggle>
              </td>
              <td  >{{item.global_active ? 'Active' : 'Inactive'}}</td>

              <td [routerLink]="['/editproduct/'+item.id]" style="text-align: center;"> <button mat-button style="margin : 10px;" color="primary" mat-raised-button 
                
                (click)="selectedProductEdit(item)" >
                  Edit
                </button>
              </td>

              <td>
                <!-- <mat-slide-toggle [matTooltip]="item.active ? 'Deactivate' : 'Active'" color="primary" [(ngModel)]="item.active"
                 (change)="!item.active ? toggleProductStatusData(item, store_obj, false):toggleProductStatusData(item, store_obj, true)">
                </mat-slide-toggle> -->
                <!-- <button *ngIf="item.active" mat-button class="bt1" color="warn"
                  (click)="openArchiveDialog(item, store_obj, false)">
                  Deactivate
                </button>
                <button *ngIf="!item.active" mat-button class="bt1" color="primary"
                  (click)="toggleProductStatusData(item, store_obj, true)">
                  Activate
                </button> -->
              </td>
            </tr>
          </table>
        </div>
      </mat-card>
      <button (click)="addproducts()" routerLink="/addproducts" *ngIf="store_obj.storefront_product.length === 0"  style="margin:auto;display: flex;justify-content: center;margin-top: 60px;" mat-raised-button color="primary">Add Product</button>


      <button *ngIf="store_obj.storefront_product.length > 0" mat-fab color="primary" class="btn-cust" (click)="addproducts()" routerLink="/addproducts" matTooltip="Add Product"
      [matTooltipPosition]="'left'">
      <mat-icon style="color:white !important" class="icons">add</mat-icon>
    </button>
    </mat-tab>
  </mat-tab-group>
</div>
