import { Component, OnInit ,OnDestroy} from '@angular/core';
import { DataService } from './../data.service';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DialogComponent } from '../dialog/dialog.component';
import { ProductTag, m_customers_obj, storefront_obj, userstorefront_obj } from '../models';
import { FormControl, Validators } from '@angular/forms';

import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';

import readXlsxFile from 'read-excel-file';
import { CompressImageService } from '../compress.service';
import { environment } from '../../environments/environment';


declare const $: any;

@Component({
  selector: 'app-addstorefront',
  templateUrl: './addstorefront.component.html',
  styleUrls: ['./addstorefront.component.css']
})
export class AddstorefrontComponent implements OnInit,OnDestroy {

  tagsList:ProductTag[] = []
  selectedTags: ProductTag[] = [];
  onlinePay = true;
  net30Pay = false;
  auto_fill = false;
  show_banner_images = false;
  SFstatus: any;
  generatedUrl = false;
  isPasscode = false;
  // SFname: any;
  statuss: any;
  bannerColor = '#3f51b5';
  titleColor = '#ffffff';
  desBgColor = '#3f51b5';
  descTextColor = '#ffffff';

  free_shipping_amount;

  show_customer_name = true;

  class = "widths"

  description = '';

  passcode = '';

  archive_message = '';
  url: any;
  cust_id: any;
  store_obj: userstorefront_obj;
  //baseurl = 'https://store.primetime.company/';
  baseurl = 'https://preview.primetime.company/';
  storefront_status = [
    { value: 'active', viewValue: 'Active' }, 
    { value: 'archive', viewValue: 'Archive' }
  ];

  showForm = false;
  constructor(
    public dataservice: DataService,
    private route: Router,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private _location: Location,
    private compressImage: CompressImageService
  ) { }


  inputxls;

  SFname = new FormControl('', [Validators.required]);
  SFurlHint = new FormControl('', [Validators.required]);
  passcodefc = new FormControl('', [Validators.required, Validators.maxLength(8)]);


  xlsErrors = [];
  xlsData = [];

  showRecords = false;

  buttonShowRecords = "View Records";

  ngOnInit() {


    this.SFstatus = 'active';
    this.getTagList();

    if (!this.dataservice.selected_customer) {
      //  this.route.navigateByUrl('/customers');

      this.router.params.subscribe(params => {
        this.cust_id = params['cust_id'];

        this.getSingleCustomer(this.cust_id);
      });
    } else {
      this.showForm = true;
      this.setupEventLisnter();
      this.cust_id = this.dataservice.selected_customer.id;
    }

    this.SFurlHint.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(term => {
        this.checkforIllegalChar(term);
      });

      if (this.dataservice.draftStorefront) {
        console.log(this.dataservice.draftStorefront);
        
       let obj = this.dataservice.draftStorefront.data;
       this.SFname.patchValue(obj.name)
       this.description = obj.description
       this.bannerColor = obj.banner_color_code
       this.titleColor = obj.title_color
       this.desBgColor = obj.desBgColor
       this.descTextColor = obj.descTextColor
       this.tagsList = obj.tags
       this.free_shipping_amount = obj.free_shipping_amount
       this.SFstatus = obj.status
       this.archive_message = obj.archive_message
       this.onlinePay = obj.online_payment
       this.net30Pay = obj.net30
       this.SFurlHint.patchValue(obj.slug)
       this.show_banner_images = obj.show_banner_images

      }



  }

  ngOnDestroy() {
    console.log("des");
    this.dataservice.draftStorefront = null;
  }

  getTagList() {
    this.dataservice.getProductTagList().then(
      res => {
        console.log(res);
        this.tagsList = res;
      }
    )
  }

  goToDraft(){
    this.route.navigate(['/draft'])

  }


  setupEventLisnter(){
    setTimeout(() => {

      this.inputxls = document.getElementById('input');

      this.inputxls.addEventListener('change', this.onFielLoad.bind(this));


      },500);
  }

  bannerImages:any[] = [];
  bannerImagesFiles:any[] = [];

  fileupload(event){
    console.log(event.target.files);
    const files = event.target.files;
    // this.bannerImages = files;
    for (let file of files) {
      this.bannerImagesFiles.push(file);

      this.compressImage
          .compress(file)
          .pipe(take(1))
          .subscribe(compressedImage => {

            let reader = new FileReader();
            reader.onload = (e: any) => {
              let obj = {
                name: compressedImage.name,
                url: e.target.result
              };
      
              this.bannerImages.push(obj);
            };
            reader.readAsDataURL(compressedImage);

          });

      
    }

  }

  removeBannerImage(i){
    this.bannerImages.splice(i, 1);
    this.bannerImagesFiles.splice(i, 1);
  }


  onFielLoad(){

    const  schema = {
      'PROPERTY_NAME': {
        prop: 'PROPERTY_NAME',
        type: String,
        required: true

      },
      'ADDRESS': {
        prop: 'ADDRESS',
        type: String


      },
      'COUNTRY': {
        prop: 'COUNTRY',
        type: String


      },
      'STATE': {
        prop: 'STATE',
        type: String

      },
      'CITY': {
        prop: 'CITY',
        type: String


      },
      'ZIPCODE': {
        prop: 'ZIPCODE',
        type: String

      },
      'SHIPPING_INSTRUCTIONS' :{
        prop: 'SHIPPING_INSTRUCTIONS',
        type: String
      }
    }
          readXlsxFile( this.inputxls['files'][0],{schema}).then((rows,errors ) => {

            this.xlsData = rows.rows;
            this.xlsErrors = rows.errors;

            console.log(this.xlsData,this.xlsErrors);
            // `rows` is an array of rows
            // each row being an array of cells.
          });
  }




  checkforIllegalChar(term: string) {
    const splChars = '*|,":<>[]{}`\';()@&$#%,\' \'';

    for (let i = 0; i < this.SFurlHint.value.length; i++) {
      if (splChars.indexOf(this.SFurlHint.value.charAt(i)) !== -1) {
        this.SFurlHint.setErrors({ containsillegalchar: true });
        return;
      }
    }
    this.checkforduplicatestoreurl(term,this.cust_id);
  }

  checkforduplicatestoreurl(term,id) {
    this.dataservice.veriftyURl(term,id).then(res => {
      console.log(res);
      this.statuss = res['status'];
      console.log(this.statuss);
      if (!this.statuss) {
        this.SFurlHint.setErrors({ alredyexist: true });
        return;
      } else {
        this.url = this.baseurl + this.SFurlHint.value + '';
      }
    });
  }

  getSingleCustomer(id: string) {
    this.dataservice.getSingleCustomer(id).subscribe(
      data => {
        console.log(data.json());
        let result = data.json();
        let selectedCustomer = <m_customers_obj>result['results'][0];
        this.dataservice.setSelectedCustomer(selectedCustomer);

        console.log(this.dataservice.getSelectedCustomer());
        this.showForm = true;
        this.setupEventLisnter();

      },
      err => {
        console.log(err);
      }
    );
  }

  // generates the Url
  generateUrl() {
    this.generatedUrl = true;
    this.SFurlHint.setValue(this.SFurlHint.value.toLowerCase());
    var splChars = '*|,":<>[]{}`\';()@&$#%,\' \'';
    if (!this.SFurlHint) {
      alert('url is Empty');
      return;
    }
    if (this.SFurlHint.valid) {
      this.dataservice.veriftyURl(this.SFurlHint.value,this.cust_id).then(res => {
        console.log(res);
        this.statuss = res['status'];
        console.log(this.statuss);
        if (!this.statuss) {
          //alert("Url Already Exist");
          // this.SFurlHint.setValue('');
          this.SFurlHint.setErrors({ alredyexist: true });
          return;
        } else {
          for (var i = 0; i < this.SFurlHint.value.length; i++) {
            if (splChars.indexOf(this.SFurlHint.value.charAt(i)) != -1) {
              alert('Illegal characters detected!');
              return;
            }
          }
          this.url = this.baseurl + this.SFurlHint.value + '';
          console.log(this.url);
        }
      });
    }
  }

  cancel() {
    this._location.back();
    //this.route.navigate(['/customers']);
  }

  CreateStorefront(create) {

    console.log(this.selectedTags);
   
    
    
    if (this.SFname.invalid || this.SFurlHint.invalid) {
      this.SFname.markAsTouched();
      this.SFurlHint.markAsTouched();

      return;
    }

    if(this.auto_fill && this.xlsErrors.length !=0){
      return;
    }

    if (!this.isPasscode) {
      this.passcode = '';
    } else {
      if (this.passcodefc.valid) {
        this.passcode = this.passcodefc.value;
      }
      else {
        return;
      }
    }

    let obj = {
      name: this.SFname.value,
      banner_color_code: this.bannerColor,
      title_color: this.titleColor,
      desc_background_color : this.desBgColor,
      desc_text_color : this.descTextColor,
      tags:this.selectedTags,
      status: this.SFstatus,
      online_payment: this.onlinePay,
      net30: this.net30Pay,
      slug: this.SFurlHint.value,
      user: this.cust_id,
      passcode: this.passcode,
      description: this.description,
      free_shipping_amount: this.free_shipping_amount,
      auto_fill : this.auto_fill,
      auto_fill_data : this.xlsData,
      show_customer_name: this.show_customer_name,
      archive_message: this.archive_message,
      show_banner_images :this.show_banner_images,
      version :environment.version

    };
    if (this.dataservice.draftStorefront) {

      obj['draft_id'] = this.dataservice.draftStorefront.id

    }
    console.log(obj);
    // return
    this.dataservice.createSf_v2(obj,create).subscribe(
      response => {
        console.log(response);
        
        let obj = response;
        obj['storefront_product'] = 0
        obj['storefront_product_total'] = 0
        console.log(obj);

        let storeobj = <userstorefront_obj>obj;
        this.uploadBannerImages(storeobj.id);


        this.store_obj = storeobj;

        this.dataservice.appendStoreObjInCustomer(storeobj);

        this.openDialog();
      },
      error => { },
      () => { }
    );
  }

  saveAsDraftStorefront(draft){
    if (this.SFname.invalid || this.SFurlHint.invalid) {
      this.SFname.markAsTouched();
      this.SFurlHint.markAsTouched();

      return;
    }

    if(this.auto_fill && this.xlsErrors.length !=0){
      return;
    }

    if (!this.isPasscode) {
      this.passcode = '';
    } else {
      if (this.passcodefc.valid) {
        this.passcode = this.passcodefc.value;
      }
      else {
        return;
      }
    }

    let obj = {
      name: this.SFname.value,
      banner_color_code: this.bannerColor,
      title_color: this.titleColor,
      desc_background_color : this.desBgColor,
      desc_text_color : this.descTextColor,
      status: this.SFstatus,
      online_payment: this.onlinePay,
      net30: this.net30Pay,
      slug: this.SFurlHint.value,
      user: this.cust_id,
      passcode: this.passcode,
      description: this.description,
      free_shipping_amount: this.free_shipping_amount,
      auto_fill : this.auto_fill,
      auto_fill_data : this.xlsData,
      show_customer_name: this.show_customer_name,
      archive_message: this.archive_message,
      show_banner_images :this.show_banner_images
    };
    console.log(obj);
    this.dataservice.createSf_v2(obj,draft).subscribe(
      response => {
        console.log(response);
        let obj = response;
        let storeobj = <userstorefront_obj>obj;
        this.uploadBannerImages(storeobj.id);
        this.openActionDialogue('Success', 'Storefront is succesfully saved as a draft!', 'Go to Draft');

      },
      error => { },
      () => { }
    );
  }



  uploadBannerImages(sfId)  {
  
    if(this.bannerImagesFiles.length > 0) {
      this.bannerImagesFiles.forEach(image => {
        this.dataservice.uploadBannerImages(sfId,image).then(
          res => {
            console.log(res);
            
          }
        ).catch(
          err => {
            console.log(err);
          }
        )
      })
      
    }
  }

  updateDraft(){

    let obj = {
      name: this.SFname.value,
      banner_color_code: this.bannerColor,
      title_color: this.titleColor,
      desc_background_color : this.desBgColor,
      desc_text_color : this.descTextColor,
      status: this.SFstatus,
      online_payment: this.onlinePay,
      net30: this.net30Pay,
      slug: this.SFurlHint.value,
      user: this.cust_id,
      passcode: this.passcode,
      description: this.description,
      free_shipping_amount: this.free_shipping_amount,
      auto_fill : this.auto_fill,
      auto_fill_data : this.xlsData,
      show_customer_name: this.show_customer_name,
      archive_message: this.archive_message,
      show_banner_images :this.show_banner_images

    };
    
  
      this.dataservice.updateStorefrontDraft(obj,this.dataservice.draftStorefront.id).subscribe(
        res => {
          console.log(res);
          this.openDialogError('Success', 'Storefront draft is succesfully updated!');

        }
      )
    
  }

  openDialogError(title: string, content: string) {


    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: title, content: content, type: 'error' }
    });
  }

  openActionDialogue(title: string, content: string, action: string) {
    const dialogRef = this.dialog.open(DialogComponent, {

      data: { title: title, content: { content: content, action: action }, type: 'action' }
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log("go to draft now");
      this.route.navigate(['/draft'])
    })

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Success',
        content: `Storefront <b>${this.SFname.value}</b> is created.`,
        type: 'addproduct'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'no') {
        console.log('The dialog was closed', result);

        this.route.navigateByUrl('/');
      }

      if (result === 'yes') {
        // //this.dataservice.setCustomer(this.returnCustomer);
        this.dataservice.selected_storefronts.push(this.store_obj);
        this.route.navigateByUrl('/addproducts');
      }
    });
  }

  returnRandomString(length: number) {
    this.passcode = Math.random()
      .toString(36)
      .substr(2, length);

    this.passcodefc.setValue(this.passcode);

    console.log(this.passcode);
  }
}
