import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inventory, m_product_obj, ProductTag, product_image_obj, product_obj_new, p_sf, Sizes } from '../models';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { product_obj, userstorefront_obj, m_customers_obj } from '../models';
import { MatChipInputEvent, MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from "lodash";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs-compat';
import { CompressImageService } from '../compress.service';
import { C, COMMA, ENTER, O, SPACE } from "@angular/cdk/keycodes";


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css'],
})
export class EditProductComponent implements OnInit {


  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];
  isImage = false;
  isPdf = false;
  isImageBackend = false;
  isPdfBackend = false;

  product_obj: m_product_obj;
  tempColorValue = "";
  tempSizeValue = "";
  product_obj_copy: m_product_obj;
  product_obj_new: product_obj = new product_obj();
  showForm = false;
  colorSizeChanged = false;
  showNewTable = false;
  prod_id: string;
  imagearry: any[] = [];
  displayedColumns: string[] = ['Tag', 'Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
  displayedColumnsSizeChart: string[] = ['Style', 'Chart'];


  colorSizeEdit = false;
  colorSizeIcon = "edit"
  selected_image;

  files: any[] = [];

  selectedPreviewImage = '';

  disableRbutton = false;
  private quantity$ = new Subject<string>();
  private price$ = new Subject<string>();
  private invIndex = 0
  private quantitySubscription: Subscription;
  private priceSubscription: Subscription;
  isLoading = false;
  tags = new FormControl();
  specialTags = new FormControl();
  tagsList: ProductTag[] = [];
  specialTagsList: ProductTag[] = [];

  productStatus = true;
  selectedstorefronts: any[] = [];
  urlindex = 0

  colorArray = [];
  sizeArray = [];
  Sizes = Sizes
  selectedSizes: string[] = [];
  tempcolorArray = [];
  tempsizeArray = [];

  constructor(
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private dataservice: DataService,
    private _location: Location,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private compressImage: CompressImageService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    const length = this.dataservice.inmemoryData.length;
    console.log(length);

    if (length > 0) {
      this.urlindex = this.dataservice.inmemoryData.findIndex(element => element.url === "api/v1/productv3/");
      console.log(this.urlindex);
      // console.log(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0]);
    }

    // this.dataservice.productEdited = true
    // this.dataservice.customerGetFlag = true;


    this.route.params.subscribe((params) => {
      this.prod_id = params['prod_id'];
    });

    this.dataservice.getSingleProduct(this.prod_id).subscribe(
      (data) => {
        console.log(data.json(), 'is it here');
        // this.getTagList();
        this.getSpecialTagList()

        this.product_obj = data.json();

        if (this.product_obj.size_chart) {
          this.isImageBackend = this.dataservice.isImageFromBackend(this.product_obj.size_chart)
          this.isPdfBackend = this.dataservice.isPdfFromBackend(this.product_obj.size_chart)
        }

        if (this.product_obj.productsizechart_set.length > 0) {
          this.product_obj.productsizechart_set.forEach(c => {
            if (c.size_chart) {
              this.isImageBackend = this.dataservice.isImageFromBackend(c.size_chart)
              this.isPdfBackend = this.dataservice.isPdfFromBackend(c.size_chart)
            }
          })
        }



        this.sortProductInv();

        if (this.product_obj.tags != null) {
          this.tagsList = this.product_obj.storefront_product[0].tags
          this.tags.setValue(this.tagsList.filter(tag => this.product_obj.tags.map(intag => intag.id).includes(tag.id)));
        }


        // this.selectedstorefronts = this.product_obj.storefront_product as userstorefront_obj[]
        this.tempColorValue = data.json().color;
        this.tempSizeValue = data.json().size;
        this.productStatus = this.product_obj.active
        this.colorArray = this.product_obj.color.split(",");
        this.sizeArray = this.product_obj.size.split(",");
        this.selectedSizes = this.sizeArray
        this.tempcolorArray = this.product_obj.color.split(",");
        this.tempsizeArray = this.product_obj.size.split(",");

        // this.product_obj_copy = data.json();
        this.dataservice.selected_product = data.json();

        this.product_obj.storefront_product.forEach(sf => {
          let obj = { id: sf.id, user: sf.user_id, name: sf.name, customer: sf.user }
          this.selectedstorefronts.push(obj)
        })

        this.showForm = true;
        this.catagories();

        console.log(this.product_obj);
        // this.shiftObjectAtTop();
      },
      (error) => { }
    );

    this.quantitySubscription = this.quantity$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    )
      .subscribe(() => {
        this.updateQ(this.invIndex);
      });
    this.priceSubscription = this.price$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    )
      .subscribe(() => {
        this.updateP(this.invIndex)

      });
  }

  ngOnDestroy(): void {
    this.dataservice.pageNumber = 0;
    this.shiftObjectAtTop();
    this.quantitySubscription.unsubscribe();
    this.priceSubscription.unsubscribe();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  sortProductInv() {
    this.product_obj.productinventory_set.sort(function (a, b) {
      if (a.tag && b.tag) {
        var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
        var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
        if (tagA < tagB) {
          return -1;
        }
        if (tagA > tagB) {
          return 1;
        }
        return 0; // tags are equal
      }

    });
  }
  sortProductInvNewGeneratedList() {
    this.product_obj_new.productinventory_set.sort(function (a, b) {
      if (a.tag && b.tag) {
        var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
        var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
        if (tagA < tagB) {
          return -1;
        }
        if (tagA > tagB) {
          return 1;
        }
        return 0; // tags are equal
      }

    });
  }

  getTagList() {
    this.dataservice.getProductTagList().then(
      res => {
        console.log(res);
        this.tagsList = res;
        console.log(this.product_obj.tags, this.tagsList);
        if (this.product_obj.tags != null) {
          this.tags.setValue(this.tagsList.filter(tag => this.product_obj.tags.map(intag => intag.id).includes(tag.id)));
        }
        else {
          // this.tags.setValue([{id:1,name:"Mens's"}]);
          // console.log(this.tags.value);
        }
      }
    )
  }
  getSpecialTagList() {
    this.dataservice.getProductSpecialTagList().then(
      res => {
        console.log(res);
        this.specialTagsList = res;
        console.log(this.product_obj.special_tags, this.specialTagsList);
        if (this.product_obj.special_tags != null) {
          this.specialTags.setValue(this.specialTagsList.filter(tag => this.product_obj.special_tags.map(intag => intag.id).includes(tag.id)));
        }
        else {
          // this.tags.setValue([{id:1,name:"Mens's"}]);
          // console.log(this.tags.value);
        }
      }
    )
  }

  getSingleProduct() {
    this.dataservice.getSingleProduct(this.prod_id).subscribe(
      (data) => {
        console.log(data.json(), 'is it here');
        this.getTagList();

        this.product_obj = data.json();
        this.tempColorValue = data.json().color;
        // this.product_obj_copy = data.json();
        // this.product_obj_new = data.json();

        this.selectedPreviewImage = this.product_obj.preview_image;


        this.showForm = true;
        this.catagories();

        this.disableRbutton = false;

        console.log(this.product_obj);
      },
      (error) => { }
    );
  }

  removeSizeChart() {
    this.uploadedFile = undefined

  }

  catagories() {
    switch (this.product_obj.category_name) {
      case 'Clothing':
        this.product_obj.categories = 1;
        break;

      case 'Paper':
        this.product_obj.categories = 2;
        break;

      case 'Accessories':
        this.product_obj.categories = 3;
        break;

      default:
        break;
    }
  }

  cancel() {

    if (this.product_obj.productinventory_set.some((element) => element.productinventoryimage_set.length <= 0)) {
      alert("Add atleast one Image to every color and size option!");
      console.log("existing img err");
      return
    }



    this._location.back();
  }

  AddComboDialogue() {
    console.log('ac');

    console.log(this.product_obj.categories);
    let sizes: any

    if (this.product_obj.categories === 1) {
      sizes = this.Sizes
    } else {
      sizes = ''
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Add Combination",
        content: { id: this.prod_id, category: this.product_obj.categories, tags: this.tags.value, color: '', size: sizes, quantity: '', price: '', files: [], storefront: this.selectedstorefronts[0].id },
        type: "addcombination"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result) {

        this.product_obj.productinventory_set.push(result);
        this.product_obj.productinventory_set = _.filter(this.product_obj.productinventory_set, function (item, index) {
          return true
        })
        if (!this.sizeArray.includes(result.size)) {
          this.sizeArray.push(result.size)
        }

        if (!this.colorArray.includes(result.color)) {
          this.colorArray.push(result.color)
        }
        this.sortProductInv()
        console.log(this.product_obj.productinventory_set);
      }
    });
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Select Storefronts",
        content: this.selectedstorefronts,
        type: "selectstorefront"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result, result.length);

      if (result.length > 0) {
        this.selectedstorefronts.forEach(sf => {
          result = result.filter((item, index) => item.id != sf.id)
        })

        console.log(result);

        result.forEach(element => {
          let obj = { product: this.product_obj.id, storefront: element.id };

          this.dataservice.addSftoProduct(obj).subscribe(
            res => {

              this.selectedstorefronts.push(element);



              if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
                let ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(element => element.id == obj.product)
                console.log(ind);
                let sfObj: p_sf = { id: element.id, name: element.name, is_active: true, status: element.status };
                this.product_obj.storefront_product.push(sfObj);
                this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind].storefront_product.push(sfObj)
                // this.shiftObjectAtTop();
              }
              this.openSnackBar("Storefront updated!", "close")


            },
            err => {
              console.log("error adding sf");
            }
          )


        });


        console.log(this.selectedstorefronts);
      }
    });
  }

  openAskDeleteSfDiaglogue(i) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to remove this storefront?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {

        let obj = { product: this.product_obj.id, storefront: this.selectedstorefronts[i].id };
        this.dataservice.deleteSffromProduct(obj).subscribe(
          res => {
            this.selectedstorefronts.splice(i, 1);
            if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
              let ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(element => element.id == obj.product)
              let indx = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind].storefront_product.findIndex(element => element.id == obj.storefront)
              console.log(indx);
              this.product_obj.storefront_product.splice(i, 1);

              this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind].storefront_product.splice(indx, 1)
              // this.shiftObjectAtTop(); 

            }
            this.openSnackBar("Storefront removed!", "close")

          }, err => {
            console.log("err deleting sf");
          }
        )


      }

    })

  }

  goToSf(id) {
    console.log(id);
    this.router.navigate(['/editstore/' + id]);

  }

  remove(index) {

    this.openAskDeleteSfDiaglogue(index)

    // this.openSnackBar();
  }

  detectFiles(event) {
    // console.log(index);
    let files = event.target.files;
    console.log(files);
    if (files) {
      for (let file of files) {
        this.files.push(file);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let obj = {
            name: file.name,
            url: e.target.result,
          };

          this.product_obj.product_images.push({
            id: 0,
            image: e.target.result,
            product: this.product_obj.id,
            color: '',
          });

          this.checkForPendingImages();
        };
        reader.readAsDataURL(file);
      }
    }
  }

  updateImages() {
    this.dataservice.UpdateImages(this.files, this.product_obj.id).subscribe(
      (data) => {
        console.log(data.json());

        // this.product_obj = data.json();
        this.getSingleProduct();
        this.confirmationDialog();
      },
      (error) => {
        console.log(error);

        this.econfirmationDialog();
      }
    );
  }

  checkForPendingImages() {
    const data = this.product_obj.product_images.find((ele) => {
      return ele.id === 0;
    });

    if (data) {
      console.log('found');
      this.disableRbutton = true;
    } else {
      this.disableRbutton = false;
    }
  }

  checkForPendingImages2(item) {
    if (!this.disableRbutton) {
      console.log(item);
      const data = this.product_obj.product_images.find((ele) => {
        return ele.id === 0;
      });

      if (data) {
        console.log('found');
        this.disableRbutton = true;
        this.selectedPreviewImage = '';
      } else {

        console.log(this.product_obj.preview_image, item);

        if (this.product_obj.preview_image !== item) {
          this.updatePreivewImage(item, true);
        }
      }
    }
  }

  increaseQ(num) {
    if (num == 1) {
      this.product_obj.productinventory_set.forEach((inv, index) => {
        inv.quantity += 10
        this.updateQ(index)
      })
    } else {
      this.product_obj_new.productinventory_set.forEach((inv, index) => {
        inv.quantity += 10

      })
    }


  }
  decreaseQ(num) {
    if (num == 1) {
      this.product_obj.productinventory_set.forEach((inv, index) => {
        if (inv.quantity > 0 && inv.quantity >= 10) {
          inv.quantity -= 10
          this.updateQ(index)

        } else {
          inv.quantity = 0
          this.updateQ(index)

        }

      })
    } else {
      this.product_obj_new.productinventory_set.forEach((inv, index) => {
        if (inv.quantity > 0 && inv.quantity >= 10) {
          inv.quantity -= 10


        } else {
          inv.quantity = 0

        }

      })
    }



  }

  onToggle(evt) {

    console.log(evt.checked);
    this.productStatus = evt.checked
    this.toggleProductstaus(this.product_obj.id, evt.checked)

  }

  toggleProductstaus(pid, status) {
    let obj = { product: pid, is_active: status };
    this.dataservice.toggleProductStatus(obj).subscribe(
      data => {
        console.log(data);
        this.updateStatusLocally(status);


      },
      error => {
        this.productStatus = !this.productStatus

      }
    );
  }

  uploadedFile: { name: string, type: string, url: SafeResourceUrl, file: any };


  uploadSizeChart(event: any, i: number) {

    console.log(i);

    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const fileType = file.type;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let obj = { style: this.product_obj.productsizechart_set[i].style, size_chart: file }
        // const url = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
        // this.uploadedFile = {
        //   name: file.name,
        //   type: fileType,
        //   url: url,
        //   file: file
        // }
        // this.product_obj.size_chart = file
        console.log(obj);
        this.updatesinglesizechart(this.product_obj.productsizechart_set[i].id, obj, i)
      };

      reader.readAsDataURL(file);
      // this.isImage = this.dataservice.isImage(fileType)
      // this.isPdf = this.dataservice.isPDF(fileType)
      // if (this.dataservice.isImage(fileType) || this.dataservice.isPDF(fileType)) {
      //   reader.readAsDataURL(file);
      // }
    }
  }

  updatesinglesizechart(i, obj, localid) {
    this.dataservice.addNewSizeChartToStyle(i, obj).then(
      val => {
        console.log(val);
        this.product_obj.productsizechart_set[localid].size_chart = val.size_chart
        if (this.product_obj.productsizechart_set.length > 0) {
          this.product_obj.productsizechart_set.forEach(c => {
            this.isImageBackend = this.dataservice.isImageFromBackend(c.size_chart)
            this.isPdfBackend = this.dataservice.isPdfFromBackend(c.size_chart)
          })
        }
      }
    )
  }


  updateStatusLocally(status) {
    if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
      let ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(element => element.id == this.product_obj.id)
      console.log(ind);

      this.product_obj.active = status

      this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind] = this.product_obj;
    }
  }

  add(event: MatChipInputEvent, i: number, type: string): void {
    const value = this.dataservice.capitalizeFirstLetter(event.value || '').trim();

    switch (type) {
      case 'color': {

        console.log(type, value, 'as');

        if (value) {
          this.colorArray.push(value);
          // this.colorSizeChanged = true

        }

        if (event.input) {
          event.input.value = '';
        }
        break;
      }
      case 'size': {

        console.log(type);
        if (value) {
          this.sizeArray.push(value.toUpperCase());
          // this.colorSizeChanged = true

        }


        if (event.input) {
          event.input.value = '';
        }

        break;
      }
      default: {
        //statements; 
        break;
      }
    }





  }

  removeC(item, i, type): void {


    switch (type) {
      case 'color': {

        console.log(type);
        const index = this.colorArray.indexOf(item);

        if (index >= 0) {
          this.colorArray.splice(index, 1);
          // this.colorSizeChanged = true


          this.showNewTable = false;
          this.product_obj_new.productinventory_set = [];
        }



        break;
      }
      case 'size': {

        console.log(type);
        const index = this.sizeArray.indexOf(item);

        if (index >= 0) {
          this.sizeArray.splice(index, 1);
          this.product_obj_new.productinventory_set = [];
          // this.colorSizeChanged = true


          this.showNewTable = false;


        }


        break;
      }
      default: {
        //statements; 
        break;
      }
    }


  }
  generateList() {

    this.colorSizeChanged = true;
    this.product_obj_new.productinventory_set = []

    // const colorArray = this.product_obj.color.split(',').filter(c => c != "");
    // const sizeArray = this.product_obj.size.split(',').filter(c => c != "");
    const colorArray = this.colorArray
    const sizeArray = this.sizeArray
    colorArray.forEach(color => {
      sizeArray.forEach(size => {

        console.log(color, size);
        this.tags.value.forEach(tag => {
          var inv: Inventory = { tag: tag.name, color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.product_obj.price), files: [], imageArr: [], isChecked: false };
          this.product_obj_new.productinventory_set.push(inv);

        })

        this.sortProductInvNewGeneratedList()
        // var inv: Inventory = {tag: ,color: color.trim(), size: size.trim(), quantity: 0, files: [], imageArr: [], isChecked: false };
        // this.product_obj_new.productinventory_set.push(inv);

        // console.log(inv);

      })
    })



    this.showNewTable = true

    console.log(this.product_obj_new);

  }

  uploadImageNew(event, i) {
    console.log(i);
    let images = event.target.files;
    var newFiles = [];

    if (images) {

      for (let image of images) {
        console.log(`Image size before compressed: ${image.size} bytes.`);

        this.compressImage
          .compress(image)
          .pipe(take(1))
          .subscribe(compressedImage => {
            newFiles.push(compressedImage)
            if (images.length === newFiles.length) {
              console.log(newFiles);
              this.openAskDialog(i, newFiles);
            }
            console.log(
              `Image size after compressed: ${(compressedImage.size)} bytes.`
            );
            // now you can do upload the compressed image
          });
      }

    }
  }

  uploadImageExisting(event, i) {
    console.log(i);
    var newFiles = [];
    let images = event.target.files;
    if (images) {
      if (this.colorSizeEdit) {
        console.log("edit");

        for (let image of images) {
          console.log(`Image size before compressed: ${image.size} bytes.`);

          this.compressImage
            .compress(image)
            .pipe(take(1))
            .subscribe(compressedImage => {
              newFiles.push(compressedImage)
              if (images.length === newFiles.length) {
                console.log(newFiles);
                this.openAskDialogForExisting(i, newFiles);

              }
              console.log(
                `Image size after compressed: ${(compressedImage.size)} bytes.`
              );
              // now you can do upload the compressed image
            });
        }

      }
    }


    return
    this.openAskDialogForExisting(i, images);


  }



  // not in use currently 
  setNewImage(image: string) {
    if (image !== null) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(
        `url(${'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'})`
      );
    }
  }

  // alldialogues
  openAskDialog(i, files): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to apply this product image to other color and size options?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {
        this.openAskColorSizeDialog(i, files);

      } else {
        for (let file of files) {
          this.product_obj_new.productinventory_set[i].files.push(file);

          let reader = new FileReader();
          reader.onload = (e: any) => {
            let obj = {
              name: file.name,
              url: e.target.result
            };

            this.product_obj_new.productinventory_set[i].imageArr.push(obj);
          };
          reader.readAsDataURL(file);
        }
      }

    });
  }

  openAskDialogForExisting(i, files): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to apply this product image to other color and size options?",
        type: "question"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {
        this.openAskColorSizeDialogForExisting(i, files);

      } else {

        for (let file of files) {
          this.addNewImageToCombo(this.product_obj.productinventory_set[i].id, file);
        }
      }

    });
  }

  openAskColorSizeDialog(i, files): void {

    let element = this.product_obj_new
    element.colorArray = this.colorArray
    element.sizeArray = this.sizeArray

    console.log(element.colorArray, element.sizeArray);

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Choose color and size!",
        content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
        type: "colorAndsizeOptions"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);


      console.log(this.product_obj_new.productinventory_set);
      this.product_obj_new.productinventory_set.forEach((inv, indexx) => {
        result.tags.forEach(tag => {
          if (tag == inv.tag) {
            if (result.colors.includes(inv.color) && result.sizes.includes(inv.size)) {
              console.log('add here');
              for (let file of files) {
                this.product_obj_new.productinventory_set[indexx].files.push(file);

                let reader = new FileReader();
                reader.onload = (e: any) => {
                  let obj = {
                    name: file.name,
                    url: e.target.result
                  };

                  this.product_obj_new.productinventory_set[indexx].imageArr.push(obj);
                };
                reader.readAsDataURL(file);
              }
            }
          }
        })

      })
    });
  }

  openAskColorSizeDialogForExisting(i, files): void {

    let element = this.product_obj_new
    element.colorArray = this.colorArray
    element.sizeArray = this.sizeArray

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Choose color and size!",
        content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
        type: "colorAndsizeOptions"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      // return

      console.log(this.product_obj.productinventory_set);
      console.log(this.product_obj.productinventory_set.length);
      this.product_obj.productinventory_set.forEach((inv, indexx) => {

        result.tags.forEach((element, ind) => {
          console.log(element, inv.tag);
          if (element == inv.tag) {
            if (result.colors.map(color => color.toLowerCase()).includes(inv.color.toLowerCase()) &&
              result.sizes.map(size => size.toLowerCase()).includes(inv.size.toLowerCase())) {
              console.log("color matched", indexx);

              for (let file of files) {
                console.log(file);
                var id = this.product_obj.productinventory_set[indexx].id

                this.addNewImageToCombo(id, file);
              }

            }
          }
        });

      })
    });

  }

  addNewImageToCombo(id, file) {

    this.isLoading = true;
    this.dataservice.addInvImage(id, file).then(
      (res) => {
        console.log(res);
        this.isLoading = false;

        let index = _.findIndex(this.product_obj.productinventory_set, (e) => {
          return e.id == id;
        }, 0);
        console.log(index);

        this.product_obj.productinventory_set[index].productinventoryimage_set.push(res);
        console.log(this.product_obj.productinventory_set[index].productinventoryimage_set);
        this.openSnackBar("Image Updated", "close")
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.openSnackBar("Image Updated error", "close")


      }
    )
  }






  inputChanged() {
    console.log("gotcha");
    this.colorSizeChanged = true
  }

  toggleColorSizeEdit() {

    this.colorSizeEdit = !this.colorSizeEdit;
    if (this.colorSizeEdit) {
      this.colorSizeIcon = "close";
    } else {
      this.showNewTable = false
      this.product_obj_new.productinventory_set = []
      this.resetCSInputValues();
      this.colorSizeChanged = false;
      this.colorSizeIcon = "edit";
    }


  }

  toggleSize(size: string): void {
    const index = this.selectedSizes.indexOf(size);
    if (index === -1) {
      this.selectedSizes.push(size);
    } else {
      this.selectedSizes.splice(index, 1);
    }
    this.sizeArray = this.selectedSizes
    console.log(this.selectedSizes);
  }

  resetCSInputValues() {


    this.product_obj.color = this.tempColorValue;
    this.product_obj.size = this.tempSizeValue;

    this.colorArray = [];
    this.sizeArray = [];
    this.tempcolorArray.forEach(col => {
      this.colorArray.push(col);
    })
    this.tempsizeArray.forEach(siz => {
      this.sizeArray.push(siz);
    })

    // console.log(this.tempColorValue, this.colorArray, this.tempcolorArray);

  }

  removeCombo(i) {
    this.product_obj_new.productinventory_set = _.filter(this.product_obj_new.productinventory_set, function (item, index) {
      return index != i
    })
  }

  openAskDeleteComboDiaglogue(i) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to delete this combination?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {
        this.isLoading = true;

        this.dataservice.deleteInvRow(this.product_obj.productinventory_set[i].id).then(
          (res) => {
            console.log(res);
            this.isLoading = false;

            this.product_obj.productinventory_set = _.filter(this.product_obj.productinventory_set, function (item, index) {
              return index != i
            })

            this.colorArray = this.colorArray.filter((color, index) => this.product_obj.productinventory_set.some((inv) => {
              return inv.color.toLowerCase() == color.toLowerCase()
            }))
            console.log(this.colorArray);

            this.sizeArray = this.sizeArray.filter((size, index) => this.product_obj.productinventory_set.some((inv) => {
              return inv.size.toLowerCase() == size.toLowerCase()

            }))
            this.selectedSizes = this.sizeArray

            this.openSnackBar("Combination Removed", "close");

          },
          (err) => {
            console.log(err);
            this.isLoading = false;
            this.openSnackBar("Combination Remove error", "close");


          }
        )


      }

    })

  }
  removeComboExisting(i) {

    this.openAskDeleteComboDiaglogue(i);

  }

  removeImageNewTable(imgin, i) {
    console.log(imgin, i);
    this.product_obj_new.productinventory_set[i].imageArr.splice(imgin, 1);
    this.product_obj_new.productinventory_set[i].files.splice(imgin, 1);
  }

  openAskDeleteImageDiaglogue(imgin, i) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to delete this image?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {

        this.deleteInvImage(imgin, i)


      }

    })

  }
  removeImageExistingTable(imgin, i) {
    console.log(imgin, i);
    this.openAskDeleteImageDiaglogue(imgin, i);

  }

  deleteInvImage(imgin, i) {

    console.log(imgin, this.product_obj.productinventory_set[i].productinventoryimage_set[imgin].id);
    let imgId = this.product_obj.productinventory_set[i].productinventoryimage_set[imgin].id


    this.dataservice.deleteImage(imgId).then(
      (res) => {
        console.log("image delete success", res);
        this.product_obj.productinventory_set[i].productinventoryimage_set.splice(imgin, 1);
        this.openSnackBar("Image Removed", "close");



      },
      (err) => {
        console.log(err);
      }
    );

  }




  updateInv() {
    if (this.showNewTable && this.colorSizeChanged && this.product_obj_new.productinventory_set.every((element) => element.imageArr.length > 0)) {
      console.log("new inv");
      let sfid = this.selectedstorefronts[0].id
      console.log(sfid);


      this.dataservice.updateNewInventory(this.product_obj.id, this.product_obj_new.productinventory_set, sfid).then(
        (res) => {
          console.log("new inv response", res);
          this.openSnackBar("Inventory Updated", "close");
          // location.reload()
          this.isLoading = false;
          this.InventoryconfirmationDialog();

        },
        (err) => {
          console.log(err);
        }
      )

    }
  }


  updateQ(i) {

    let invObj = {
      color: this.product_obj.productinventory_set[i].color,
      size: this.product_obj.productinventory_set[i].size,
      quantity: this.product_obj.productinventory_set[i].quantity,
    }
    this.isLoading = true;
    this.dataservice.updateInventory(this.product_obj.productinventory_set[i].id, invObj).then(
      (res) => {
        this.isLoading = false;

        console.log("update quantity res", res);
        this.openSnackBar("quantity updated", "close");
      }, err => {
        this.isLoading = false;
        this.openSnackBar("quantity update error", "close");

      }
    )

  }

  updateP(i) {
    let invObj = {
      color: this.product_obj.productinventory_set[i].color,
      size: this.product_obj.productinventory_set[i].size,
      price: this.product_obj.productinventory_set[i].price,
    }
    this.isLoading = true;
    this.dataservice.updateInventory(this.product_obj.productinventory_set[i].id, invObj).then(
      (res) => {
        this.isLoading = false;

        console.log("update price res", res);
        this.openSnackBar("price updated", "close");
      }, err => {
        this.isLoading = false;
        this.openSnackBar("price update error", "close");

      }
    )
  }

  updateQuantity(q, i) {
    this.invIndex = i
    this.quantity$.next(q);
  }

  updatePrice(p, i) {
    console.log(p, i);
    this.invIndex = i
    this.price$.next(p);
  }

  shiftObjectAtTop() {
    if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
      let ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(element => element.id == this.product_obj.id)
      console.log(ind);

      this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].splice(ind, 1);
      this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0].unshift(this.product_obj);
    }
  }




  update2() {

    let obj = {
      title: this.product_obj.title,
      code: this.product_obj.code,
      description: this.product_obj.description,
      product_background_color: this.product_obj.product_background_color,
      product_text_color: this.product_obj.product_text_color,
      price: this.product_obj.price,
      categories: this.product_obj.categories,
      tags: this.tags.value,
      special_tags: this.specialTags.value,
      is_featured: this.product_obj.is_featured,
      storefront: this.selectedstorefronts[0].id
    };

    if (this.uploadedFile) {
      obj['size_chart'] = this.uploadedFile.file
    }

    if (obj.title.length <= 0 || obj.code.length <= 0 || obj.price == null) {
      alert("Fill All The Details");
      return
    }

    if (this.showNewTable && this.colorSizeChanged) {

      obj['color'] = this.dataservice.makeColorStr(this.colorArray);
      obj['size'] = this.sizeArray.toString();

      if (this.product_obj_new.productinventory_set.some((element) => element.imageArr.length <= 0)) {
        alert("Add atleast one Image to every color and size option!");
        console.log("new img err");

        return
      }
    } else {
      if (this.product_obj.productinventory_set.some((element) => element.productinventoryimage_set.length <= 0)) {
        alert("Add atleast one Image to every color and size option!");
        console.log("existing img err");
        return
      }

    }

    if (this.tags.value == null) {
      alert("Select atleast one product type option!");
      return
    }

    console.log(this.tags.value, obj);


    console.log(this.product_obj.id, obj["color"], obj["size"]);



    this.isLoading = true;
    this.dataservice.updateProduct2(this.product_obj.id, obj).then(
      (res) => {
        console.log(res);

        this.updateProductLocally(res);


        // this.updateProductInselectedStore(res as any);
        this.updateInv();
        // this.openSnackBar("Product Updated","close");
        if (!this.showNewTable && !this.colorSizeChanged) {
          this.isLoading = false;
          this.confirmationDialog()
        }


      }

      ,
      (err) => {
        console.error(err);
        this.econfirmationDialog();
      }
    )

  }

  // update() {
  //   let obj = {
  //     title: this.product_obj.title,
  //     code: this.product_obj.code,
  //     description: this.product_obj.description,
  //     price: this.product_obj.price,
  //     categories: this.product_obj.categories,
  //     color: this.product_obj.color,
  //     size: this.product_obj.size,
  //     is_featured: this.product_obj.is_featured,
  //   };

  //   this.dataservice.updateProduct(obj, this.product_obj.id).subscribe(
  //     (data) => {
  //       console.log(data);

  //       this.updateProductInselectedStore(data.json());

  //       if (this.files.length > 0) {
  //         this.updateImages();
  //       } else {
  //         this.confirmationDialog();
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.econfirmationDialog();
  //     }
  //   );
  // }



  updateProductLocally(productObj) {

    if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
      let ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(element => element.id == productObj.id)
      console.log(ind, this.dataservice.inmemoryData[this.urlindex].multicollectionarry);
      if (ind != -1) {
        this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind] = productObj
        this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].splice(ind, 1);
        this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0].unshift(productObj);
      }


    }

  }

  updatePreivewImage(url, showC) {
    this.dataservice
      .updateProduct({ preview_image: url }, this.product_obj.id)
      .subscribe((data) => {
        console.log(data);
        this.product_obj = data.json();
        if (showC) {
          this.confirmationDialogPreviewImage();
        }
      });
  }

  updateProductInselectedStore(data) {
    console.log(data);
    console.log(this.dataservice.selected_storefront);
    if (this.dataservice.selected_storefront) {
      const index = this.dataservice.selected_storefront.storefront_product.findIndex(
        (element) => element.id === this.product_obj.id
      );

      console.log(this.dataservice.selected_storefront);
      console.log(data, index, 'idk');
      if (index !== -1) {
        this.dataservice.selected_storefront.storefront_product[
          index
        ].title = this.product_obj.title;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].code = this.product_obj.code;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].description = this.product_obj.description;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].price = this.product_obj.price;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].categories = this.product_obj.categories;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].color = this.product_obj.color;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].size = this.product_obj.size;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].is_featured = this.product_obj.is_featured;
        this.dataservice.selected_storefront.storefront_product[
          index
        ].category_name = data.category_name;
      }
    }
  }

  InventoryconfirmationDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Success',
        content: 'Product has been successfully updated',
        type: 'confirmation',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      location.reload();
    });
  }

  confirmationDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Success',
        content: 'Product has been successfully updated',
        type: 'confirmation',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }


  confirmationDialogPreviewImage() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Success',
        content: 'Preivew Image has been successfully updated',
        type: 'confirmation',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  econfirmationDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Error', content: 'Something went wrong!', type: 'error' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      location.reload();
    });
  }

  copySizes() {
    this.dataservice.copiedSizes$.next(this.sizeArray)
    this.openSnackBar("Sizes Copied Successfully.", "");

  }
}
