
  <h1 class="text-center h1s"><img class="img1" src="assets/images/fcon.png" height="60">{{this.singlesfobj.chain_name}}</h1>
    <mat-card class="cardss">
        <table class="table table-hover">
            <thead>
              <tr class="headesrs font-fmly">
                <th>Storefront Name</th>
                <th>Location</th>
                <th>Status</th>
                <th>Order History</th>
                <th>Preview</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let storefront of selected_storefronts; let i = index;">
                <td>{{storefront.name}}</td>
                <td>{{storefront.location}}</td>
                <td>{{storefront.status}}</td>
                <td><button class="btns" mat-raised-button color="primary">Order History</button></td>
                <td><button class="btns" mat-raised-button color="primary" (click)="preview(i)">Preview</button></td>
              </tr>
            </tbody>
          </table>
      </mat-card>
