<!-- admin -->
<div class="class1" *ngIf="type === 'admin'">
  <div class="blocks">
    <h1 class="text-center names font-fmly">Primetime Admin</h1>
    <mat-card class="card-container card">
      <img class="img1" src="assets/images/fcon.png" height="60">
      <!-- <mat-toolbar class="tas">Login</mat-toolbar> -->
      <div class="example-container" style="margin:10px;">
        <mat-form-field class="txt1">
          <input matInput placeholder="Username" [(ngModel)]="username">
        </mat-form-field>

        <mat-form-field class="txt1">
          <input matInput placeholder="Password" type="password" [(ngModel)]="password">
        </mat-form-field>
        <button mat-raised-button color="primary" class="btn1" (click)="login()">Login</button>
      </div>
    </mat-card>
  </div>
</div>

<!-- customer -->

<div class="class1" *ngIf="type === 'customer'">
  <div class="blocks">
    <h1 *ngIf="!showDrpdwn" class="text-center names font-fmly">Customer</h1>
    <h1 *ngIf="showDrpdwn"  class="text-center names">{{selectedStoreFront.chain_name}}</h1>

    <mat-card class="card-container card">
      <img class="img1" src="assets/images/fcon.png" height="60">
      <!-- <mat-toolbar class="tas">Login</mat-toolbar> -->
      <div class="example-container" style="margin:10px;" *ngIf="!showDrpdwn">
        <mat-form-field class="txt1">
          <input matInput placeholder="Passcode" type="password" [(ngModel)]="password">
        </mat-form-field>
        <button mat-raised-button color="primary" class="btn1" (click)="custlogin()">Login</button>
      </div>
      <!-- Stores select -->
      <div class="example-container" style="margin:10px;" *ngIf="showDrpdwn">
        <mat-form-field class="txt1">
          <mat-label>Select Store</mat-label>
          <mat-select [(value)]="selectedStoreFront" >
            <mat-option *ngFor="let store of dataservice.protected_selected_storefronts"  [value]="store" >
              {{store.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" class="btn1" (click)="launchStore()">Launch Store</button>
      </div>
      <!--  -->
    </mat-card>
  </div>
</div>