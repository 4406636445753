import { OnInit } from "@angular/core";
import { DataService } from "./../data.service";
import { Router, ActivatedRoute } from "@angular/router";
var ProductsComponent = /** @class */ (function () {
    function ProductsComponent(dataservice, router, route) {
        this.dataservice = dataservice;
        this.router = router;
        this.route = route;
        this.quantity = 1;
        this.prod_list_shop = true;
        this.newarry = [];
        this.product_num = 0;
        this.cart = [];
        this.Cart_total = 0;
        this.prveiw = true;
        this.view_cart = false;
        this.checkouts = false;
        this.years = [];
        this.month = [];
        this.paymentGate = false;
        this.holdername = "";
        this.cardnumbers = "";
        this.success = false;
        this.Clothbtn = false;
        this.paperBtn = false;
        this.Accesorbtn = false;
    }
    ProductsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.SF_id = params["id"];
            _this.user_id = params["user"];
        });
        this.dataservice.getProduct(this.SF_id).then(function (res) {
            _this.storefront_products = res;
            console.log(_this.storefront_products, "hello");
            _this.prod_list = _this.storefront_products.storefront_product;
            _this.prod_name = _this.storefront_products.name;
            //
            _this.dataservice.editCustomer(_this.user_id).then(function (res) {
                _this.cust_res = res;
                console.log(_this.cust_res);
                _this.cust_name = _this.cust_res.name;
                _this.cust_logo = _this.cust_res.logo;
            });
        });
    };
    //
    ProductsComponent.prototype.prod_details = function (i) {
        this.quantity = 1;
        this.prod_list_shop = false;
        console.log(i);
        console.log(this.prod_list[i]);
        this.price_prod = this.prod_list[i].price;
        this.title_prod = this.prod_list[i].title;
        this.product_id = this.prod_list[i].id;
        this.product_desc = this.prod_list[i].description;
        this.total_price = this.price_prod * this.quantity;
        console.log(this.newarry);
        this.product_img = this.prod_list[i].product_images;
        console.log(this.product_img, "imgs list");
        this.main_image = this.product_img[0].image;
        // console.log(this.main_image);
        for (var j = 0; j < this.product_img.length; j++) {
            this.img_arry = this.product_img[j].image;
            console.log(this.img_arry);
        }
        this.selectd_img = this.product_img[0].image;
        //this.image_prod=this.products_details[i].product_images;
    };
    ProductsComponent.prototype.addcart = function () {
        this.newarry.push({
            price: this.price_prod,
            title: this.title_prod,
            total_price: this.total_price,
            prod_decription: this.product_desc,
            product_id: this.product_id,
            SF_id: this.SF_id,
            image: this.selectd_img
        });
        this.cart.push(this.newarry);
        // console.log(this.total_price);
        console.log(this.cart, "my cart");
        for (var i = 0; i < this.cart.length; i++) {
            this.mycart = this.cart[i];
            //console.log(this.mycart, 'cart products');
        }
        this.Cart_total = 0;
        for (var k = 0; k < this.mycart.length; k++) {
            console.log(this.mycart[k].total_price);
            this.Cart_total = this.Cart_total + this.mycart[k].total_price;
            console.log(this.Cart_total, "total");
        }
        this.product_num = this.cart.length;
        console.log(this.product_num);
        localStorage.setItem("price", this.price_prod);
        localStorage.setItem("total_price", this.total_price);
        localStorage.setItem("prod_decription", this.product_desc);
        localStorage.setItem("product_id", this.product_id);
        localStorage.setItem("SF_id", this.SF_id);
    };
    ProductsComponent.prototype.backProdList = function () {
        this.selectd_img = "";
        this.prod_list_shop = true;
    };
    ProductsComponent.prototype.showImg = function (i) {
        this.selectd_img = this.product_img[i].image;
        console.log(this.selectd_img);
    };
    ProductsComponent.prototype.incart = function () {
        this.prveiw = false;
        this.view_cart = true;
    };
    ProductsComponent.prototype.continueShop = function () {
        this.prveiw = true;
        this.view_cart = false;
    };
    ProductsComponent.prototype.checkout = function () {
        this.view_cart = false;
        this.checkouts = true;
    };
    ProductsComponent.prototype.backViewCart = function () {
        this.view_cart = true;
        this.checkouts = false;
    };
    ProductsComponent.prototype.backTo = function () {
        this.paymentGate = false;
        this.prveiw = true;
    };
    ProductsComponent.prototype.quantities = function ($event) {
        console.log(this.quantity);
        this.total_price = this.price_prod * this.quantity;
        console.log(this.total_price, "total price 1prduct");
    };
    ProductsComponent.prototype.NextStep = function () {
        for (var i = 2018; i <= 2050; i++) {
            this.years.push(i);
        }
        for (var i = 1; i <= 12; i++) {
            this.month.push(i);
        }
        this.checkouts = false;
        this.paymentGate = true;
    };
    ProductsComponent.prototype.payNow = function () {
        if (this.Cardvalue == "mastercard") {
            console.log(this.Cardvalue, "mast");
            if (this.holdername == "") {
                alert("Please Enter Name");
                return;
            }
            if (this.cardnumbers == "") {
                alert("Please Enter  Number");
                return;
            }
            console.log(this.cardnumbers.toString().length);
            if (this.cardnumbers.toString().length != 16) {
                alert("Number Should be 16 Digit");
                return;
            }
            else {
                this.success = true;
                alert("$" + this.Cart_total + " Paid successfully");
                this.mycart = [];
                this.product_num = 0;
            }
        }
        // Visa method
        if (this.Cardvalue == "visa") {
            console.log(this.Cardvalue, "vis");
            if (this.holdername == "") {
                alert("Please Enter Name");
                return;
            }
            if (this.cardnumbers == "") {
                alert("Please Enter  Number");
                return;
            }
            console.log(this.cardnumbers.toString().length);
            if (this.cardnumbers.toString().length != 16) {
                alert("Number Should be 16 Digit");
                return;
            }
            else {
                this.success = true;
                alert("$" + this.Cart_total + " Paid successfully");
                this.mycart = [];
                this.product_num = 0;
            }
        }
        // American Express
        if (this.Cardvalue == "america") {
            console.log(this.Cardvalue, "american");
            if (this.holdername == "") {
                alert("Please Enter Name");
                return;
            }
            if (this.cardnumbers == "") {
                alert("Please Enter  Number");
                return;
            }
            console.log(this.cardnumbers.toString().length);
            if (this.cardnumbers.toString().length != 15) {
                alert("Number Should be 15 Digit");
                return;
            }
            else {
                this.success = true;
                alert("$" + this.Cart_total + " Paid successfully");
                this.mycart = [];
                this.product_num = 0;
            }
        }
        // Discover Card
        if (this.Cardvalue == "discover") {
            console.log(this.Cardvalue, "discovr");
            if (this.holdername == "") {
                alert("Please Enter Name");
                return;
            }
            if (this.cardnumbers == "") {
                alert("Please Enter  Number");
                return;
            }
            console.log(this.cardnumbers.toString().length);
            if (this.cardnumbers.toString().length != 16) {
                alert("Number Should be 16 Digit");
                return;
            }
            else {
                this.success = true;
                alert("$" + this.Cart_total + " Paid successfully");
                this.mycart = [];
                this.product_num = 0;
            }
        }
    };
    ProductsComponent.prototype.next_funcP = function () {
        var _this = this;
        this.dataservice.paperProduct(this.next).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.prev_funcP = function () {
        var _this = this;
        this.dataservice.paperProduct(this.prev).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
        console.log(this.prev);
    };
    // Cloths function Prev Next
    ProductsComponent.prototype.next_funcC = function () {
        var _this = this;
        this.dataservice.clothProduct(this.next).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.prev_funcC = function () {
        var _this = this;
        this.dataservice.clothProduct(this.prev).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
        console.log(this.prev);
    };
    ProductsComponent.prototype.next_funcA = function () {
        var _this = this;
        this.dataservice.clothProduct(this.next).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.prev_funcA = function () {
        var _this = this;
        this.dataservice.clothProduct(this.prev).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
        console.log(this.prev);
    };
    ProductsComponent.prototype.Clothing = function () {
        var _this = this;
        this.Clothbtn = true;
        this.paperBtn = false;
        this.Accesorbtn = false;
        this.dataservice.clothProduct(this.SF_id).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.count, "count");
            console.log(_this.next, "next");
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.Accesories = function () {
        var _this = this;
        this.Clothbtn = false;
        this.paperBtn = false;
        this.Accesorbtn = true;
        this.dataservice.accessoriesProduct(this.SF_id).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.Paper = function () {
        var _this = this;
        this.paperBtn = true;
        this.Clothbtn = false;
        this.Accesorbtn = false;
        this.dataservice.paperProduct(this.SF_id).then(function (res) {
            _this.response_c = res;
            _this.next = _this.response_c.next;
            _this.count = _this.response_c.count;
            _this.prev = _this.response_c.previous;
            console.log(_this.response_c);
            _this.prod_list = _this.response_c.results;
            console.log(_this.prod_list);
        });
    };
    ProductsComponent.prototype.all = function () {
        var _this = this;
        this.dataservice.getProduct(this.SF_id).then(function (res) {
            _this.prod_res = res;
            console.log(_this.prod_res);
            _this.prod_list = _this.prod_res.storefront_product;
            console.log(_this.prod_list, "product list");
            for (var i = 0; i < _this.prod_list.length; i++) {
                var imgs = _this.prod_list[i].product_images;
                _this.prods_img = imgs[0].image;
                console.log(_this.prods_img);
            }
        });
    };
    return ProductsComponent;
}());
export { ProductsComponent };
