import { Router } from '@angular/router';
import { DataService } from './data.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(dataservice, route) {
        this.dataservice = dataservice;
        this.route = route;
    }
    AuthGuardService.prototype.canActivate = function () {
        if (!sessionStorage.getItem('token')) {
            this.route.navigate(['login/admin']);
            return false;
        }
        return true;
    };
    return AuthGuardService;
}());
export { AuthGuardService };
