import { Component, OnInit } from "@angular/core";
import { DataService } from "./../data.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"]
})
export class ProductsComponent implements OnInit {
  constructor(
    private dataservice: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  SF_id: any;
  user_id: any;
  storefront_products: any;
  prod_list: any;
  prod_name: any;
  cust_res: any;
  cust_name: any;
  cust_logo: any;
  quantity = 1;
  prod_list_shop = true;
  price_prod: any;
  title_prod: any;
  product_id: any;
  product_desc: any;
  total_price: any;
  newarry: any = [];
  product_num = 0;
  product_img: any;
  main_image: any;
  img_arry: any;
  selectd_img: any;
  cart: any = [];
  Cart_total = 0;
  mycart: any;
  prveiw = true;
  view_cart = false;
  checkouts = false;
  years: any = [];
  month: any = [];
  paymentGate = false;
  Cardvalue: any;
  holdername = "";
  cardnumbers: string = "";
  success = false;
  next: any;
  response_c: any;
  count: any;
  prev: any;
  Clothbtn = false;
  paperBtn = false;
  Accesorbtn = false;
  prod_res: any;
  prods_img: any;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.SF_id = params["id"];
      this.user_id = params["user"];
    });

    this.dataservice.getProduct(this.SF_id).then(res => {
      this.storefront_products = res;
      console.log(this.storefront_products, "hello");
      this.prod_list = this.storefront_products.storefront_product;
      this.prod_name = this.storefront_products.name;
      //
      this.dataservice.editCustomer(this.user_id).then(res => {
        this.cust_res = res;
        console.log(this.cust_res);
        this.cust_name = this.cust_res.name;
        this.cust_logo = this.cust_res.logo;
      });
    });
  }

  //
  prod_details(i) {
    this.quantity = 1;
    this.prod_list_shop = false;
    console.log(i);
    console.log(this.prod_list[i]);
    this.price_prod = this.prod_list[i].price;
    this.title_prod = this.prod_list[i].title;
    this.product_id = this.prod_list[i].id;
    this.product_desc = this.prod_list[i].description;
    this.total_price = this.price_prod * this.quantity;
    console.log(this.newarry);
    this.product_img = this.prod_list[i].product_images;
    console.log(this.product_img, "imgs list");
    this.main_image = this.product_img[0].image;
    // console.log(this.main_image);
    for (var j = 0; j < this.product_img.length; j++) {
      this.img_arry = this.product_img[j].image;
      console.log(this.img_arry);
    }
    this.selectd_img = this.product_img[0].image;

    //this.image_prod=this.products_details[i].product_images;
  }

  addcart() {
    this.newarry.push({
      price: this.price_prod,
      title: this.title_prod,
      total_price: this.total_price,
      prod_decription: this.product_desc,
      product_id: this.product_id,
      SF_id: this.SF_id,
      image: this.selectd_img
    });
    this.cart.push(this.newarry);
    // console.log(this.total_price);
    console.log(this.cart, "my cart");
    for (var i = 0; i < this.cart.length; i++) {
      this.mycart = this.cart[i];
      //console.log(this.mycart, 'cart products');
    }
    this.Cart_total = 0;
    for (var k = 0; k < this.mycart.length; k++) {
      console.log(this.mycart[k].total_price);
      this.Cart_total = this.Cart_total + this.mycart[k].total_price;
      console.log(this.Cart_total, "total");
    }

    this.product_num = this.cart.length;
    console.log(this.product_num);
    localStorage.setItem("price", this.price_prod);
    localStorage.setItem("total_price", this.total_price);
    localStorage.setItem("prod_decription", this.product_desc);
    localStorage.setItem("product_id", this.product_id);
    localStorage.setItem("SF_id", this.SF_id);
  }

  backProdList() {
    this.selectd_img = "";
    this.prod_list_shop = true;
  }
  showImg(i) {
    this.selectd_img = this.product_img[i].image;
    console.log(this.selectd_img);
  }
  incart() {
    this.prveiw = false;
    this.view_cart = true;
  }
  continueShop() {
    this.prveiw = true;
    this.view_cart = false;
  }

  checkout() {
    this.view_cart = false;
    this.checkouts = true;
  }
  backViewCart() {
    this.view_cart = true;
    this.checkouts = false;
  }
  backTo() {
    this.paymentGate = false;
    this.prveiw = true;
  }

  quantities($event) {
    console.log(this.quantity);
    this.total_price = this.price_prod * this.quantity;
    console.log(this.total_price, "total price 1prduct");
  }

  NextStep() {
    for (var i = 2018; i <= 2050; i++) {
      this.years.push(i);
    }
    for (var i = 1; i <= 12; i++) {
      this.month.push(i);
    }
    this.checkouts = false;
    this.paymentGate = true;
  }

  payNow() {
    if (this.Cardvalue == "mastercard") {
      console.log(this.Cardvalue, "mast");
      if (this.holdername == "") {
        alert("Please Enter Name");
        return;
      }

      if (this.cardnumbers == "") {
        alert("Please Enter  Number");
        return;
      }
      console.log(this.cardnumbers.toString().length);
      if (this.cardnumbers.toString().length != 16) {
        alert("Number Should be 16 Digit");
        return;
      } else {
        this.success = true;
        alert("$" + this.Cart_total + " Paid successfully");
        this.mycart = [];
        this.product_num = 0;
      }
    }

    // Visa method
    if (this.Cardvalue == "visa") {
      console.log(this.Cardvalue, "vis");
      if (this.holdername == "") {
        alert("Please Enter Name");
        return;
      }

      if (this.cardnumbers == "") {
        alert("Please Enter  Number");
        return;
      }
      console.log(this.cardnumbers.toString().length);
      if (this.cardnumbers.toString().length != 16) {
        alert("Number Should be 16 Digit");
        return;
      } else {
        this.success = true;
        alert("$" + this.Cart_total + " Paid successfully");
        this.mycart = [];
        this.product_num = 0;
      }
    }

    // American Express
    if (this.Cardvalue == "america") {
      console.log(this.Cardvalue, "american");
      if (this.holdername == "") {
        alert("Please Enter Name");
        return;
      }

      if (this.cardnumbers == "") {
        alert("Please Enter  Number");
        return;
      }
      console.log(this.cardnumbers.toString().length);
      if (this.cardnumbers.toString().length != 15) {
        alert("Number Should be 15 Digit");
        return;
      } else {
        this.success = true;
        alert("$" + this.Cart_total + " Paid successfully");
        this.mycart = [];
        this.product_num = 0;
      }
    }

    // Discover Card
    if (this.Cardvalue == "discover") {
      console.log(this.Cardvalue, "discovr");
      if (this.holdername == "") {
        alert("Please Enter Name");
        return;
      }

      if (this.cardnumbers == "") {
        alert("Please Enter  Number");
        return;
      }
      console.log(this.cardnumbers.toString().length);
      if (this.cardnumbers.toString().length != 16) {
        alert("Number Should be 16 Digit");
        return;
      } else {
        this.success = true;
        alert("$" + this.Cart_total + " Paid successfully");
        this.mycart = [];
        this.product_num = 0;
      }
    }
  }

  next_funcP() {
    this.dataservice.paperProduct(this.next).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }

  prev_funcP() {
    this.dataservice.paperProduct(this.prev).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
    console.log(this.prev);
  }

  // Cloths function Prev Next
  next_funcC() {
    this.dataservice.clothProduct(this.next).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }

  prev_funcC() {
    this.dataservice.clothProduct(this.prev).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
    console.log(this.prev);
  }

  next_funcA() {
    this.dataservice.clothProduct(this.next).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }

  prev_funcA() {
    this.dataservice.clothProduct(this.prev).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
    console.log(this.prev);
  }

  Clothing() {
    this.Clothbtn = true;
    this.paperBtn = false;
    this.Accesorbtn = false;

    this.dataservice.clothProduct(this.SF_id).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.count, "count");
      console.log(this.next, "next");
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }

  Accesories() {
    this.Clothbtn = false;
    this.paperBtn = false;
    this.Accesorbtn = true;
    this.dataservice.accessoriesProduct(this.SF_id).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }
  Paper() {
    this.paperBtn = true;
    this.Clothbtn = false;
    this.Accesorbtn = false;

    this.dataservice.paperProduct(this.SF_id).then(res => {
      this.response_c = res;
      this.next = this.response_c.next;
      this.count = this.response_c.count;
      this.prev = this.response_c.previous;
      console.log(this.response_c);
      this.prod_list = this.response_c.results;
      console.log(this.prod_list);
    });
  }

  all() {
    this.dataservice.getProduct(this.SF_id).then(res => {
      this.prod_res = res;
      console.log(this.prod_res);
      this.prod_list = this.prod_res.storefront_product;
      console.log(this.prod_list, "product list");
      for (var i = 0; i < this.prod_list.length; i++) {
        var imgs = this.prod_list[i].product_images;
        this.prods_img = imgs[0].image;
        console.log(this.prods_img);
      }
    });
  }
}
