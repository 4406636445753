import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.css']
})
export class LearnComponent implements OnInit {
  safeURL:any;
  vUrl:any = 0;
  constructor( private _sanitizer: DomSanitizer){
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videos[0].url);
 }

  

  videos = [
    { url: 'https://www.youtube.com/embed/a3gIv2vmFW0', viewValue: 'Create Customer' },
    { url: 'https://www.youtube.com/embed/TmXHAE6DYgA', viewValue: 'Create Storefront' },
    { url: 'https://www.youtube.com/embed/ZBI_2Aq4S9I', viewValue: 'Create Product' },
    { url: 'https://www.youtube.com/embed/ZiXccxaTZYY', viewValue: 'Create Multiple Storefront' },
    { url: 'https://www.youtube.com/embed/uz_7zPEgwCQ', viewValue: 'One flow' },
    { url: 'https://www.youtube.com/embed/Rb4OcRsKKJ0', viewValue: 'Order Filter' }
  ];

  

  ngOnInit() {
    
  }

  url(index){
    this.vUrl = index;
    console.log(this.vUrl,index)
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videos[index].url);
    console.log(this.safeURL);
  }


}
