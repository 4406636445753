<div class="navbar portal-navbr" *ngIf="prveiw == true">
  <div class="col-sm-60 row60">
    <div class="col-sm-5"></div>
    <div class="col-sm-35 text-center">
      <h1 class="text-left">{{ cust_name }}</h1>
      <h2 class="text-left">{{ prod_name }}</h2>
    </div>
    <div class="col-sm-20 text-center">
      <img src="{{ cust_logo }}" class="img-responsive" width="250" />
    </div>
  </div>
</div>
<!---->

<nav class="navbar selects-products" *ngIf="prveiw == true">
  <div class="col-sm-offset-5 col-sm-35 container-fluid">
    <ul class="nav navbar-nav">
      <li class="active" (click)="all()"><a>All</a></li>
      <li class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown" (click)="Clothing()">Clothing <span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li><a href="">Page 1-1</a></li>
          <li><a href="">Page 1-2</a></li>
          <li><a href="">Page 1-3</a></li>
        </ul>
      </li>
      <li class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown" (click)="Accesories()">Accesories <span
            class="caret"></span></a>
        <ul class="dropdown-menu">
          <li><a href="">Page 1-1</a></li>
          <li><a href="">Page 1-2</a></li>
          <li><a href="">Page 1-3</a></li>
        </ul>
      </li>
      <li class="dropdown">
        <a class="dropdown-toggle" data-toggle="dropdown" (click)="Paper()">Paper<span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li><a href="">Page 1-1</a></li>
          <li><a href="">Page 1-2</a></li>
          <li><a href="">Page 1-3</a></li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="col-sm-8" style="margin-top:10px;">
    <a class="btn btn-default btn-lg cart-block" (click)="incart()">
      MY CART<span class="glyphicon glyphicon-shopping-cart " style="padding-left:5px;"><span class="badge"
          style="background-color: red;padding-bottom:5px;">{{ product_num }}</span></span>
    </a>
  </div>
</nav>

<!--Card-->
<div class="" *ngIf="prveiw == true">
  <div class="cards-storefront container" *ngIf="prod_list_shop == true">
    <div class="row">
      <div class="col-sm-20" *ngFor="let product of prod_list; let i = index">
        <mat-card class="example-card" (click)="prod_details(i)">
          <mat-card-header> </mat-card-header>
          <img mat-card-image src="{{ imgss.image }}" class="banner"
            onError="this.src='assets/images/deaault_store_img.png';"
            *ngFor="let imgss of (product.product_images | slice: 0:1)" alt="Store Front" height="200" width="150" />
          <mat-card-content>
            <p>{{ product.title }}</p>
            <p class="prod-price">
              <mat-icon class="icon-shop">shopping_cart</mat-icon>${{
                product.price
              }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<!--End Card div-->

<!-- product Details-->
<div class="" *ngIf="prveiw == true">
  <mat-icon style="float:right;margin-right:20px;cursor:pointer;" (click)="backProdList()"
    *ngIf="prod_list_shop == false">close</mat-icon>
  <div class="col-sm-60 details-prod" *ngIf="prod_list_shop == false">
    <div class="col-sm-offset-10 col-sm-20" style="margin-right: 50px;">
      <img class="prod-dtails-img" src="{{ selectd_img }}"
        onError="this.src='https://www.tibs.org.tw/images/default.jpg';" height="300" width="400" />
      <p class="title-prod">{{ this.title_prod }}</p>
    </div>
    <div class="col-sm-offset-10 col-sm-15">
      <p class="color-text">Color</p>
      <span class="colors">
        <div class="color-box" style="background-color:blue"></div>
        <div class="color-box" style="background-color:red"></div>
        <div class="color-box" style="background-color:yellow"></div>
        <div class="color-box" style="background-color:grey"></div>
      </span>
      <p class="pls-refer">
        *Please refer to <span class="sizchart">sizing chart</span> for product
        dimension
      </p>
      <hr />
      <table style="margin-bottom: 80px;">
        <tr>
          <td>
            <span>Size:</span><input type="number" value="0" style="width: 95%;" />
          </td>
          <td>
            <span>Quantity:</span><input type="number" min="1" value="1" style="width: 95%;" [(ngModel)]="quantity"
              (change)="quantities($event)" />
          </td>
        </tr>
      </table>

      <hr />
      <span style="padding-right: 60px;font-size: 15px;">Total Price</span>
      <span class="total-price">${{ total_price }}</span>
      <button mat-raised-button class="cart" *ngIf="prod_list_shop == false" (click)="addcart()">
        Add to Cart
      </button>
    </div>
  </div>
  <div class="row" class="images-list1" *ngIf="prod_list_shop == false">
    <div class="col-sm-3"></div>
    <div class="col-sm-8" *ngFor="let images of product_img; let i = index">
      <img class="img-select" (click)="showImg(i)" src="{{ images.image }}" height="120" width="140" />
    </div>
  </div>
</div>
<!---->

<!--Cart List products-->

<div class="cartview" *ngIf="view_cart == true">
  <div class="container">
    <h1 class="text-center" style="padding-bottom:20px;">My Cart</h1>
    <table id="cart" class="table table-hover table-condensed">
      <thead>
        <tr>
          <th style="width:50%">Product</th>
          <th style="width:10%">Price</th>
          <th style="width:8%">Quantity</th>
          <th style="width:22%" class="text-center">Subtotal</th>
          <th style="width:10%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let products of mycart; let i = index">
          <td data-th="Product">
            <div class="row">
              <div class="col-sm-20 hidden-xs">
                <img src="{{ products.image }}" alt="..." class="img-responsive" />
              </div>
              <div class="col-sm-40">
                <h4 class="nomargin">{{ products.title }}</h4>
                <p>{{ products.prod_decription }}</p>
              </div>
            </div>
          </td>
          <td data-th="Price">${{ products.price }}</td>
          <td data-th="Quantity">
            <input type="number" class="form-control text-center" value="1" disabled />
          </td>
          <td data-th="Subtotal" class="text-center">
            ${{ products.total_price }}
          </td>
          <td class="actions" data-th="">
            <button class="btn btn-info btn-sm">
              <i class="fa fa-refresh"></i>
            </button>
            <button class="btn btn-danger btn-sm">
              <i class="fa fa-trash-o"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="visible-xs">
          <td class="text-center"><strong>Total 1.99</strong></td>
        </tr>
        <tr>
          <td>
            <a class="btn btn-warning" style="border-radius:0px;" (click)="continueShop()"><i
                class="fa fa-angle-left"></i> Continue Shopping</a>
          </td>
          <td colspan="2" class="hidden-xs"></td>
          <td class="hidden-xs text-center">
            <strong>$ {{ this.Cart_total }}</strong>
          </td>
          <!--<td><a class="btn btn-success btn-block" (click)="checkout()">Checkout <i class="fa fa-angle-right"></i></a></td>-->
          <td>
            <button class="btn-block" (click)="checkout()" mat-raised-button color="primary">
              Checkout<mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<!---->

<div class="checkout-form" *ngIf="checkouts == true">
  <div class="container-fluid form-fluid">
    <mat-card style="margin-top: 40px;">
      <mat-toolbar>Customers Details</mat-toolbar>
      <form class="example-form">
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td class="td-1">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="First name" required />
              </mat-form-field>
            </td>
            <td class="td-2">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Last Name" required />
              </mat-form-field>
            </td>
          </tr>
        </table>

        <div class="row">
          <div class="col-sm-30">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Email" type="email" value="abc@gmail.com" required />
            </mat-form-field>
          </div>
        </div>
        <!---->
        <div class="row">
          <div class="col-sm-30">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="telephone" type="number" value="965487258" required />
            </mat-form-field>
          </div>
        </div>
        <p>
          <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Address" required>
1600 Amphitheatre Pkwy</textarea>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <textarea matInput placeholder="(Options)"></textarea>
          </mat-form-field>
        </p>

        <table class="example-full-width" cellspacing="0">
          <tr>
            <td class="td-1">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="City" required />
              </mat-form-field>
            </td>
            <!--<td>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="State">
                            </mat-form-field>
                        </td>-->
            <td class="td-2">
              <mat-form-field class="example-full-width">
                <input matInput #postalCode maxlength="5" placeholder="Postal Code" value="94043" required />
                <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
              </mat-form-field>
            </td>
          </tr>
        </table>

        <!---->
        <table class="example-full-width" cellspacing="0">
          <tr>
            <td class="td-1">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="State" required />
              </mat-form-field>
            </td>
            <td class="td-2">
              <mat-form-field>
                <mat-select placeholder="Country" required>
                  <mat-option value="option1">United States</mat-option>
                  <mat-option value="option2">India</mat-option>
                  <mat-option value="option2">Canada</mat-option>
                  <mat-option value="option2">United Kingdom</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </form>
    </mat-card>
  </div>
</div>
<button class="text-right nxtbtn1" (click)="backViewCart()" mat-raised-button *ngIf="checkouts == true">
  Back To Checkout Method
</button>
<button class="text-right nxtbtn" (click)="NextStep()" mat-raised-button *ngIf="checkouts == true">
  Next Step
</button>

<!---->

<div class="payment" *ngIf="paymentGate == true">
  <div class="">
    <mat-list>
      <mat-toolbar>Payment Method</mat-toolbar>
      <mat-radio-group [(ngModel)]="Cardvalue">
        <mat-list-item class="matlists">
          <mat-radio-button class="example-margin" value="mastercard">
            <img class="cardImg" src="http://www.freepngimg.com/download/mastercard/8-2-mastercard-png-picture.png"
              height="100" width="150" />
            <span> MaterCard</span></mat-radio-button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="matlists">
          <mat-radio-button class="example-margin" value="visa">
            <img class="cardImg" src="http://nbidu.com/wp-content/uploads/2017/10/Visa_Payment_icon.png" height="110"
              width="150" />
            <span> Visa</span>
          </mat-radio-button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="matlists">
          <mat-radio-button class="example-margin" value="america">
            <img class="cardImg" src="https://skismart.co.uk/site/wp-content/themes/SkiSmart/images/amex_512.png"
              height="110" width="150" />
            <span> American Express</span>
          </mat-radio-button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class="matlists">
          <mat-radio-button class="example-margin" value="discover">
            <img class="cardImg" src="http://handystopnursery.com/img/cc_discover_128.png" height="110" width="150" />
            <span>Discover Card</span>
          </mat-radio-button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-radio-group>
    </mat-list>
  </div>
  <br /><br />
  <!--Form Card -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-offset-5 col-sm-20">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Card Holder Name" [(ngModel)]="holdername" />
        </mat-form-field>
        <!---->
        <mat-form-field class="example-full-width" style="margin-bottom:10px;">
          <input type="number" matInput #input minlength="16" placeholder="Enter 16 Digit Number" id="numbers"
            [(ngModel)]="cardnumbers" />
          <mat-hint align="end">{{
            input.value?.length || 0
          }}</mat-hint>
        </mat-form-field><br />
        <table class="example-full-width" cellspacing="0">
          <tr>
            <span class="expry">Expiry Date:</span>
            <td style="width:100px;">
              <mat-select placeholder="Month" width="100%">
                <mat-option *ngFor="let mn of month" [value]="mn">
                  {{ mn }}
                </mat-option>
              </mat-select>
            </td>
            <td style="width:100px;">
              <mat-select placeholder="Years" width="100%">
                <mat-option *ngFor="let yr of years" [value]="yr">
                  {{ yr }}
                </mat-option>
              </mat-select>
            </td>
          </tr>
        </table>

        <br /><br />
        <div class="">
          <p class="paid-amt">Total Amount : ${{ this.Cart_total }}</p>
        </div>
        <button class="text-right paynow" (click)="payNow()" mat-raised-button>
          Pay Now
        </button>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col-sm-offset-30 col-sm-30 bckToProducts">
      <button class="text-right" (click)="backTo()" mat-raised-button>
        Back To Products
      </button>
    </div>
  </div>
</div>