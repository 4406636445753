<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="z-index: 50;
position: fixed;
top: 51px;"></mat-progress-bar>
<div class="main">
  <app-breadcrumb></app-breadcrumb>

  <!-- <div class="">
    <mat-card class="tagss">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let item of selectedstorefronts; let i = index" (removed)="remove(i)">
          {{ item.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <mat-chip color="primary" selected (click)="openAddDialog()">
          Add storefronts
          <mat-icon>add</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </div> -->

  <div class="productlist" *ngIf="showProgress">
    <mat-card class="cards1">
      <mat-toolbar>
        {{progressStatus}}
      </mat-toolbar>



      <table>
        <tr>
          <th>Product name</th>
          <th style="text-align: center">Status</th>
        </tr>
        <tr *ngFor="let item of statusArr; let i=index">
          <td style="word-break: break-word;">{{item.title}}</td>
          <td style="text-align: center;">{{item.status}}</td>
        </tr>

      </table>

    </mat-card>

  </div>
  <div *ngIf="!showProgress" class="productlist">
    <mat-card class="cards1">
      <div style="padding: 25px 0">


        <!-- <mat-toolbar>Add detail for product {{ index + 1 }}
            <span class="example-fill-remaining-space"></span>
            <button *ngIf="!first" (click)="removeProduct(index)" mat-button>
              <mat-icon>delete</mat-icon>
            </button>
          </mat-toolbar> -->

        <div class="">
          <mat-card>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let item of selectedstorefronts; let i = index" (removed)="remove(i)">
                {{ item.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>

              <mat-chip color="primary" selected (click)="openAddDialog()">
                Add storefronts
                <mat-icon>add</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-card>
        </div>
        <div style="margin-top: 40px">
          <!-- Product Name -->

          <div class="from-group">
            <mat-form-field class="widths">
              <input matInput placeholder="Product Name" [(ngModel)]="productcollection.title"
                [formControl]="productName" required />
              <!-- name="productName"
                  #productName="ngModel" -->
            </mat-form-field>
            <!-- <div
                class="alert alert-danger"
                *ngIf="productName.touched && !productName.valid"
              >
                <small *ngIf="productName.errors.required">
                  Product Name is required
                </small>
              </div> -->
          </div>

          <!-- Product Code  -->

          <div class="from-group">
            <mat-form-field class="widths">
              <input matInput placeholder="Product Code" [(ngModel)]="productcollection.code"
                [formControl]="productCode" required />
              <!-- name="productCode"
                  #productCode="ngModel" -->
            </mat-form-field>
            <!-- <div
                class="alert alert-danger"
                *ngIf="productCode.touched && !productCode.valid"
              >
                <small *ngIf="productCode.errors.required">
                  Product Code is required
                </small>
              </div> -->
          </div>
          <!-- Product Description -->

          <div class="from-group">
            <mat-form-field class="example-full-width widths">
              <textarea matInput placeholder="Product Description"
                [(ngModel)]="productcollection.description"></textarea>
            </mat-form-field>
          </div>

          <div class="from-group">
            <mat-form-field style="width: auto;" class="example-full-width widths">
              <!-- <textarea matInput placeholder="Product Background Color" [(ngModel)]="productcollection.description"></textarea> -->
              <input matInput class="example-margin" type="color" placeholder="Product Background Color"
                [(ngModel)]="productcollection.product_background_color" />

            </mat-form-field>
          </div>
          <div class="from-group">
            <mat-form-field style="width: auto;" class="example-full-width widths">
              <!-- <textarea matInput placeholder="Product Background Color" [(ngModel)]="productcollection.description"></textarea> -->
              <input matInput class="example-margin" type="color" placeholder="Product Text Color"
                [(ngModel)]="productcollection.product_text_color" />

            </mat-form-field>
          </div>


          <!-- Product Image -->



         

          <!--Product categories  -->

          <div class="from-group">
            <mat-form-field class="example-full-width widths">
              <mat-select placeholder="Product Type" [(ngModel)]="productcollection.categories" (selectionChange)="onSelectionChangeCat($event)" [(value)]="selected"
                required>
                <mat-option [value]="1">Clothing</mat-option>
                <mat-option [value]="2">Paper</mat-option>
                <mat-option [value]="3">Accessories</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-form-field class="widths" appearance="fill">
            <mat-label>Product Tag Options</mat-label>
            <mat-select [formControl]="specialTags" multiple>
              <mat-option *ngFor="let tag of specialTagList" [value]="tag">{{tag.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="widths">
            <!-- <mat-form-field class="color-chip-list" appearance="fill" >
                <mat-label>Product Type Options</mat-label>
                <mat-chip-list #chipListType aria-label="Type selection">
                  <mat-chip *ngFor="let type of productcollection.typeArray" [selectable]="selectable" [removable]="removable"
                    (removed)="removeC(type,index,'type')">
                    {{type}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="New Type..." [matChipInputFor]="chipListType"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event,index,'type')">
                </mat-chip-list>
              </mat-form-field> -->


          </div>

          

          <table *ngIf="tags.value != null"  style="width: 50% !important;"  mat-table matSort
          [dataSource]="productcollection.size_chart" class="mat-elevation-z8">
          <ng-container matColumnDef="Style">
            <th mat-header-cell *matHeaderCellDef> Style </th>
            <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.style.name |
              titlecase}}
            </td>
          </ng-container>
          <ng-container matColumnDef="Chart">
            <th mat-header-cell *matHeaderCellDef> Chart </th>
            <td style="display: flex;align-items: center;height: 100px;" mat-cell
              *matCellDef="let element,let i = index">


              

              <button (click)="sizechartinput.click()" mat-mini-fab color="disabled"
                aria-label="Example icon button with a menu icon">
                <input type="file" #sizechartinput (change)="uploadSizeChart($event,i)" style="display: none;">

                <mat-icon>upload</mat-icon>

              </button>

              <div *ngIf="element.charturl"  style="position: relative;margin: 0 10px" >
                
                <img *ngIf="isImage" [src]="element.charturl.url"  width="50" height="50" class="img-p">
                
                <a *ngIf="isPdf" [href]="element.charturl.url" target="_blank" rel="noopener noreferrer">{{
                  element.charturl.name }}</a>
                  <mat-icon class="icons"
                    style="position: absolute;top: 0px;right: -20px; color: rgb(64, 64, 64);cursor: pointer;"
                     class="" (click)="removeChart(i)">

                    clear
                  </mat-icon>
              </div>



              <!-- <div class="row " style="overflow-x: auto;margin: 0 10px;max-width: 400px;">
              <div style="margin:5px;" class="" *ngFor="let item of element.imageArr; let imgin = index">
                <div style="position: relative;">
                  <img [src]="item.url" width="50" height="50" class="img-p" />
                  <mat-icon class="icons"
                    style="position: absolute;top: 0px;right: 0px; color: white;cursor: pointer;"
                    (click)="removeImage2(imgin,i)" class="">

                    clear
                  </mat-icon>
                </div>

              </div>
            </div> -->
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsSizeChart"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSizeChart;"></tr>
        </table>

        <div *ngIf="tags.value != null">
          <button (click)="generateSizeChartTable()" mat-raised-button color="primary" class="btn-save">Generate size
            chart list <i class="fa fa-cloud-upload"></i></button>
        </div>



          <!-- <div class="from-group widths" style="display: flex;column-gap: 10px;">
              <mat-form-field class="widths">
                <input matInput placeholder="Color Options" value="" [(ngModel)]="inventory.c"
                  [formControl]="productColor_new" required />
              </mat-form-field>
              <mat-form-field class="widths">
                <input matInput placeholder="Size Options" value="" [(ngModel)]="inventory.s"
                  [formControl]="productSize_new" required />
               
              </mat-form-field>
              <mat-form-field class="widths">
                <input matInput type="number" placeholder="Quantity" value="" [(ngModel)]="inventory.q"
                  [formControl]="productQuantity_new" required />
               
              </mat-form-field>
              <button (click)="csq()"
                [disabled]="!productColor_new.valid || !productSize_new.valid || !productQuantity_new.valid"
                mat-mini-fab color="primary">
                <mat-icon>add</mat-icon>
              </button>


            </div>
            <div class="from-group widths" *ngFor="let inventory2 of inventoryArray; let i = index"
              style="display: flex;column-gap: 10px;">
              <mat-form-field class="widths">
                <input matInput placeholder="Color Options" value="" [(ngModel)]="inventory2.c"  />
              </mat-form-field>
              <mat-form-field class="widths">
                <input matInput placeholder="Size Options" value="" [(ngModel)]="inventory2.s"  />
                
              </mat-form-field>
              <mat-form-field class="widths">
                <input matInput type="number" placeholder="Quantity" value="" [(ngModel)]="inventory2.q"
                    />
               
              </mat-form-field>
              <button (click)="deleteinv(i)" mat-mini-fab color="warn"
                aria-label="Example icon button with a menu icon">
                <mat-icon>delete</mat-icon>
              </button>


            </div> -->

          <div class="widths gLDiv" style="display: flex;flex-direction: column;">
             <!-- Product price -->

          <div class="from-group">
            <mat-form-field class="widths">
              <input type="number" matInput placeholder="Price" value="0" [(ngModel)]="productcollection.price"
                [formControl]="productPrice" required />
              <!-- name="productPrice"
                  #productPrice="ngModel" -->
              <span matPrefix style="padding-right: 10px">$</span>
            </mat-form-field>
            <!-- <div
                class="alert alert-danger"
                *ngIf="productPrice.touched && !productPrice.valid"
              >
                <small *ngIf="productPrice.errors.required">
                  Product Size is requiredF
                </small>
              </div> -->
          </div>
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Product Style Options</mat-label>
              <mat-select [formControl]="tags" multiple (selectionChange)="onSelectionChange()"> 
                <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- color chips  -->
            <mat-form-field class="color-chip-list" appearance="fill">
              <mat-label>Color Options</mat-label>
              <mat-chip-list #chipListColor aria-label="Fruit selection">
                <mat-chip *ngFor="let color  of productcollection.colorArray" [selectable]="selectable"
                  [removable]="removable" (removed)="removeC(color,'color')">
                  {{color | titlecase}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="New color..." [matChipInputFor]="chipListColor"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event,'color')">
              </mat-chip-list>
            </mat-form-field>

            <div class="sizesDiv">
              <!-- size chips  -->
              <section class="" *ngIf="productcollection.categories == 1">
                <mat-label>Size Options</mat-label>

                <div class="sizescheckbox">
                  <p *ngFor="let item of Sizes">
                    <mat-checkbox (change)="toggleSize(item)"
                      [checked]="selectedSizes.includes(item)">{{item}}</mat-checkbox>
                  </p>
                </div>

              </section>
              <mat-form-field class="color-chip-list" appearance="fill" *ngIf="productcollection.categories != 1">
                <mat-label>Size Options</mat-label>
                <mat-chip-list #chipListSize aria-label="Fruit selection">
                  <mat-chip *ngFor="let size of productcollection.sizeArray" [selectable]="selectable"
                    [removable]="removable" (removed)="removeC(size,'size')">
                    {{size}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="New size..." [matChipInputFor]="chipListSize"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event,'size')">
                </mat-chip-list>
              </mat-form-field>
              <!-- <div (click)="paste()" style="cursor: pointer;display: flex;flex-direction: column;right: -88px;
              top: 12px;position: absolute;align-items: center;">
                <span class="material-icons">content_paste</span>
                <p>paste sizes</p>
              </div> -->

            </div>

            <button *ngIf="productcollection.colorArray.length > 0 && productcollection.sizeArray.length > 0 && tags.value != null && productcollection.price > 0"
              mat-raised-button color="primary" class="btn-save" (click)="generateList()">
              Generate Inventory List On Product Template
            </button>
          </div>



          <div class="widths gLDiv hidden">
            <!-- Product Color-->
            <!-- og -->
            <div class="from-group">
              <mat-form-field class="widths">
                <input matInput placeholder="Color Options" value="" [(ngModel)]="productcollection.color"
                  [formControl]="productColor" required />

              </mat-form-field>
            </div>
            <!--Product size -->
            <!-- og -->
            <div class="from-group">
              <mat-form-field class="widths">
                <input matInput placeholder="Size Options" value="" [(ngModel)]="productcollection.size"
                  (ngModelChange)="productcollection.size = $event.toUpperCase()" [formControl]="productSize"
                  required />
              </mat-form-field>

            </div>


          </div>



          <div class="from-group">
            <mat-checkbox [(ngModel)]="productcollection.is_featured"><b>Featured</b></mat-checkbox>
          </div>


          <!-- <p *ngIf="showCombinationList" style="padding: 5px 0;">Maximum allowed file size is 2mb.</p> -->


         


          <table style="width: 50% !important;" *ngIf="showCombinationList" mat-table matSort
            [dataSource]="productcollection.productinventory_set" class="mat-elevation-z8">


            <ng-container matColumnDef="Tag" >
              <th  mat-header-cell *matHeaderCellDef> Style </th>
              <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.tag | titlecase}}
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="Color">
              <th mat-header-cell *matHeaderCellDef> Color </th>
              <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.color | titlecase}}
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="Size">
              <th mat-header-cell *matHeaderCellDef> Size </th>
              <td style="width: 7%;" mat-cell *matCellDef="let element"> {{element.size.toUpperCase()}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Quantity">

              <th mat-header-cell *matHeaderCellDef> Quantity
                <button class="stepperButton" (click)="increaseQ()" style="margin: 0 10px;">+</button>
                <button class="stepperButton" (click)="decreaseQ()">-</button>
              </th>

              <td style="width: 22%;" mat-cell *matCellDef="let element">
                <div style="margin: 10px;">
                  <input type="number" min="0" step="10" class="age-input" [(ngModel)]="element.quantity">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Price">

              <th mat-header-cell *matHeaderCellDef> Price</th>

              <td style="width: 22%;" mat-cell *matCellDef="let element">
                <div style="margin: 10px;">
                  <input type="number" min="0" step="10" class="age-input" [(ngModel)]="element.price">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="Images">
              <th mat-header-cell *matHeaderCellDef> Images </th>
              <td style="display: flex;align-items: center;height: 100px;" mat-cell
                *matCellDef="let element,let i = index">

                <button (click)="fileE.click()" mat-mini-fab color="disabled"
                  aria-label="Example icon button with a menu icon">
                  <input (change)="detectFiles2($event,i)" #fileE (click)="$event.target.value=null" multiple
                    type="file" accept="image/*" />
                  <mat-icon>upload</mat-icon>

                </button>



                <div class="row " style="overflow-x: auto;margin: 0 10px;max-width: 400px;">
                  <div style="margin:5px;" class="" *ngFor="let item of element.imageArr; let imgin = index">
                    <div style="position: relative;">
                      <img [src]="item.url" width="50" height="50" class="img-p" />
                      <mat-icon class="icons"
                        style="position: absolute;top: 0px;right: 0px; color: white;cursor: pointer;"
                        (click)="removeImage2(imgin,i)" class="">

                        clear
                      </mat-icon>
                    </div>

                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="columndelete">
              <th style="width:15%;" mat-header-cell *matHeaderCellDef>Remove</th>
              <td mat-cell *matCellDef="let element;let i = index">
                <button (click)="removeCombo(i)" mat-mini-fab color="warn"
                  aria-label="Example icon button with a menu icon">
                  <mat-icon>delete</mat-icon>
                </button>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>







        <div class="button-row row-btns text-center button-row-new">
          <!-- <button mat-raised-button color="primary" [disabled]="selectedstorefronts.length === 0" (click)="goAhead()"
                  class="btn-save">{{btnText}}</button> -->
          <button mat-raised-button color="primary" [disabled]="selectedstorefronts.length === 0"
            (click)="addProducts()" class="btn-save hidden">
            Add More
          </button>

          <button mat-raised-button color="primary" class="btn-save" (click)="onSave()">
            Add Product
          </button>
        </div>
      </div>
    </mat-card>

  </div>

</div>
<!-- [disabled]="selectedstorefronts.length === 0" -->