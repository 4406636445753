<div class="main">
  <app-breadcrumb></app-breadcrumb>

  <mat-card class="cards">
    <mat-toolbar class="subtitle font-fmly">
      <span>Create Multiple Storefront - {{ customername }}</span>
    </mat-toolbar>
    <mat-form-field class="widths">
      <input
        matInput
        placeholder="Storefront Group Name"
        [formControl]="chain_name"
        
      />
      <mat-error *ngIf="chain_name.errors?.required"
        >Storefront group name is required
      </mat-error> </mat-form-field
    ><br />
    <mat-checkbox
      class="example-margin"
      [(ngModel)]="isPasscode"
      (click)="returnRandomString(8)"
      >Password Protected</mat-checkbox
    ><br />
    <mat-form-field class="widths" *ngIf="isPasscode">
      <input matInput placeholder="Passcode" class=""  [formControl]="passcode"/>
      <mat-error *ngIf="passcode.errors?.required"
        >Passcode is required
      </mat-error>
    </mat-form-field>

    <!-- <button class="loc" mat-raised-button (click)="add_location()">Add Location</button> -->
    <br />
    <mat-radio-group [(ngModel)]="template_value">
      <mat-radio-button class="common" value="1"
        >Common template</mat-radio-button
      >
      <mat-radio-button value="2"
        >Custom template per storefront</mat-radio-button
      >
      <!-- <mat-radio-button value="3">Upload using CSV?</mat-radio-button> --> </mat-radio-group
    ><br />
    <mat-form-field class="widths" *ngIf="template_value">
      <input
        matInput
        type="number"
        placeholder="No. of Storefront"
        [formControl]="storefront_count"
      />
      <mat-error *ngIf="storefront_count.errors?.required"
        >Number of stores is required
      </mat-error>
      <mat-error *ngIf="storefront_count.errors?.max"
      >Number of stores cannot exceed 10
    </mat-error>
    <mat-error *ngIf="storefront_count.errors?.min"
      >Number of stores cannot be less than 2
    </mat-error>
    </mat-form-field>
    <!-- Option 1 -->
    <div class="option1" *ngIf="template_value == 1">
      <br />
      <div class="" *ngFor="let obj of colletionObj; let i = index">
        <mat-form-field class="widths">
          <input
            matInput
            placeholder="Address"
            [(ngModel)]="obj.location"
            required
          />
        </mat-form-field>
      </div>
      <mat-form-field class="widths">
        <input matInput placeholder="Description" [(ngModel)]="description" />
      </mat-form-field>
      <table>
        <tr class="color-tab">
          <td>Storefront Banner Color:</td>
          <td>
            <input
              type="color"
              class="input-color"
              placeholder="Storefront Banner Color"
              [(ngModel)]="SFcolor"
            />
          </td>
        </tr>
        <tr class="color-tab">
          <td>Storefront Title Color :</td>
          <td>
            <input
              type="color"
              class="input-color"
              placeholder="Storefront Title Color"
              [(ngModel)]="SFtitlecolor"
            />
          </td>
        </tr>
      </table>

      <section class="example-section">
        <mat-checkbox class="example-margin" [(ngModel)]="onlinePay"
          >Online</mat-checkbox
        >
        <mat-checkbox class="example-margin" [(ngModel)]="net30Pay"
          >Net30</mat-checkbox
        >
      </section>
    </div>
    <!-- Option 2 -->
    <div class="option2" *ngIf="template_value == 2">
      <div class="" *ngFor="let obj of colletionObj; let i = index">
        <mat-form-field class="widths">
          <input matInput placeholder="Address*" [(ngModel)]="obj.location" />
        </mat-form-field>
        <mat-form-field class="widths">
          <input
            matInput
            placeholder="Description"
            [(ngModel)]="obj.description"
          />
        </mat-form-field>
        <table>
          <tr class="color-tab">
            <td>Storefront Banner Color:</td>
            <td>
              <input
                type="color"
                class="input-color"
                placeholder="Storefront Banner Color"
                [(ngModel)]="obj.banner_color_code"
              />
            </td>
          </tr>
          <tr class="color-tab">
            <td>Storefront Title Color :</td>
            <td>
              <input
                type="color"
                class="input-color"
                placeholder="Storefront Title Color"
                [(ngModel)]="obj.title_color"
              />
            </td>
          </tr>
        </table>

        <br />
        <section class="example-section">
          <mat-checkbox class="example-margin" [(ngModel)]="obj.online_payment"
            >Online</mat-checkbox
          >
          <mat-checkbox class="example-margin" [(ngModel)]="obj.net30"
            >Net30</mat-checkbox
          >
        </section>
        <br />
        <mat-divider></mat-divider><br />
      </div>
    </div>
    <!-- Option 3 -->
    <div class="option2" *ngIf="template_value == 3">
      <div class="container">
        <div class="form-group">
          <input
            type="file"
            (change)="onFileSelect($event.target)"
            name="myfile"
          />
        </div>
      </div>
    </div>
    <!--  -->

    <div class="button-row row-btns text-center">
      <button
        mat-raised-button
        color="primary"
        class="chains"
        (click)="create_chain()"
        
      >
        Create Group
      </button>
      <button mat-raised-button color="primary" (click)="cancel()">
        Cancel
      </button>
    </div>
  </mat-card>
</div>
