<div
  class="mains"
  *ngIf="navbar_set == 0"
  style="position: fixed;z-index: 4;width: 100%;"
>
  <nav
    class="navbar navbar-inverse navbar-bg"
    (clickOutside)="onClickOutside($event)"
  >
    <div class="container-fluid cont">
      <div
        class="navbar-header"
        style=" display: flex;align-items: center;
      "
      >
        <a class="navbar-brand menus">
          <mat-icon class="icons" (click)="showMenu()">menu</mat-icon>
        </a>
        <h1 class="logo-txt">Storefront Management System</h1>
      </div>
      <img class="imgss" src="assets/images/fcon.png" height="40" />
    </div>
  </nav>
</div>

<div
  id="mySidepanel"
  class="sidepanel"
  [style.width.px]="width"
  [ngStyle]="{ display: displaytype }"
>
  <!-- <a class="closebtn" (click)="showMenu()">×</a> -->
  <!-- <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/dashboard"
    [routerLinkActive]="['changBg']"
    matTooltip="Dashboard"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">dashboard</mat-icon
    ><span class="names">Dashboard</span>
  </a> -->
  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/customers"
    matTooltip="Customers"
    [matTooltipPosition]="'right'"
    [routerLinkActive]="['changBg']"
  >
    <mat-icon class="icons">store</mat-icon
    ><span class="names">Customers / Stores</span>
  </a>
  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/productcatalogue"
    [routerLinkActive]="['changBg']"
    matTooltip="Products"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">inventory_2</mat-icon
    ><span class="names">Products</span>
  </a>
  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/bundles"
    [routerLinkActive]="['changBg']"
    matTooltip="Bundles"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">shopping_basket</mat-icon
    ><span class="names">Bundles</span>
  </a>
  <a
  class="routing"
  [style.padding-left.px]="padding"
  routerLink="/utility"
  [routerLinkActive]="['changBg']"
  matTooltip="Utility"
  [matTooltipPosition]="'right'"
>
  <mat-icon class="icons">build</mat-icon
  ><span class="names">Utility</span>
</a>
  <a
  class="routing"
  [style.padding-left.px]="padding"
  routerLink="/draft"
  [routerLinkActive]="['changBg']"
  matTooltip="Draft"
  [matTooltipPosition]="'right'"
>
  <mat-icon class="icons">drafts</mat-icon
  ><span class="names">Draft</span>
</a>

  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/orders"
    [routerLinkActive]="['changBg']"
    matTooltip="Orders"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">list</mat-icon><span class="names">Orders</span>
  </a>
  
  <!-- Inventory -->
  <!-- <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/inventory"
    [routerLinkActive]="['changBg']"
    matTooltip="Inventory"
    [matTooltipPosition]="'right'"
  >
  <mat-icon class="icons">assignment</mat-icon>
  <span class="names">Inventory</span>
  </a> -->


  
  <!--  -->
  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/learn"
    [routerLinkActive]="['changBg']"
    matTooltip="Learn"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">library_books</mat-icon
    ><span class="names">Learn</span>
  </a>
  <!--  -->
  <a
    class="routing"
    [style.padding-left.px]="padding"
    routerLink="/usersetting"
    [routerLinkActive]="['changBg']"
    matTooltip="User Setting"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">account_circle</mat-icon>
    <span class="names">User Setting</span>
  </a>

  <!--  -->

  <a
    class="routing logout"
    [style.padding-left.px]="padding"
    [routerLinkActive]="['changBg']"
    (click)="openDialog()"
    matTooltip="Logout"
    [matTooltipPosition]="'right'"
  >
    <mat-icon class="icons">logout</mat-icon><span class="names">Logout</span>
  </a>
</div>
<ng-progress [showSpinner]="false"></ng-progress>
<div class="mains" [style.margin-left.px]="left" style="height:100%;">
  <!-- <div class="main"> -->
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <router-outlet></router-outlet>
  <!-- </div> -->
</div>
