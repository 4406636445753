<div class="main">


  <div style="margin: 10px;">
    <h3>Change log</h3>

    <div>
      <h2>Version : 2.1.2</h2>

      <p>Added way to add custom archive message on archived store </p>


    </div>
    <div>
      <h2>Version : 2.1.1</h2>

      <p>Added way to show hide customer name on store front </p>


    </div>
    <div>
      <h2>Version : 2.1.0</h2>

      <p>Added Auto Drop Down Upload </p>
      <p>Added #po support to excel </p>


    </div>
    <div>
      <h2>Version : 2.0.9</h2>

      <p>Added Delete Coupon</p>
      <p>Bug fixed for edit coupon</p>


    </div>
    <div>
      <h2>Version : 2.0.8</h2>

      <p>Added Enable Freeshiping</p>
      <p>Removed unused components</p>


    </div>




    <div>
      <h2>Version : 2.0.7</h2>

      <p>Added way to have secondary email</p>
      <p>Now you can edit coupons</p>
      <p>Deleting customer will change it name and you can reuse the name</p>





    </div>


    <div>
      <h2>Version : 2.0.6</h2>

      <p>Bug Fixes </p>
      <p>Changed swapped billing address and shipping address issse and export to exel data too </p>




    </div>
    <div>
      <h2>Version : 2.0.5</h2>

      <p>Api changes, faster load time! </p>
      <p>Added usps! </p>




    </div>

    <div>
      <h2>Version : 2.0.4</h2>

      <p>Pdf bug! </p>



    </div>

    <div>
      <h2>Version : 2.0.3</h2>

      <p>Added international shipping support </p>



    </div>

    <div>
      <h2>Version : 2.0.2</h2>

      <p>Bug fixes at customer(StoreOwner) creation </p>



    </div>

    <div>
      <h2>Version : 2.0.1</h2>

      <p>Added way to select preview image to products </p>



    </div>

    <div>
      <h2>Version : 2.0.0</h2>

      <p>Added coupons, and minor bug fix and changes in preview </p>
      <p>Moved Admin Dashboard to new site i.e https://admin.primetime.company/login/admin </p>


    </div>

    <div>
      <h2>Version : 1.0.10</h2>

      <p>Added description to storefront, and preview </p>

    </div>
    <div>
      <h2>Version : 1.0.9</h2>

      <p>
        bug fixes, on edit store/edit product.
      </p>

      <p>
        Added better navigation to browse products of a store.
      </p>

    </div>

    <div>
      <h2>Version : 1.0.8</h2>

      <p>
        Changed model title and message.
      </p>

    </div>
    <div>
      <h2>Version : 1.0.7</h2>

      <p>
        Fixed typo on add to cart, change pop up model.
      </p>

    </div>

    <div>
      <h2>Version : 1.0.6</h2>

      <p>
        Added way to sell 0$ products, payment getway wont show up on 0$ total cart value, and in orders view you will
        see NA for 0 dollar charge<br>
        Remove all console log<br>
      </p>

    </div>
    <div>
      <h2>Version : 1.0.5</h2>

      <p>
        Added company name in customer details,<br>
        Added shipping details in store,<br>
        ordres will reflect newly added fields in detail view and export csv file.
      </p>

    </div>

    <div>
      <h2>Version : 1.0.4</h2>
      <p>
        Added stripe id and stripe genrated pdf, to orders view.
      </p>
    </div>

    <div>
      <h2>Version : 1.0.3</h2>
      <p>
        Added support for amex cards.
      </p>
    </div>

    <div>
      <h2>Version : 1.0.2</h2>
      <p>
        Changed, Shipping Cost to service fee.
      </p>
    </div>
    <div>
      <h2>Version : 1.0.1</h2>
      <p>
        Added, Shipping Cost.
      </p>
      <p>
        You can add shipping cost as standard, just like sales taxt.
      </p>
    </div>
    <div>
      <h2>Version : 1.0.0</h2>
      <p>
        Added, copy storeurl.
      </p>
      <p>
        Can search orders by order_id.
      </p>
    </div>
  </div>
</div>
