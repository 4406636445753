<ol class="breadcrumb">
  <li *ngFor="let url of logUrlPath; last as Last" class="breadcrumb-item">
    <ng-container *ngIf="!Last">
      <a [routerLink]="[url.url]" routerLinkActive="active">{{ url.label }} </a>
    </ng-container>
    <ng-container *ngIf="Last">
      <span>{{ url.label }} </span>
    </ng-container>
  </li>
</ol>
