import { AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { m_product_obj, userstorefront_obj, ProductTag } from '../models';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { Location } from '@angular/common';
import readXlsxFile from 'read-excel-file';
import { ElementRef } from '@angular/core';
import { CompressImageService } from '../compress.service';
var EditStorefrontComponent = /** @class */ (function () {
    function EditStorefrontComponent(route, dataservice, dialog, _location, router, elementRef, compressImage) {
        this.route = route;
        this.dataservice = dataservice;
        this.dialog = dialog;
        this._location = _location;
        this.router = router;
        this.elementRef = elementRef;
        this.compressImage = compressImage;
        this.tagsList = [];
        this.selectedTags = [];
        this.displayedColumns = [
            'code',
        ];
        this.showForm = false;
        this.checked = false;
        this.auto_fill = false;
        //  show_customer_name;
        this.description = '';
        this.passcode = '';
        this.sortByVar = {
            field: 'code',
            filter: ''
        };
        this.selectedIndex = 0;
        this.xlsErrors = [];
        this.xlsData = [];
        this.showRecords = false;
        this.buttonShowRecords = "View Records";
        this.storefront_status = [
            { value: 'active', viewValue: 'Active' },
            { value: 'archive', viewValue: 'Archive' }
        ];
    }
    EditStorefrontComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.router.url);
        this.getTagList();
        var urlArray = this.router.url.split('/');
        var index = urlArray.findIndex(function (element) { return element === 'listproducts'; });
        if (index !== -1) {
            this.selectedIndex = 1;
        }
        // if (this.dataservice.selected_storefront) {
        //   this.store_obj = this.dataservice.selected_storefront;
        //   this.passcode = this.store_obj.passcode;
        //   this.description = this.store_obj.description;
        //   console.log(this.passcode);
        //   if (this.store_obj.passcode) {
        //     this.checked = true;
        //   }
        //   this.showForm = true;
        // }
        // else
        if (true) {
            this.route.params.subscribe(function (params) {
                console.log(params);
                _this.store_id = params['storefront_id'];
            });
            this.dataservice.getSingleStore(this.store_id).subscribe(function (data) {
                console.log(data.json());
                _this.store_obj = data.json();
                _this.selectedTags = _this.store_obj.tags;
                _this.dataservice.selected_storefront = _this.store_obj;
                _this.passcode = _this.store_obj.passcode;
                _this.description = _this.store_obj.description;
                _this.auto_fill = _this.store_obj.auto_fill;
                _this.xlsData = _this.store_obj.auto_fill_data === null ? [] : _this.store_obj.auto_fill_data;
                _this.store_obj.storefront_product.sort(function (a, b) {
                    // Check if 'active' is true
                    if (a.active && !b.active)
                        return -1; // 'a' comes before 'b'
                    if (!a.active && b.active)
                        return 1; // 'b' comes before 'a'
                    // If both have 'active' as true or false, then sort by 'code'
                    if (a.code < b.code)
                        return -1;
                    if (a.code > b.code)
                        return 1;
                    return 0; // 'a' and 'b' are equal
                    return 0;
                });
                _this.showForm = true;
                setTimeout(function () {
                    _this.inputxls = document.getElementById('input');
                    _this.inputxls.addEventListener('change', _this.onFielLoad.bind(_this));
                }, 500);
                console.log(_this.store_obj);
                if (_this.store_obj.passcode) {
                    _this.checked = true;
                }
            }, function (error) {
            });
        }
    };
    EditStorefrontComponent.prototype.ngAfterViewInit = function () {
    };
    EditStorefrontComponent.prototype.compareFn = function (tag1, tag2) {
        return tag1 && tag2 ? tag1.name === tag2.name : tag1 === tag2;
    };
    EditStorefrontComponent.prototype.getTagList = function () {
        var _this = this;
        this.dataservice.getProductTagList().then(function (res) {
            console.log(res);
            _this.tagsList = res;
        });
    };
    EditStorefrontComponent.prototype.ontagschange = function (event) {
        console.log(event.value); // Handle the change event here
        this.selectedTags = event.value;
    };
    EditStorefrontComponent.prototype.openArchiveDialog = function (item, item2, status) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Archive", content: "Are you sure you want archive product " + item.title + ", by doing so the product will not be listed in " + item2.name, type: 'question' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
                if (result === 'yes') {
                    _this.toggleProductStatusData(item, item2, status);
                }
            }
            // this.animal = result;
        });
    };
    EditStorefrontComponent.prototype.cancel = function () {
        this._location.back();
    };
    EditStorefrontComponent.prototype.toggleProductStatusData = function (item, item2, status) {
        var _this = this;
        var obj = { "product": item.id, "storefront": item2.id, "is_active": status };
        this.dataservice.toggleProductInStoreStatus(obj).subscribe(function (data) {
            console.log(data);
            _this.dataservice.productEdited = true;
            _this.toggleStatusInProductsTab(item, item2, status);
            _this.toggleProductStatusStatic(item.id, status);
        }, function (error) {
            console.log(error);
        });
    };
    EditStorefrontComponent.prototype.toggleStatusInProductsTab = function (prod, storefront, status) {
        var index = this.dataservice.inmemoryData.findIndex(function (element) { return element.datatype === 'product'; });
        if (index > -1) {
            console.log(index);
            console.log(this.dataservice.inmemoryData[index].multicollectionarry[0]);
            this.dataservice.inmemoryData[index].multicollectionarry[0].forEach(function (product) {
                if (product.id == prod.id) {
                    product.storefront_product.forEach(function (sf) {
                        if (sf.id == storefront.id) {
                            sf.is_active = status;
                            console.log(sf.is_active);
                        }
                    });
                }
            });
        }
    };
    EditStorefrontComponent.prototype.toggleProductStatusStatic = function (pid, status) {
        for (var index = 0; index < this.store_obj.storefront_product.length; index++) {
            if (this.store_obj.storefront_product[index].id === pid) {
                this.store_obj.storefront_product[index].active = status;
            }
        }
    };
    // Toggle passcode
    EditStorefrontComponent.prototype.togglePascode = function ($event) {
        console.log($event.checked);
        if ($event.checked) {
            this.passcode = this.store_obj.passcode;
        }
        else {
            this.passcode = '';
        }
    };
    EditStorefrontComponent.prototype.generateUrl = function () {
        var _this = this;
        this.store_obj.slug = this.store_obj.slug.toLowerCase();
        var splChars = "*|,\":<>[]{}`\';()@&$#%,' '";
        if (!this.store_obj.slug) {
            alert('url is Empty');
            return;
        }
        if (this.store_obj.slug) {
            this.dataservice.veriftyURl(this.store_obj.slug, this.store_obj.user).then(function (res) {
                console.log(res);
                var statuss = res['status'];
                console.log(statuss);
                if (!statuss) {
                    alert('This url already exists! Please generate a new url');
                    // this.store_obj.slug = '';
                    return;
                }
                else {
                    for (var i = 0; i < _this.store_obj.slug.length; i++) {
                        if (splChars.indexOf(_this.store_obj.slug.charAt(i)) != -1) {
                            alert("Illegal characters detected!");
                            return;
                        }
                    }
                    _this.store_obj.slug = _this.store_obj.slug;
                    console.log(_this.store_obj.slug);
                }
            });
        }
    };
    EditStorefrontComponent.prototype.fileupload = function (event) {
        var _this = this;
        console.log(event.target.files);
        var files = event.target.files;
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            this.dataservice.uploadBannerImages(this.store_obj.id, file).then(function (res) {
                console.log(res);
                _this.store_obj.storefrontbanner_set.push(res);
            }).catch(function (err) {
                console.log(err);
            });
        }
    };
    EditStorefrontComponent.prototype.removeBannerImage = function (id, index) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to remove this banner image?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result == 'yes') {
                _this.dataservice.removeBannerImage(id).then(function (res) {
                    _this.store_obj.storefrontbanner_set.splice(index, 1);
                }).catch(function (err) {
                });
            }
        });
    };
    // Storefront Update
    EditStorefrontComponent.prototype.update = function () {
        var _this = this;
        if (this.xlsErrors.length > 0) {
            return;
        }
        console.log(this.store_obj);
        var obj = {
            'id': this.store_obj.id,
            'name': this.store_obj.name,
            'chain_name': this.store_obj.chain_name,
            'banner_color_code': this.store_obj.banner_color_code,
            'title_color': this.store_obj.title_color,
            'desc_background_color': this.store_obj.desc_background_color,
            'desc_text_color': this.store_obj.desc_text_color,
            'tags': this.selectedTags,
            'status': this.store_obj.status,
            'location': this.store_obj.location,
            'slug': this.store_obj.slug,
            'online_payment': this.store_obj.online_payment,
            'net30': this.store_obj.net30,
            'passcode': this.passcode,
            'description': this.store_obj.description,
            'user': this.store_obj.user,
            'free_shipping_amount': this.store_obj.free_shipping_amount === null ? 0 : this.store_obj.free_shipping_amount,
            'auto_fill': this.auto_fill,
            'auto_fill_data': this.xlsData,
            'show_customer_name': this.store_obj.show_customer_name,
            'archive_message': this.store_obj.archive_message,
            'show_banner_images': this.store_obj.show_banner_images,
        };
        console.log(obj, 'from update');
        this.dataservice.updateStoreFront(obj).then(function (res) {
            // this.dataservice.selected_storefront =  <userstorefront_obj>res;
            _this.dataservice.customer_list.forEach(function (cust) {
                if (cust.id === obj.user) {
                    cust.user_storefront.forEach(function (sf) {
                        if (sf.id === obj.id) {
                            console.log('match');
                            sf.name = obj.name;
                            sf.slug = obj.slug;
                            sf.status = obj.status;
                        }
                    });
                }
            });
            console.log(res);
            // alert('Updated');
            _this.openDialog();
        }, function (error) {
            alert(JSON.stringify(error));
        });
    };
    EditStorefrontComponent.prototype.openDialog = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Success", content: "Storefront <b>" + this.store_obj.name + "</b> is updated.", type: 'confirmation' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
        });
    };
    //Mark Featured
    EditStorefrontComponent.prototype.onChange = function (event, index, item) {
        console.log(index, event, item);
        this.toggleProductFeaturedstaus(event.checked, item);
    };
    EditStorefrontComponent.prototype.toggleProductFeaturedstaus = function (status, item) {
        var obj = { is_featured: status };
        this.dataservice.updateProduct(obj, item.id).subscribe(function (data) {
            console.log(data);
            item.is_featured = status;
        }, function (error) { });
    };
    EditStorefrontComponent.prototype.selectedProductEdit = function (item) {
        console.log(item);
        this.dataservice.selected_product = item;
    };
    EditStorefrontComponent.prototype.addproducts = function () {
        this.dataservice.selected_storefronts = [];
        this.dataservice.selected_storefronts.push(this.store_obj);
    };
    EditStorefrontComponent.prototype.sizeDialogue = function (size) {
        console.log(size);
        var colArr = size.split(",");
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Color Options',
                content: colArr,
                type: 'colorsizeinfo'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
            }
        });
    };
    // initializeXls($event) {
    //   if (this.auto_fill) {
    //     const input = document.getElementById('input');
    //     console.log(input);
    //     input.addEventListener('change', () => {
    //       const  schema = {
    //   'PROPERTY_NAME': {
    //     prop: 'PROPERTY_NAME',
    //     type: String,
    //     required: true
    //   },
    //   'ADDRESS': {
    //     prop: 'ADDRESS',
    //     type: String,
    //     required: true
    //   },
    //   'COUNTRY': {
    //     prop: 'COUNTRY',
    //     type: String,
    //     required: true
    //   },
    //   'STATE': {
    //     prop: 'STATE',
    //     type: String,
    //     required: true
    //   },
    //   'CITY': {
    //     prop: 'CITY',
    //     type: String,
    //     required: true
    //   },
    //   'ZIPCODE': {
    //     prop: 'ZIPCODE',
    //     type: String,
    //     required: true
    //   }
    // }
    //       readXlsxFile(input['files'][0],{schema}).then((rows,errors ) => {
    //         this.xlsData = rows.rows;
    //         this.xlsErrors = rows.errors;
    //         console.log(this.xlsData,this.xlsErrors);
    //         // `rows` is an array of rows
    //         // each row being an array of cells.
    //       })
    //     })
    //   }
    // }
    EditStorefrontComponent.prototype.onFielLoad = function () {
        var _this = this;
        var schema = {
            'PROPERTY_NAME': {
                prop: 'PROPERTY_NAME',
                type: String,
                required: true
            },
            'ADDRESS': {
                prop: 'ADDRESS',
                type: String
            },
            'COUNTRY': {
                prop: 'COUNTRY',
                type: String
            },
            'STATE': {
                prop: 'STATE',
                type: String
            },
            'CITY': {
                prop: 'CITY',
                type: String
            },
            'ZIPCODE': {
                prop: 'ZIPCODE',
                type: String
            },
            'SHIPPING_INSTRUCTIONS': {
                prop: 'SHIPPING_INSTRUCTIONS',
                type: String
            }
        };
        readXlsxFile(this.inputxls['files'][0], { schema: schema }).then(function (rows, errors) {
            _this.xlsData = rows.rows;
            _this.xlsErrors = rows.errors;
            console.log(_this.xlsData, _this.xlsErrors);
            // `rows` is an array of rows
            // each row being an array of cells.
        });
    };
    return EditStorefrontComponent;
}());
export { EditStorefrontComponent };
