import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
var DraftComponent = /** @class */ (function () {
    function DraftComponent(service, router) {
        this.service = service;
        this.router = router;
        this.selectedIndex = 0;
        this.displayedColumns = ['position', 'name', 'phone', 'email'];
        this.displayedColumnsSf = ['position', 'name'];
    }
    DraftComponent.prototype.ngOnInit = function () {
        this.getCustomerDraft();
    };
    DraftComponent.prototype.onTabChange = function ($event) {
        console.log($event);
        switch ($event.index) {
            case 0:
                console.log("cust");
                this.mainData = this.draftData.filter(function (data) { return data.entity_type == "customer"; });
                break;
            case 1:
                console.log("sf");
                this.mainData = this.draftData.filter(function (data) { return data.entity_type == "storefront"; });
                break;
            case 2:
                console.log("products");
                this.mainData = this.draftData.filter(function (data) { return data.entity_type == "product"; });
                break;
        }
    };
    DraftComponent.prototype.customerClicked = function (row) {
        console.log(row);
        this.service.draftCustomer = row;
        this.router.navigate(['/addcustomer']);
    };
    DraftComponent.prototype.sfClicked = function (row) {
        console.log(row);
        this.service.draftStorefront = row;
        this.router.navigate(['/addstorefront/' + row.data.user]);
    };
    DraftComponent.prototype.getCustomerDraft = function () {
        var _this = this;
        this.service.getCustomerDraft().then(function (res) {
            console.log(res);
            _this.draftData = res;
            _this.mainData = _this.draftData.filter(function (data) { return data.entity_type == "customer"; });
        });
    };
    return DraftComponent;
}());
export { DraftComponent };
