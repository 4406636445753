import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "../data.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { userstorefront_obj, m_customers_obj } from "../models";
import { storefront_collection } from "../multi-sf/multi-sf.component";
import {
  Sort,
  MatDialog,
  MatTableDataSource,
  MatSort
} from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";

@Component({
  selector: "app-stores-view",
  templateUrl: "./stores-view.component.html",
  styleUrls: ["./stores-view.component.css"]
})
export class StoresViewComponent implements OnInit {
  numberofstores = 0;
  numberofpage = 0;
  dataSource = new MatTableDataSource([]);

  storecust_collection: groupStoreview[] = [];

  displayedColumns: string[] = [
    "name",
    "customer",
    "store_Group",
    "No_of_Products",
    "orders",
    "status",
    "btn1",
    "btn2",
    "btn3"
  ];

  multiStorefront_collection: userstorefront_obj[][] = [];

  storefront_collection: userstorefront_obj[] = [];
  public selectedVal: string = "grid";
  searchText: any;
  url = "api/v1/storefront/";

  constructor(
    private dataservice: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    console.log("yo");

    // this.dataservice.getCommon('api/v1/storefront/').subscribe(data => {
    //   console.log(data.json());
    //   this.paginatingFunc(data.json().results, data.json().count);
    // },
    //   error => {
    //     console.log(error);
    //   }
    // )
  }

  onData($event) {
    this.storefront_collection = $event;

    const unique = this.storefront_collection
      .map(item => item.user)
      .filter((value, index, self) => self.indexOf(value) === index);

    unique.forEach(element => {
      this.storecust_collection.push(this.returnStores(element));
    });
    console.log("------->", this.storefront_collection);
    this.dataSource = new MatTableDataSource(this.storefront_collection);
  }

  returnStores(user: string) {
    let stores: userstorefront_obj[] = [];
    let name: string = "";

    this.storefront_collection.forEach(element => {
      if (element.user === user) {
        stores.push(element);
      }
    });

    name = stores[0].user_v1;

    let obj: groupStoreview = { cust_name: name, storefronts: stores };

    return obj;
  }

  // Edit storefront
  editStore(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;

    this.router.navigate(["/editstore/" + item.id + ""]);
  }

  showOrders(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;
    this.router.navigateByUrl("/orders/" + item.id);
  }

  sortData(sort: Sort) {
    const data = this.storefront_collection.slice();
    if (!sort.active || sort.direction === "") {
      this.storefront_collection = data;
      return;
    }

    this.storefront_collection = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name":
          return this.compare(a.name, b.name, isAsc);
        case "numberstores":
          return this.compare(
            a.storefront_product.length,
            b.storefront_product.length,
            isAsc
          );
        case "order":
          return this.compare(
            a.storefront_order.length,
            b.storefront_order.length,
            isAsc
          );
        case "status":
          return this.compare(a.status, b.status, isAsc);
        case "owner":
          return this.compare(a.user_v1, b.user_v1, isAsc);

        // case 'user_type': return this.compare(a.user_type, b.user_type, isAsc);

        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }

  sortDataList(sort: String) {
    if (!this.dataSource) return;
    this.dataSource.filter = sort.trim().toLowerCase();
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public onValChange(val: string) {
    this.selectedVal = val;
  }

  // Preview
  previewStore(item: userstorefront_obj) {
    let slug = item.slug;
    console.log(slug);
    if (!slug) {
      alert("No Url Added");
      return;
    }
    console.log(slug, "url");
    window.open(
      slug,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800"
    );
  }

  toggleStoreFrontStatus(
    item: userstorefront_obj,
    status: string,
    index: number
  ) {
    this.openArchiveDialog(item, status, index);
  }

  openArchiveDialog(
    item: userstorefront_obj,
    status: string,
    index: number
  ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Archive",
        content: "Are you sure, you want to archive store " + item.name + "?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      if (result) {
        if (result == "yes") {
          this.toggleStoreStatusData(item, status, index);
        }
      }

      // this.animal = result;
    });
  }

  openAddCustomerDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Select Customer", content: "", type: "selectcustomer" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      // this.selectedstorefronts = result;

      if (result !== "no") {
        const selectedcustomer: m_customers_obj = result;

        this.dataservice.selected_customer = selectedcustomer;

        this.router.navigateByUrl("/addstorefront/" + selectedcustomer.id);
      } else {
        console.log(result);
      }
    });
  }

  toggleStoreStatusData(item: userstorefront_obj, statuss, index) {
    this.dataservice
      .toggleStoreStatus(item.id.toString(), { status: statuss })
      .subscribe(
        data => {
          this.toggleStoreStatusStatic(item.id, statuss);

          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }

  toggleStoreStatusStatic(sid, status) {
    for (let index = 0; index < this.storefront_collection.length; index++) {
      if (this.storefront_collection[index].id === sid) {
        this.storefront_collection[index].status = status;

        break;
      }
    }
  }
}

interface groupStoreview {
  cust_name: string;
  storefronts: userstorefront_obj[];
}
