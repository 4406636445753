<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="z-index: 50;
position: fixed;
top: 51px;"></mat-progress-bar>
<div class="main">
  <app-breadcrumb></app-breadcrumb>
  <div class="productlist" *ngIf="showProgress">
    <mat-card class="cards1">
      <mat-toolbar>
        {{progressStatus}}
      </mat-toolbar>



      <table>
        <tr>
          <th>Bundle name</th>
          <th style="text-align: center">Status</th>
        </tr>
        <tr *ngFor="let item of statusArr; let i=index">
          <td style="word-break: break-word;">{{item.title}}</td>
          <td style="text-align: center;">{{item.status}}</td>
        </tr>

      </table>

    </mat-card>

  </div>
  <div *ngIf="!showProgress" class="productlist">
  <div class="">
    <mat-card>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let item of selectedstorefronts; let i = index" (removed)="remove(i)">
          {{ item.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>

        <mat-chip color="primary" selected (click)="openAddDialog()">
          Add storefronts
          <mat-icon>add</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </div>
  <form [formGroup]="formGroup" class="example-form">
    <div class="form-field">
      <label for="bundleName">Bundle Name:</label>
      <mat-form-field>
        <input matInput id="bundleName" placeholder="Bundle Name" formControlName="bundleName" required>
      </mat-form-field>
    </div>

    <div class="form-field">
      <label for="desc">Description:</label>
      <mat-form-field>
        <textarea matInput id="desc" placeholder="Description" formControlName="desc"></textarea>
      </mat-form-field>
    </div>

    <div class="form-field">
      <label for="code">Code:</label>
      <mat-form-field>
        <input matInput id="code" placeholder="Code" formControlName="code" required>
      </mat-form-field>
    </div>
    <div class="image-form">
    <table class="example-full-width custlogo widths" cellspacing="0">
      <tr>
        <td>
          <mat-form-field>
            <input matInput placeholder="Preview Image" formControlName="previewImage" disabled />
          </mat-form-field>
        </td>
        <td>
          <label color="primary" for="file-upload" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i> Browse..</label>
          <input id="file-upload" (change)="fileupload($event)" type="file" />
        </td>
      </tr>
    </table>
    </div>

    <div class="form-field">
      <label for="price">Price:</label>
      <mat-form-field>
        <input matInput id="price" placeholder="Price" formControlName="price" type="number" required>
      </mat-form-field>
    </div>

    <h5>Select Category</h5>
    <mat-form-field>
      <mat-label>Select Categories</mat-label>
      <mat-select multiple (selectionChange)="onSelectCategories($event)">
        <mat-option *ngFor="let category of bundleCategories" [value]="category">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- category-table.component.html -->
    <table mat-table [dataSource]="selectedCategories">
      <!-- Category Title Column -->
      <ng-container matColumnDef="title">
        <th style="width: 100px;" mat-header-cell *matHeaderCellDef> Category Title </th>
        <td mat-cell *matCellDef="let category"> {{category.name}} 
          <div style="display: flex;flex-direction: column;margin-top: 10px;">
            <mat-form-field>
              <mat-label>Select Products</mat-label>
              <mat-select multiple (selectionChange)="onSelectProducts($event,category.id)">
                <mat-option *ngFor="let product of products" [value]="product">
                  {{ product.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <!-- count Column -->
      <ng-container matColumnDef="Count">
        <th mat-header-cell *matHeaderCellDef> Count </th>
        <td mat-cell *matCellDef="let count ;let i = index;"> 
          <mat-form-field>
            <mat-label>Category Count</mat-label>
            <input matInput placeholder="count" formControlName="category_count" (change)="onCategoryCountChange(i)">
          </mat-form-field>
        
        </td>
      </ng-container>
      <!-- Products Column -->
      <ng-container matColumnDef="products">
        <th mat-header-cell *matHeaderCellDef> Products </th>
        <td mat-cell *matCellDef="let category"> <span *ngFor="let product of category.products">
          {{ product.title }}{{ !last ? ', ' : '' }}
        </span></td>
      </ng-container>

      <!-- Table Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <button mat-raised-button color="primary" (click)="addBundle()">Add Bundle</button>
  </form>
  </div>
</div>