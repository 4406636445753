import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var LearnComponent = /** @class */ (function () {
    function LearnComponent(_sanitizer) {
        this._sanitizer = _sanitizer;
        this.vUrl = 0;
        this.videos = [
            { url: 'https://www.youtube.com/embed/a3gIv2vmFW0', viewValue: 'Create Customer' },
            { url: 'https://www.youtube.com/embed/TmXHAE6DYgA', viewValue: 'Create Storefront' },
            { url: 'https://www.youtube.com/embed/ZBI_2Aq4S9I', viewValue: 'Create Product' },
            { url: 'https://www.youtube.com/embed/ZiXccxaTZYY', viewValue: 'Create Multiple Storefront' },
            { url: 'https://www.youtube.com/embed/uz_7zPEgwCQ', viewValue: 'One flow' },
            { url: 'https://www.youtube.com/embed/Rb4OcRsKKJ0', viewValue: 'Order Filter' }
        ];
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videos[0].url);
    }
    LearnComponent.prototype.ngOnInit = function () {
    };
    LearnComponent.prototype.url = function (index) {
        this.vUrl = index;
        console.log(this.vUrl, index);
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videos[index].url);
        console.log(this.safeURL);
    };
    return LearnComponent;
}());
export { LearnComponent };
