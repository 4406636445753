<div class="main">
    <mat-toolbar class="container learn">Learn</mat-toolbar>
  <div class="video text-center">
   

      <iframe width="860" height="515" [src]="safeURL" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  
<div class="list scrolls">
    <mat-card [class.highlighted]="i === vUrl" class="cards" *ngFor="let item of videos; let i = index" (click)="url(i)"><p class="abc text-center">{{item.viewValue}}</p></mat-card>
    
    
</div>
</div>