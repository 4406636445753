<div class="main">
  <app-breadcrumb></app-breadcrumb>
  <form [formGroup]="customerDetails">
    <ng-template matStepLabel>Customer Details</ng-template>



    <mat-card class="cards">
      <mat-toolbar class="subtitle font-fmly">Add Customer</mat-toolbar><br />
      <button *ngIf="this.dataservice.draftCustomer" mat-raised-button color="primary" style="margin:10px 0;" (click)="goToDraft()">
        Go back to draft
      </button><br/>
      <mat-form-field class="customers-width">
        <input matInput placeholder="*Customer Name"  formControlName="name" />
      </mat-form-field><br />
      <mat-form-field class="customers-width">
        <mat-select placeholder="*Customer Type" formControlName="user_type" (selectionChange)="getType()">
          <mat-option *ngFor="let ctype of type" [value]="ctype.value">
            {{ ctype.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="get_Ctype" (click)="addClubRow()" mat-mini-fab color="primary" style="margin: 0 10px;"
        matTooltip="Add Location" [matTooltipPosition]="'above'">
        <mat-icon class="icons">add</mat-icon>
      </button>

      <br />
      <div *ngIf="get_Ctype" formArrayName="club">
        <div *ngFor="let item of customerDetails.get('club').controls; let i = index;">
          <mat-form-field [formGroupName]="i">
            <input matInput placeholder="Enter Location {{i+1}}" formControlName="name" />
          </mat-form-field>
          <button  (click)="removeClubRow(i)" mat-mini-fab color="warn" style="margin: 0 10px;"
            matTooltip="Add Location" [matTooltipPosition]="'above'">
            <mat-icon class="icons">delete</mat-icon>
          </button>
        </div>
      </div>
      <mat-form-field class="example-full-width" style="width:250px;">
        <span matPrefix>+1 &nbsp;</span>
        <input type="number" matInput placeholder="Telephone" formControlName="phone_number" #input maxlength="10"
          onkeypress="if(this.value.length==10) return false;" onkeydown="if(this.value.length==10) return true;" />
      </mat-form-field>
      <table class="example-full-width custlogo widths" cellspacing="0">
        <tr>
          <td>
            <mat-form-field>
              <input matInput placeholder="Customer Logo" formControlName="logo" disabled />
            </mat-form-field>
          </td>
          <td>
            <label color="primary" for="file-upload" class="custom-file-upload">
              <i class="fa fa-cloud-upload"></i> Browse..</label>
            <input id="file-upload" (change)="fileupload($event)" type="file" />
          </td>
        </tr>
      </table>

      <br />
      <mat-toolbar class="subtitle font-fmly">Customer Address</mat-toolbar>
      <mat-form-field class="customers-width">
        <input matInput placeholder="Street Address" formControlName="address_street"/>
      </mat-form-field><br />
      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="City" formControlName="address_city" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-select placeholder="Select State" formControlName="address_state">
                <mat-option *ngFor="let state of states" [value]="state.value">
                  {{ state.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Zip Code" #input maxlength="5"  formControlName="postal_code" />
            </mat-form-field>
          </td>
        </tr>
      </table>

      <br />
      <mat-toolbar class="subtitle font-fmly">Customer Contact</mat-toolbar>
      <br />
      <mat-form-field class="customers-width">
        <input matInput placeholder="Contact Name" #input maxlength="25" formControlName="contact_name" />
      </mat-form-field>
      <mat-form-field class="customers-width">
        <input matInput placeholder="Contact Title" #input maxlength="25" formControlName="contact_title" />
      </mat-form-field>
      <mat-form-field class="customers-width">
        <input matInput placeholder="Primary Contact Email*" formControlName="email" required />
      </mat-form-field>

      <mat-form-field class="customers-width">

        <mat-chip-list #chipList aria-label="Secondary Email Id">
          <mat-chip *ngFor="let emailid of allEmailId" [selectable]="selectable" [removable]="removable"
            (removed)="remove(emailid)">
            {{emailid}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="Secondary Emails" formControlName="s_email" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addSecondaryEmail($event)">

        </mat-chip-list>


      </mat-form-field>
      <mat-form-field class="customers-width">
        <input matInput placeholder="Contact User Name" #input maxlength="15" formControlName="username" />
      </mat-form-field>
      <mat-form-field class="customers-width">
        <input type="text" matInput placeholder="Contact Password" #input maxlength="10" minlength="6"
          formControlName="password_text" />
      </mat-form-field>
      <br />
      <br />
      <mat-toolbar class="subtitle font-fmly">Customer Status</mat-toolbar>
      <br />
      <br />
      <mat-form-field class="customers-width">
        <mat-select placeholder="Select Status" formControlName="status">
          <mat-option *ngFor="let stat of status" [value]="stat.value">
            {{ stat.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-toolbar class="subtitle font-fmly">Instructions</mat-toolbar>
      <br />
      <mat-form-field class="customers-width">
        <textarea matInput placeholder="Shipping Instruction" formControlName="shipping_instructions"></textarea>
      </mat-form-field>
      <br>

      <mat-form-field class="customers-width">
        <input type="number" matInput placeholder="Service Fee" formControlName="shipping_cost" style="">
        <span matPrefix style="padding-right: 10px">$</span>

      </mat-form-field>
      <br />
      <mat-form-field>
        <input type="number" matInput placeholder="Sales Tax" formControlName="sales_tax" />
        <span matPrefix style="padding-right: 10px">%</span>

      </mat-form-field>
      <br>

      <mat-checkbox class="example-margin" formControlName="is_international_shipping">Enable international shipping
      </mat-checkbox>
      <br>
      <mat-form-field class="customers-width" *ngIf="customerDetails.controls['is_international_shipping'].value">
        <input matInput placeholder="Service Fee" formControlName="shipping_cost_international" />
        <mat-icon matSuffix>attach_money</mat-icon>
      </mat-form-field>
    </mat-card>



    <div class="text-center">
      <button mat-raised-button color="primary" style="margin: 0 10px;" (click)="this.dataservice.draftCustomer ? updateDraft() : saveAsDraftCust(false)">
        {{this.dataservice.draftCustomer? 'Update' : 'Save as'}} Draft
      </button>
      <button mat-raised-button color="primary" style="margin: 0 10px;" (click)="saveCust(true)">
        Create Customer
      </button>
      <button mat-raised-button class="bt1" routerLink="/customers">
        Cancel
      </button>
    </div>
  </form>
</div>
