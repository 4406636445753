import { SizeChart, tags } from './../models';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "./../data.service";
import {
  product_obj,
  userstorefront_obj,
  m_customers_obj,
  customer_obj,
  storefront_obj,
  Inventory,
  ProductTag,
  Sizes
} from "../models";
import { FormControl, FormGroup } from "@angular/forms";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatChipInputEvent,
  MatSort,
  MatTableDataSource
} from "@angular/material";

import { DialogComponent } from "../dialog/dialog.component";
import { C, COMMA, ENTER, O, SPACE } from "@angular/cdk/keycodes";
import { element } from "protractor";
import * as _ from "lodash";
import { NgxImageCompressService } from "ngx-image-compress";
import { CompressImageService } from "../compress.service";
import { take } from "rxjs/operators";
import { shareReplay } from 'rxjs-compat/operator/shareReplay';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: "app-multiple-prodcts",
  templateUrl: "./multiple-prodcts.component.html",
  styleUrls: ["./multiple-prodcts.component.css"]
})
export class MultipleProdctsComponent implements OnInit {



  constructor(
    private dataservice: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private compressImage: CompressImageService,
    private sanitizer: DomSanitizer
  ) {
    this.dataservice.showHide(true);
  }

  Sizes = Sizes
  selectedSizes: string[] = [];
  isImage = false;
  isPdf = false;


  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];

  productcollection: product_obj;
  inventory: any = {};
  inventoryArray: Inventory[] = [];
  colorSizeCombinationArray: Inventory[] = [];
  displayedColumns: string[] = ['Tag','Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
  displayedColumnsSizeChart: string[] = ['Style', 'Chart'];
  showCombinationList = false;
  showSizeChartList = true;

  customercollection: m_customers_obj[] = [];

  storetext = "Select Store Fronts To Add Products.";

  btnText = "Next";

  selectedstorefronts: userstorefront_obj[] = [];

  numberofproducts = 0;

  addStoreFront = false;

  progressStatus = 'Adding products ....';
  tags = new FormControl();
  tagsList: ProductTag[] = [];
  specialTags = new FormControl();

  specialTagList: ProductTag[] = [];

  showAddProducts = false;
  addProduct = false;
  searchText = "";

  productName = new FormControl();
  productCode = new FormControl();
  productPrice = new FormControl();
  productCat = new FormControl();
  productColor = new FormControl();
  productSize = new FormControl();

  // productQuantity_new = new FormControl();
  // productSize_new = new FormControl();
  // productColor_new = new FormControl();

  statusArr: {
    title: string;
    status: string;
  }[] = [];

  showProgress = false;
  prevnum = 0;
  isLoading = false

  sub: Subscription;
  copiedSizes = [];

  ngOnInit() {
    this.selectedstorefronts = this.dataservice.selected_storefronts;

    if (this.selectedstorefronts.length > 0) {
      this.tagsList = this.selectedstorefronts[0].tags
    }
    console.log(this.tagsList);

    console.log(this.selectedstorefronts);
    //this.getStoreFrontData();

    this.addProducts();
    this.getSpecialTagList();
    // this.getTagList();
    // this.addBlankProduct();
    this.sub = this.dataservice.copiedSizesObs$.subscribe(sizes => {
      if (sizes.length != 0) {
        console.log(sizes);
        this.copiedSizes = sizes
      }
    })

  }

  ngOnDestroy() {
    if (this.sub != undefined) {
      console.log('on de');
      this.sub.unsubscribe();
    }
  }

  getTagList() {
    this.dataservice.getProductTagList().then(
      res => {
        console.log(res);
        this.tagsList = res;
      }
    )
  }

  getSpecialTagList() {
    this.dataservice.getProductSpecialTagList().then(
      res => {
        console.log(res);
        this.specialTagList = res;
      }
    )
  }
  uploadedFile: { name: string, type: string, url: SafeResourceUrl, file: any };






  toggleSize(size: string): void {
    const index = this.selectedSizes.indexOf(size);
    if (index === -1) {
      this.selectedSizes.push(size);
    } else {
      this.selectedSizes.splice(index, 1);
    }
    this.productcollection.sizeArray = this.selectedSizes
    console.log(this.selectedSizes);
  }

  add(event: MatChipInputEvent, type: string): void {
    console.log('uu', event.value,type);
    const value = (event.value || '').trim().toUpperCase();

    switch (type) {
      case 'color': {

        console.log(type);

        if (value) {
          this.productcollection.colorArray.push(value);
        }

        if (event.input) {
          event.input.value = '';
        }
        break;
      }
      case 'size': {

        console.log(type);
        if (value) {
          this.productcollection.sizeArray.push(value.toUpperCase());
        }


        if (event.input) {
          event.input.value = '';
        }

        break;
      }

      case 'type': {
        console.log(type);
        // if (value) {
        //   this.productcollection[i].typeArray.push(value);
        // }


        // if (event.input) {
        //   event.input.value = '';
        // }
      }
      default: {
        //statements; 
        break;
      }
    }





  }

  removeC(item, type): void {

    switch (type) {
      case 'color': {

        console.log(type);
        const index = this.productcollection.colorArray.indexOf(item);

        if (index >= 0) {
          this.productcollection.colorArray.splice(index, 1);

          this.showCombinationList = false;
          this.productcollection.productinventory_set = [];
        }


        break;
      }
      case 'size': {

        console.log(type);
        const index = this.productcollection.sizeArray.indexOf(item);

        if (index >= 0) {
          this.productcollection.sizeArray.splice(index, 1);
          this.productcollection.productinventory_set = [];

          this.showCombinationList = false;


        }


        break;
      }

      case 'type': {
        console.log(type);
        const index = this.productcollection.typeArray.indexOf(item);

        if (index >= 0) {
          this.productcollection.typeArray.splice(index, 1);

        }
      }
      default: {
        //statements; 
        break;
      }
    }


  }

  csq() {
    this.inventoryArray.push(this.inventory);
    this.inventory = {};
    console.log(this.inventoryArray);


  }


  removeCombo(i) {
    console.log(i);

    this.productcollection.productinventory_set = this.productcollection.productinventory_set.filter((item, index) => index !== i);

    this.productcollection.colorArray = this.productcollection.colorArray.filter((color, index) => this.productcollection.productinventory_set.some((inv) => {
      return inv.color == color
    }))

    this.productcollection.sizeArray = this.productcollection.sizeArray.filter((size, index) => this.productcollection.productinventory_set.some((inv) => {
      return inv.size == size

    }))
    this.selectedSizes = this.productcollection.sizeArray
    console.log(this.productcollection);

  }

  increaseQ() {
    this.productcollection.productinventory_set.forEach(inv => {
      inv.quantity += 10
    })


  }
  decreaseQ() {
    this.productcollection.productinventory_set.forEach(inv => {

      if (inv.quantity > 0 && inv.quantity >= 10) {
        inv.quantity -= 10

      } else {
        inv.quantity = 0
      }

    })


  }

  increasePrice() {
    this.productcollection.productinventory_set.forEach(inv => {
      inv.price += 10
    })
  }
  decreasePrice() {
    this.productcollection.productinventory_set.forEach(inv => {

      if (inv.price > 0 && inv.price >= 10) {
        inv.price -= 10

      } else {
        inv.price = 0
      }

    })
  }

  removeChart(i) {
    this.productcollection.size_chart[i].chart = null;
    this.productcollection.size_chart[i].charturl = null;
  }

  uploadSizeChart(event: any, i: number) {
    console.log(i);

    console.log(i);

    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const reader = new FileReader();

      reader.onload = (e: any) => {

        const url = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);

        // Create an object with file name and sanitized URL
        const obj = {
          name: file.name,
          url: url
        };

        this.productcollection.size_chart[i].chart = file;
        this.productcollection.size_chart[i].charturl = obj

        console.log(this.productcollection.size_chart);
      };


      this.isImage = this.dataservice.isImage(fileType)
      this.isPdf = this.dataservice.isPDF(fileType)

      console.log(this.isImage, this.isPdf);

      if (this.dataservice.isImage(fileType) || this.dataservice.isPDF(fileType)) {
        reader.readAsDataURL(file);
      }
    }

    console.log(this.productcollection.size_chart);
  }
  
  onSelectionChange() {
  
    console.log('Selection changed:', this.tags.value);
    
  }

  onSelectionChangeCat(event: any) {
    // This method will be called whenever the selection changes
    console.log('Selection changed:', event.value);

    // if(event.value === 2){
    //   this.tags.setValue(['Paper'])
    // }else if(event.value === 3){
    //   this.tags.setValue(['Accessories'])
    // }
    // You can perform any actions you need here
    // if(event.value === 1){
    //   this.displayedColumns.unshift('Tag')
    // }else{
    //   this.displayedColumns = ['Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
    // }
    // console.log(this.displayedColumns);
  }

  generateSizeChartTable() {
    console.log('upload here');
    console.log(this.tags.value);
    this.productcollection.size_chart = []
    if (this.tags.value <= 0) {
      alert("Select atleast one product style option!");
      return
    }
    this.tags.value.forEach((val: string) => {

      const sizeChart: SizeChart = {
        style: val,
        chart: null,
        charturl: null
      };
      this.productcollection.size_chart.push(sizeChart);
    });

    console.log(this.productcollection.size_chart);
  }

  generateList() {

    let element = this.productcollection
    element.productinventory_set = []
    console.log(element);

    const colorArray = element.colorArray;
    const sizeArray = element.sizeArray;

    colorArray.forEach(color => {
      sizeArray.forEach(size => {
        this.tags.value.forEach(tag => {
          var inv: Inventory = { tag: tag.name, color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.productcollection.price), files: [], imageArr: [], isChecked: false };
          element.productinventory_set.push(inv);

        })

      })
    })

    // if(this.productcollection.categories == 1){
    //   colorArray.forEach(color => {
    //     sizeArray.forEach(size => {
    //       this.tags.value.forEach(tag => {
    //         var inv: Inventory = { tag: tag.name, color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.productcollection.price), files: [], imageArr: [], isChecked: false };
    //         element.productinventory_set.push(inv);
  
    //       })
  
    //     })
    //   })
    // }else{
    //   colorArray.forEach(color => {
    //     sizeArray.forEach(size => {
    //         var inv: Inventory = { color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.productcollection.price), files: [], imageArr: [], isChecked: false };
    //         element.productinventory_set.push(inv);
  
    //     })
    //   })
    // }
    

    element.productinventory_set.sort(function (a, b) {
      var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
      var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
      if (tagA < tagB) {
        return -1;
      }
      if (tagA > tagB) {
        return 1;
      }
      return 0; // tags are equal
    });

    this.showCombinationList = true


  }

  deleteinv(index) {
    this.inventoryArray.splice(index, 1);
    console.log(this.inventoryArray);

  }

  removeImage2(imgin, i) {
    console.log(imgin, i);

    this.productcollection.productinventory_set[i].imageArr.splice(imgin, 1);
    this.productcollection.productinventory_set[i].files.splice(imgin, 1);
  }


  detectFiles2(event, i) {
    let images = event.target.files;
    var newFiles = [];

    if (images) {

      for (let image of images) {
        console.log(`Image size before compressed: ${image.size} bytes.`);

        this.compressImage
          .compress(image)
          .pipe(take(1))
          .subscribe(compressedImage => {
            newFiles.push(compressedImage)
            if (images.length === newFiles.length) {
              console.log(newFiles);
              this.openAskDialog(i, newFiles);
            }
            console.log(
              `Image size after compressed: ${(compressedImage.size)} bytes.`
            );
            // now you can do upload the compressed image
          });
      }

    }

    console.log(this.productcollection);
  }
  openAskDialog(i, files): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to apply this product image to other color and size options?",
        type: "question"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);
      if (result == 'yes') {
        this.openAskColorSizeDialog(i, files);

      } else {
        console.log(i);
        for (let file of files) {
          this.productcollection.productinventory_set[i].files.push(file);

          let reader = new FileReader();
          reader.onload = (e: any) => {
            let obj = {
              name: file.name,
              url: e.target.result
            };

            this.productcollection.productinventory_set[i].imageArr.push(obj);
          };
          reader.readAsDataURL(file);
        }
      }

    });
  }
  openAskColorSizeDialog(i, files): void {

    let element = this.productcollection
    console.log(this.tags.value);

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Choose color and size!",
        content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
        type: "colorAndsizeOptions"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);


      console.log(this.productcollection.productinventory_set);

      this.productcollection.productinventory_set.forEach((inv, indexx) => {
        result.tags.forEach(tag => {
          if (tag == inv.tag) {
            if (result.colors.includes(inv.color) && result.sizes.includes(inv.size)) {
              console.log('add here');
              for (let file of files) {
                this.productcollection.productinventory_set[indexx].files.push(file);

                let reader = new FileReader();
                reader.onload = (e: any) => {
                  let obj = {
                    name: file.name,
                    url: e.target.result
                  };

                  this.productcollection.productinventory_set[indexx].imageArr.push(obj);
                };
                reader.readAsDataURL(file);
              }
            }
          }
        })

      })
      // this.productcollection.productinventory_set.forEach((inv, indexx) => {

      //   result.forEach((element, ind) => {
      //     if (element.color == inv.color && element.size == inv.size) {
      //       console.log("color matched", indexx);

      // for (let file of files) {
      //   this.productcollection.productinventory_set[indexx].files.push(file);

      //   let reader = new FileReader();
      //   reader.onload = (e: any) => {
      //     let obj = {
      //       name: file.name,
      //       url: e.target.result
      //     };

      //     this.productcollection.productinventory_set[indexx].imageArr.push(obj);
      //   };
      //   reader.readAsDataURL(file);
      // }

      //     }
      //   });

      // })
    });
  }

  // detectFiles(event, index) {
  //   console.log(index);
  //   let files = event.target.files;
  //   if (files) {
  //     for (let file of files) {
  //       this.productcollection[index].files.push(file);

  //       let reader = new FileReader();
  //       reader.onload = (e: any) => {
  //         let obj = {
  //           name: file.name,
  //           url: e.target.result
  //         };

  //         this.productcollection[index].imagearry.push(obj);

  //       };
  //       reader.readAsDataURL(file);
  //     }

  //   }

  //   console.log(this.productcollection);
  // }

  addProducts() {

    this.addBlankProduct();
  }

  showAddStoreFront() {
    // this.addProduct = true;
    // this.getStoreFronts()
  }

  removeImage(i, index) {
    console.log(i, index);

    this.productcollection[index].imagearry.splice(i, 1);
    this.productcollection[index].files.splice(i, 1);
  }

  addBlankProduct() {

    let obj = new product_obj();
    obj.title = "";
    obj.size = "";
    obj.color = "";
    obj.price = "";
    obj.description = "";
    obj.multiple_colors = false;
    obj.categories = 1;
    obj.imagearry = [];
    obj.files = [];
    obj.storefront = "";
    obj.is_featured = false;
    obj.productinventory_set = [];



    this.productcollection = obj
  }

  getStoreFrontString() {
    var returnstring = [];
    this.selectedstorefronts.forEach(element => {
      returnstring.push(element.id);
    });

    return returnstring.toString();
  }

  showStoreFronts() {
    if (this.addStoreFront) {
      this.addStoreFront = false;
    } else {
      this.addStoreFront = true;
    }
  }

  getStoreFrontData() {
    this.dataservice.getAllStoreFronts().subscribe(data => {
      let obj = data.json();
      this.customercollection = obj["results"];

      this.selectedstorefronts.forEach(element => {
        this.checkData(element.id);
      });

      console.log(this.customercollection);
    });
  }

  updateStoreFronts() {
    console.log(this.customercollection);
    this.getSelectedStoreFronts();
  }

  selectCustomerStoreFront(id) {
    let pos = this.customercollection.findIndex(element => {
      return element.id == id;
    });

    for (
      let index = 0;
      index < this.customercollection[pos].user_storefront.length;
      index++
    ) {
      this.customercollection[pos].user_storefront[
        index
      ].ischecked = this.customercollection[pos].ischecked;
    }
    this.getSelectedStoreFronts();
  }

  checkData(storefrontid: number) {
    for (let index = 0; index < this.customercollection.length; index++) {
      for (
        let indexx = 0;
        indexx < this.customercollection[index].user_storefront.length;
        indexx++
      ) {
        if (
          storefrontid ===
          this.customercollection[index].user_storefront[indexx].id
        ) {
          this.customercollection[index].user_storefront[
            indexx
          ].ischecked = true;
        }
      }
    }
  }

  getSelectedStoreFronts() {
    this.selectedstorefronts = [];

    this.customercollection.forEach(element => {
      element.user_storefront.forEach(obj => {
        if (obj.ischecked) {
          this.selectedstorefronts.push(obj);
        }
      });
    });

    console.log(this.selectedstorefronts);
  }

  goAhead() {
    if (this.btnText === "Next") {
      this.btnText = "Back";
      this.showAddProducts = true;
      this.storetext = "Add Products.";
    } else {
      this.btnText = "Next";
      this.showAddProducts = false;
      this.storetext = "Select Store Fronts To Add Products.";
    }
  }

  paste() {
    if (this.copiedSizes.length != 0) {
      this.productcollection[0].sizeArray = this.copiedSizes
    }
  }


  onSave() {
    console.log(this.tags.value);
    console.log(this.specialTags.value);

    if(this.productcollection.size_chart.length <= 0){
      this.generateSizeChartTable();
    }

    if (this.selectedstorefronts.length <= 0) {
      alert("Add Store Front ");
      return
    }


    console.log(this.productcollection);


    if (this.productcollection.productinventory_set.length <= 0) {
      alert("Generate List to add quantity!");

      return
    };


    if (this.productcollection.productinventory_set.some((element) => element.imageArr.length <= 0)) {
      alert("Add atleast one Image to every color and size option!");
      return
    }

    // if (!this.productcollection.size_chart) {
    //   alert("Please add size chart to the product");
    //   return
    // }



    if (this.tags.value <= 0 && this.productcollection.categories == 1) {

      alert("Select atleast one product style option!");
      return

    } else {
      console.log(this.tags.value);
      this.productcollection.typeArray = this.tags.value
    }

    this.productcollection.specialTags = this.specialTags.value





    if (
      this.productName.valid &&
      this.productCode.valid &&
      this.productPrice.valid &&
      this.selectedstorefronts.length > 0
    ) {


      this.isLoading = true
      let element = this.productcollection

      this.statusArr.push({ title: element.title, status: 'In progress' });



      this.showProgress = true;
      let i = 0;

      this.statusArr[i].status = "Working";
      element.storefront = this.getStoreFrontString();

      this.dataservice.createMultipleProduct(element).subscribe(data => {
        console.log(data);

        data.forEach(element => {
          this.dataservice.appendProduct_List(element);

        });

        this.selectedstorefronts.forEach(elementx => {

          this.dataservice.appendProductObjinStore(data as any, elementx.user, elementx.id);

        });

        this.isLoading = false
        this.statusArr[i].status = "Done";
        this.dataservice.productEdited = true;


        if (i === this.statusArr.length - 1) {

          this.progressStatus = "Products added successfully";
        }

        // notthisbelow
        // this.openSnackBar();
      }
        ,
        error => {

          this.statusArr[i].status = "Error";
          this.isLoading = false
        },

        () => {
          i++;

        }


      );



    } else {
      alert("Fill All The Details");
    }

  }

  //

  openAddDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Select Storefronts",
        content: this.selectedstorefronts,
        type: "selectstorefront"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      if (result.length > 0) {
        // let temp = this.selectedstorefronts;
        // let temp2 = result;

        this.selectedstorefronts = result;
        this.tagsList = this.selectedstorefronts[0].tags
        console.log(this.tagsList);
        // temp.forEach(element => {
        //   this.selectedstorefronts.push(element);
        // });

        // temp2.forEach(element => {
        //   this.selectedstorefronts.push(element);
        // });

        console.log(this.selectedstorefronts);
      }
    });
  }

  removeProduct(index) {
    // this.productcollection.splice(index, 1);

    // this.openSnackBar();
  }

  remove(index) {
    this.selectedstorefronts.splice(index, 1);

    // this.openSnackBar();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(PizzaPartyComponent, {
      duration: 3 * 1000,
      horizontalPosition: "end",
      verticalPosition: "bottom"
    });
  }
}

@Component({
  selector: "snack-bar",
  templateUrl: "snack-bar.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `
  ]
})
export class PizzaPartyComponent {
  lbl = "Added to cart";
}



@Component({
  selector: "snack-bar",
  templateUrl: "snack-bar.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `
  ]
})
export class CopyUrl {
  lbl = "Store url copied to clipboard";
}


