import { Http, Headers, RequestOptions } from "@angular/http";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { URLSearchParams } from "@angular/http";
import "rxjs/add/operator/toPromise";
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/map";
import { product_obj, userstorefront_obj, m_product_obj, order_status, inmemoryCell, productinventory_set, Inventory, ProductTag } from "./models";
import { throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material";
//import { CustomersComponent } from './customers/customers.component';
import { environment } from '../environments/environment';
var DataService = /** @class */ (function () {
    function DataService(http, httpClient, _snackBar) {
        this.http = http;
        this.httpClient = httpClient;
        this._snackBar = _snackBar;
        this.inmemoryData = [];
        this.productEdited = false;
        this.customerGetFlag = false;
        this.pageNumber = 0;
        //Mainsite
        //baseurl = 'https://store-api.primetime.company/';
        //Devlopment
        // baseurl = 'https://primetime-api.analogyplus.com/';
        //Test-stie
        // baseurl = "https://store-api.analogyplus.com/";
        //Prod-2
        // baseurl = 'https://storev1-api.primetime.company/';
        //Dev
        // baseurl ='https://storedev-api.primetime.company/'
        // storeUrl = 'https://storedev.primetime.company'
        // prod v2
        // baseurl ='https://storev2-api.primetime.company/'
        // storeUrl = 'https://storev2.primetime.company/'
        this.baseurl = environment.baseurl;
        this.storeUrl = environment.storeUrl;
        this.subBoolShowHideSource = new Subject();
        this.subBoolShowHide$ = this.subBoolShowHideSource.asObservable();
        this.customer_list = [];
        this.selected_storefronts = [];
        this.protected_selected_storefronts = [];
        this.customerListState = 'list';
        this.productListState = 'list';
        this.orderstatuscollection = [
            {
                value: "0",
                viewvalue: "Order received"
            },
            {
                value: "1",
                viewvalue: "Order processing"
            },
            {
                value: "2",
                viewvalue: "Order dispatch"
            },
            {
                value: "3",
                viewvalue: "Order delivered"
            }
        ];
        this.sfDeleteId = new BehaviorSubject(0);
        this.sfDeleteId$ = this.sfDeleteId.asObservable();
        this.copiedSizes$ = new BehaviorSubject([]);
        this.copiedSizesObs$ = this.copiedSizes$.asObservable();
        this.customerUpdated$ = new BehaviorSubject(null);
        this.imgs = [];
    }
    DataService.prototype.deleteSfFromProductsCatalogue = function (id) {
        console.log(id);
        this.sfDeleteId.next(id);
    };
    DataService.prototype.getNativeWindow = function () {
        return window;
    };
    DataService.prototype.setCustomer_List = function (data) {
        this.customer_list = data;
    };
    DataService.prototype.getCustomer_List = function () {
        return this.customer_list;
    };
    DataService.prototype.appendCustomer_List = function (obj) {
        var index = this.inmemoryData.findIndex(function (element) { return element.datatype === 'customer-store'; });
        if (index > -1) {
            this.inmemoryData[index].multicollectionarry[0].unshift(obj);
        }
    };
    DataService.prototype.appendProduct_List = function (obj) {
        // console.log( this.inmemoryData[0].multicollectionarry[0]);
        // console.log(obj);
        var index = this.inmemoryData.findIndex(function (element) { return element.datatype === 'product'; });
        if (index > -1) {
            console.log(this.inmemoryData[index].multicollectionarry[0]);
            this.inmemoryData[index].multicollectionarry[0].unshift(obj);
            console.log(this.inmemoryData[index].multicollectionarry[0]);
        }
    };
    DataService.prototype.appendStoreObjInCustomer = function (obj) {
        var index = this.inmemoryData.findIndex(function (element) { return element.datatype === 'customer-store'; });
        if (index > -1) {
            var numberofPage = this.inmemoryData[index].multicollectionarry.length;
            if (numberofPage === 1) {
                this.inmemoryData[index].multicollectionarry[0].forEach(function (element) {
                    if (element['id'] === obj.user) {
                        element['user_storefront'].unshift(obj);
                    }
                });
            }
        }
    };
    DataService.prototype.appendProductObjinStore = function (obj, customerid, storefrontid) {
        // console.log(obj);
        // console.log(this.inmemoryData);
        var index = this.inmemoryData.findIndex(function (element) { return element.datatype === 'customer-store'; });
        console.log(index);
        if (index > -1) {
            var numberofPage = this.inmemoryData[index].multicollectionarry.length;
            console.log(numberofPage);
            if (numberofPage === 1) {
                console.log(this.inmemoryData[index].multicollectionarry[0]);
                this.inmemoryData[index].multicollectionarry[0].forEach(function (element) {
                    console.log(element);
                    if (element['id'] === customerid) {
                        element['user_storefront'].forEach(function (elementx) {
                            if (elementx['id'] === storefrontid) {
                                console.log(elementx['id'], storefrontid);
                                elementx['storefront_product'] += 1;
                                elementx['storefront_product_total'] += 1;
                                console.log(elementx['storefront_product']);
                                return;
                            }
                        });
                    }
                });
            }
        }
    };
    DataService.prototype.removeProductObjFromStore = function (pid, sfid, uid) {
        var index = this.inmemoryData.findIndex(function (element) { return element.datatype === 'customer-store'; });
        if (index > -1) {
            var numberofPage = this.inmemoryData[index].multicollectionarry.length;
            if (numberofPage === 1) {
                this.inmemoryData[index].multicollectionarry[0].forEach(function (element) {
                    if (element['id'] === uid) {
                        element['user_storefront'].forEach(function (elementx) {
                            console.log(elementx);
                            if (elementx['id'] === sfid) {
                                var indexofp = elementx['storefront_product'].findIndex(function (elemenzzt) {
                                    return elemenzzt.id === pid;
                                });
                                elementx['storefront_product'].splice(indexofp, 1);
                                elementx['storefront_product_total'].splice(indexofp, 1);
                            }
                        });
                    }
                });
            }
        }
    };
    DataService.prototype.setSelectedCustomer = function (data) {
        this.selected_customer = data;
    };
    DataService.prototype.getSelectedCustomer = function () {
        return this.selected_customer;
    };
    DataService.prototype.getCustomerId = function (id) {
        this.CustId = id;
        console.log(this.CustId, "heId");
    };
    DataService.prototype.getMultiSF_Obj = function (obj, multiBoolean) {
        this.objs = obj;
        this.multiBoolean = multiBoolean;
        console.log(this.objs);
    };
    DataService.prototype.showHide = function (eventValue) {
        this.subBoolShowHideSource.next(eventValue);
        console.log("show hide service " + eventValue);
    };
    DataService.prototype.getCustId = function (id) {
        this.users_id = id;
    };
    DataService.prototype.setSfname = function (name) {
        this.Sfname = name;
        console.log(this.Sfname, "ajaj");
    };
    DataService.prototype.creatCustomer = function (Cusername, Cname, Ctype, Ctelephone, Caddress, Ccity, Cstate, Cpostal, Cdesignation, Cemail, Cpassword, Cstatus, Custlogo, Ccontact, shipping, ClubData, tax) {
        var _this = this;
        console.log(ClubData);
        console.log(Cemail, "see");
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        //let array1;
        //array1 = [{ "subfolder_name": subfolder, "file_upload": list }];
        //console.log(array1);
        var formData = new FormData();
        formData.append("username", Cusername);
        formData.append("name", Cname);
        formData.append("email", Cemail);
        formData.append("address_street", Caddress);
        formData.append("address_city", Ccity);
        formData.append("address_state", Cstate);
        formData.append("postal_code", Cpostal);
        formData.append("phone_number", Ctelephone);
        formData.append("designation", Cdesignation);
        formData.append("status", Cstatus);
        formData.append("shipping_instructions", shipping);
        formData.append("user_type", Ctype);
        formData.append("username", Cusername);
        formData.append("sales_tax", tax);
        if (Custlogo) {
            formData.append("logo", Custlogo);
        }
        formData.append("password_text", Cpassword);
        formData.append("password", Cpassword);
        formData.append("user_sub_type", ClubData);
        //headers.append('enctype', 'multipart/form-data');
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/users/", formData, options)
                .subscribe(function (success) {
                //console.log('success');
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    //getuserdetail
    DataService.prototype.getUserDetail = function () {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + "api/v1/userdetailv1/", options);
    };
    DataService.prototype.addcustomer = function (obj, img, isClubs, allEmailid, create) {
        // console.log(clubs)
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        var formData = new FormData();
        formData.append("username", obj.username);
        formData.append("name", obj.name);
        formData.append("email", obj.email);
        formData.append("secondary_emails", allEmailid.toString());
        formData.append("address_street", obj.address_street);
        formData.append("address_city", obj.address_city);
        formData.append("address_state", obj.address_state);
        formData.append("postal_code", obj.postal_code);
        formData.append("phone_number", obj.phone_number);
        formData.append("status", obj.status);
        formData.append("shipping_instructions", obj.shipping_instructions);
        formData.append("user_type", obj.user_type);
        formData.append("username", obj.username);
        formData.append("sales_tax", obj.sales_tax);
        formData.append('contact_name', obj.contact_name);
        formData.append('contact_title', obj.contact_title);
        formData.append('entity_type', "customer");
        if (obj.shipping_cost === null) {
            formData.append("shipping_cost", '0');
        }
        else {
            formData.append("shipping_cost", obj.shipping_cost);
        }
        formData.append("is_international_shipping", obj["is_international_shipping"]);
        formData.append("shipping_cost_international", obj["shipping_cost_international"] === null ? "0" : obj.shipping_cost_international);
        if (img != undefined) {
            formData.append("logo", img);
        }
        console.log(img);
        formData.append("password_text", obj.password_text);
        if (isClubs) {
            var objname_1 = [];
            obj.club.forEach(function (element) {
                if (element.name !== '') {
                    objname_1.push(element.name);
                }
            });
            console.log(objname_1);
            formData.append("user_sub_type", objname_1.toString());
        }
        else {
            formData.append("user_sub_type", '');
        }
        var api = "";
        if (create) {
            api = "api/v1/users/";
            if (this.draftCustomer) {
                formData.append('draft_id', obj.draft_id);
            }
        }
        else {
            api = "api/v1/storefront/draft/";
        }
        console.log(api);
        //var returnobj = new Promise((resolve, reject) => {
        return this.http
            .post(this.baseurl + api, formData, requestOptions);
        //     .subscribe(
        //       data => {
        //         returnobj = data.json();
        //         resolve(returnobj);
        //       },
        //       error => {
        //         console.log("error1");
        //         //resolve(returnobj);
        //       },
        //       () => {
        //         // console.log('completed');
        //       }
        //     );
        // });
        // return returnobj;
    };
    DataService.prototype.getCustomerDraft = function () {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.get(_this.baseurl + "api/v1/storefront/draft/", { headers: headers }).pipe(map(function (data) { return data.results; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.updateStorefrontDraft = function (obj, draft_id) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        obj['entity_type'] = "storefront";
        return this.httpClient
            .patch(this.baseurl + "api/v1/storefront/draft/" + draft_id + "/", obj, {
            headers: headers
        });
    };
    DataService.prototype.updateCustomerDraft = function (draft_id, obj, s_emailIds) {
        var headers = new Headers({
            Authorization: "JWT " + window.sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append("username", obj["username"]);
        formData.append("name", obj["name"]);
        formData.append("email", obj["email"]);
        formData.append("secondary_emails", s_emailIds.toString());
        formData.append("address_street", obj["address_street"]);
        formData.append("address_city", obj["address_city"]);
        formData.append("address_state", obj["address_state"]);
        formData.append("postal_code", obj["postal_code"]);
        formData.append("phone_number", obj["phone_number"]);
        formData.append("designation", obj["designation"]);
        formData.append("status", obj["status"]);
        formData.append("user_type", obj["user_type"]);
        formData.append("shipping_instructions", obj["shipping_instructions"]);
        formData.append('entity_type', "customer");
        if (obj["shipping_cost"] === null) {
            formData.append("shipping_cost", '0');
        }
        else {
            formData.append("shipping_cost", obj["shipping_cost"]);
        }
        formData.append("is_international_shipping", obj["is_international_shipping"]);
        formData.append("shipping_cost_international", obj["shipping_cost_international"]);
        formData.append("user_sub_type", obj["user_sub_type"]);
        formData.append("sales_tax", obj["sales_tax"]);
        formData.append("contact_name", obj["contact_name"]);
        formData.append("contact_title", obj["contact_title"]);
        if (typeof obj["logo"] === "string") {
            console.log("no image uploaded");
        }
        else {
            formData.append("logo", obj["logo"]);
        }
        //headers.append('enctype', 'multipart/form-data');
        return this.http
            .patch(this.baseurl + "api/v1/storefront/draft/" + draft_id + "/", formData, {
            headers: headers
        });
    };
    DataService.prototype.updatesCustomers = function (cust_id, obj) {
        var headers = new Headers({
            Authorization: "JWT " + window.sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append("username", obj["username"]);
        formData.append("name", obj["name"]);
        formData.append("email", obj["email"]);
        formData.append("secondary_emails", obj["secondary_emails"]);
        formData.append("address_street", obj["address_street"]);
        formData.append("address_city", obj["address_city"]);
        formData.append("address_state", obj["address_state"]);
        formData.append("postal_code", obj["postal_code"]);
        formData.append("phone_number", obj["phone_number"]);
        formData.append("designation", obj["designation"]);
        formData.append("status", obj["status"]);
        formData.append("user_type", obj["user_type"]);
        formData.append("shipping_instructions", obj["shipping_instructions"]);
        if (obj["shipping_cost"] === null) {
            formData.append("shipping_cost", '0');
        }
        else {
            formData.append("shipping_cost", obj["shipping_cost"]);
        }
        formData.append("is_international_shipping", obj["is_international_shipping"]);
        formData.append("shipping_cost_international", obj["shipping_cost_international"]);
        formData.append("user_sub_type", obj["user_sub_type"]);
        formData.append("sales_tax", obj["sales_tax"]);
        formData.append("contact_name", obj["contact_name"]);
        formData.append("contact_title", obj["contact_title"]);
        if (typeof obj["logo"] === "string" || obj["logo"] == undefined) {
            console.log("no image uploaded");
        }
        else {
            formData.append("logo", obj["logo"]);
        }
        //headers.append('enctype', 'multipart/form-data');
        return this.http
            .patch(this.baseurl + "api/v1/userdetail/" + cust_id + "/", formData, {
            headers: headers
        });
    };
    DataService.prototype.createSf_v2 = function (obj, create) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        obj['entity_type'] = "storefront";
        var api = "";
        if (create) {
            api = "api/v1/storefront/";
        }
        else {
            api = "api/v1/storefront/draft/";
        }
        return this.httpClient.post(this.baseurl + api, obj, { headers: headers });
    };
    //Create StoreFront
    DataService.prototype.creatStoreFront = function (SFname, SFcolor, SFtitlecolor, SFdateStart, SFdateEnd, SFurlHint, Productlogo, user_id, SFstatus, net30Pay, onlinePay) {
        var _this = this;
        // console.log("Do Login");
        //console.log(SFdateStart, 'see');
        var headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("name", SFname);
        formData.append("banner_color_code", SFcolor);
        formData.append("title_color", SFtitlecolor);
        // formData.append("online_payment", onlinePayment);
        if (Productlogo) {
            formData.append("banner", Productlogo);
        }
        formData.append("slug", SFurlHint);
        formData.append("user", user_id);
        formData.append("status", SFstatus);
        formData.append("online_payment", onlinePay);
        formData.append("net30", net30Pay);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/storefront/", formData, options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    // Add url in SF
    DataService.prototype.addUrlSf = function (SFurlHint) {
        var headers = new Headers();
        //headers.append('Content-Type', 'application/json')
        //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + "api/v1/storefront/detailv2/" + SFurlHint + "/", options);
    };
    DataService.prototype.newSFdata = function (newdata) {
        this.sfData = newdata;
    };
    // Update Storefront
    DataService.prototype.updateStoreFront = function (obj) {
        var _this = this;
        //console.log(SFdateEnd, 'date');
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("name", obj["name"]);
        formData.append("banner_color_code", obj["banner_color_code"]);
        formData.append("title_color", obj["title_color"]);
        formData.append("desc_background_color", obj["desc_background_color"]);
        formData.append("desc_text_color", obj["desc_text_color"]);
        formData.append("tags", JSON.stringify(obj.tags));
        formData.append("slug", obj["slug"]);
        formData.append("user", obj["user"]);
        formData.append("net30", obj["net30"]);
        formData.append("passcode", obj["passcode"]);
        if (obj['location']) {
            formData.append("location", obj["location"]);
        }
        else {
            formData.append("location", '');
        }
        if (obj['location']) {
            formData.append("chain_name", obj["chain_name"]);
        }
        else {
            formData.append("chain_name", '');
        }
        formData.append("online_payment", obj["online_payment"]);
        formData.append("description", obj["description"]);
        formData.append("status", obj["status"]);
        formData.append("free_shipping_amount", obj["free_shipping_amount"]);
        formData.append("auto_fill", obj["auto_fill"]);
        formData.append("show_banner_images", obj["show_banner_images"]);
        formData.append("auto_fill_data", JSON.stringify(obj["auto_fill_data"]));
        formData.append("show_customer_name", obj["show_customer_name"]);
        formData.append("archive_message", obj["archive_message"]);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.baseurl + "api/v1/storefront/detail/" + obj["id"] + "/", formData, options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.updateStoreFrontNoImg = function (SFname, SFcolor, SFtitlecolor, SFdateStart, SFdateEnd, SFurlHint, user_id, SFstatus, product_id, net30Pay, onlinePay) {
        var _this = this;
        // console.log(SFdateEnd, 'date');
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("name", SFname);
        formData.append("banner_color_code", SFcolor);
        formData.append("title_color", SFtitlecolor);
        formData.append("online_payment", onlinePay);
        formData.append("net30", net30Pay);
        //formData.append("online_payment", onlinePayment);
        // Start Date
        // if (SFdateStart == 'null') {
        //   console.log('blank date');
        // }
        // else {
        //   formData.append("startdate", SFdateStart);
        // }
        // End Date
        // if (SFdateEnd == 'null') {
        //   console.log('blank date');
        // }
        // else {
        //   formData.append("enddate", SFdateEnd);
        // }
        // formData.append("enddate", SFdateEnd);
        formData.append("slug", SFurlHint);
        formData.append("user", user_id);
        formData.append("status", SFstatus);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.baseurl + "api/v1/storefront/detail/" + product_id + "/", formData, options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.UpdateImages = function (files, product_id) {
        var formData = new FormData();
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        formData.append("product_id", product_id.toString());
        for (var index = 0; index < files.length; index++) {
            var element_1 = files[index];
            var cont = index + 1;
            formData.append("image_" + cont, element_1);
        }
        return this.http.post(this.baseurl + "api/v1/product/", formData);
    };
    DataService.prototype.UpdateOnlyImgPrd = function (Prd_logo, image1, image2, image3, image4, image5, edit_product_id) {
        var _this = this;
        console.log(Prd_logo, image1, image2, image3, image4, image5, edit_product_id, "Update Img");
        var formData = new FormData();
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        formData.append("product_id", edit_product_id);
        if (Prd_logo == undefined) {
            console.log("no image logo");
        }
        else {
            formData.append("image_1", Prd_logo);
        }
        // img1
        if (image1 == undefined) {
            console.log("no image1");
        }
        else {
            formData.append("image_2", image1);
        }
        // img2
        if (image2 == undefined) {
            console.log("no image2");
        }
        else {
            formData.append("image_3", image2);
        }
        // img3
        if (image3 == undefined) {
            console.log("no image3");
        }
        else {
            formData.append("image_4", image3);
        }
        // img4
        if (image4 == undefined) {
            console.log("no image4");
        }
        else {
            formData.append("image_5", image4);
        }
        // img5
        if (image5 == undefined) {
            console.log("no image5");
        }
        else {
            formData.append("image_6", image5);
        }
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.post(_this.baseurl + "api/v1/product/", formData).subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.deleteInvRow = function (invid) {
        var _this = this;
        console.log(invid);
        // return
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var obj = { "is_active": false };
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.patch(_this.baseurl + "api/v1/productinv/" + invid + "/", obj, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.copyStorefront = function (obj) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.post(_this.baseurl + "api/v1/storefront/clone/", obj, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.addNewSizeChartToStyle = function (id, obj) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append('size_chart', obj.size_chart);
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.put(_this.baseurl + ("api/v1/product/sizechart/" + id + "/"), formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.addInvImage = function (invrowid, img) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append("product_inv", invrowid);
        formData.append("image", img);
        formData.forEach(function (value, key) {
            console.log(key + " " + value);
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.post(_this.baseurl + "api/v1/product/invimage/", formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.deleteImage = function (imgId) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.delete(_this.baseurl + "api/v1/product/invimage/" + imgId + "/", { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.deleteProductPatch = function (id) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        // let obj = {deleted:true};
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.delete(_this.baseurl + "api/v1/productv3/" + id + "/", { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.deleteBundle = function (id) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.delete(_this.baseurl + "api/v1/bundle/" + id + "/", { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.ArrayToString = function (arr) {
        var strReturn = "";
        arr.forEach(function (el) {
            strReturn += el + ',';
        });
        return "";
    };
    DataService.prototype.updateNewInventory = function (id, obj, sfid) {
        var _this = this;
        console.log(id);
        console.log(obj);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        var inventory_obj = {};
        var inventory_items = [];
        obj.forEach(function (inv) {
            inventory_obj = { tag: inv.tag, color: _this.camelString(inv.color), size: _this.camelString(inv.size), quantity: inv.quantity, price: inv.price };
            inv.files.forEach(function (file, index) {
                var cont = index + 1;
                formData.append(inv.tag + '_' + _this.camelString(inv.color) + '_' + _this.camelString(inv.size) + '_' + cont, file);
            });
            console.log(inventory_obj);
            inventory_items.push(inventory_obj);
        });
        console.log(inventory_items);
        formData.append("inventory_items", JSON.stringify(inventory_items));
        formData.append("storefront", sfid);
        formData.forEach(function (value, key) {
            console.log(key + " " + value);
        });
        console.log(JSON.stringify(formData));
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.patch(_this.baseurl + "api/v1/productv3/" + id + "/", formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.getProductTagList = function () {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.get(_this.baseurl + "api/v1/product/tags/", { headers: headers }).pipe(map(function (data) { return data.results; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.getProductSpecialTagList = function () {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.get(_this.baseurl + "api/v1/product/special_tags/", { headers: headers }).pipe(map(function (data) { return data.results; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.getBundleCategoryList = function () {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.get(_this.baseurl + "api/v1/bundle/category/", { headers: headers }).pipe(map(function (data) { return data.results; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.addBundleCategory = function (category) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var obj = { name: category };
        return this.httpClient.post(this.baseurl + "api/v1/bundle/category/", obj, { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.removeBundleCategory = function (id) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        return this.httpClient.delete(this.baseurl + ("api/v1/bundle/category/" + id + "/"), { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.addProductTag = function (tag) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var obj = { name: tag };
        return this.httpClient.post(this.baseurl + "api/v1/product/tags/", obj, { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.removeProductTag = function (id) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        return this.httpClient.delete(this.baseurl + ("api/v1/product/tags/" + id + "/"), { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.addProductSpecialag = function (tag) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var obj = { name: tag };
        return this.httpClient.post(this.baseurl + "api/v1/product/special_tags/", obj, { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.removeProductSpecialTag = function (id) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        return this.httpClient.delete(this.baseurl + ("api/v1/product/special_tags/" + id + "/"), { headers: headers }).pipe(map(function (res) { return res; }));
    };
    DataService.prototype.transform = function (value) {
        var first = value.substr(0, 1).toUpperCase();
        return first + value.substr(1);
    };
    DataService.prototype.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    DataService.prototype.camelString = function (val) {
        var _this = this;
        var colorArray = val.split(',').filter(function (c) { return c != ""; });
        console.log(colorArray);
        var col = "";
        colorArray.forEach(function (c) {
            col += _this.transform(c.trim()) + ",";
            console.log(c);
        });
        console.log(col.slice(0, -1));
        return col.slice(0, -1);
    };
    DataService.prototype.removeSpace = function (val) {
        var arr = val.split(',').filter(function (s) { return s != ""; });
        var str = "";
        arr.forEach(function (element) {
            str += element.trim() + ",";
        });
        return str.slice(0, -1);
    };
    DataService.prototype.makeColorStr = function (colA) {
        var _this = this;
        var col = "";
        colA.forEach(function (c) {
            col += _this.capitalizeFirstLetter(c.trim()) + ",";
            console.log(c);
        });
        console.log(col.slice(0, -1));
        return col.slice(0, -1);
    };
    DataService.prototype.makeSizeStr = function (sizeA) {
        var size = "";
        sizeA.forEach(function (c) {
            size += c.trim().toUpperCase();
            +",";
            console.log(c);
        });
        console.log(size.slice(0, -1));
        return size.slice(0, -1);
    };
    DataService.prototype.openSnackBar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: 2000
        });
    };
    DataService.prototype.addInvCombination = function (obj) {
        var _this = this;
        console.log(obj);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append("product", obj.id);
        formData.append("tag", obj.tag.name);
        formData.append("color", this.capitalizeFirstLetter(obj.color));
        formData.append("size", obj.size);
        formData.append("storefront", obj.storefront);
        formData.append("quantity", obj.quantity);
        formData.append("price", obj.price);
        obj.files.forEach(function (file, index) {
            var cont = index + 1;
            formData.append(obj.tag.name + '_' + _this.capitalizeFirstLetter(obj.color) + '_' + obj.size + '_' + cont, file);
        });
        formData.forEach(function (value, key) {
            console.log(key + " " + value);
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.post(_this.baseurl + "api/v1/productinv/ ", formData, { headers: headers }).pipe(tap(function (data) { return console.log(data); }), map(function (data) { return data; }), catchError(function (error) {
                console.log(error.error.non_field_errors[0]);
                return throwError(error.error.non_field_errors[0]);
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.isAtLeastOneChecked = function (array, property) {
        return array.some(function (item) { return item[property]; });
    };
    DataService.prototype.createMultipleProduct = function (boj) {
        console.log(boj, 'test');
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        // "Authorization",
        //   "JWT" + " " + sessionStorage.getItem("token")
        var formData = new FormData();
        formData.append("title", boj.title);
        formData.append("categories", "" + boj.categories);
        formData.append("code", boj.code);
        formData.append("color", this.makeColorStr(boj.colorArray));
        formData.append("description", boj.description);
        formData.append("product_background_color", boj.product_background_color);
        formData.append("product_text_color", boj.product_text_color);
        formData.append("special_tags", JSON.stringify(boj.specialTags));
        formData.append("multiple_colors", "true");
        formData.append("price", boj.price);
        formData.append("size", boj.sizeArray.toString());
        formData.append("storefront", boj.storefront);
        // formData.append("size_chart", boj.size_chart);
        if (boj.is_featured) {
            formData.append("is_featured", "True");
        }
        else {
            formData.append("is_featured", "False");
        }
        var tagsArr = [];
        boj.typeArray.forEach(function (type) {
            tagsArr.push(type);
        });
        formData.append('tags', JSON.stringify(tagsArr));
        var inventory_obj = {};
        var inventory_items = [];
        boj.productinventory_set.forEach(function (inv, i) {
            inventory_obj = { tag: inv.tag, color: inv.color, size: inv.size, quantity: inv.quantity, price: inv.price };
            inv.files.forEach(function (file, index) {
                var cont = index + 1;
                formData.append(inv.tag + '_' + inv.color + '_' + inv.size + '_' + cont, file);
            });
            console.log(inventory_obj);
            inventory_items.push(inventory_obj);
        });
        console.log(inventory_items);
        formData.append("inventory_items", JSON.stringify(inventory_items));
        boj.size_chart.forEach(function (item) {
            // if(item.chart){
            console.log(item.style, item.chart);
            formData.append(item.style.name, item.chart);
            // }
        });
        formData.forEach(function (value, key) {
            console.log(key + " " + value);
        });
        // for (let index = 0; index < boj.files.length; index++) {
        //   const element = boj.files[index];
        //   let cont = index + 1;
        //   formData.append("image_" + cont, element);
        // }
        return this.httpClient.post(this.baseurl + "api/v1/product/inv/", formData, { headers: headers });
    };
    DataService.prototype.updateProduct2 = function (id, obj) {
        var _this = this;
        console.log(id, obj);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        Object.keys(obj).forEach(function (key) {
            formData.append(key, obj[key]);
        });
        formData.append('special_tags', JSON.stringify(obj.special_tags));
        formData.append('tags', JSON.stringify(obj.tags));
        if (obj.size_chart) {
            formData.append('size_chart', obj.size_chart);
        }
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.patch(_this.baseurl + "api/v1/productv3/" + id + "/", formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.updateInventory = function (id, obj) {
        var _this = this;
        console.log(id, obj);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.patch(_this.baseurl + "api/v1/productinv/" + id + "/", obj, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.createProduct = function (Pname, pcode, Psize, Pdescription, Ptype, Pcolor, SF_id, price, availColr, Prd_logo, image1, image2, image3, image4, image5) {
        var _this = this;
        console.log(availColr, "see image");
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var formData = new FormData();
        formData.append("title", Pname);
        formData.append("code", pcode);
        formData.append("description", Pdescription);
        formData.append("categories", Ptype);
        formData.append("color", Pcolor);
        formData.append("storefront", SF_id);
        formData.append("price", price);
        formData.append("size", Psize);
        formData.append("multiple_colors", availColr);
        // images Logo
        if (Prd_logo == undefined) {
            console.log("no image logo");
        }
        else {
            formData.append("image_1", Prd_logo);
        }
        // img1
        if (image1 == undefined) {
            console.log("no image1");
        }
        else {
            formData.append("image_2", image1);
        }
        // img2
        if (image2 == undefined) {
            console.log("no image2");
        }
        else {
            formData.append("image_3", image2);
        }
        // img3
        if (image3 == undefined) {
            console.log("no image3");
        }
        else {
            formData.append("image_4", image3);
        }
        // img4
        if (image4 == undefined) {
            console.log("no image4");
        }
        else {
            formData.append("image_5", image4);
        }
        // img5
        if (image5 == undefined) {
            console.log("no image5");
        }
        else {
            formData.append("image_6", image5);
        }
        if (Pdescription == undefined) {
            Pdescription = "";
        }
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.post(_this.baseurl + "api/v1/product/", formData).subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    // Update Products images
    DataService.prototype.updateProduct_images = function (img_id, ProductUImg) {
        var _this = this;
        console.log(img_id, "date");
        console.log(ProductUImg, "date");
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("image", ProductUImg);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.baseurl + "api/v1/product/image/" + img_id + "/", formData, options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.deleteImageFromProduct = function (imageid) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.delete(this.baseurl + "api/v1/product/image/" + imageid + "/", options);
    };
    DataService.prototype.updateProduct = function (obj, product_id) {
        //console.log(edit_product_id, 'see id');
        //console.log(Pname, 'see name');
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.patch(this.baseurl + "api/v1/product/detail/" + product_id + "/", obj, { headers: headers });
    };
    DataService.prototype.updateBundle = function (obj, bundle_id) {
        //console.log(edit_product_id, 'see id');
        //console.log(Pname, 'see name');
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        return this.http.patch(this.baseurl + "api/v1/bundle/" + bundle_id + "/", obj, { headers: headers });
    };
    // Delete Product
    DataService.prototype.deleteProduct = function (edit_product_id) {
        var _this = this;
        console.log(edit_product_id, "see id");
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .delete(_this.baseurl + "api/v1/product/detail/" + edit_product_id + "/", options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    // User StoreFront
    DataService.prototype.userStoreFront = function (user_id, product_id, storefront_id) {
        var _this = this;
        // console.log("Do Login");
        var body = {
            user: user_id,
            product: storefront_id,
            storefront: product_id
        };
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        // headers.append("Authorization", "JWT" + " " + localStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/user_storefront/", body, {
                headers: headers
            })
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    // Customer order
    DataService.prototype.custorder = function (user, obj, sf_id) {
        var _this = this;
        var body = {
            data: obj,
            user: user,
            storefront: sf_id
        };
        console.log(user, obj, "user id");
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/cart_create/", body, options)
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    // Get User Order details
    DataService.prototype.getCustOrder = function (userid) {
        var _this = this;
        console.log(userid, "id services");
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/cart_details/user/?user_id=" + userid, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    // Customers List
    DataService.prototype.getCustomers = function (page) {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        console.log(sessionStorage.getItem("token"), "token");
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.get(_this.baseurl + "api/v1/list/?" + page, options).subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    // Get Customers details to edit it,on customer Id
    DataService.prototype.editCustomer = function (cust_id) {
        var _this = this;
        console.log(cust_id, "see id");
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        //formData.append("folder_name",fold_name)
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/userdetail/" + cust_id + "/", options)
                .subscribe(function (success) {
                //console.log('success');
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.getStoreFront = function (cust_id) {
        console.log(cust_id, "see id");
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        //headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        //formData.append("folder_name",fold_name)
        // console.log(searchstring)
        return this.http.get(this.baseurl + "api/v1/user_strorefront_v2/?user_id=" + cust_id, options);
    };
    // Get storefront details to edit it,on customer Id
    DataService.prototype.editStorefront = function (store_id) {
        var _this = this;
        console.log(store_id, "see id");
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        //formData.append("folder_name",fold_name)
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.baseurl + "api/v1/storefront/detail/" + store_id + "/", options)
                .subscribe(function (success) {
                //console.log('success');
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.sortCustlist = function (status_selected) {
        var _this = this;
        console.log(status_selected);
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/list/?status=" + status_selected, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.sortProductType = function (prodct_type, SF_id) {
        var _this = this;
        console.log(prodct_type);
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl +
                "api/v1/product/?category=" +
                prodct_type +
                "&storefront_id=" +
                SF_id, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.clothProduct = function (SF_id) {
        var _this = this;
        var prodct_type = "Clothing";
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl +
                "api/v1/product/?category=" +
                prodct_type +
                "&storefront_id=" +
                SF_id, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');image_1
            });
        });
        return returnobj;
    };
    DataService.prototype.accessoriesProduct = function (SF_id) {
        var _this = this;
        var prodct_type = "Accessories";
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl +
                "api/v1/product/?category=" +
                prodct_type +
                "&storefront_id=" +
                SF_id, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.paperProduct = function (SF_id) {
        var _this = this;
        var prodct_type = "Paper";
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl +
                "api/v1/product/?category=" +
                prodct_type +
                "&storefront_id=" +
                SF_id, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.addBundle = function (obj, img) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append('title', obj.title);
        formData.append('code', obj.code);
        formData.append('description', obj.description);
        formData.append('price', obj.price);
        formData.append('storefront', obj.storefront);
        formData.append('bundle_items', JSON.stringify(obj.bundle_items));
        if (img != undefined) {
            formData.append('preview_image', img);
        }
        return this.httpClient.post(this.baseurl + "api/v1/bundle/inv/", formData, { headers: headers });
    };
    DataService.prototype.getProduct = function (storesfront_id) {
        var _this = this;
        console.log(storesfront_id);
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/storefront/detail/" + storesfront_id + "/", options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.login = function (username, password) {
        var _this = this;
        // console.log("Do Login");
        var body = {
            username: username,
            password: password
        };
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        // headers.append("Authorization", "JWT" + " " + localStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "rest-auth/login/", body, { headers: headers })
                .subscribe(function (success) {
                returnobj = success.json();
                resolve(returnobj);
            }, function (error) {
                alert("Incorrect Username Or Password");
                console.log("error");
                //resolve(returnobj);
                //alert('Incorrect Username or Password');
            }, function () {
                //console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.veriftyURl = function (SFurlHint, cid) {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("storefront_url", SFurlHint);
        formData.append("customer_id", cid);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/storefront/slug/", formData, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.payStripe = function (obj) {
        var _this = this;
        console.log(obj, "obj");
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/orders/", obj, { headers: headers })
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.getOrders = function () {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        //console.log( sessionStorage.getItem('token'),'token');
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.get(_this.baseurl + "api/v1/orderlist/", options).subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.getOrdersV2 = function () {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + "api/v1/orderlist/", options);
    };
    // Get storefront names
    DataService.prototype.getSFname = function () {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        //console.log( sessionStorage.getItem('token'),'token');
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/storefrontnames/", options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.filterOrders = function () {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        // requestOptions.search = params;
        requestOptions.headers = headers;
        console.log(requestOptions);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderfilter/", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.postTrackdetails = function (couriertype, order_status, estimatedD, informCust, trackid, exptedD, delievryNotes, thiProdelivered, order_id) {
        var _this = this;
        console.log(order_status, "stat");
        console.log(order_id, "ids");
        var body;
        if (order_status == 1) {
            console.log(order_status, "second");
            body = {
                status: order_status,
                delivery_date: estimatedD,
                inform_customer: informCust,
                order: order_id
            };
        }
        if (order_status == 2) {
            body = {
                status: order_status,
                inform_customer: informCust,
                delivery_service: couriertype,
                departure: exptedD,
                notes: delievryNotes,
                tracking_id: trackid,
                order: order_id
            };
        }
        if (order_status == 3) {
            body = {
                status: order_status,
                inform_customer: informCust,
                is_delivered: true,
                order: order_id
            };
        }
        console.log(body, "hello");
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/shipment/", body, { headers: headers })
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    // Update Or patch track
    DataService.prototype.patchTrackdetails = function (couriertype, order_status, estimatedD, informCust, trackid, exptedD, delievryNotes, thiProdelivered, order_id) {
        var _this = this;
        console.log(informCust, "stat");
        console.log(order_id, "ids");
        var body;
        if (order_status == 1) {
            console.log(order_status, "second");
            body = {
                status: order_status,
                delivery_date: estimatedD,
                inform_customer: informCust,
                order: order_id
            };
        }
        if (order_status == 2) {
            body = {
                status: order_status,
                inform_customer: informCust,
                delivery_service: couriertype,
                departure: exptedD,
                notes: delievryNotes,
                tracking_id: trackid,
                order: order_id
            };
        }
        if (order_status == 3) {
            body = {
                status: order_status,
                inform_customer: informCust,
                is_delivered: true,
                order: order_id
            };
        }
        console.log(body, "hello");
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .patch(_this.baseurl + "api/v1/shipment/detail/" + order_id + "/", body, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.getTrackdetails = function (order_id) {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        //console.log( sessionStorage.getItem('token'),'token');
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/shipment/detail/" + order_id + "/", options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //  resolve(returnobj);
                //  return error;
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.sendemail = function (order_id, obj) {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        formData.append("order_id", order_id);
        formData.append("receipt", obj);
        //formData.append("order_file", obj);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/ordermail/", formData, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.reportsOrder = function (cust_id) {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderfilter/?customer_id=" + cust_id + "", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.filterSf = function (params) {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.search = params;
        requestOptions.headers = headers;
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderfilter/", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.filterSatus = function (params) {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.search = params;
        requestOptions.headers = headers;
        // let headers = new Headers();
        //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        // let requestOptions = new RequestOptions();
        // requestOptions.headers = headers;
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderfilter/", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    // For Excel filter response
    DataService.prototype.excelReponseFilter = function (params) {
        var _this = this;
        var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.search = params;
        requestOptions.headers = headers;
        // let headers = new Headers();
        //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        // let requestOptions = new RequestOptions();
        // requestOptions.headers = headers;
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderproducts/", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.BulkstatusUpdate = function (ids, status) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        var body = {
            order_id: ids,
            status: status
        };
        return this.http.post(this.baseurl + "api/v1/orderupdate/", body, options);
    };
    // Customer (Product Purchaser) Registration
    DataService.prototype.CustRegistration = function (username, email, pwd) {
        var _this = this;
        console.log(username, email, pwd);
        var headers = new Headers();
        // headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        // formData.append("order_id", ids);
        // formData.append("status", status);
        var body = {
            username: username,
            email: email,
            password_text: pwd
        };
        //formData.append("order_file", obj);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/usercreate/", body, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    // Customer Login (Product Purchaser)
    DataService.prototype.CustLogin = function (pwd, email) {
        var _this = this;
        console.log(email, pwd);
        var headers = new Headers();
        // headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        var options = new RequestOptions({ headers: headers });
        var formData = new FormData();
        // formData.append("order_id", ids);
        // formData.append("status", status);
        var body = {
            email: email,
            password_text: pwd
        };
        //formData.append("order_file", obj);
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .post(_this.baseurl + "api/v1/customervalidate/", body, options)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.getTags = function () {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.get(_this.baseurl + "api/v1/tagslist/", options).subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.addTag = function (tags) {
        console.log(tags);
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            name: tags
        };
        return this.http.post(this.baseurl + "api/v1/tagslist/", body, options);
    };
    //  Add tags to the order id
    DataService.prototype.postTagsOrder = function (orderId, tags) {
        console.log(orderId, tags);
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            order_ids: orderId,
            tag_ids: tags
        };
        //formData.append("order_file", obj);
        return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
    };
    //  Add Archive to the order id
    DataService.prototype.addArchive = function (orderId, archives) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            archive_order_ids: orderId,
            is_archived: archives
        };
        return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
    };
    // Create Chain
    DataService.prototype.createChain = function (obj) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            storefront_data: obj
        };
        return this.http.post(this.baseurl + "api/v1/multiple_storefront/", body, options);
    };
    //  for filter customer and storefront ids
    DataService.prototype.get_Custom_SF_Data = function () {
        var _this = this;
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var returnobj = new Promise(function (resolve, reject) {
            _this.http.get(_this.baseurl + "api/v1/userstorefront/", options).subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.OrdersFilters = function (params) {
        var _this = this;
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.search = params;
        requestOptions.headers = headers;
        // let headers = new Headers();
        //  headers.append("Authorization", "JWT" + " " + sessionStorage.getItem('token'));
        // let requestOptions = new RequestOptions();
        // requestOptions.headers = headers;
        var returnobj = new Promise(function (resolve, reject) {
            _this.http
                .get(_this.baseurl + "api/v1/orderfilter/", requestOptions)
                .subscribe(function (data) {
                returnobj = data.json();
                resolve(returnobj);
            }, function (error) {
                console.log("error1");
                //resolve(returnobj);
            }, function () {
                // console.log('completed');
            });
        });
        return returnobj;
    };
    DataService.prototype.orderFilterV2 = function (params) {
        // tslint:disable-next-line: deprecation
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        // tslint:disable-next-line: deprecation
        var requestOptions = new RequestOptions();
        requestOptions.params = params;
        requestOptions.headers = headers;
        return this.http.get(this.baseurl + "api/v1/orderfilter/", requestOptions);
    };
    DataService.prototype.getOrdersReport = function (obj) {
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token"),
            'Content-Type': "text/plain"
        });
        var body = new HttpParams({ fromObject: obj });
        var httpOptions = {
            headers: headers,
            params: body,
            responseType: 'blob'
        };
        console.log(body);
        return this.httpClient.get(this.baseurl + "api/v1/ordercsv/", httpOptions);
    };
    DataService.prototype.getAllStoreFronts = function () {
        return this.http.get(this.baseurl + "api/v1/userstorefront/");
    };
    DataService.prototype.getCommon = function (url) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + url, options);
    };
    DataService.prototype.getCommonWithNextPage = function (url, pagenumber) {
        var params = new URLSearchParams();
        params.append("page", pagenumber);
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        options.search = params;
        options.headers = headers;
        return this.http.get(this.baseurl + url, options);
    };
    DataService.prototype.getSingleCustomer = function (id) {
        var params = new URLSearchParams();
        params.append("cust_id", id);
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.search = params;
        requestOptions.headers = headers;
        return this.http.get(this.baseurl + "api/v1/userlist/", requestOptions);
    };
    DataService.prototype.getPasscodeprostorefront = function (passcode) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.post(this.baseurl + "api/v1/storefront/login/", { passcode: passcode }, requestOptions);
    };
    DataService.prototype.deleteSffromProduct = function (obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.post(this.baseurl + "api/v1/storefront/product/delete/", obj, requestOptions);
    };
    DataService.prototype.addSftoProduct = function (obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.post(this.baseurl + "api/v1/storefront/product/", obj, requestOptions);
    };
    DataService.prototype.toggleProductStatus = function (obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.post(this.baseurl + "api/v1/storefront/product/", obj, requestOptions);
    };
    DataService.prototype.toggleBundleStatus = function (bundleId, obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        // return true
        return this.http.patch(this.baseurl + "api/v1/bundle/" + bundleId + "/", obj, requestOptions);
    };
    DataService.prototype.toggleProductInStoreStatus = function (obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.post(this.baseurl + "api/v1/storefront/product/active/", obj, requestOptions);
    };
    DataService.prototype.toggleStoreStatus = function (store_id, obj) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.patch(this.baseurl + "api/v1/storefront/detail/" + store_id + "/", obj, requestOptions);
    };
    DataService.prototype.getSingleProduct = function (product_id) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.get(this.baseurl + "api/v1/productv3/" + product_id + "/", requestOptions);
    };
    DataService.prototype.getSingleBundle = function (bundle_id) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.get(this.baseurl + "api/v1/bundle/" + bundle_id + "/", requestOptions);
    };
    DataService.prototype.updateSingleBundle = function (id, obj) {
        var _this = this;
        console.log(id, obj);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var formData = new FormData();
        formData.append('title', obj.title);
        formData.append('code', obj.code);
        formData.append('description', obj.description);
        formData.append('price', obj.price);
        // formData.append('storefront', obj.storefront)
        formData.append('bundle_items', JSON.stringify(obj.bundle_items));
        console.log(typeof obj.preview_image);
        if (typeof obj.preview_image != 'string') {
            formData.append('preview_image', obj.preview_image);
        }
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.patch(_this.baseurl + "api/v1/bundle/" + id + "/", formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.getSingleStore = function (store_id) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var requestOptions = new RequestOptions();
        requestOptions.headers = headers;
        return this.http.get(this.baseurl + "api/v1/storefront/detail/" + store_id + "/", requestOptions);
    };
    DataService.prototype.removeTag = function (tag, order) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            order_ids: order,
            tag_ids_remove: tag
        };
        return this.http.post(this.baseurl + "api/v1/tagbulk/", body, options);
    };
    //Sort Common Function
    DataService.prototype.dynamicSort = function (property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var val1 = parseFloat(a[property])
                ? parseFloat(a[property])
                : parseFloat(a[property]) === 0
                    ? 0
                    : a[property];
            var val2 = parseFloat(b[property])
                ? parseFloat(b[property])
                : parseFloat(b[property]) === 0
                    ? 0
                    : b[property];
            var result = val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
            return result * sortOrder;
        };
    };
    //Delete
    DataService.prototype.deleteCustomer = function (id) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        var body = {
            deleted_v1: true
        };
        return this.http.patch(this.baseurl + "api/v1/userdetail/" + id + "/", body, options);
    };
    DataService.prototype.deleteStore = function (id) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        // let body = {
        //   deleted: true
        // };
        return this.http.delete(this.baseurl + "api/v1/storefront/delete/" + id + "/", options);
    };
    DataService.prototype.createCoupon = function (obj) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        // tslint:disable-next-line: deprecation
        var options = new RequestOptions({ headers: headers });
        return this.http.post(this.baseurl + "api/v1/coupon/", obj, options);
    };
    DataService.prototype.updateCoupon = function (obj, id) {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        // tslint:disable-next-line: deprecation
        var options = new RequestOptions({ headers: headers });
        return this.http.patch(this.baseurl + ("api/v1/coupondetail/" + id + "/"), obj, options);
    };
    DataService.prototype.couponNameValidityCheck = function (coupon_name) {
        // debounce
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + "api/v1/couponname/?coupon_name=" + coupon_name, options);
    };
    DataService.prototype.getCoupons = function () {
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        var options = new RequestOptions({ headers: headers });
        return this.http.get(this.baseurl + 'api/v1/coupon/', options);
    };
    DataService.prototype.patchCoupon = function (couponId, obj) {
        // tslint:disable-next-line: deprecation
        var headers = new Headers();
        headers.append("Authorization", "JWT" + " " + sessionStorage.getItem("token"));
        // tslint:disable-next-line: deprecation
        var options = new RequestOptions({ headers: headers });
        return this.http.patch(this.baseurl + ("api/v1/coupondetail/" + couponId + "/"), obj, options);
    };
    DataService.prototype.getInventory = function (prodId) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.get(_this.baseurl + ("api/v1/productv3/" + prodId + "/"), { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject(err);
            });
        });
        return promise;
    };
    DataService.prototype.uploadBannerImages = function (sfId, image) {
        var _this = this;
        var formData = new FormData();
        formData.append("storefront", sfId);
        formData.append("image", image);
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.post(_this.baseurl + "api/v1/storefront/banner/ ", formData, { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.removeBannerImage = function (id) {
        var _this = this;
        var headers = new HttpHeaders({
            'Authorization': 'JWT ' + sessionStorage.getItem("token")
        });
        var promise = new Promise(function (resolve, reject) {
            return _this.httpClient.delete(_this.baseurl + "api/v1/storefront/banner/" + id + "/", { headers: headers }).pipe(map(function (data) { return data; }), catchError(function (error) {
                return throwError('something went wrong');
            }))
                .toPromise().then(function (res) {
                console.log(res);
                resolve(res);
            }, function (err) {
                console.log(err);
                reject();
            });
        });
        return promise;
    };
    DataService.prototype.isImage = function (type) {
        return type.startsWith('image/');
    };
    DataService.prototype.isPDF = function (type) {
        return type === 'application/pdf';
    };
    DataService.prototype.isImageFromBackend = function (filePath) {
        return filePath.toLowerCase().endsWith('.jpg') ||
            filePath.toLowerCase().endsWith('.jpeg') ||
            filePath.toLowerCase().endsWith('.png');
    };
    DataService.prototype.isPdfFromBackend = function (filePath) {
        return filePath.toLowerCase().endsWith('.pdf');
    };
    return DataService;
}());
export { DataService };
