import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { m_customers_obj } from '../models';

@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.css']
})
export class DraftComponent implements OnInit {

  selectedIndex = 0;
  draftData :any;
  mainData :any;
  displayedColumns: string[] = ['position', 'name', 'phone', 'email'];
  displayedColumnsSf: string[] = ['position', 'name'];
 


  constructor(private service:DataService,private router :Router) { }

  ngOnInit() {
    this.getCustomerDraft();
  }

  onTabChange($event) {
    console.log($event);
    switch ($event.index) {
      case 0 :
        console.log("cust");
        this.mainData = this.draftData.filter(data => data.entity_type == "customer")

        break;
      case 1 :
        console.log("sf");
        this.mainData = this.draftData.filter(data => data.entity_type == "storefront")
        break;
      case 2 :
        console.log("products");
        this.mainData = this.draftData.filter(data => data.entity_type == "product")

        break;
    }
    
  }

  customerClicked(row){
    console.log(row);
    this.service.draftCustomer = row;
    this.router.navigate(['/addcustomer']);
  }
  sfClicked(row){
    console.log(row);
    this.service.draftStorefront = row;
    this.router.navigate(['/addstorefront/' + row.data.user]);
  }

  getCustomerDraft(){
    this.service.getCustomerDraft().then(
      res => {
        console.log(res);
        this.draftData =  res;
        this.mainData = this.draftData.filter(data => data.entity_type == "customer")
      }

    )
  }

}
