<!-- <button mat-button class="bt1" (click)="paginate(i)">{{i+1}}</button> -->
<ng-container *ngIf="dataservice.inmemoryData.length > 0">

  <ng-container *ngIf="dataservice.inmemoryData[urlindex]">

    <ul class="pagination" *ngFor="let item of dataservice.inmemoryData[urlindex].multicollectionarry; let i=index">
      <li><a class="paginates font-fmly" [ngClass]="{'highlight' : indexs === i}" (click)="paginate(i)">{{i+1}}</a></li>
      
    </ul>
  </ng-container>
</ng-container>