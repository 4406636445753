<div class="main" *ngIf="showForm">
  <!-- <mat-list>
      <h3 matSubheader>{{customer_obj.name}}</h3>
      <mat-list-item *ngFor="let item of storefrontlist">
         <mat-icon matListIcon>store</mat-icon>
         <h3 matLine> {{item.name}} </h3>
         <p matLine> {{item.status}} </p>

         <div matLine> <a mat-raised-button color="basic" target="_blank" routerLink="/{{item.slug}}">Preview Store
               Front</a> <button mat-raised-button color="primary">Edit</button> </div>

      </mat-list-item>
   </mat-list> -->
  <app-breadcrumb></app-breadcrumb>

  <mat-card class="cards">
    <mat-toolbar class="subtitle font-fmly"
      >{{ customer_obj.name }} 's Storefront</mat-toolbar
    ><br />
    <table class="table table-hover table-responsive">
      <thead class="headers font-fmly">
        <tr>
          <th class="ths">Storefront Name</th>
          <th class="ths text-center">Passcode</th>
          <th class="ths text-center">Status</th>

          <th class="ths text-center">Preview</th>
          <th class="ths text-center">Add Product</th>
          <th class="ths text-center"></th>
          <th class="ths text-center">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr class="trs" *ngFor="let item of storefrontlist; let i = index">
          <td class="text-left">{{ item.name }}</td>
          <td class="trs">{{ item.passcode }}</td>
          <td class="trs">
            {{ item.status | uppercase }}
          </td>
          <td class="trs">
            <button mat-raised-button color="primary" (click)="prev(i, item)">
              Preview
            </button>
          </td>

          <td class="trs">
            <button mat-mini-fab color="primary" (click)="gotoAddProduct(item)">
              <mat-icon>add</mat-icon>
            </button>
          </td>
          <td>
            <button
              mat-raised-button
              color="warn"
              *ngIf="item.status === 'active'"
              (click)="toggleStoreFrontStatus(item, 'archive', i)"
            >
              Archive
            </button>
            <button
              mat-raised-button
              color="primary"
              *ngIf="item.status !== 'active'"
              (click)="toggleStoreStatusData(item, 'active', i)"
            >
              Activate
            </button>
          </td>
          <td class="trs" class="editsf">
            <button mat-raised-button color="primary" (click)="edit(item)">
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <button
    mat-fab
    color="primary"
    class="btn-cust"
    (click)="gotoAddStoreFront()"
    matTooltip="Add StoreFront"
    [matTooltipPosition]="'left'"
  >
    <mat-icon class="icons">add</mat-icon>
  </button>
</div>
