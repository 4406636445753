import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
var EditBundleComponent = /** @class */ (function () {
    function EditBundleComponent(dialog, route, router, dataservice, formBuilder) {
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.dataservice = dataservice;
        this.formBuilder = formBuilder;
        this.displayedColumns = ['title', 'Count', 'products'];
        this.bundleCategories = [];
        this.bundleCategoriesControl = new FormControl();
        this.category_count = new FormControl();
        this.products = [];
        this.imagePreview = null;
        this.isLoading = false;
    }
    EditBundleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.bundleId = params['bundle_id'];
        });
        console.log(this.bundleId);
        this.getBunlde(this.bundleId);
    };
    EditBundleComponent.prototype.getBundleCategoryList = function () {
        var _this = this;
        this.dataservice.getBundleCategoryList().then(function (res) {
            console.log(res);
            _this.bundleCategories = res;
            console.log(_this.categoryNamesObj);
            var selected = _this.bundleCategories.filter(function (category) {
                return _this.categoryNamesObj.some(function (obj) { return obj.name.trim().toLowerCase() === category.name.trim().toLowerCase(); });
            });
            _this.bundleCategoriesControl.setValue(selected);
        });
    };
    EditBundleComponent.prototype.getBunlde = function (id) {
        var _this = this;
        this.dataservice.getSingleBundle(id).subscribe(function (bundle) {
            console.log(bundle.json());
            _this.bundleObj = bundle.json();
            _this.initializeForm();
            _this.categoryNamesObj = _this.getCategoryNames(_this.bundleObj.bundleinventory_set);
            _this.getBundleCategoryList();
            _this.getProductsOfSf();
        });
    };
    EditBundleComponent.prototype.getProductsOfSf = function () {
        var _this = this;
        this.dataservice.getProduct(this.bundleObj.storefront).then(function (product) {
            console.log(product.storefront_product);
            _this.products = product.storefront_product;
            console.log(_this.products);
            _this.setProductsInCatObject();
        });
    };
    EditBundleComponent.prototype.setProductsInCatObject = function () {
        var _this = this;
        this.bundleProductsControls = this.formBuilder.array([]);
        this.categoryNamesObj.forEach(function (element) {
            var selected = _this.products.filter(function (prod) {
                return element.products.some(function (product) {
                    return product.title.trim().toLowerCase() === prod.title.trim().toLowerCase();
                });
            });
            _this.bundleProductsControls.push(_this.formBuilder.control(selected));
        });
        console.log(this.bundleProductsControls.value);
    };
    EditBundleComponent.prototype.getFormControl = function (index) {
        return this.bundleProductsControls.at(index);
    };
    EditBundleComponent.prototype.onSelectProducts = function (e, i) {
        console.log(e.value, i);
        var selectedProducts = e.value;
        var index = this.categoryNamesObj.findIndex(function (item) { return item.id === i; });
        this.categoryNamesObj[index]['products'] = selectedProducts;
    };
    EditBundleComponent.prototype.onCategoryCountChange = function (id) {
        console.log(id);
        this.categoryNamesObj[id]['count'] = this.category_count.value;
    };
    // getCategoryNames(bundleInventorySet: any[]): string[] {
    //   const categoryNames = [];
    //   const category_set = [];
    //   for (const item of bundleInventorySet) {
    //     const categoryName = item.category_name;
    //     if (!category_set.includes(categoryName)) {
    //       category_set.push(categoryName)
    //       categoryNames.push({ name: categoryName, count: item.category_count});
    //     }
    //   }
    //   console.log(categoryNames);
    //   return categoryNames;
    // }
    EditBundleComponent.prototype.getCategoryNames = function (bundleInventorySet) {
        var categoryNames = [];
        var category_set = new Map();
        for (var _i = 0, bundleInventorySet_1 = bundleInventorySet; _i < bundleInventorySet_1.length; _i++) {
            var item = bundleInventorySet_1[_i];
            var catId = item.bundle_category;
            var categoryName = item.category_name;
            var categoryCount = item.category_count;
            var product = item.product_data; // Assuming this is the product data you want to include
            if (!category_set.has(categoryName)) {
                category_set.set(categoryName, { id: catId, name: categoryName, count: categoryCount, products: [product] });
            }
            else {
                var existingCategory = category_set.get(categoryName);
                existingCategory.products.push(product);
            }
        }
        category_set.forEach(function (value) { return categoryNames.push(value); });
        console.log(categoryNames);
        return categoryNames;
    };
    EditBundleComponent.prototype.onSelectCategories = function (event) {
        var _this = this;
        console.log(event.value);
        // this.selectedCategories = event.value;
        //   this.bundleCategoriesControl.setValue(selectedCategories);
        var selectedCategories = event.value;
        selectedCategories.forEach(function (cat) {
            var categoryExists = _this.categoryNamesObj.some(function (element) { return element.id === cat.id; });
            if (!categoryExists) {
                var newobj = {
                    id: cat.id,
                    name: cat.name,
                    count: 1,
                    products: []
                };
                _this.categoryNamesObj = _this.categoryNamesObj.concat([newobj]);
            }
            else {
                // console.log('remove');
            }
        });
        // this.categoryNamesObj = this.categoryNamesObj.filter(cat => {
        //   return selectedCategories.some(element => element.id === cat.id);
        // });
        this.updateCategoriesAndProducts(selectedCategories);
        // let newCategoryNamesObj = [];
        // this.categoryNamesObj.forEach((cat) => {
        //   let categoryExists = selectedCategories.some(element => element.id === cat.id);
        //   if (categoryExists) {
        //     newCategoryNamesObj.push(cat);
        //   } else {
        //     console.log('remove');
        //   }
        // });
        // this.categoryNamesObj = newCategoryNamesObj;
        console.log(this.categoryNamesObj);
    };
    EditBundleComponent.prototype.updateCategoriesAndProducts = function (selectedCategories) {
        var _this = this;
        var newCategoryNamesObj = [];
        var newBundleProductsControls = this.formBuilder.array([]);
        this.categoryNamesObj.forEach(function (cat) {
            var categoryExists = selectedCategories.some(function (element) { return element.id === cat.id; });
            if (categoryExists) {
                newCategoryNamesObj.push(cat);
                var selected = _this.products.filter(function (prod) {
                    return cat.products.some(function (product) {
                        return product.title.trim().toLowerCase() === prod.title.trim().toLowerCase();
                    });
                });
                newBundleProductsControls.push(_this.formBuilder.control(selected));
            }
            else {
                console.log('remove');
            }
        });
        this.categoryNamesObj = newCategoryNamesObj;
        this.bundleProductsControls = newBundleProductsControls;
        console.log(this.bundleProductsControls.value);
    };
    EditBundleComponent.prototype.fileupload = function (event) {
        var _this = this;
        var file = event.target.files[0];
        if (file) {
            this.formGroup.patchValue({ preview_image: file });
            this.formGroup.get('preview_image').updateValueAndValidity();
            // Preview the selected image
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                _this.imagePreview = reader_1.result;
            };
            reader_1.readAsDataURL(file);
        }
    };
    EditBundleComponent.prototype.resetImage = function () {
        this.formGroup.patchValue({ preview_image: this.bundleObj.preview_image });
        this.formGroup.get('preview_image').updateValueAndValidity();
        this.imagePreview = this.formGroup.get('preview_image').value;
        console.log(this.formGroup.get('preview_image').value);
    };
    EditBundleComponent.prototype.initializeForm = function () {
        this.formGroup = this.formBuilder.group({
            title: [this.bundleObj.title, Validators.required],
            description: [this.bundleObj.description],
            code: [this.bundleObj.code, Validators.required],
            price: [this.bundleObj.price, Validators.required],
            preview_image: [this.bundleObj.preview_image, Validators.required]
        });
    };
    EditBundleComponent.prototype.showMsgDialog = function (title, msg, type) {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: title,
                content: msg,
                type: type,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            location.reload();
        });
    };
    EditBundleComponent.prototype.updateBundle = function () {
        var _this = this;
        // First, define the bundleItems array
        var bundleItems = [];
        // Then, iterate through the categories
        this.categoryNamesObj.forEach(function (category) {
            // Iterate through the products of each category
            category.products.forEach(function (product) {
                // Create a bundleItem object and push it to the bundleItems array
                bundleItems.push({
                    bundle_category: category.id,
                    product: product.id,
                    category_count: category.count
                });
            });
        });
        var obj = {
            'title': this.formGroup.get('title').value,
            'code': this.formGroup.get('code').value,
            'description': this.formGroup.get('description').value,
            'price': this.formGroup.get('price').value,
            'bundle_items': bundleItems,
            'preview_image': this.formGroup.get('preview_image').value,
        };
        console.log(obj);
        this.isLoading = true;
        this.dataservice.updateSingleBundle(this.bundleId, obj).then(function (result) {
            _this.isLoading = false;
            _this.showMsgDialog('Success', 'Bundle has been successfully updated.', 'confirmation');
        }).catch(function (error) {
            _this.isLoading = false;
            _this.showMsgDialog('error', 'Something went wrong!', 'error');
        });
    };
    return EditBundleComponent;
}());
export { EditBundleComponent };
