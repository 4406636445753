<div class="main">
  <mat-toolbar style="justify-content: space-between;">
    <h3 class="page-heading">Bundles</h3>

    <div>
      <input class="input-style" placeholder="Search" [(ngModel)]="searchText"
        (keyup)="sortDataList($event.target.value)" />
      <mat-form-field style="margin-right: 20px">
        <mat-label style="font-size: 16px">Sort By</mat-label>
        <mat-select [(ngModel)]="selectedSort" style="font-size: 16px">
          <mat-option *ngFor="let item of sortParams" [value]="item.value" (click)="sortDataDropDown()">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup"
        [value]="selectedVal" (change)="onValChange(group.value)">
        <mat-button-toggle value="grid">
          <mat-icon class="example-icon">view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
          <mat-icon class="example-icon">view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </mat-toolbar>
  <div class="mess">
    <!-- Grid view -->
    <div class="cards-div" *ngIf="selectedVal === 'grid'">
      <div class="product-cat-grid">
        <div *ngFor="let item of (product_collection | productName: searchText)">
          <mat-card class="product-card">
            <mat-card-header>
              <mat-icon class="edit-btn icon-black" (click)="editProd(item)" matTooltip="Edit"
                [matTooltipPosition]="'above'">
                edit
              </mat-icon>
              <div class="image-tag" style="background-size: cover; border-radius: 6px;background-size: contain;"
                [style.background-image]="getImage2(item.preview_image)"></div>
              <mat-card-title class="titles font-fmly">{{ item.title }}</mat-card-title>
              <mat-card-subtitle>{{ item.description }}</mat-card-subtitle>
            </mat-card-header>
            <div class="image-tag" style="background-size: cover; border-radius: 6px;background-size: contain;"
              [style.background-image]="getImage2(item.image)"></div>
            <mat-card-content>
              <div class="details">
                <span class="detail-title">Code: </span>
                <span>{{ item.code }}</span>
              </div>
              <div class="details">
                <span class="detail-title">Price: </span>
                <span>${{ item.price }}</span>
              </div>
              <div class="details">
                <span class="detail-title">Is Featured: </span>
                <span>{{ item.is_featured }}</span>
              </div>
              <div class="details">
                <span class="detail-title">Active: </span>
                <span>{{ item.active }}</span>
              </div>
              <div class="details">
                <span class="detail-title">StoreFront: </span>
                <span>{{ item.storefront_name }}</span>
              </div>
            </mat-card-content>
            <mat-card-actions class="text-center flex space-evenly align-items" style="height: 63px;">
              <button mat-button (click)="openAddDialog(item)" matTooltip="Add To Storefronts"
                [matTooltipPosition]="'above'" class="product-card-grid-icon">
                <mat-icon class="product-card-grid-icon-store">store</mat-icon>
                <mat-icon class="product-card-grid-icon-plus">exposure_plus_1</mat-icon>
              </button>
              <!-- <mat-slide-toggle color="primary" [(ngModel)]="item.active" (change)="onToggle($event, item.id)"></mat-slide-toggle> -->
              <mat-slide-toggle [matTooltip]="item.active ? 'Inactive' : 'Active'" color="primary"
                [(ngModel)]="item.active" (change)="onToggle($event,item.id)">
              </mat-slide-toggle>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <!-- End grid view -->

    <!-- List view -->
    <div *ngIf="selectedVal === 'list'">
      <table class="table-list table-striped" matSort (matSortChange)="sortData($event)">
        <thead>
          <tr class="row-header font-fmly">
            <th mat-sort-header="id"><b>Id</b></th>
            <th mat-sort-header="preview_image">Image</th>
            <th mat-sort-header="title">Title</th>
            <th mat-sort-header="description">Description</th>
            <th mat-sort-header="code">Code</th>
            <th mat-sort-header="price">Price</th>
            <th mat-sort-header="is_featured">Featured</th>
            <th mat-sort-header="active">Status</th>
            <th mat-sort-header="storefront">StoreFront</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of (product_collection | productName: searchText);let i = index">
            <td (click)="editProd(item)">{{ item.id }}</td>
            <td (click)="editProd(item)">
              <img style="height: 50px; width: 50px;object-fit: cover;border-radius: 50%;" src="{{item.preview_image}}"
                onError="this.src='assets/images/deaault_store_img.png';" alt="">
            </td>
            <td (click)="editProd(item)">{{ item.title }}</td>
            <td (click)="editProd(item)">{{ item.description }}</td>
            <td (click)="editProd(item)">{{ item.code }}</td>
            <td (click)="editProd(item)">${{ item.price }}</td>
            <td>
              <mat-checkbox [checked]="item.is_featured" (change)="onChange($event, i, item)"></mat-checkbox>
            </td>
            <td>
              <mat-slide-toggle [matTooltip]="item.active ? 'Inactive' : 'Active'" color="primary"
                [(ngModel)]="item.active" (change)="onToggle($event,item.id)">
              </mat-slide-toggle>
            </td>
            <!--<td (click)="editProd(item)">{{ item.storefront_name }}</td>-->
            <td style="max-width: 200px;">
              <mat-chip-list #chipList id="style-3">
                <mat-chip style="flex: none;align-self: center;cursor: pointer;" [matTooltip]="itemx.user"
                  [matTooltipPosition]="'above'" *ngFor="let itemx of item.storefront_bundle"
                  (removed)="removeSf(itemx.name, itemx.id, item.title, item.id)">
                  {{ itemx.name }}

                  <button style="background-color: transparent;border:none;display: flex;
                  justify-content: center;" (click)="previewStore(itemx,item,$event)">
                    <mat-icon style="width: 20px;
                    height: 20px;
                    font-size: 20px;
                    margin: 0 5px;" class="product-card-table-i product-card-table-delete-i" matTooltip="Preview store"
                      [matTooltipPosition]="'above'">
                      store
                    </mat-icon>
                  </button>
                  <div [ngClass]="itemx.is_active? 'activeInStore' : 'inActiveInStore'"
                    [matTooltip]="itemx.is_active ? 'Available in store' : 'Unavailable in store'"
                    [matTooltipPosition]="'above'"></div>

                  <mat-icon class="icon-black" (click)="goToStorefront(itemx.id)" matTooltip="Edit"
                    [matTooltipPosition]="'above'">
                    edit
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </td>
            <td>
              <button mat-icon-button (click)="editProd(item)" matTooltip="Edit" [matTooltipPosition]="'above'">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteProduct($event, item.id, i)" matTooltip="Delete"
                [matTooltipPosition]="'above'">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- End list view -->

    <div class="col-sm-60 text-center paginat">
      <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
    </div>

    <button mat-fab color="primary" class="btn-cust" routerLink="/addbundle" matTooltip="Add Bundle"
      [matTooltipPosition]="'left'">
      <mat-icon class="icons">add</mat-icon>
    </button>
  </div>
</div>