import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { DataService } from '../data.service';
import { productinventory_set } from '../models';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit  {



  url = 'api/v1/productinv/'
  public selectedVal: string;
  inventory_set: productinventory_set[] = []
  sortedData: productinventory_set[];
  
  displayedColumns: string[] = ['id', 'product_name', 'color', 'size', 'quantity'];
  dataSource :MatTableDataSource<productinventory_set>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private cd:ChangeDetectorRef) { }

  ngOnInit(){
    console.log("onit");
    this.selectedVal = "grid"

  }



  public onValChange(val: string) {
    this.selectedVal = val;
  
  }

  sortData(sort: Sort){
    const data = this.inventory_set.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'product_name': return this.compare(a.product_name, b.product_name, isAsc);
        case 'color': return this.compare(a.color, b.color, isAsc);
        case 'size': return this.compare(a.size, b.size, isAsc);
        case 'quantity': return this.compare(a.quantity, b.quantity, isAsc);
        
        default: return 0;
      }
    });
  }

   compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onData($event) {
    console.log("ond");

    // console.log($event);
    this.inventory_set = $event;
    this.sortedData = this.inventory_set;
    this.dataSource = new MatTableDataSource(this.inventory_set)
    this.dataSource.sort = this.sort;

    console.log(this.inventory_set);
    // this.dataSource = new MatTableDataSource(this.product_collection);
  }

}
