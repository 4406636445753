<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="z-index: 50;
position: fixed;
top: 51px;"></mat-progress-bar>

<div class="main" *ngIf="formGroup">
    <div *ngIf="bundleObj">
        <mat-card  style="margin: 0; margin-bottom: 20px;">

          <mat-chip-list #chipList>
            <mat-chip style="cursor: pointer;">
              {{ bundleObj.storefront_name }}

            </mat-chip>
  
          </mat-chip-list>
        </mat-card>
      </div>
      <form [formGroup]="formGroup" class="example-form">
        <div class="form-field">
          <label for="title">Bundle Name:</label>
          <mat-form-field>
            <input matInput id="title" placeholder="Bundle Name" formControlName="title" required>
          </mat-form-field>
        </div>
    
        <div class="form-field">
          <label for="description">Description:</label>
          <mat-form-field>
            <textarea matInput id="description" placeholder="Description" formControlName="description"></textarea>
          </mat-form-field>
        </div>
    
        <div class="form-field">
          <label for="code">Code:</label>
          <mat-form-field>
            <input matInput id="code" placeholder="Code" formControlName="code" required>
          </mat-form-field>
        </div>
        <div>
          <label for="code">Preview Image:</label>
          <img style="object-fit: contain;
          width: 200px;
          height: 200px;display: block;"  [src]="imagePreview || bundleObj.preview_image" 
          onError="this.src='assets/images/deaault_store_img.png';" alt="">
          <label color="primary" for="file-upload" class="custom-file-upload">
            <i class="fa fa-cloud-upload"></i> Browse..</label>
          <input id="file-upload"  (change)="fileupload($event)" type="file" />
          <button mat-raised-button matTooltip="Reset Image" color="primary" (click)="resetImage()"><mat-icon>refresh</mat-icon></button>
        </div>
        <!-- <div class="image-form"> 
        <table class="example-full-width custlogo widths" cellspacing="0">
          <tr>
            <td>
              <mat-form-field>
                <input matInput placeholder="Preview Image" formControlName="previewImage" disabled />
              </mat-form-field>
            </td>
            <td>
              <label color="primary" for="file-upload" class="custom-file-upload">
                <i class="fa fa-cloud-upload"></i> Browse..</label>
              <input id="file-upload" (change)="fileupload($event)" type="file" />
            </td>
          </tr>
        </table>
        </div> -->
    
        <div class="form-field">
          <label for="price">Price:</label>
          <mat-form-field>
            <input matInput id="price" placeholder="Price" formControlName="price" type="number" required>
          </mat-form-field>
        </div>
    
        <h5>Select Category</h5>
        <mat-form-field>
          <mat-label>Select Categories</mat-label>
          <mat-select multiple [formControl]="bundleCategoriesControl" (selectionChange)="onSelectCategories($event)">
            <mat-option *ngFor="let category of bundleCategories" [value]="category">
              {{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <table mat-table [dataSource]="selectedCategories">
       
          <ng-container matColumnDef="title">
            <th style="width: 100px;" mat-header-cell *matHeaderCellDef> Category Title </th>
            <td mat-cell *matCellDef="let category;let i = index"> {{category.name}} 
              <div style="display: flex;flex-direction: column;margin-top: 10px;">
                <mat-form-field>
                  <mat-label>Select Products</mat-label>
                  <mat-select  multiple  (selectionChange)="onSelectProducts($event,category.id)">
                    <mat-option *ngFor="let product of products" [value]="product">
                      {{ product.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="Count">
            <th mat-header-cell *matHeaderCellDef> Count </th>
            <td mat-cell *matCellDef="let count ;let i = index;">
              <mat-form-field>
                <mat-label>Category Count</mat-label>
                <input matInput placeholder="count" [formControl]="category_count" (change)="onCategoryCountChange(i)">
              </mat-form-field>
            
            </td>
          </ng-container> 
        
          <ng-container matColumnDef="products">
            <th mat-header-cell *matHeaderCellDef> Products </th>
            <td mat-cell *matCellDef="let category"> <span *ngFor="let product of category.products">
              {{ product.title }}{{ !last ? ', ' : '' }}
            </span></td>
          </ng-container>
    
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table> -->
    
        <table mat-table [dataSource]="categoryNamesObj">
       
          <ng-container matColumnDef="title">
            <th style="width: 100px;" mat-header-cell *matHeaderCellDef> Category Title </th>
            <td mat-cell *matCellDef="let category;let i = index"> 
             <p style="margin: 10px 0;">{{category.name}} </p>
              <div *ngIf="bundleProductsControls" style="display: flex;flex-direction: column;margin-top: 10px;">
                <mat-form-field>
                  <mat-label>Select Products</mat-label>
                  <mat-select  multiple  [formControl]="getFormControl(i)" (selectionChange)="onSelectProducts($event,category.id)">
                    <mat-option *ngFor="let product of products" [value]="product">
                      {{ product.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="Count" >
            <th mat-header-cell *matHeaderCellDef> Count </th>
            <td mat-cell *matCellDef="let count ;let i = index;" style="margin: 0 12px;">
              <mat-form-field class="no-underline" >
                <mat-label>Category Count</mat-label>
                <input matInput placeholder="count" [formControl]="category_count"  [value]="count.count" (change)="onCategoryCountChange(i)">
              </mat-form-field>
            
            </td>
          </ng-container> 
        
          <ng-container matColumnDef="products">
            <th mat-header-cell *matHeaderCellDef> Products </th>
            <td mat-cell *matCellDef="let category"> <span *ngFor="let product of category.products">
              {{ product.title }}{{ !last ? ', ' : '' }}
            </span></td>
          </ng-container>
    
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <button mat-raised-button color="primary" (click)="updateBundle()">Update Bundle</button>
      </form>
</div>