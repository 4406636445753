import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule
} from "@angular/forms";
import { DataService } from "./../data.service";
import { RouterModule, Routes, Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "../app.component";
import { userstorefront_obj } from "../models";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  type = "";
  showDrpdwn = false;

  selectedStoreFront: userstorefront_obj;

  constructor(
    private dataservice: DataService,
    private router: Router,
    private appcomp: AppComponent,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.type = params["login_type"];
      console.log(this.type);
    });
  }

  username: any;
  password: string;
  user_details: any;
  userid: any;

  ngOnInit() {
    this.setNavbaroff();
  }
  login() {
    // if (this.username = '') {
    //   alert('Please Enter Username');
    //   return;
    // }
    // if (this.password = '') {
    //   alert('Please Enter Password');
    //   return;
    // }
    //console.log('hello');
    //console.log(this.username);
    //console.log(this.password);
    this.dataservice.login(this.username, this.password).then(res => {
      this.user_details = res;
      this.setNavbarOn();
      //console.log(this.user_details);
      //this.dataservice.users_id = this.user_details.user.pk;
      this.userid = this.user_details.token;
      sessionStorage.setItem("token", this.userid);
      var data = sessionStorage.getItem("token");
      // console.log(data, 'data');
      this.router.navigateByUrl("/customers");
    });
  }

  // this will removes the navbar and sidebar (hides)
  setNavbaroff() {
    this.appcomp.navbar_set = 1;
    this.appcomp.width = 0;
    this.appcomp.left = 0;
    this.appcomp.displaytype = 'none';
  }

  setNavbarOn() {
    this.appcomp.navbar_set = 0;
    this.appcomp.width = 50;
    this.appcomp.left = 50;
    this.appcomp.displaytype = 'block';

  }

  custlogin() {
    // if (this.password.length === 8) {
      this.dataservice
        .getPasscodeprostorefront(this.password)
        .subscribe(data => {
          console.log(data.json());

          this.dataservice.protected_selected_storefronts = data.json();

          this.selectedStoreFront = this.dataservice.protected_selected_storefronts[0];

          this.showDrpdwn = true;

          //  this.router.navigateByUrl('/storelist_protected')
        });
    // }
  }

  onSelect(item: userstorefront_obj) {
    console.log(item);
  }

  launchStore() {
    this.prev(this.selectedStoreFront.slug + '/' + this.password);
  }

  prev(slug: string) {
    console.log(slug);
    if (!slug) {
      alert("No Url Added");
      return;
    }
    console.log(slug, "url");
    window.open(
      slug,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800"
    );
  }
}
