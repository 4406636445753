import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { DataService } from '../data.service';
import { m_product_obj, userstorefront_obj, storefront_obj, ProductTag } from '../models';
import { MatDialog} from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { Location } from '@angular/common';

import readXlsxFile from 'read-excel-file';
import { ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { CompressImageService } from '../compress.service';


@Component({
  selector: 'app-edit-storefront',
  templateUrl: './edit-storefront.component.html',
  styleUrls: ['./edit-storefront.component.css']
})
export class EditStorefrontComponent implements OnInit, AfterViewInit {


  tagsList:ProductTag[] = []
  selectedTags: ProductTag[] = [];

  displayedColumns: string[] = [

    'code',


    // "btn2"
  ];


  store_obj: userstorefront_obj;
  showForm = false;
  store_id: string;
  url: any;
  checked = false;
  auto_fill = false;


  //  show_customer_name;


  description = '';

  passcode = '';

  inputxls;

  constructor(private route: ActivatedRoute, public dataservice: DataService, private dialog: MatDialog, private _location: Location, private router: Router, private elementRef: ElementRef, private compressImage: CompressImageService) { }


  sortByVar = {
    field: 'code',
    filter: ''

  };


  selectedIndex = 0;

  xlsErrors = [];
  xlsData = [];

  showRecords = false;

  buttonShowRecords = "View Records";


  ngOnInit() {



    console.log(this.router.url);
    this.getTagList();


    const urlArray = this.router.url.split('/');


    const index = urlArray.findIndex(element => element === 'listproducts')

    if (index !== -1) {

      this.selectedIndex = 1;
    }



    // if (this.dataservice.selected_storefront) {

    //   this.store_obj = this.dataservice.selected_storefront;

    //   this.passcode = this.store_obj.passcode;


    //   this.description = this.store_obj.description;

    //   console.log(this.passcode);
    //   if (this.store_obj.passcode) {
    //     this.checked = true;
    //   }

    //   this.showForm = true;

    // }
    // else

    if (true) {

      this.route.params.subscribe(params => {

        console.log(params);
        this.store_id = params['storefront_id'];
      });

      this.dataservice.getSingleStore(this.store_id).subscribe(
        data => {


          console.log(data.json());

          this.store_obj = data.json();
          this.selectedTags = this.store_obj.tags


          this.dataservice.selected_storefront = this.store_obj;


          this.passcode = this.store_obj.passcode;

          this.description = this.store_obj.description;


          this.auto_fill = this.store_obj.auto_fill;



          this.xlsData = this.store_obj.auto_fill_data === null ? [] : this.store_obj.auto_fill_data;

          

          this.store_obj.storefront_product.sort((a, b) => {
            // Check if 'active' is true
            if (a.active && !b.active) return -1; // 'a' comes before 'b'
            if (!a.active && b.active) return 1; // 'b' comes before 'a'

            // If both have 'active' as true or false, then sort by 'code'
            if (a.code < b.code) return -1;
            if (a.code > b.code) return 1;
            return 0; // 'a' and 'b' are equal
            return 0;
          });


          this.showForm = true;
          setTimeout(() => {

            this.inputxls = document.getElementById('input');

            this.inputxls.addEventListener('change', this.onFielLoad.bind(this));


          }, 500);

          console.log(this.store_obj);
          if (this.store_obj.passcode) {
            this.checked = true;
          }

        },
        error => {

        }

      )

    }

  }




  ngAfterViewInit(): void {



  }
  compareFn(tag1: any, tag2: any) {
    return tag1 && tag2 ? tag1.name === tag2.name : tag1 === tag2;
  }

  getTagList() {
    this.dataservice.getProductTagList().then(
      res => {
        console.log(res);
        this.tagsList = res;
      }
    )
  }

  ontagschange(event: any) {
    console.log(event.value); // Handle the change event here
    this.selectedTags = event.value;
  }



  openArchiveDialog(item: m_product_obj, item2: userstorefront_obj, status: boolean): void {


    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Archive", content: "Are you sure you want archive product " + item.title + ", by doing so the product will not be listed in " + item2.name, type: 'question' }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed', result);

      if (result) {
        if (result === 'yes') {

          this.toggleProductStatusData(item, item2, status);
        }
      }


      // this.animal = result;
    });
  }

  cancel() {
    this._location.back();
  }


  toggleProductStatusData(item: m_product_obj, item2: userstorefront_obj, status: boolean) {

    let obj = { "product": item.id, "storefront": item2.id, "is_active": status };

    this.dataservice.toggleProductInStoreStatus(obj).subscribe(data => {

      console.log(data)
      this.dataservice.productEdited = true
      this.toggleStatusInProductsTab(item, item2, status);
      this.toggleProductStatusStatic(item.id, status);

    },
      error => {
        console.log(error)
      }
    )
  }

  toggleStatusInProductsTab(prod, storefront, status) {
    let index = this.dataservice.inmemoryData.findIndex(element => element.datatype === 'product');

    if (index > -1) {
      console.log(index);

      console.log(this.dataservice.inmemoryData[index].multicollectionarry[0])
      this.dataservice.inmemoryData[index].multicollectionarry[0].forEach(product => {
        if (product.id == prod.id) {
          product.storefront_product.forEach(sf => {
            if (sf.id == storefront.id) {
              sf.is_active = status;
              console.log(sf.is_active);

            }
          });
        }
      })
    }
  }


  toggleProductStatusStatic(pid, status) {


    for (let index = 0; index < this.store_obj.storefront_product.length; index++) {
      if (this.store_obj.storefront_product[index].id === pid) {

        this.store_obj.storefront_product[index].active = status;


      }

    }



  }

  // Toggle passcode
  togglePascode($event) {
    console.log($event.checked);
    if ($event.checked) {
      this.passcode = this.store_obj.passcode;

    }
    else {
      this.passcode = ''

    }

  }

  generateUrl() {
    this.store_obj.slug = this.store_obj.slug.toLowerCase();
    var splChars = "*|,\":<>[]{}`\';()@&$#%,' '";
    if (!this.store_obj.slug) {
      alert('url is Empty');
      return;
    }
    if (this.store_obj.slug) {
      this.dataservice.veriftyURl(this.store_obj.slug,this.store_obj.user).then(
        res => {
          console.log(res);
          let statuss = res['status'];
          console.log(statuss);
          if (!statuss) {
            alert('This url already exists! Please generate a new url');
            // this.store_obj.slug = '';
            return
          }
          else {
            for (var i = 0; i < this.store_obj.slug.length; i++) {

              if (splChars.indexOf(this.store_obj.slug.charAt(i)) != -1) {
                alert("Illegal characters detected!");
                return;
              }

            }
            this.store_obj.slug = this.store_obj.slug;
            console.log(this.store_obj.slug);
          }

        }
      )
    }

  }




  fileupload(event) {
    console.log(event.target.files);
    const files = event.target.files;

    for (let file of files) {

      this.dataservice.uploadBannerImages(this.store_obj.id, file).then(
        res => {
          console.log(res);
          this.store_obj.storefrontbanner_set.push(res);
        }
      ).catch(
        err => {
          console.log(err);
        }
      )



    }

  }


  removeBannerImage(id, index) {

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "",
        content: "Do you want to remove this banner image?",
        type: "question"
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result == 'yes') {
        this.dataservice.removeBannerImage(id).then(
          res => {
            this.store_obj.storefrontbanner_set.splice(index, 1);
          }
        ).catch(
          err => {

          }
        )
      }

    });


  }

  // Storefront Update
  update() {

    if (this.xlsErrors.length > 0) {
      return;
    }

    console.log(this.store_obj);
    let obj = {
      'id': this.store_obj.id,  // storefront id
      'name': this.store_obj.name,
      'chain_name': this.store_obj.chain_name,
      'banner_color_code': this.store_obj.banner_color_code,
      'title_color': this.store_obj.title_color,
      'desc_background_color': this.store_obj.desc_background_color,
      'desc_text_color': this.store_obj.desc_text_color,
      'tags':this.selectedTags,
      'status': this.store_obj.status,
      'location': this.store_obj.location,
      'slug': this.store_obj.slug,
      'online_payment': this.store_obj.online_payment,
      'net30': this.store_obj.net30,
      'passcode': this.passcode,
      'description': this.store_obj.description,
      'user': this.store_obj.user, //customer id
      'free_shipping_amount': this.store_obj.free_shipping_amount === null ? 0 : this.store_obj.free_shipping_amount,
      'auto_fill': this.auto_fill,
      'auto_fill_data': this.xlsData,
      'show_customer_name': this.store_obj.show_customer_name,
      'archive_message': this.store_obj.archive_message,
      'show_banner_images': this.store_obj.show_banner_images,



    }

    console.log(obj, 'from update');
    this.dataservice.updateStoreFront(obj).then(
      res => {
        // this.dataservice.selected_storefront =  <userstorefront_obj>res;
        this.dataservice.customer_list.forEach(cust => {
          if (cust.id === obj.user) {
            cust.user_storefront.forEach(sf => {
              if (sf.id === obj.id) {
                console.log('match');

                sf.name = obj.name;
                sf.slug = obj.slug;
                sf.status = obj.status;
              }
            })
          }
        })
        console.log(res);
        // alert('Updated');
        this.openDialog();
      },
      error => {

        alert(JSON.stringify(error));
      }
    )
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Success", content: `Storefront <b>${this.store_obj.name}</b> is updated.`, type: 'confirmation' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  storefront_status = [
    { value: 'active', viewValue: 'Active' },
    { value: 'archive', viewValue: 'Archive' }
  ];


  //Mark Featured

  onChange(event, index, item) {


    console.log(index, event, item);
    this.toggleProductFeaturedstaus(event.checked, item);

  }

  toggleProductFeaturedstaus(status, item) {
    let obj = { is_featured: status };
    this.dataservice.updateProduct(obj, item.id).subscribe(
      data => {
        console.log(data);
        item.is_featured = status;

      },
      error => { }
    );
  }


  selectedProductEdit(item) {

    console.log(item);
    this.dataservice.selected_product = item;


  }

  addproducts() {
    this.dataservice.selected_storefronts = [];
    this.dataservice.selected_storefronts.push(this.store_obj)
  }

  sizeDialogue(size) {
    console.log(size);
    let colArr = size.split(",")

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Color Options',
        content: colArr,
        type: 'colorsizeinfo'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {

      }
    });

  }

  // initializeXls($event) {

  //   if (this.auto_fill) {
  //     const input = document.getElementById('input');

  //     console.log(input);
  //     input.addEventListener('change', () => {

  //       const  schema = {
  //   'PROPERTY_NAME': {
  //     prop: 'PROPERTY_NAME',
  //     type: String,
  //     required: true

  //   },
  //   'ADDRESS': {
  //     prop: 'ADDRESS',
  //     type: String,
  //     required: true


  //   },
  //   'COUNTRY': {
  //     prop: 'COUNTRY',
  //     type: String,
  //     required: true


  //   },
  //   'STATE': {
  //     prop: 'STATE',
  //     type: String,
  //     required: true


  //   },
  //   'CITY': {
  //     prop: 'CITY',
  //     type: String,
  //     required: true


  //   },
  //   'ZIPCODE': {
  //     prop: 'ZIPCODE',
  //     type: String,
  //     required: true


  //   }
  // }
  //       readXlsxFile(input['files'][0],{schema}).then((rows,errors ) => {

  //         this.xlsData = rows.rows;
  //         this.xlsErrors = rows.errors;

  //         console.log(this.xlsData,this.xlsErrors);
  //         // `rows` is an array of rows
  //         // each row being an array of cells.
  //       })
  //     })
  //   }
  // }


  onFielLoad() {

    const schema = {
      'PROPERTY_NAME': {
        prop: 'PROPERTY_NAME',
        type: String,
        required: true

      },
      'ADDRESS': {
        prop: 'ADDRESS',
        type: String


      },
      'COUNTRY': {
        prop: 'COUNTRY',
        type: String


      },
      'STATE': {
        prop: 'STATE',
        type: String


      },
      'CITY': {
        prop: 'CITY',
        type: String


      },
      'ZIPCODE': {
        prop: 'ZIPCODE',
        type: String

      },
      'SHIPPING_INSTRUCTIONS': {
        prop: 'SHIPPING_INSTRUCTIONS',
        type: String
      }

    }
    readXlsxFile(this.inputxls['files'][0], { schema }).then((rows, errors) => {

      this.xlsData = rows.rows;
      this.xlsErrors = rows.errors;

      console.log(this.xlsData, this.xlsErrors);
      // `rows` is an array of rows
      // each row being an array of cells.
    });
  }


}
