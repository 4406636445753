<div class="main" *ngIf="showForm">
  <app-breadcrumb></app-breadcrumb>
  <mat-card class="cards">
    <mat-toolbar class="subtitle font-fmly" style="margin-bottom: 10px;">
      <span>Add Storefront - {{ dataservice.getSelectedCustomer().name }}</span>
    </mat-toolbar>
    <button *ngIf="this.dataservice.draftStorefront" mat-raised-button color="primary" style="margin:10px 0;" (click)="goToDraft()">
      Go back to draft
    </button><br/>
    <mat-form-field class="widths">
      <input matInput placeholder="Storefront Name" [formControl]="SFname" />
      <mat-error *ngIf="SFname.errors?.required">Store name is required</mat-error>
      <!-- <mat-error *ngIf="SFname.errors?.maxlength">Store name too large</mat-error> -->
    </mat-form-field>

    <br>
    <mat-form-field class="widths">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="..." [(ngModel)]="description"></textarea>
    </mat-form-field>


    <table>
      <tr class="color-tab ">
        <td>Storefront Banner Color:</td>
        <td>
          <input class="example-margin" type="color" placeholder="Storefront Banner Color" [(ngModel)]="bannerColor" />
        </td>
      </tr>
      <tr class="color-tab">
        <td class="example-margin">Storefront Title Color :</td>
        <td>
          <input class="example-margin" type="color" placeholder="Storefront Title Color" [(ngModel)]="titleColor" />
        </td>
      </tr>
      <tr class="color-tab">
        <td class="example-margin">Description Background Color :</td>
        <td>
          <input class="example-margin" type="color" placeholder="Description Background Color" [(ngModel)]="desBgColor" />
        </td>
      </tr>
      <tr class="color-tab">
        <td class="example-margin">Description Title Color :</td>
        <td>
          <input class="example-margin" type="color" placeholder="Description Title Color" [(ngModel)]="descTextColor" />
        </td>
      </tr>

    </table>
    <br>
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Store Style Items</mat-label>
      <mat-select [(ngModel)]="selectedTags" multiple>
        <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <div style="margin-bottom: 10px;">
      <mat-checkbox  [(ngModel)]="show_banner_images" >Show banner images on store front</mat-checkbox>
      <br>
      <label   color="primary" for="file-upload" class="custom-file-upload">
        <i class="fa fa-cloud-upload"></i> Upload Images</label>
      <input id="file-upload" (change)="fileupload($event)" type="file" multiple style="display: none;"/>
    </div>
    <div class="row " style="overflow-x: auto;margin: 0 10px;width: 50%;display: flex;">
      <div style="margin:5px;" class="" *ngFor="let item of bannerImages; let imgin = index">
        <div style="position: relative;">
          <img [src]="item.url" width="100" height="100" class="img-p" style="object-fit: cover;"/>
          <mat-icon class="icons"
            style="position: absolute;top: 0px;right: 0px; color: white;cursor: pointer;background-color: gray;
            border-radius: 50%;"
            (click)="removeBannerImage(imgin)" class="">

            clear
          </mat-icon>
        </div>

      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <mat-checkbox  [(ngModel)]="show_customer_name" >Show customer name on store front</mat-checkbox>
    </div>

    <div>
      <mat-checkbox  [(ngModel)]="auto_fill" >Auto fill address data on checkout</mat-checkbox>

      <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="input" [class.hidden]="!auto_fill" />

      <div>

        <div [class.hidden]="!auto_fill" style="margin-top: 10px;">
          <li *ngFor="let item of xlsErrors; let i=index" style="padding-left: 0px !important;">Error  <span style="color: red;margin-right: 5px;margin-left: 5px;">{{item.column}}</span>   value {{item.error}} at row {{item.row}}  </li>

         </div>
      <button *ngIf="xlsData.length > 0" style="margin-top: 10px;" mat-raised-button color="primary"  (click)="showRecords = !showRecords"  >
        {{showRecords?"Hide Records":"Show Records"}}
      </button>


    <div >
      <table class="table" *ngIf="showRecords">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">PROPERTY_NAME</th>
            <th scope="col">ADDRESS</th>
            <th scope="col">COUNTRY</th>
            <th scope="col">CITY</th>
            <th scope="col">STATE</th>
            <th scope="col">ZIPCODE</th>
            <th scope="col">SHIPPING_INSTRUCTIONS</th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of xlsData; let i=index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.PROPERTY_NAME}}</td>
            <td>{{item.ADDRESS}}</td>
            <td>{{item.COUNTRY}}</td>
            <td>{{item.CITY}}</td>
            <td>{{item.STATE}}</td>
            <td>{{item.ZIPCODE}}</td>
            <td>{{item.SHIPPING_INSTRUCTIONS}}</td>



          </tr>

        </tbody>

      </table>

    </div>
    </div>

    </div>

    <mat-form-field class="widths">
      <input matInput placeholder="Enable Free Shipping Threshold Amount" [(ngModel)]="free_shipping_amount"
        type="number" />
      <mat-icon matSuffix>attach_money</mat-icon>
    </mat-form-field>
    <br />
    <mat-form-field class=" widths">
      <mat-select placeholder="Storefront Status" [(ngModel)]="SFstatus">
        <mat-option *ngFor="let status of storefront_status" [value]="status.value">
          {{ status.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field class="widths">
      <mat-label>Archive Message</mat-label>
      <textarea matInput placeholder="Custom message to show when storefront status is archived" [(ngModel)]="archive_message"></textarea>
    </mat-form-field>
    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="onlinePay">Online</mat-checkbox>

      <mat-checkbox class="example-margin" style="margin-left: 20px;" [(ngModel)]="net30Pay">Net30</mat-checkbox>
    </section>
    <!-- <table class="example-full-width" cellspacing="0">
      <tr>
        <td>
            <mat-form-field > -->

    <mat-form-field class="widths">
      <input matInput placeholder="Storefront URL Hint" [formControl]="SFurlHint" />
      <mat-error *ngIf="SFurlHint.errors?.required">Store URL is required
      </mat-error>

      <mat-error *ngIf="SFurlHint.errors?.containsillegalchar">Store URL contains invalid character
      </mat-error>

      <mat-error *ngIf="SFurlHint.errors?.alredyexist">Store URL already exist
      </mat-error>
    </mat-form-field>

    <br />

    <mat-checkbox class="example-margin" [(ngModel)]="isPasscode" (click)="returnRandomString(8)">Password Protected
    </mat-checkbox><br />
    <mat-form-field class="widths" *ngIf="isPasscode">
      <input matInput placeholder="Passcode" class="" [formControl]="passcodefc" required />
      <mat-error *ngIf="passcodefc.errors?.required">Passcode is required
      </mat-error>

      <mat-error *ngIf="passcodefc.errors?.maxlength">Passcode should be less than 8 char
      </mat-error>
    </mat-form-field>
    <br />
    <mat-form-field class="widths">
      <input matInput placeholder="Storefront URL Hint" value="{{ url }}" disabled />
    </mat-form-field>
    <div class="button-row row-btns">
      <button mat-raised-button color="primary" class="bt1" (click)="this.dataservice.draftStorefront ? updateDraft() : saveAsDraftStorefront(false)" [disabled]="!SFname">
        {{this.dataservice.draftStorefront? 'Update' : 'Save as'}} Draft
      </button>
      <button mat-raised-button color="primary" class="bt1" (click)="CreateStorefront(true)" [disabled]="!SFname">
        Add Storefront
      </button>
      <button mat-raised-button class="bt1" (click)="cancel()">Cancel</button>

      <button mat-raised-button style="float: right;visibility: hidden;" color="primary" class="bt1"
        routerLink="/addmultiplestorefront/{{ cust_id }}">
        Add Multiple StoreFront
      </button>
    </div>
  </mat-card>


</div>

