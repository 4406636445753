import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { m_customers_obj, storefront_obj, Coupon, userstorefront_obj, ProductTag } from '../models';
import { DataService } from '../data.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { Observable, timer } from 'rxjs';
import {
  filter,
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.css'],
})
export class UtilityComponent implements OnInit {
  customercollection: m_customers_obj[] = [];

  selectedStores: storefront_obj[] = [];

  tags: ProductTag[] = []
  specialTags: ProductTag[] = []
  bundleCategories = []

  newStyle = ""
  newSpecialTag = ""
  bundleCategory = ""
  isInEditMode = false;

  coupon_type = 'flat_discount';

  validity_type = 'default';

  tabLabel = 'Create Coupon'

  is_valid = true;


  coupon_list: Coupon[] = [];


  displayedColumns: string[] = ['coupon_name', 'coupon_type', 'coupon_valid', 'discount_value'];

  selectedIndex = 0;

  selectedCoupon: Coupon;


  // coupon_name = new FormControl(null, {validators: Validators.compose([Validators.required, this.checkForCouponCode.bind(this)]), updateOn: 'change'});

  coupon_name = new FormControl(null, [
    Validators.required,
    // this.checkForCouponCode.bind(this),
  ]);

  selectedStoreCol = new FormControl('', Validators.required);

  flatdiscount = new FormControl('', Validators.required);

  percentagediscount = new FormControl('', Validators.required);

  expirydate = new FormControl(null, [this.dateExpireValidater]);

  usagelimit = new FormControl(0, Validators.min(1));

  constructor(private dataservice: DataService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getStoreFrontData();
    this.getTagList();
    this.getSpecialTagList();
    this.getBundleCategoryList();

    this.coupon_name.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((data) => {
          return data;
        })
      )

      .subscribe((data) => {
        console.log('from valuechanges', data);

        this.dataservice.couponNameValidityCheck(data).subscribe((res) => {
          const response = res.json();

          if (response.status) {
            if (!this.isInEditMode) {
              this.coupon_name.setErrors({ couponInuse: true });
            }
          }
        });

      });
  }


  onEdit() {

  }

  getTagList() {
    this.dataservice.getProductTagList().then(
      res => {
        console.log(res);
        this.tags = res;
      }
    )
  }
  getSpecialTagList() {
    this.dataservice.getProductSpecialTagList().then(
      res => {
        console.log(res);
        this.specialTags = res;
      }
    )
  }

  getBundleCategoryList(){
    this.dataservice.getBundleCategoryList().then(
      res =>{
        console.log(res);
        this.bundleCategories = res;
      }
    )
  }

  addTag() {
    this.dataservice.addProductTag(this.newStyle).subscribe(
      data => {

        this.tags.push(data);

      },
      error => {
        console.log(error);
      }
    );
  }
  addspecialTag() {
    this.dataservice.addProductSpecialag(this.newSpecialTag).subscribe(
      data => {

        this.specialTags.push(data);

      },
      error => {
        console.log(error);
      }
    );
  }

  addCategory(){
    this.dataservice.addBundleCategory(this.bundleCategory).subscribe(
      data => {

        this.bundleCategories.push(data);

      },
      error => {
        console.log(error);
      }
    );
  }

  

  removeStyle(id) {

    this.openDialog('style',id)



  }

  removeSpecialTag(id) {
    console.log(id);
    this.openDialog('specialtags',id) 
  }
  removeBundleCategory(id){
    this.dataservice.removeBundleCategory(id).subscribe(
      data => {
        this.bundleCategories = this.bundleCategories.filter(cat => cat.id != id);
      },
      error => {
        console.log(error);
      }
    )
  }

  openDialog(type,id): void {
    let msg = type == 'style' ? 'This action will remove the style from every store. Are you sure?' : 'This action will remove the tag from every product. Are you sure?'
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Remove", content: msg, type: "question" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed", result);

      if (result) {
        if (result == "yes") {
          if (type == 'style') {
            this.dataservice.removeProductTag(id).subscribe(
              data => {
                this.tags = this.tags.filter(tag => tag.id != id);
              },
              error => {
                console.log(error); 
              }
            )
          } else if (type == 'specialtags') {
            this.dataservice.removeProductSpecialTag(id).subscribe(
              data => {
                this.specialTags = this.specialTags.filter(tag => tag.id != id);
        
              },
              error => {
                console.log(error);
              }
            )
          }
        }
      }

      // this.animal = result;
    });
  }

  getStoreFrontData() {
    if (this.dataservice.customer_list.length === 0) {
      this.dataservice.getAllStoreFronts().subscribe((data) => {
        const obj = data.json();
        this.customercollection = obj['results'];

        this.dataservice.customer_list = this.customercollection;

        // this.consumeSelectedOptions(datax);

        console.log(this.customercollection);
      });
    } else {
      this.customercollection = this.dataservice.customer_list;
      // this.consumeSelectedOptions(datax as userstorefront_obj[]);
    }
  }

  onCreateCoupon() {
    if (this.coupon_name.valid) {
      if (this.selectedStoreCol.valid) {
        if (this.coupon_type === 'flat_discount') {
          if (this.flatdiscount.valid) {
            this.createCoupon(this.coupon_type);
          } else {
            this.flatdiscount.markAsTouched();
          }
        } else {
          if (this.percentagediscount.valid) {
            this.createCoupon(this.coupon_type);
          } else {
            this.percentagediscount.markAsTouched();
          }
        }
      } else {
        this.selectedStoreCol.markAsTouched();
      }
    } else {
      this.coupon_name.markAsTouched();
    }
  }

  createCoupon(couponType: string) {
    if (this.expirydate.valid) {
      if (couponType === 'flat_discount') {
        const obj = {
          coupon_name: this.coupon_name.value,
          coupon_type: couponType,
          discount_value: this.flatdiscount.value,
          allowed_usage_count: this.usagelimit.value,
          expiry_date: this.expirydate.value,
          coupon_valid: this.is_valid,
          data: this.returnStoreId(),
        };

        console.log(obj);
        this.postCoupon(obj);
      }

      if (couponType === 'percentage_discount') {
        const obj = {
          coupon_name: this.coupon_name.value,
          coupon_type: couponType,
          percent_discount_value: this.percentagediscount.value,
          allowed_usage_count: this.usagelimit.value,
          expiry_date: this.expirydate.value,
          coupon_valid: this.is_valid,
          data: this.returnStoreId(),
        };
        console.log(obj);

        this.postCoupon(obj);
      }
    }
  }

  postCoupon(obj) {

    if (!this.isInEditMode) {
      this.dataservice.createCoupon(obj).subscribe(
        (data) => {
          console.log(data);

          this.alert(
            'Coupoun ' + this.coupon_name.value + ' was created successfully',
            'Success',
            'confirmation'
          );
        },
        (error) => {
          console.log(error);

          this.alert('Something went wrong', 'Error', 'error2');
        }
      );
    } else {


      this.dataservice.updateCoupon(obj, this.selectedCoupon.coupon_id).subscribe(
        (data) => {
          console.log(data);

          this.alert(
            'Coupoun ' + this.coupon_name.value + ' was updated successfully',
            'Success',
            'confirmation'
          );
        },
        (error) => {
          console.log(error);

          this.alert('Something went wrong', 'Error', 'error2');
        }
      );
    }

  }

  returnStoreId() {

    console.log(this.selectedStoreCol.value);
    const id = [];
    this.selectedStoreCol.value.forEach((element) => {
      id.push({ storefront_id: element.id });
    });

    return id;
  }

  dateExpireValidater(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (
      control.value !== undefined &&
      control.value !== null &&
      isNaN(control.value)
    ) {
      console.log(control.value);

      const datefromcontrol = Date.parse(control.value);
      console.log(datefromcontrol, Date.now());

      if (datefromcontrol <= Date.now()) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  callTp(data) {
    console.log(data);
  }

  alert(content, title, type) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',

      data: {
        title: title,
        content: content,
        type: type,
      },
    });
  }

  // Select Coupons


  onTabChange($event) {
    console.log($event);
    if ($event.index === 1) {
      this.getCoupons();
    }
  }


  getCoupons() {
    this.dataservice.getCoupons().subscribe(
      (data) => {
        // console.log(data);
        const result = data.json().results;

        this.coupon_list = result;
        console.log(this.coupon_list);

        this.reorderCoupons();

      },
      (error) => {
        console.log(error);
      }
    );
  }


  reorderCoupons() {
    this.coupon_list = this.coupon_list.filter(element => {

      return element.is_active === true;

    });
  }


  openCouponStatusChangeDialog(coupon: Coupon): void {



    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: coupon.coupon_valid ? 'Deactivate Coupon' : 'Activate Coupon',
        content: `Are you sure, you want to ${coupon.coupon_valid ? 'deactivate coupon' : 'activate coupon'}   ${coupon.coupon_name} ?`,
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
      if (result) {
        if (result === 'yes') {


          this.dataservice.patchCoupon(coupon.coupon_id, { 'coupon_valid': !coupon.coupon_valid }).subscribe(
            data => {

              console.log(data.json());
              coupon.coupon_valid = !coupon.coupon_valid;

            },
            error => {

              console.log(error);
            }
          );

        }
      }

      // this.animal = result;
    });
  }



  openCouponDeleteDialog(coupon: Coupon): void {



    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete Coupon',
        content: `Are you sure, you want to delete ${coupon.coupon_name} ?`,
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
      if (result) {
        if (result === 'yes') {


          this.dataservice.patchCoupon(coupon.coupon_id, { 'is_active': false }).subscribe(
            data => {

              console.log(data.json());
              coupon.is_active = false;
              //coupon.coupon_valid = !coupon.coupon_valid;
              this.reorderCoupons();
            },
            error => {

              console.log(error);
            }
          );

        }
      }

      // this.animal = result;
    });
  }



  editCoupon(coupon: Coupon) {


    console.log(coupon);

    this.isInEditMode = true;
    this.tabLabel = 'Edit Coupon';

    this.selectedCoupon = coupon;


    this.coupon_name.setValue(coupon.coupon_name);


    this.usagelimit.setValue(coupon.allowed_usage_count);

    this.is_valid = coupon.coupon_valid;
    const currentDate = new Date(coupon.expiry_date).toISOString().substring(0, 10);
    this.expirydate.setValue(currentDate);


    const obj = [];
    coupon.data.forEach(element => {



      obj.push(this.getSelectedStorefront(element['storefront_id']));

      console.log(this.getSelectedStorefront(element['storefront_id']));

    });

    this.selectedStoreCol.setValue(obj);

    console.log(obj);


    this.flatdiscount.setValue(Number(coupon.discount_value));

    this.percentagediscount.setValue(Number(coupon.percent_discount_value));

    console.log(this.percentagediscount.value);

    this.coupon_type = coupon.coupon_type;

  }



  getSelectedStorefront(id): userstorefront_obj {



    let item: userstorefront_obj;
    this.customercollection.forEach(element => {


      element.user_storefront.forEach(obj => {

        if (obj.id === id) {


          item = obj;


          return item;
        }

      });



    });



    return item;

  }




  setToCreateCoupon() {



    this.isInEditMode = false;
    this.tabLabel = 'Create Coupon';

    // this.selectedCoupon = coupon;

    this.coupon_type = 'flat_discount';

    this.coupon_name.setValue('');

    this.flatdiscount.setValue('');

    this.percentagediscount.setValue('');

    this.usagelimit.setValue('');

    this.is_valid = true;
    const currentDate = new Date().toISOString().substring(0, 10);
    this.expirydate.setValue(currentDate);



    this.selectedStoreCol.setValue(null);


  }



}
