import { Subject } from 'rxjs/Subject';
import { ProductCatComponent } from './../product-cat/product-cat.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { m_customers_obj, userstorefront_obj } from '../models';
import { DataService } from './../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { storefront_collection } from '../multi-sf/multi-sf.component';
import { Sort } from '@angular/material';

import { MatSnackBar } from '@angular/material';


import {
  PageEvent,
  MatTableDataSource,
  MatSort,
  MatDialog
} from '@angular/material';
import { element } from 'protractor';
import { PizzaPartyComponent, CopyUrl } from '../multiple-prodcts/multiple-prodcts.component';
import { Subscription } from 'rxjs-compat';
import { I } from '@angular/cdk/keycodes';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
  selector: 'app-customer-store-view',
  templateUrl: './customer-store-view.component.html',
  styleUrls: ['./customer-store-view.component.css']
})
export class CustomerStoreViewComponent implements OnInit {
  url = 'api/v1/userlist/';
  customer_collection: m_customers_obj[] = [];
  public selectedVal: string;
  dataSource: MatTableDataSource<m_customers_obj>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginatorComponent: PaginatorComponent;
  pageSize = 100;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchText: any;
  multiStorefront_collection: userstorefront_obj[][] = [];
  storefront_collection: userstorefront_obj[] = [];
  selectedSort;
  asedesSort;

  cust_sub : Subscription;

  sortParams = [
    {
      viewValue: 'Customer Name',
      value: 'name'
    },
    {
      viewValue: 'Number of stores',
      value: 'user_storefront'
    }
  ];

  displayedColumns: string[] = [
    'id',
    'name',
    'customer_image',
    'user_type',
    'user_storefront',
    'address_city',
    'status',
    'storefronts',
    'viewStore',
    'vieworders',
    'delete'
    // "btn2",
    // "btn3"
  ];

  constructor(
    private dataservice: DataService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) { }

  //Grid or List Show True false
  public onValChange(val: string) {
    this.selectedVal = val;
    this.dataservice.customerListState = val;
    if (this.selectedVal == "grid") {
      if (this.dataservice.customerGetFlag) {
        this.paginatorComponent.url = this.url

        this.paginatorComponent.initialCall();
        this.dataservice.customerGetFlag = false;
      }
    }

  }

  ngOnInit() {
    this.selectedVal = this.dataservice.customerListState;
    if (this.selectedVal == "grid") {
      if (this.dataservice.customerGetFlag) {
        this.paginatorComponent.url = this.url
        this.paginatorComponent.initialCall();
        this.dataservice.customerGetFlag = false;
      }
    }


  }

  ngOnDestroy(){
    if(this.cust_sub != undefined){
    console.log('unsub');

      this.cust_sub.unsubscribe();

    }
  }


  // create customer
  addCustomer() {
    this.dataservice.draftCustomer = null;
    this.router.navigate(['/addcustomer']);
  }

  // -----------------------------------------------------------------------------------------------customer-card-------------------------------------------------------------------------------

  // fetData to DataBase and Store to customer_collection
  onData($event) {
    this.customer_collection = $event;
    console.log(this.customer_collection);
    let storefront_collection = this.customer_collection.map(
      customer => customer.user_storefront
    );



    this.dataSource = new MatTableDataSource(this.customer_collection);
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    }, 2000);
    this.dataservice.setCustomer_List(this.customer_collection);

    this.cust_sub = this.dataservice.customerUpdated$.subscribe(customer => {
      if (customer != null){
        const id = this.customer_collection.findIndex((cust => cust.id == customer.id))
        this.customer_collection[id] = customer;
      }
    })
  }

  copyStore(store) {

    console.log(store.id, store.user,store);
    this.openCustomerDialogue(store.id, store.user,store);

  }
  isLoading = false
  openCustomerDialogue(storeId, custId,store) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: "Select Customer",
        content: custId,
        type: "copystorefront"
      }
    });

    dialogRef.afterClosed().map((res: m_customers_obj[]) => res).subscribe(result => {
      console.log("The dialog was closed", result);


      if (result.length > 0) {
        var obj = { storefront_id: storeId, customer_ids: [] }

        result.forEach(cust => {

          obj.customer_ids.push({ name: cust.name, id: cust.id })


        });

        console.log(obj);
        this.isLoading = true;
        this.dataservice.copyStorefront(obj).then(
          (copiedStores) => {
            console.log(copiedStores);
            this.isLoading = false;


            copiedStores.forEach(newStore => {
            console.log( newStore.user);

            this.customer_collection.forEach(cust => {
              if (cust.id == newStore.user){ 
                console.log(cust.id , newStore.user);
                let storeToPush = <any>newStore;
                console.log(storeToPush);
                storeToPush.storefront_product = storeToPush.storefront_product.length
                storeToPush.storefront_product_total = storeToPush.storefront_product
                cust.user_storefront.push(storeToPush);
                console.log(cust.user_storefront);
              }
            })


            })



          }
        )
          .catch(
            err => {
              console.log(err);
              this.isLoading = false;
            }
          )







      }
    });
  }



  //view store
  viewStores(item: m_customers_obj) {
    this.dataservice.setSelectedCustomer(item);
    this.router.navigate(['/liststorefronts/' + item.id]);
  }

  //edit
  editCustomer(item: m_customers_obj) {
    this.dataservice.selected_customer = item;
    this.router.navigateByUrl('/editcustomer/' + item.id);
  }

  // add store
  addStore(item: m_customers_obj) {
    this.dataservice.setSelectedCustomer(item);
    this.router.navigate(['/addstorefront/' + item.id]);
  }

  // set background image card
  getBackground(image: string) {
    if (image !== null) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(
        `url(${'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'})`
      );
    }
  }

  // -----------------------------------------------------------------------------------------------stores-card--------------------------------------------------------------------------------

  //edit store
  editStore(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;
    this.router.navigate(['/editstore/' + item.id + '']);
  }

  editStoreP(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;
    this.router.navigate(['/editstore/listproducts/' + item.id + '']);
  }


  //copystoreurl

  copyUrlToClipboard(customer_slug,tem: userstorefront_obj) {

    console.log(customer_slug);

    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.dataservice.storeUrl + '/' + customer_slug + '/' + tem.slug));
      e.preventDefault();
      this.openSnackBar();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  //showOrders
  showOrders(item: userstorefront_obj) {
    this.dataservice.selected_storefront = item;
    this.router.navigateByUrl('/orders/' + item.id);
  }

  // Preview
  previewStore(item: userstorefront_obj, customer: m_customers_obj, evt) {
    if (evt) {
      evt.stopPropagation()

    }
    let slug = item.slug;
    console.log(item.slug);
    if (!slug) {
      alert('No Url Added');
      return;
    }
    
    if (customer.status.toLocaleLowerCase() === 'archive') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Archived customer',
          content: `Customer status is archived, press okay to view store`,
          type: 'confirmation'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          console.log("", result)
          if (result === 'okay') {

            slug = item.slug + "/?is_admin=true";

            this.openStoreFront(customer.name_slug,slug);

          }
        }

      });
    } else if (item.status.toLocaleLowerCase() === 'archive') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Archived store',
          content: `Store is archived, press okay to view store`,
          type: 'confirmation'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          console.log("", result)
          if (result === 'okay') {

            slug = item.slug + "/?is_admin=true";

            this.openStoreFront(customer.name_slug,slug);

          }
        }

      });
    } else {

      this.openStoreFront(customer.name_slug,slug);
    }
  }


  // openStoreFront(url) {
  //   window.open(
  //     `https://store.primetime.company/${url}`,
  //     '_blank',
  //     'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800'
  //   );
  // }

  openStoreFront(customer,url) {
    console.log(url);
    window.open(
      `${this.dataservice.storeUrl}/${customer}/${url}`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800'
    );
  }


  onToggle(evt, Item, item, i) {
    console.log(evt, Item, item, i);
    var status = ""
    if (evt.checked) {
      status = "active"
      this.toggleStoreStatusData(Item, item, status, i);


    } else {
      status = "archive"
      this.toggleStoreStatusData(Item, item, status, i);



    }
    console.log(status);

  }
  toggleStoreFrontStatus(
    citem: m_customers_obj,
    item: userstorefront_obj,
    status: string,
    index: number
  ) {
    this.openArchiveDialog(citem, item, status, index);
  }
  openArchiveDialog(
    citem: m_customers_obj,
    item: userstorefront_obj,
    status: string,
    index: number
  ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Archive',
        content: 'Are you sure, you want to archive store ' + item.name + '?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'yes') {
          //item.status = status;
          this.toggleStoreStatusData(citem, item, status, index);
        } else {

        }
      }

      // this.animal = result;
    });
  }
  // t-34
  toggleStoreStatusData(
    citem: m_customers_obj,
    item: userstorefront_obj,
    statuss,
    index
  ) {
    this.dataservice
      .toggleStoreStatus(item.id.toString(), { status: statuss })
      .subscribe(
        data => {
          this.toggleStoreStatusStatic(citem, item, statuss);
          this.dataservice.productEdited = true;
        },
        error => {
          console.log(error);
        }
      );
  }

  toggleStoreStatusStatic(
    citem: m_customers_obj,
    item: userstorefront_obj,
    status
  ) {
    let index = this.customer_collection.indexOf(citem);

    let storeindex = this.customer_collection[index].user_storefront.indexOf(
      item
    );

    this.customer_collection[index].user_storefront[storeindex].status = status;
  }

  // -----------------------------------------------------------------------------------------------page pagination----------------------------------------------------------------------------
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  onchange($event: PageEvent) {
    this.pageSize = $event.pageSize;
  }

  // -----------------------------------------------------------------------------------------------Sort----------------------------------------------------------------------------

  sortDataDropDown() {
    let bool;

    if (this.selectedSort === this.asedesSort) {
      bool = false;
    } else {
      bool = true;
    }

    this.asedesSort = this.selectedSort;

    if (bool) {
      this.customer_collection.sort(
        this.dataservice.dynamicSort(this.selectedSort)
      );
    } else {
      this.customer_collection
        .sort(this.dataservice.dynamicSort(this.selectedSort))
        .reverse();
      this.asedesSort = '';
    }
  }

  sortData(sort: Sort) {
    const data = this.customer_collection.slice();
    if (!sort.active || sort.direction === '') {
      this.customer_collection = data;
      return;
    }

    this.customer_collection = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'nstorefront':
          return this.compare(
            a.user_storefront.length,
            b.user_storefront.length,
            isAsc
          );
        case 'location':
          return this.compare(a.address_city, b.address_city, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'user_type':
          return this.compare(a.user_type, b.user_type, isAsc);

        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteCustomer(item: m_customers_obj) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete',
        content: 'Are you sure, you want to delete customer ' + item.name + '?',
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'yes') {
          this.dataservice.deleteCustomer(item.id).subscribe(data => {
            this.customer_collection.splice(
              this.customer_collection.indexOf(item),
              1
            );
            this.dataservice.productEdited = true;
            this.dataSource = new MatTableDataSource(this.customer_collection);
          });
        }
      }
    });
  }

  deleteStore(item: m_customers_obj, index, slug,storeId) {
    console.log(storeId);
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Delete',
        content: `Are you sure, you want to delete store
          ${item.user_storefront[index].name} from ${item.user_storefront[index].user_v1
          }?`,
        type: 'question'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'yes') {
          this.dataservice
            .deleteStore(storeId)
            .subscribe(data => {
              item.user_storefront.splice(index, 1);
              this.dataservice.productEdited = true;
              console.log(storeId);
              this.dataservice.deleteSfFromProductsCatalogue(storeId);
            });
        }
      }
    });
  }

  

  openSnackBar() {
    this.snackbar.openFromComponent(CopyUrl, {
      duration: 1500,
      horizontalPosition: "end",
      verticalPosition: "bottom"
    });
  }
}
