<div class="main">
  <mat-toolbar>
    <span>Stores</span>
    <span class="example-fill-remaining-space"></span>
    <input class="input-style" placeholder="Search" [(ngModel)]="searchText" value=""
      (keyup)="sortDataList($event.target.value)" />
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup"
      [value]="selectedVal" (change)="onValChange(group.value)">
      <mat-button-toggle value="grid">
        <mat-icon class="example-icon">view_module</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="list">
        <mat-icon class="example-icon">view_list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-toolbar>
  <div class="mess">
    <!-- grid -->
    <div *ngIf="selectedVal === 'grid'" style="overflow-y: -webkit-paged-x;">
      <div *ngFor="
          let itemx of (storecust_collection | filter: searchText);
          let i = index
        " class="grps">
        <p class="cust_names">{{ itemx.cust_name }}</p>
        <div class="row rows">
          <div class="col-sm-15" *ngFor="let item of itemx.storefronts; let i = index">
            <mat-card class="customer-card">
              <mat-card-header>
                <mat-card-title class="title font-fmly" matTooltip="{{ item.name }}" [matTooltipPosition]="'above'">
                  {{ item.name }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="tap-height">
                  <p class="taps">
                    Status : <span class="Cityname">{{ item.status }}</span>
                  </p>
                  <p *ngIf="item.location != null" class="taps">
                    Location : <span class="Cityname">{{ item.location }}</span>
                  </p>

                  <table>
                    <tr *ngIf="item.chain_name != null" class="row-height">
                      <td class="">Store Group :</td>
                      <td class="">
                        <span class="contests" matTooltip="{{ item.chain_name }}"
                          [matTooltipPosition]="'above'">{{ item.chain_name }}</span>
                      </td>
                    </tr>
                    <tr class="row-height">
                      <td class="">Customer :</td>
                      <td class="">
                        <span class="contests" matTooltip="{{ item.user_v1 }}"
                          [matTooltipPosition]="'above'">{{ item.user_v1 }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <table class="tab1">
                  <tr>
                    <td style="padding-left: 5px;">No. of Products</td>
                    <td>No. of Orders</td>
                  </tr>
                  <tr>
                    <td class="nums num1" (click)="viewStores(item)">
                      {{ item.storefront_product.length }}
                    </td>
                    <td class="nums num2" (click)="showOrders(item)">
                      {{ item.storefront_order.length }}
                    </td>
                  </tr>
                </table>
              </mat-card-content>
              <mat-card-actions class="text-center">
                <button mat-button class="bt1" (click)="editStore(item)">
                  Edit Store
                </button>
                <button mat-button class="bt1" (click)="previewStore(item)">
                  Preview Store
                </button>
                <button mat-raised-button color="warn" class="warns" *ngIf="item.status === 'active'"
                  (click)="toggleStoreFrontStatus(item, 'archive', i)">
                  Archive
                </button>
                <button mat-raised-button color="primary" *ngIf="item.status !== 'active'"
                  (click)="toggleStoreStatusData(item, 'active', i)">
                  Activate
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <br />
      </div>
    </div>

    <!--end grid  -->

    <div *ngIf="selectedVal === 'list'">
      <div class="col-md-60">
        <mat-table #table class="table-list table-striped" *ngIf="selectedVal === 'list'" matSort
          [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              Name
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="customer">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.user_v1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="No_of_Products">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              No of products
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.chain_name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="store_Group">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              Store Group
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.storefront_product.length }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="orders">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              Orders
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.storefront_order.length }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef mat-sort-header class="row-header ">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.status }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="btn1">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef class="row-header ">
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button class="bt1" (click)="editStore(element)">
                Edit Store
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="btn2">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef class="row-header ">
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button class="bt1" (click)="previewStore(element)">
                Preview Store
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="btn3">
            <mat-header-cell style="padding-top: 15px" *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button color="warn" class="warns" *ngIf="element.status === 'active'"
                (click)="toggleStoreFrontStatus(element, 'archive', i)">
                Archive
              </button>
              <button mat-raised-button color="primary" *ngIf="element.status !== 'active'"
                (click)="toggleStoreStatusData(element, 'active', i)">
                Activate
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
    <div class="col-sm-60 text-center paginat">
      <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
    </div>
    <button mat-fab color="primary" class="btn-cust" (click)="openAddCustomerDialog()" matTooltip="Add Storefront"
      [matTooltipPosition]="'left'">
      <mat-icon class="icons">add</mat-icon>
    </button>
  </div>
</div>