import { ChangeDetectorRef, OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { MatSnackBar } from '@angular/material';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { CopyUrl } from '../multiple-prodcts/multiple-prodcts.component';
import { PaginatorComponent } from '../paginator/paginator.component';
var CustomerStoreViewComponent = /** @class */ (function () {
    function CustomerStoreViewComponent(dataservice, _sanitizer, router, dialog, activatedRoute, snackbar, cd) {
        this.dataservice = dataservice;
        this._sanitizer = _sanitizer;
        this.router = router;
        this.dialog = dialog;
        this.activatedRoute = activatedRoute;
        this.snackbar = snackbar;
        this.cd = cd;
        this.url = 'api/v1/userlist/';
        this.customer_collection = [];
        this.pageSize = 100;
        this.pageSizeOptions = [5, 10, 25, 100];
        this.multiStorefront_collection = [];
        this.storefront_collection = [];
        this.sortParams = [
            {
                viewValue: 'Customer Name',
                value: 'name'
            },
            {
                viewValue: 'Number of stores',
                value: 'user_storefront'
            }
        ];
        this.displayedColumns = [
            'id',
            'name',
            'customer_image',
            'user_type',
            'user_storefront',
            'address_city',
            'status',
            'storefronts',
            'viewStore',
            'vieworders',
            'delete'
            // "btn2",
            // "btn3"
        ];
        this.isLoading = false;
    }
    //Grid or List Show True false
    CustomerStoreViewComponent.prototype.onValChange = function (val) {
        this.selectedVal = val;
        this.dataservice.customerListState = val;
        if (this.selectedVal == "grid") {
            if (this.dataservice.customerGetFlag) {
                this.paginatorComponent.url = this.url;
                this.paginatorComponent.initialCall();
                this.dataservice.customerGetFlag = false;
            }
        }
    };
    CustomerStoreViewComponent.prototype.ngOnInit = function () {
        this.selectedVal = this.dataservice.customerListState;
        if (this.selectedVal == "grid") {
            if (this.dataservice.customerGetFlag) {
                this.paginatorComponent.url = this.url;
                this.paginatorComponent.initialCall();
                this.dataservice.customerGetFlag = false;
            }
        }
    };
    CustomerStoreViewComponent.prototype.ngOnDestroy = function () {
        if (this.cust_sub != undefined) {
            console.log('unsub');
            this.cust_sub.unsubscribe();
        }
    };
    // create customer
    CustomerStoreViewComponent.prototype.addCustomer = function () {
        this.dataservice.draftCustomer = null;
        this.router.navigate(['/addcustomer']);
    };
    // -----------------------------------------------------------------------------------------------customer-card-------------------------------------------------------------------------------
    // fetData to DataBase and Store to customer_collection
    CustomerStoreViewComponent.prototype.onData = function ($event) {
        var _this = this;
        this.customer_collection = $event;
        console.log(this.customer_collection);
        var storefront_collection = this.customer_collection.map(function (customer) { return customer.user_storefront; });
        this.dataSource = new MatTableDataSource(this.customer_collection);
        setTimeout(function () {
            _this.dataSource.sort = _this.sort;
        }, 2000);
        this.dataservice.setCustomer_List(this.customer_collection);
        this.cust_sub = this.dataservice.customerUpdated$.subscribe(function (customer) {
            if (customer != null) {
                var id = _this.customer_collection.findIndex((function (cust) { return cust.id == customer.id; }));
                _this.customer_collection[id] = customer;
            }
        });
    };
    CustomerStoreViewComponent.prototype.copyStore = function (store) {
        console.log(store.id, store.user, store);
        this.openCustomerDialogue(store.id, store.user, store);
    };
    CustomerStoreViewComponent.prototype.openCustomerDialogue = function (storeId, custId, store) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Select Customer",
                content: custId,
                type: "copystorefront"
            }
        });
        dialogRef.afterClosed().map(function (res) { return res; }).subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result.length > 0) {
                var obj = { storefront_id: storeId, customer_ids: [] };
                result.forEach(function (cust) {
                    obj.customer_ids.push({ name: cust.name, id: cust.id });
                });
                console.log(obj);
                _this.isLoading = true;
                _this.dataservice.copyStorefront(obj).then(function (copiedStores) {
                    console.log(copiedStores);
                    _this.isLoading = false;
                    copiedStores.forEach(function (newStore) {
                        console.log(newStore.user);
                        _this.customer_collection.forEach(function (cust) {
                            if (cust.id == newStore.user) {
                                console.log(cust.id, newStore.user);
                                var storeToPush = newStore;
                                console.log(storeToPush);
                                storeToPush.storefront_product = storeToPush.storefront_product.length;
                                storeToPush.storefront_product_total = storeToPush.storefront_product;
                                cust.user_storefront.push(storeToPush);
                                console.log(cust.user_storefront);
                            }
                        });
                    });
                })
                    .catch(function (err) {
                    console.log(err);
                    _this.isLoading = false;
                });
            }
        });
    };
    //view store
    CustomerStoreViewComponent.prototype.viewStores = function (item) {
        this.dataservice.setSelectedCustomer(item);
        this.router.navigate(['/liststorefronts/' + item.id]);
    };
    //edit
    CustomerStoreViewComponent.prototype.editCustomer = function (item) {
        this.dataservice.selected_customer = item;
        this.router.navigateByUrl('/editcustomer/' + item.id);
    };
    // add store
    CustomerStoreViewComponent.prototype.addStore = function (item) {
        this.dataservice.setSelectedCustomer(item);
        this.router.navigate(['/addstorefront/' + item.id]);
    };
    // set background image card
    CustomerStoreViewComponent.prototype.getBackground = function (image) {
        if (image !== null) {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + image + ")");
        }
        else {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg' + ")");
        }
    };
    // -----------------------------------------------------------------------------------------------stores-card--------------------------------------------------------------------------------
    //edit store
    CustomerStoreViewComponent.prototype.editStore = function (item) {
        this.dataservice.selected_storefront = item;
        this.router.navigate(['/editstore/' + item.id + '']);
    };
    CustomerStoreViewComponent.prototype.editStoreP = function (item) {
        this.dataservice.selected_storefront = item;
        this.router.navigate(['/editstore/listproducts/' + item.id + '']);
    };
    //copystoreurl
    CustomerStoreViewComponent.prototype.copyUrlToClipboard = function (customer_slug, tem) {
        var _this = this;
        console.log(customer_slug);
        var listener = function (e) {
            e.clipboardData.setData('text/plain', (_this.dataservice.storeUrl + '/' + customer_slug + '/' + tem.slug));
            e.preventDefault();
            _this.openSnackBar();
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    };
    //showOrders
    CustomerStoreViewComponent.prototype.showOrders = function (item) {
        this.dataservice.selected_storefront = item;
        this.router.navigateByUrl('/orders/' + item.id);
    };
    // Preview
    CustomerStoreViewComponent.prototype.previewStore = function (item, customer, evt) {
        var _this = this;
        if (evt) {
            evt.stopPropagation();
        }
        var slug = item.slug;
        console.log(item.slug);
        if (!slug) {
            alert('No Url Added');
            return;
        }
        if (customer.status.toLocaleLowerCase() === 'archive') {
            var dialogRef = this.dialog.open(DialogComponent, {
                data: {
                    title: 'Archived customer',
                    content: "Customer status is archived, press okay to view store",
                    type: 'confirmation'
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    console.log("", result);
                    if (result === 'okay') {
                        slug = item.slug + "/?is_admin=true";
                        _this.openStoreFront(customer.name_slug, slug);
                    }
                }
            });
        }
        else if (item.status.toLocaleLowerCase() === 'archive') {
            var dialogRef = this.dialog.open(DialogComponent, {
                data: {
                    title: 'Archived store',
                    content: "Store is archived, press okay to view store",
                    type: 'confirmation'
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    console.log("", result);
                    if (result === 'okay') {
                        slug = item.slug + "/?is_admin=true";
                        _this.openStoreFront(customer.name_slug, slug);
                    }
                }
            });
        }
        else {
            this.openStoreFront(customer.name_slug, slug);
        }
    };
    // openStoreFront(url) {
    //   window.open(
    //     `https://store.primetime.company/${url}`,
    //     '_blank',
    //     'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800'
    //   );
    // }
    CustomerStoreViewComponent.prototype.openStoreFront = function (customer, url) {
        console.log(url);
        window.open(this.dataservice.storeUrl + "/" + customer + "/" + url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800');
    };
    CustomerStoreViewComponent.prototype.onToggle = function (evt, Item, item, i) {
        console.log(evt, Item, item, i);
        var status = "";
        if (evt.checked) {
            status = "active";
            this.toggleStoreStatusData(Item, item, status, i);
        }
        else {
            status = "archive";
            this.toggleStoreStatusData(Item, item, status, i);
        }
        console.log(status);
    };
    CustomerStoreViewComponent.prototype.toggleStoreFrontStatus = function (citem, item, status, index) {
        this.openArchiveDialog(citem, item, status, index);
    };
    CustomerStoreViewComponent.prototype.openArchiveDialog = function (citem, item, status, index) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Archive',
                content: 'Are you sure, you want to archive store ' + item.name + '?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result == 'yes') {
                    //item.status = status;
                    _this.toggleStoreStatusData(citem, item, status, index);
                }
                else {
                }
            }
            // this.animal = result;
        });
    };
    // t-34
    CustomerStoreViewComponent.prototype.toggleStoreStatusData = function (citem, item, statuss, index) {
        var _this = this;
        this.dataservice
            .toggleStoreStatus(item.id.toString(), { status: statuss })
            .subscribe(function (data) {
            _this.toggleStoreStatusStatic(citem, item, statuss);
            _this.dataservice.productEdited = true;
        }, function (error) {
            console.log(error);
        });
    };
    CustomerStoreViewComponent.prototype.toggleStoreStatusStatic = function (citem, item, status) {
        var index = this.customer_collection.indexOf(citem);
        var storeindex = this.customer_collection[index].user_storefront.indexOf(item);
        this.customer_collection[index].user_storefront[storeindex].status = status;
    };
    // -----------------------------------------------------------------------------------------------page pagination----------------------------------------------------------------------------
    CustomerStoreViewComponent.prototype.setPageSizeOptions = function (setPageSizeOptionsInput) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(function (str) { return +str; });
    };
    CustomerStoreViewComponent.prototype.onchange = function ($event) {
        this.pageSize = $event.pageSize;
    };
    // -----------------------------------------------------------------------------------------------Sort----------------------------------------------------------------------------
    CustomerStoreViewComponent.prototype.sortDataDropDown = function () {
        var bool;
        if (this.selectedSort === this.asedesSort) {
            bool = false;
        }
        else {
            bool = true;
        }
        this.asedesSort = this.selectedSort;
        if (bool) {
            this.customer_collection.sort(this.dataservice.dynamicSort(this.selectedSort));
        }
        else {
            this.customer_collection
                .sort(this.dataservice.dynamicSort(this.selectedSort))
                .reverse();
            this.asedesSort = '';
        }
    };
    CustomerStoreViewComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.customer_collection.slice();
        if (!sort.active || sort.direction === '') {
            this.customer_collection = data;
            return;
        }
        this.customer_collection = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return _this.compare(a.name, b.name, isAsc);
                case 'nstorefront':
                    return _this.compare(a.user_storefront.length, b.user_storefront.length, isAsc);
                case 'location':
                    return _this.compare(a.address_city, b.address_city, isAsc);
                case 'status':
                    return _this.compare(a.status, b.status, isAsc);
                case 'user_type':
                    return _this.compare(a.user_type, b.user_type, isAsc);
                // case 'protein': return compare(a.protein, b.protein, isAsc);
                default:
                    return 0;
            }
        });
    };
    CustomerStoreViewComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    CustomerStoreViewComponent.prototype.deleteCustomer = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete',
                content: 'Are you sure, you want to delete customer ' + item.name + '?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result == 'yes') {
                    _this.dataservice.deleteCustomer(item.id).subscribe(function (data) {
                        _this.customer_collection.splice(_this.customer_collection.indexOf(item), 1);
                        _this.dataservice.productEdited = true;
                        _this.dataSource = new MatTableDataSource(_this.customer_collection);
                    });
                }
            }
        });
    };
    CustomerStoreViewComponent.prototype.deleteStore = function (item, index, slug, storeId) {
        var _this = this;
        console.log(storeId);
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete',
                content: "Are you sure, you want to delete store\n          " + item.user_storefront[index].name + " from " + item.user_storefront[index].user_v1 + "?",
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result == 'yes') {
                    _this.dataservice
                        .deleteStore(storeId)
                        .subscribe(function (data) {
                        item.user_storefront.splice(index, 1);
                        _this.dataservice.productEdited = true;
                        console.log(storeId);
                        _this.dataservice.deleteSfFromProductsCatalogue(storeId);
                    });
                }
            }
        });
    };
    CustomerStoreViewComponent.prototype.openSnackBar = function () {
        this.snackbar.openFromComponent(CopyUrl, {
            duration: 1500,
            horizontalPosition: "end",
            verticalPosition: "bottom"
        });
    };
    return CustomerStoreViewComponent;
}());
export { CustomerStoreViewComponent };
