import { OnInit } from '@angular/core';
import { FormControl, Validators, AbstractControl, } from '@angular/forms';
import { DataService } from '../data.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { map, debounceTime, distinctUntilChanged, } from 'rxjs/operators';
var UtilityComponent = /** @class */ (function () {
    function UtilityComponent(dataservice, dialog) {
        this.dataservice = dataservice;
        this.dialog = dialog;
        this.customercollection = [];
        this.selectedStores = [];
        this.tags = [];
        this.specialTags = [];
        this.bundleCategories = [];
        this.newStyle = "";
        this.newSpecialTag = "";
        this.bundleCategory = "";
        this.isInEditMode = false;
        this.coupon_type = 'flat_discount';
        this.validity_type = 'default';
        this.tabLabel = 'Create Coupon';
        this.is_valid = true;
        this.coupon_list = [];
        this.displayedColumns = ['coupon_name', 'coupon_type', 'coupon_valid', 'discount_value'];
        this.selectedIndex = 0;
        // coupon_name = new FormControl(null, {validators: Validators.compose([Validators.required, this.checkForCouponCode.bind(this)]), updateOn: 'change'});
        this.coupon_name = new FormControl(null, [
            Validators.required,
        ]);
        this.selectedStoreCol = new FormControl('', Validators.required);
        this.flatdiscount = new FormControl('', Validators.required);
        this.percentagediscount = new FormControl('', Validators.required);
        this.expirydate = new FormControl(null, [this.dateExpireValidater]);
        this.usagelimit = new FormControl(0, Validators.min(1));
    }
    UtilityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getStoreFrontData();
        this.getTagList();
        this.getSpecialTagList();
        this.getBundleCategoryList();
        this.coupon_name.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged(), map(function (data) {
            return data;
        }))
            .subscribe(function (data) {
            console.log('from valuechanges', data);
            _this.dataservice.couponNameValidityCheck(data).subscribe(function (res) {
                var response = res.json();
                if (response.status) {
                    if (!_this.isInEditMode) {
                        _this.coupon_name.setErrors({ couponInuse: true });
                    }
                }
            });
        });
    };
    UtilityComponent.prototype.onEdit = function () {
    };
    UtilityComponent.prototype.getTagList = function () {
        var _this = this;
        this.dataservice.getProductTagList().then(function (res) {
            console.log(res);
            _this.tags = res;
        });
    };
    UtilityComponent.prototype.getSpecialTagList = function () {
        var _this = this;
        this.dataservice.getProductSpecialTagList().then(function (res) {
            console.log(res);
            _this.specialTags = res;
        });
    };
    UtilityComponent.prototype.getBundleCategoryList = function () {
        var _this = this;
        this.dataservice.getBundleCategoryList().then(function (res) {
            console.log(res);
            _this.bundleCategories = res;
        });
    };
    UtilityComponent.prototype.addTag = function () {
        var _this = this;
        this.dataservice.addProductTag(this.newStyle).subscribe(function (data) {
            _this.tags.push(data);
        }, function (error) {
            console.log(error);
        });
    };
    UtilityComponent.prototype.addspecialTag = function () {
        var _this = this;
        this.dataservice.addProductSpecialag(this.newSpecialTag).subscribe(function (data) {
            _this.specialTags.push(data);
        }, function (error) {
            console.log(error);
        });
    };
    UtilityComponent.prototype.addCategory = function () {
        var _this = this;
        this.dataservice.addBundleCategory(this.bundleCategory).subscribe(function (data) {
            _this.bundleCategories.push(data);
        }, function (error) {
            console.log(error);
        });
    };
    UtilityComponent.prototype.removeStyle = function (id) {
        this.openDialog('style', id);
    };
    UtilityComponent.prototype.removeSpecialTag = function (id) {
        console.log(id);
        this.openDialog('specialtags', id);
    };
    UtilityComponent.prototype.removeBundleCategory = function (id) {
        var _this = this;
        this.dataservice.removeBundleCategory(id).subscribe(function (data) {
            _this.bundleCategories = _this.bundleCategories.filter(function (cat) { return cat.id != id; });
        }, function (error) {
            console.log(error);
        });
    };
    UtilityComponent.prototype.openDialog = function (type, id) {
        var _this = this;
        var msg = type == 'style' ? 'This action will remove the style from every store. Are you sure?' : 'This action will remove the tag from every product. Are you sure?';
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Remove", content: msg, type: "question" }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result) {
                if (result == "yes") {
                    if (type == 'style') {
                        _this.dataservice.removeProductTag(id).subscribe(function (data) {
                            _this.tags = _this.tags.filter(function (tag) { return tag.id != id; });
                        }, function (error) {
                            console.log(error);
                        });
                    }
                    else if (type == 'specialtags') {
                        _this.dataservice.removeProductSpecialTag(id).subscribe(function (data) {
                            _this.specialTags = _this.specialTags.filter(function (tag) { return tag.id != id; });
                        }, function (error) {
                            console.log(error);
                        });
                    }
                }
            }
            // this.animal = result;
        });
    };
    UtilityComponent.prototype.getStoreFrontData = function () {
        var _this = this;
        if (this.dataservice.customer_list.length === 0) {
            this.dataservice.getAllStoreFronts().subscribe(function (data) {
                var obj = data.json();
                _this.customercollection = obj['results'];
                _this.dataservice.customer_list = _this.customercollection;
                // this.consumeSelectedOptions(datax);
                console.log(_this.customercollection);
            });
        }
        else {
            this.customercollection = this.dataservice.customer_list;
            // this.consumeSelectedOptions(datax as userstorefront_obj[]);
        }
    };
    UtilityComponent.prototype.onCreateCoupon = function () {
        if (this.coupon_name.valid) {
            if (this.selectedStoreCol.valid) {
                if (this.coupon_type === 'flat_discount') {
                    if (this.flatdiscount.valid) {
                        this.createCoupon(this.coupon_type);
                    }
                    else {
                        this.flatdiscount.markAsTouched();
                    }
                }
                else {
                    if (this.percentagediscount.valid) {
                        this.createCoupon(this.coupon_type);
                    }
                    else {
                        this.percentagediscount.markAsTouched();
                    }
                }
            }
            else {
                this.selectedStoreCol.markAsTouched();
            }
        }
        else {
            this.coupon_name.markAsTouched();
        }
    };
    UtilityComponent.prototype.createCoupon = function (couponType) {
        if (this.expirydate.valid) {
            if (couponType === 'flat_discount') {
                var obj = {
                    coupon_name: this.coupon_name.value,
                    coupon_type: couponType,
                    discount_value: this.flatdiscount.value,
                    allowed_usage_count: this.usagelimit.value,
                    expiry_date: this.expirydate.value,
                    coupon_valid: this.is_valid,
                    data: this.returnStoreId(),
                };
                console.log(obj);
                this.postCoupon(obj);
            }
            if (couponType === 'percentage_discount') {
                var obj = {
                    coupon_name: this.coupon_name.value,
                    coupon_type: couponType,
                    percent_discount_value: this.percentagediscount.value,
                    allowed_usage_count: this.usagelimit.value,
                    expiry_date: this.expirydate.value,
                    coupon_valid: this.is_valid,
                    data: this.returnStoreId(),
                };
                console.log(obj);
                this.postCoupon(obj);
            }
        }
    };
    UtilityComponent.prototype.postCoupon = function (obj) {
        var _this = this;
        if (!this.isInEditMode) {
            this.dataservice.createCoupon(obj).subscribe(function (data) {
                console.log(data);
                _this.alert('Coupoun ' + _this.coupon_name.value + ' was created successfully', 'Success', 'confirmation');
            }, function (error) {
                console.log(error);
                _this.alert('Something went wrong', 'Error', 'error2');
            });
        }
        else {
            this.dataservice.updateCoupon(obj, this.selectedCoupon.coupon_id).subscribe(function (data) {
                console.log(data);
                _this.alert('Coupoun ' + _this.coupon_name.value + ' was updated successfully', 'Success', 'confirmation');
            }, function (error) {
                console.log(error);
                _this.alert('Something went wrong', 'Error', 'error2');
            });
        }
    };
    UtilityComponent.prototype.returnStoreId = function () {
        console.log(this.selectedStoreCol.value);
        var id = [];
        this.selectedStoreCol.value.forEach(function (element) {
            id.push({ storefront_id: element.id });
        });
        return id;
    };
    UtilityComponent.prototype.dateExpireValidater = function (control) {
        if (control.value !== undefined &&
            control.value !== null &&
            isNaN(control.value)) {
            console.log(control.value);
            var datefromcontrol = Date.parse(control.value);
            console.log(datefromcontrol, Date.now());
            if (datefromcontrol <= Date.now()) {
                return { invalidDate: true };
            }
        }
        return null;
    };
    UtilityComponent.prototype.callTp = function (data) {
        console.log(data);
    };
    UtilityComponent.prototype.alert = function (content, title, type) {
        var dialogRef = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: title,
                content: content,
                type: type,
            },
        });
    };
    // Select Coupons
    UtilityComponent.prototype.onTabChange = function ($event) {
        console.log($event);
        if ($event.index === 1) {
            this.getCoupons();
        }
    };
    UtilityComponent.prototype.getCoupons = function () {
        var _this = this;
        this.dataservice.getCoupons().subscribe(function (data) {
            // console.log(data);
            var result = data.json().results;
            _this.coupon_list = result;
            console.log(_this.coupon_list);
            _this.reorderCoupons();
        }, function (error) {
            console.log(error);
        });
    };
    UtilityComponent.prototype.reorderCoupons = function () {
        this.coupon_list = this.coupon_list.filter(function (element) {
            return element.is_active === true;
        });
    };
    UtilityComponent.prototype.openCouponStatusChangeDialog = function (coupon) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: coupon.coupon_valid ? 'Deactivate Coupon' : 'Activate Coupon',
                content: "Are you sure, you want to " + (coupon.coupon_valid ? 'deactivate coupon' : 'activate coupon') + "   " + coupon.coupon_name + " ?",
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            if (result) {
                if (result === 'yes') {
                    _this.dataservice.patchCoupon(coupon.coupon_id, { 'coupon_valid': !coupon.coupon_valid }).subscribe(function (data) {
                        console.log(data.json());
                        coupon.coupon_valid = !coupon.coupon_valid;
                    }, function (error) {
                        console.log(error);
                    });
                }
            }
            // this.animal = result;
        });
    };
    UtilityComponent.prototype.openCouponDeleteDialog = function (coupon) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Delete Coupon',
                content: "Are you sure, you want to delete " + coupon.coupon_name + " ?",
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            if (result) {
                if (result === 'yes') {
                    _this.dataservice.patchCoupon(coupon.coupon_id, { 'is_active': false }).subscribe(function (data) {
                        console.log(data.json());
                        coupon.is_active = false;
                        //coupon.coupon_valid = !coupon.coupon_valid;
                        _this.reorderCoupons();
                    }, function (error) {
                        console.log(error);
                    });
                }
            }
            // this.animal = result;
        });
    };
    UtilityComponent.prototype.editCoupon = function (coupon) {
        var _this = this;
        console.log(coupon);
        this.isInEditMode = true;
        this.tabLabel = 'Edit Coupon';
        this.selectedCoupon = coupon;
        this.coupon_name.setValue(coupon.coupon_name);
        this.usagelimit.setValue(coupon.allowed_usage_count);
        this.is_valid = coupon.coupon_valid;
        var currentDate = new Date(coupon.expiry_date).toISOString().substring(0, 10);
        this.expirydate.setValue(currentDate);
        var obj = [];
        coupon.data.forEach(function (element) {
            obj.push(_this.getSelectedStorefront(element['storefront_id']));
            console.log(_this.getSelectedStorefront(element['storefront_id']));
        });
        this.selectedStoreCol.setValue(obj);
        console.log(obj);
        this.flatdiscount.setValue(Number(coupon.discount_value));
        this.percentagediscount.setValue(Number(coupon.percent_discount_value));
        console.log(this.percentagediscount.value);
        this.coupon_type = coupon.coupon_type;
    };
    UtilityComponent.prototype.getSelectedStorefront = function (id) {
        var item;
        this.customercollection.forEach(function (element) {
            element.user_storefront.forEach(function (obj) {
                if (obj.id === id) {
                    item = obj;
                    return item;
                }
            });
        });
        return item;
    };
    UtilityComponent.prototype.setToCreateCoupon = function () {
        this.isInEditMode = false;
        this.tabLabel = 'Create Coupon';
        // this.selectedCoupon = coupon;
        this.coupon_type = 'flat_discount';
        this.coupon_name.setValue('');
        this.flatdiscount.setValue('');
        this.percentagediscount.setValue('');
        this.usagelimit.setValue('');
        this.is_valid = true;
        var currentDate = new Date().toISOString().substring(0, 10);
        this.expirydate.setValue(currentDate);
        this.selectedStoreCol.setValue(null);
    };
    return UtilityComponent;
}());
export { UtilityComponent };
