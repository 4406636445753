import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DataService } from '../data.service';
import { CompressImageService } from '../compress.service';
import { DialogComponent } from '../dialog/dialog.component';
import { FormBuilder, Validators } from '@angular/forms';
var AddbundleComponent = /** @class */ (function () {
    function AddbundleComponent(dataservice, dialog, snackBar, compressImage, sanitizer, formBuilder) {
        this.dataservice = dataservice;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.compressImage = compressImage;
        this.sanitizer = sanitizer;
        this.formBuilder = formBuilder;
        this.selectedstorefronts = [];
        this.bundleCategories = [];
        this.products = [];
        this.selectedCategories = [];
        this.displayedColumns = ['title', 'Count', 'products'];
        this.storefrontId = 0;
        this.progressStatus = 'Adding products ....';
        this.showProgress = false;
        this.prevnum = 0;
        this.isLoading = false;
        this.statusArr = [];
    }
    AddbundleComponent.prototype.ngOnInit = function () {
        this.initializeForm();
        this.getBundleCategoryList();
    };
    AddbundleComponent.prototype.fileupload = function (event) {
        var files = event.srcElement.files;
        if (files) {
            this.previewImage = files[0];
            var obj = this.formGroup.get('previewImage');
            obj.setValue(this.previewImage.name);
            obj.disable();
        }
    };
    AddbundleComponent.prototype.initializeForm = function () {
        this.formGroup = this.formBuilder.group({
            bundleName: ['', Validators.required],
            desc: [''],
            code: ['', Validators.required],
            price: ['', Validators.required],
            storefront: ['', Validators.required],
            category_count: ['', Validators.required],
            previewImage: ['', Validators.required]
        });
    };
    AddbundleComponent.prototype.getBundleCategoryList = function () {
        var _this = this;
        this.dataservice.getBundleCategoryList().then(function (res) {
            console.log(res);
            _this.bundleCategories = res;
        });
    };
    AddbundleComponent.prototype.onSelectCategories = function (event) {
        this.selectedCategories = event.value;
        console.log(this.selectedCategories);
    };
    AddbundleComponent.prototype.onSelectProducts = function (e, i) {
        console.log(e.value, i);
        var selectedProducts = e.value;
        var index = this.selectedCategories.findIndex(function (item) { return item.id === i; });
        console.log(index);
        this.selectedCategories[index]['products'] = selectedProducts;
        // this.selectedCategories[index]['category_count'] = this.formGroup.get('category_count').value
    };
    AddbundleComponent.prototype.onCategoryCountChange = function (id) {
        console.log(id);
        this.selectedCategories[id]['category_count'] = this.formGroup.get('category_count').value;
    };
    // addProductsToCategory(categoryId){this.formGroup.get(controlName)?.value;
    //   console.log(categoryId);
    // }
    AddbundleComponent.prototype.addBundle = function () {
        var _this = this;
        // First, define the bundleItems array
        var bundleItems = [];
        // Then, iterate through the categories
        this.selectedCategories.forEach(function (category) {
            // Iterate through the products of each category
            category.products.forEach(function (product) {
                // Create a bundleItem object and push it to the bundleItems array
                bundleItems.push({
                    bundle_category: category.id,
                    product: product.id,
                    category_count: category.category_count
                });
            });
        });
        // Now bundleItems contains the desired structure
        // console.log(bundleItems);
        // console.log(this.formGroup.value);
        var obj = {
            'title': this.formGroup.get('bundleName').value,
            'code': this.formGroup.get('code').value,
            'description': this.formGroup.get('desc').value,
            'price': this.formGroup.get('price').value,
            'storefront': this.storefrontId,
            'bundle_items': bundleItems,
        };
        this.statusArr.push({ title: this.formGroup.get('bundleName').value, status: 'In progress' });
        console.log(obj);
        this.dataservice.addBundle(obj, this.previewImage).subscribe(function (val) {
            var i = 0;
            _this.showProgress = true;
            _this.isLoading = true;
            console.log(val);
            val.forEach(function (element) {
                _this.dataservice.appendProduct_List(element);
            });
            _this.statusArr[i].status = "Done";
            _this.isLoading = false;
            _this.progressStatus = "Products added successfully";
        });
    };
    AddbundleComponent.prototype.openAddDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Select Storefronts",
                content: this.selectedstorefronts,
                type: "selectstorefront"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result.length > 0) {
                // let temp = this.selectedstorefronts;
                // let temp2 = result;
                _this.selectedstorefronts = result;
                _this.storefrontId = _this.selectedstorefronts[0].id;
                _this.dataservice.getProduct(_this.selectedstorefronts[0].id).then(function (product) {
                    console.log(product.storefront_product);
                    _this.products = product.storefront_product;
                    console.log(_this.products);
                });
            }
        });
    };
    AddbundleComponent.prototype.remove = function (index) {
        this.selectedstorefronts.splice(index, 1);
    };
    return AddbundleComponent;
}());
export { AddbundleComponent };
