import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { DataService } from './../data.service';
import { URLSearchParams } from '@angular/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatChipInputEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DialogComponent } from '../dialog/dialog.component';

import { m_customers_obj } from '../models'
import { ENTER, COMMA, SPACE, TAB } from '@angular/cdk/keycodes';





@Component({
  selector: 'app-addcustomer',
  templateUrl: './addcustomer.component.html',
  styleUrls: ['./addcustomer.component.css']
})
export class AddcustomerComponent implements OnInit {

  isLinear = false;
  tax = 8.75;
  Custlogo: any;
  logo_name: any;
  get_Ctype = false
  Cstatus: any = 'Active';
  customerDetails: FormGroup;
  secondFormGroup: FormGroup;
  // teams: {

  //   title: string;
  // }[];
  clubs: any = [];

  returnCustomer: m_customers_obj;

  teams: FormArray;

  constructor(private _formBuilder: FormBuilder, public dataservice: DataService, private route: Router, public dialog: MatDialog, private _location: Location) { }


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, TAB];

  allEmailId: string[] = [];

  ngOnInit() {

    this.customerDetails = this._formBuilder.group({
      name: ['', Validators.required],
      user_type: ['', Validators.required],
      logo: [''],
      phone_number: ['', Validators.pattern('[- +()0-9]+')],
      address_street: [''],
      address_city: [''],
      address_state: [''],
      postal_code: [''],
      contact_name: [''],
      contact_title: [''],
      username: [''],
      password_text: [''],
      status: [''],
      shipping_instructions: [''],
      sales_tax: [0],
      shipping_cost: [0],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      s_email: [''],
      is_international_shipping: [false],
      shipping_cost_international: [0],
      club: this._formBuilder.array([])
    });

    if (this.dataservice.draftCustomer) {
      console.log(this.dataservice.draftCustomer);
      console.log(this.dataservice.draftCustomer.data.secondary_emails);
      let draft_id = this.dataservice.draftCustomer.id
      this.customerDetails.patchValue(this.dataservice.draftCustomer.data);
      this.customerDetails.addControl('draft_id', new FormControl(draft_id));
      console.log(this.customerDetails.value);
      if (this.dataservice.draftCustomer.data.secondary_emails == "undefined" || this.dataservice.draftCustomer.data.secondary_emails == ""){

      }else{
        this.allEmailId = this.dataservice.draftCustomer.data.secondary_emails.split(',')


      }
    }
  }

  ngOnDestroy() {
    console.log("des");
    this.dataservice.draftCustomer = null;
  }

  goToDraft() {
    this.route.navigate(['/draft'])
  }


  createItem(): FormGroup {
    return this._formBuilder.group({
      name: ''
    });

  }


  addSecondaryEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim() && this.validateEmail(value)) {
      this.allEmailId.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.customerDetails.controls['s_email'].setValue(null);
  }



  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  remove(fruit: string): void {
    const index = this.allEmailId.indexOf(fruit);

    if (index >= 0) {
      this.allEmailId.splice(index, 1);
    }
  }

  saveCust(create) {
    console.log(this.customerDetails.value);
    console.log(this.Custlogo);
    if(this.Custlogo != undefined){
      console.log('logo');
    }else{
      console.log('no logo');
    }


    if (this.customerDetails.valid) {

      // let draft_id = this.dataservice.draftCustomer.id



      this.dataservice.addcustomer(this.customerDetails.value, this.Custlogo, this.get_Ctype, this.allEmailId, create).subscribe(res => {
        console.log(res);
        this.returnCustomer = <m_customers_obj>res.json();
        console.log(this.returnCustomer);
        this.dataservice.appendCustomer_List(this.returnCustomer);
        this.openDialog();
      },
        error => {
          const keys = Object.keys(error.json());
          const errorarry = [];
          keys.forEach(element => {
            //  console.log(error.json()[element]);
            let obj = {
              title: element,
              desc: error.json()[element].toString()
            };

            errorarry.push(obj);

          });



          console.log(errorarry);


          let errorconent = '';

          errorarry.forEach(element => {

            errorconent += `<p><b style="text-transform: capitalize;">${element.title}</b> ${element.desc}</p>`;

          });

          this.openDialogError('Error', errorconent);
        }


      )

    } else {

      this.openDialogError('Error', 'Please fill out all fields marked by *');

    }


  }

  saveAsDraftCust(draft) {

    if (this.customerDetails.valid) {



      this.dataservice.addcustomer(this.customerDetails.value, this.Custlogo, this.get_Ctype, this.allEmailId, draft).subscribe(res => {
        console.log(res);
        this.openActionDialogue('Success', 'Customer is succesfully saved as a draft!', 'Go to Draft');

      },
        error => {
          const keys = Object.keys(error.json());
          const errorarry = [];
          keys.forEach(element => {
            //  console.log(error.json()[element]);
            let obj = {
              title: element,
              desc: error.json()[element].toString()
            };

            errorarry.push(obj);

          });



          console.log(errorarry);


          let errorconent = '';

          errorarry.forEach(element => {

            errorconent += `<p><b style="text-transform: capitalize;">${element.title}</b> ${element.desc}</p>`;

          });

          this.openDialogError('Error', errorconent);
        }


      )

    } else {

      this.openDialogError('Error', 'Please fill out all fields marked by *');

    }
  }

  updateDraft() {
    console.log("update");
    console.log(this.customerDetails.value,this.allEmailId);

    if (this.customerDetails.valid) {
      this.dataservice.updateCustomerDraft(this.dataservice.draftCustomer.id, this.customerDetails.value,this.allEmailId).subscribe(
        res => {
          console.log(res);
          this.openDialogError('Success', 'Customer draft is succesfully updated!');

        }
      )
    } else {

      this.openDialogError('Error', 'Please fill out all fields marked by *');

    }

  }


  //Create Customer Logo
  fileupload(event) {
    // console.log(event);
    //console.log('got it file');
    var files = event.srcElement.files;
    if (files) {
      this.Custlogo = files[0];
      const obj = this.customerDetails.get('logo');
      obj.setValue(this.Custlogo.name);
      obj.disable();

    }


  }

  addClubRow() {
    this.addClub();
  }

  removeClubRow(index) {

    this.teams.removeAt(index);

  }

  addClub() {
    this.teams = this.customerDetails.get('club') as FormArray;
    this.teams.push(this.createItem());

  }

  addItem(): void {




    this.teams = this.customerDetails.get('club') as FormArray;


    if (this.teams.length === 0) {
      for (let index = 0; index < 5; index++) {
        this.teams.push(this.createItem());

      }
    }

  }

  getType() {
    console.log(this.customerDetails.value.user_type)
    this.get_Ctype = true;

    // this.addItem();

    // if (this.customerDetails.value.user_type === 'baseball' || this.customerDetails.value.user_type === 'volleyball') {
    //   this.get_Ctype = true;

    //   this.addItem();



    // }
    // else {
    //   this.get_Ctype = false;
    // }
  }




  cancel() {
    this._location.back();
    // this.route.navigate(['/customers']);
  }


  openDialogError(title: string, content: string) {


    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: title, content: content, type: 'error' }
    });
  }

  openActionDialogue(title: string, content: string, action: string) {
    const dialogRef = this.dialog.open(DialogComponent, {

      data: { title: title, content: { content: content, action: action }, type: 'action' }
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log("go to draft now");
      this.route.navigate(['/draft'])
    })

  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Success", content: `Customer <b>${this.customerDetails.value.name}</b> is created.`, type: 'addstore' }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == 'no') {
        console.log('The dialog was closed', result);

        this.route.navigateByUrl('/customers');
      }

      if (result === 'yes') {

        this.dataservice.setSelectedCustomer(this.returnCustomer);
        this.route.navigateByUrl('/addstorefront/' + this.returnCustomer.id)

      }
    });
  }


  type: Ctype[] = [
    { value: 'school', viewValue: 'Schools', showteam: false },
    { value: 'business', viewValue: 'Business', showteam: false },
    { value: 'corporate', viewValue: 'Corporate', showteam: false },
    { value: 'restaurant', viewValue: 'Restaurant', showteam: false },
    { value: 'sports', viewValue: 'Sports', showteam: true },
    // { value: 'volleyball', viewValue: 'Volleyball', showteam: true },
    // { value: 'baseball', viewValue: 'Baseball', showteam: true },
    { value: 'other', viewValue: 'Other', showteam: false }
  ];
  states = [
    { value: 'AL', viewValue: ' AL' },
    { value: 'AK', viewValue: 'AK' },
    { value: 'AZ', viewValue: 'AZ' },
    { value: 'AR', viewValue: 'AR' },
    { value: 'CA', viewValue: 'CA' },
    { value: 'CO', viewValue: 'CO' },
    { value: 'CT', viewValue: 'CT' },
    { value: 'DE', viewValue: 'DE' },
    { value: 'FL', viewValue: 'FL' },
    { value: 'GA', viewValue: 'GA' },
    { value: 'HI', viewValue: 'HI' },
    { value: 'ID', viewValue: 'ID' },
    { value: 'IL', viewValue: 'IL' },
    { value: 'IN', viewValue: 'IN' },
    { value: 'IA', viewValue: 'IA' },
    { value: 'KY', viewValue: 'KY' },
    { value: 'LA', viewValue: 'LA' },
    { value: 'ME', viewValue: 'ME' },
    { value: 'MD', viewValue: 'MD' },
    { value: 'MA', viewValue: 'MA' },
    { value: 'MI', viewValue: 'MI' },
    { value: 'MN', viewValue: 'MN' },
    { value: 'MS', viewValue: 'MS' },
    { value: 'MO', viewValue: 'MO' },
    { value: 'MT', viewValue: 'MT' },
    { value: 'NE', viewValue: 'NE' },
    { value: 'NV', viewValue: 'NV' },
    { value: 'NH', viewValue: 'NH' },
    { value: 'NJ', viewValue: 'NJ' },
    { value: 'NM', viewValue: 'NM' },
    { value: 'NY', viewValue: 'NY' },
    { value: 'NC', viewValue: 'NC' },
    { value: 'ND', viewValue: 'ND' },
    { value: 'OH', viewValue: 'OH' },
    { value: 'OK', viewValue: 'OK' },
    { value: 'OR', viewValue: 'OR' },
    { value: 'PA', viewValue: 'PA' },
    { value: 'RI', viewValue: 'RI' },
    { value: 'SC', viewValue: 'SC' },
    { value: 'SD', viewValue: 'SD' },
    { value: 'TN', viewValue: 'TN' },
    { value: 'TX', viewValue: 'TX' },
    { value: 'UT', viewValue: 'UT' },
    { value: 'VT', viewValue: 'VT' },
    { value: 'VA', viewValue: 'VA' },
    { value: 'WA', viewValue: 'WA' },
    { value: 'WV', viewValue: 'WV' },
    { value: 'WI', viewValue: 'WI' },
    { value: 'WY', viewValue: 'WY' },


  ];
  status = [
    { value: 'Potential', viewValue: 'Potential' },
    { value: 'Active', viewValue: 'Active' },
    { value: 'Archive', viewValue: 'Archive' }
  ];
}

export interface Ctype {
  value: string;
  viewValue: string;
  showteam: boolean;
}

