<mat-spinner
  style="position: absolute;
top: 50%;
left: 47%;
}"
  *ngIf="isLoading"
></mat-spinner>

<span [ngSwitch]="data.type">
  <div *ngSwitchCase="'confirmation'">
    <h1 mat-dialog-title>{{ data.title }}</h1>

    <mat-dialog-content [innerHtml]="data.content"></mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'okay'" cdkFocusInitial>
        Okay
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'error'">
    <h2 mat-dialog-title>{{ data.title }}</h2>

    <mat-dialog-content [innerHtml]="data.content"></mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-raised-button color="primary" [mat-dialog-close]="'okay'">
        Okay
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'action'">
    <h2 mat-dialog-title>{{ data.title }}</h2>

    <mat-dialog-content [innerHtml]="data.content.content"></mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-raised-button color="primary" [mat-dialog-close]="'okay'">
        {{ data.content.action }}
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'error2'">
    <h2 mat-dialog-title>{{ data.title }}</h2>

    <mat-dialog-content>
      <p>{{ data.content }}</p>
    </mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-raised-button color="primary" [mat-dialog-close]="'okay'">
        Okay
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'question'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>{{ data.content }}</mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'" cdkFocusInitial>No</button>

      <button mat-button [mat-dialog-close]="'yes'">Yes</button>
    </div>
  </div>

  <div *ngSwitchCase="'colorsizeinfo'">
    <mat-dialog-content>
      <div class="customerlist">
        <div class="mainlist">
          <div
            *ngFor="let item of data.content; let i = index"
            class="blockunit"
          >
            <li style="font-size: 20px">
              {{ item }}
            </li>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div style="width: 400px" *ngSwitchCase="'colorAndsizeOptions'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <div class="mainlist" style="list-style-type: none">
        <li>
          <h4>
            <mat-checkbox
              [(ngModel)]="allTagsChecked"
              (change)="selectAllTags()"
            >
              Styles
            </mat-checkbox>
          </h4>
          <div
            *ngFor="let tag of uniqueTagsArray; let i = index"
            class="blockunit" style="margin-left: 16px;"
          >
            <li>
              <mat-checkbox
                [checked]="tag.isChecked"
                [(ngModel)]="tag.isChecked"
                (change)="selectTag(i, $event.checked)"
              >
              </mat-checkbox>
              {{ tag.name }}
            </li>
          </div>
        </li>

        <li>
          <h4>
            <mat-checkbox
              [(ngModel)]="allColorChecked"
              (change)="selectAllColors()"
            >
              Color
            </mat-checkbox>
          </h4>
          <div
            *ngFor="let color of uniqueColorArray; let i = index"
            class="blockunit" style="margin-left: 16px;"
          >
            <li>
              <mat-checkbox
                [(ngModel)]="selectedColors[i]"
                (change)="selectColor(i, $event.checked)"
              >
              </mat-checkbox>
              {{ color }}
            </li>
          </div>
        </li>

        <li>
          <h4>
            <mat-checkbox
              [(ngModel)]="allSizeChecked"
              (change)="selectAllSizes()"
            >
              Size
            </mat-checkbox>
          </h4>
          <div
            *ngFor="let size of uniqueSizeArray; let i = index"
            class="blockunit" style="margin-left: 16px;"
          >
            <li>
              <mat-checkbox
                [(ngModel)]="selectedSizes[i]"
                (change)="selectSize(i, $event.checked)"
              >
              </mat-checkbox>
              {{ size }}
            </li>
          </div>
        </li>
      </div>
      <!-- <button (click)="getSelectedItems()">get</button> -->

      <!-- <ol class="mainlist">
        <h4>
          <mat-checkbox [(ngModel)]="allColorChecked" (change)="selectAllColors()">
            Color
          </mat-checkbox>
        </h4>
        <div *ngFor="
            let item of data.content
          " class="blockunit">
          <li>
            <mat-checkbox [checked]="allColorChecked"  [(ngModel)]="item.isChecked" (change)="selectColor()">
            </mat-checkbox>
             {{ item.c }}
          </li>

        </div> 
      </ol>
      <ol>
        <h4>
          <mat-checkbox [(ngModel)]="allSizeChecked" (change)="selectAllSizes()">
            Size
          </mat-checkbox>
        </h4>

        <div *ngFor="
            let size of data.content
          " class="blockunit">
          <li>
            <mat-checkbox [checked]="allSizeChecked" [(ngModel)]="size.isChecked" (change)="selectSize()">
            </mat-checkbox>
             {{ size.s }}
          </li>
          </div>
      </ol> -->
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>

      <button
        mat-button
        (click)="confirmClick()"
        [disabled]="selectedColors.length == 0 && selectedSizes.length == 0"
      >
        Confirm
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'reorder'">
    <h1 mat-dialog-title>History</h1>
    <mat-dialog-content>
      <table id="cart" class="table table-hover table-condensed">
        <thead>
          <tr class="font-fmly">
            <th style="width: 20%"></th>
            <th style="width: 10%">Price</th>
            <th style="width: 60%">Color / Size / Quantity</th>
            <th style="width: 10%" class="text-left">Subtotal</th>
          </tr>
        </thead>
        <tbody *ngFor="let iteml1 of reorderData">
          <tr *ngFor="let product of iteml1; let i = index">
            <td data-th="Product">
              <div class="row">
                <div class="col-sm-20 hidden-xs">
                  <img
                    *ngIf="product.product_images[0]"
                    src="{{ product.product_images[0].image }}"
                    alt="..."
                    class="img-responsive"
                  />
                  <p class="" *ngIf="!product.product_images">No Image</p>
                </div>
                <div class="col-sm-40">
                  <!-- <h4 class="nomargin">{{products.title}}</h4> -->
                  <p>{{ product.title }}</p>
                </div>
              </div>
            </td>
            <td data-th="Price">${{ product.unit_price }}</td>
            <td data-th="csq">
              <div *ngFor="let item of product.scq">
                {{ item.color }} / {{ item.size }} / {{ item.quantity }}
              </div>
            </td>

            <td data-th="Subtotal" class="text-left">
              ${{ product.sub_total }}
            </td>
          </tr>
          <tr>
            <td class="actions" style="border-top: none" data-th="">
              Amount : {{ calculateTotal(iteml1) }}$
            </td>
            <td class="actions" style="border-top: none" data-th="">
              <button
                class="btn-block btn-bg"
                mat-raised-button
                [mat-dialog-close]="iteml1"
              >
                Re-order
              </button>
            </td>
          </tr>
        </tbody>
        <!-- <tfoot>
          <td class="actions" data-th="">

          </td>
          <button class="btn-block btn-bg" mat-raised-button (click)="checkout()">Re-order</button>

        </tfoot> -->
      </table>
    </mat-dialog-content>
    <!-- <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'" cdkFocusInitial>No</button>

      <button mat-button [mat-dialog-close]="'yes'">Yes</button>
    </div> -->
  </div>

  <div *ngSwitchCase="'addstore'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content [innerHtml]="data.content"></mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>

      <button mat-button [mat-dialog-close]="'yes'" cdkFocusInitial>
        Add StoreFront
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'addproduct'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content [innerHtml]="data.content"></mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>

      <button mat-button [mat-dialog-close]="'yes'" cdkFocusInitial>
        Add Product
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'selectstorefront'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <div class="customerlist">
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="Search"
            [(ngModel)]="searchText"
            value=""
          />
        </mat-form-field>
        <ol class="mainlist">
          <div
            *ngFor="
              let item of customercollection | filter : searchText;
              let i = index
            "
            class="blockunit"
          >
            <li>
              <!-- <mat-checkbox [(ngModel)]="item.ischecked" (change)="selectCustomerStoreFront(item.id)">
              </mat-checkbox> -->
              <mat-icon>business</mat-icon>
              {{ item.name }}
            </li>

            <ol
              *ngIf="!item.isshown"
              style="list-style-type: circle; padding-bottom: 0"
            >
              <li
                style="margin-left: 2em"
                *ngFor="let obj of item.user_storefront; let i = index"
              >
                <!-- <mat-checkbox [(ngModel)]="obj.ischecked" (change)="updateStoreFronts()">
                </mat-checkbox>
                {{ obj.name }} <mat-icon class="icons">storefront</mat-icon> -->
                <mat-radio-group
                  [(ngModel)]="selectedStoreFront"
                  (change)="updateStoreFronts()"
                >
                  <mat-radio-button [value]="obj">
                    {{ obj.name }} <mat-icon class="icons">storefront</mat-icon>
                  </mat-radio-button>
                </mat-radio-group>
              </li>
            </ol>
          </div>
        </ol>
      </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="[]">Cancel</button>

      <button
        mat-button
        [disabled]="selectedstorefronts.length == 0"
        [mat-dialog-close]="selectedstorefronts"
      >
        Confirm
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'selectcustomer'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchText"
          value=""
        />
      </mat-form-field>
      <ol class="mainlist" style="list-style-type: none">
        <mat-radio-group [(ngModel)]="selectedCustomer">
          <div
            *ngFor="
              let item of customercollection | filter : searchText;
              let i = index
            "
          >
            <li>
              <mat-radio-button class="example-radio-button" [value]="item">
                {{ item.name }}
              </mat-radio-button>
            </li>
          </div>
        </mat-radio-group>
      </ol>
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>

      <button
        mat-button
        [disabled]="!selectedCustomer"
        [mat-dialog-close]="selectedCustomer"
      >
        Confirm
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'copystorefront'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div>
      <h4>Owner</h4>
      <mat-form-field class="example-full-width">
        <mat-label>Storefront Owner</mat-label>
        <mat-select [formControl]="selectedOwnerCol" multiple>
          <mat-select-trigger>
            {{ selectedOwnerCol.value ? selectedOwnerCol.value[0]?.name : "" }}
            <span
              *ngIf="selectedOwnerCol.value?.length > 1"
              class="example-additional-selection"
            >
              (+{{ selectedOwnerCol.value.length - 1 }}
              {{ selectedOwnerCol.value?.length === 2 ? "other" : "others" }})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of customercollection" [value]="item">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>

      <button
        mat-button
        [disabled]="!selectedOwnerCol.value"
        [mat-dialog-close]="selectedOwnerCol.value"
      >
        Confirm
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'filter'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content style="overflow: hidden">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Order Id" [(ngModel)]="orderId" value="" />
      </mat-form-field>
      <mat-form-field style="padding-left: 20px" class="example-full-width">
        <input
          matInput
          placeholder="Customer Name"
          [(ngModel)]="searchText"
          value=""
        />
      </mat-form-field>
      <!-- Owner / Store -->
      <div style="display: flex">
        <div>
          <h4>Owner</h4>
          <mat-form-field class="example-full-width">
            <mat-label>Storefront Owner</mat-label>
            <mat-select [formControl]="selectedOwnerCol" multiple>
              <mat-select-trigger>
                {{
                  selectedOwnerCol.value ? selectedOwnerCol.value[0]?.name : ""
                }}
                <span
                  *ngIf="selectedOwnerCol.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ selectedOwnerCol.value.length - 1 }}
                  {{
                    selectedOwnerCol.value?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let item of customercollection"
                [value]="item"
                >{{ item.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div style="padding-left: 20px">
          <h4>Store</h4>

          <mat-form-field class="example-full-width">
            <mat-label>Storefronts</mat-label>
            <mat-select [formControl]="selectedStoreCol" multiple>
              <mat-select-trigger>
                {{
                  selectedStoreCol.value ? selectedStoreCol.value[0]?.name : ""
                }}
                <span
                  *ngIf="selectedStoreCol.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ selectedStoreCol.value.length - 1 }}
                  {{
                    selectedStoreCol.value?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>

              <mat-optgroup
                *ngFor="let group of customercollection"
                [label]="group.name"
                [disabled]="group.disabled"
              >
                <mat-option
                  *ngFor="let item of group.user_storefront"
                  [value]="item"
                >
                  {{ item.name }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- status / tags -->
      <div style="display: flex">
        <div>
          <h4>Status</h4>
          <mat-form-field class="example-full-width">
            <mat-label>Order Status</mat-label>
            <mat-select [formControl]="selectedOrderStatus" multiple>
              <mat-select-trigger>
                {{
                  selectedOrderStatus.value
                    ? selectedOrderStatus.value[0]?.viewvalue
                    : ""
                }}
                <span
                  *ngIf="selectedOrderStatus.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ selectedOrderStatus.value.length - 1 }}
                  {{
                    selectedOrderStatus.value?.length === 2
                      ? "other"
                      : "others"
                  }})
                </span>
              </mat-select-trigger>

              <mat-option
                *ngFor="let item of orderstatuscollection"
                [value]="item"
              >
                {{ item.viewvalue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="padding-left: 20px">
          <h4>Tags</h4>
          <mat-form-field class="example-full-width">
            <mat-label>Tags</mat-label>
            <mat-select [formControl]="selectedTags" multiple>
              <mat-select-trigger>
                {{ selectedTags.value ? selectedTags.value[0]?.name : "" }}
                <span
                  *ngIf="selectedTags.value?.length > 1"
                  class="example-additional-selection"
                >
                  (+{{ selectedTags.value.length - 1 }}
                  {{ selectedTags.value?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>

              <mat-option *ngFor="let item of allTags" [value]="item">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Date / Custom Date-->
      <div style="display: flex">
        <div>
          <h4>Date</h4>
          <mat-form-field>
            <mat-label>Quick Dates</mat-label>
            <mat-select [formControl]="order_date">
              <mat-option *ngFor="let item of quickdates" [value]="item">
                {{ item.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div style="padding-left: 20px">
          <h4>Custom Date</h4>

          <mat-form-field class="example-full-width">
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Custom Date"
              [formControl]="date"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <h4>Archive</h4>

      <mat-slide-toggle [formControl]="is_archive"
        >Show archive orders</mat-slide-toggle
      >
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>

      <button
        mat-button
        (click)="checkData()"
        [mat-dialog-close]="searchparams"
      >
        Confirm
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'bulkupdate'">
    <h1 mat-dialog-title>{{ data.title }}</h1>

    <mat-dialog-content>
      {{ data.content }}

      <mat-form-field>
        <mat-label>Order status</mat-label>
        <mat-select [formControl]="order_date">
          <mat-option
            *ngFor="let item of orderstatuscollection"
            [value]="item.value"
          >
            {{ item.viewvalue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>
      <button mat-button [mat-dialog-close]="order_date.value" cdkFocusInitial>
        Confirm
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'archive'">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content style="overflow: hidden">
      {{ data.content }}
      <mat-slide-toggle [formControl]="is_archive"></mat-slide-toggle>
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="'no'">Cancel</button>
      <button mat-button [mat-dialog-close]="is_archive.value" cdkFocusInitial>
        Confirm
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'tags'" style="width: 450px">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <span>{{ data.content }}</span>
      <br /><br />
      <mat-form-field class="example-chip-list" style="width: 100%">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let fruit of tags"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(fruit)"
          >
            {{ fruit.name }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="New tags..."
            [formControl]="tagCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
        >
          <mat-option
            *ngFor="let fruit of allTags | filter : tagCtrl.value"
            [value]="fruit"
          >
            {{ fruit.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="[]">Cancel</button>
      <button mat-button [mat-dialog-close]="tags" cdkFocusInitial>
        Confirm
      </button>
    </div>
  </div>

  <div
    *ngSwitchCase="'showInventory'"
    style="display: flex; gap: 20px; margin: 0 10px"
  >
    <div *ngFor="let inventory of ProductInventory; let i = index">
      <app-inventory-card [inventory]="inventory"></app-inventory-card>
    </div>
  </div>

  <div *ngSwitchCase="'addcombination'">
    <h1>Add Combination</h1>
    <div>
      <form
        [formGroup]="comboForm"
        style="display: flex; flex-direction: column; gap: 10px"
        class="example-form"
      >
        <!-- <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Product Tag Options</mat-label>
            <mat-select [formControl]="tags" multiple>
              <mat-option *ngFor="let tag of data.content.tags" [value]="tag">{{tag.name}}</mat-option>
            </mat-select>
          </mat-form-field> -->

        <mat-radio-group
          formControlName="tag"
          style="display: grid; grid-template-columns: repeat(2, auto)"
        >
          <div
            style="margin-left: 2em"
            *ngFor="let item of data.content.tags; let i = index"
          >
            <mat-radio-button [value]="item">
              {{ item.name }}
            </mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Add Color</mat-label>
          <input
            matInput
            placeholder="Red"
            [(ngModel)]="data.content.color"
            formControlName="color"
          />
        </mat-form-field>

        <mat-form-field *ngIf="data.content.category != 1" class="example-full-width" appearance="fill">
          <mat-label>Add Size</mat-label>
          <input matInput placeholder="M" [(ngModel)]="data.content.size" formControlName="size">
        </mat-form-field>

        <mat-radio-group
        *ngIf="data.content.category === 1"
          formControlName="size"
          style="display: grid; grid-template-columns: repeat(2, auto)"
        >
          <div
            style="margin-left: 2em"
            *ngFor="let item of data.content.size; let i = index"
          >
            <mat-radio-button [value]="item">
              {{ item }}
            </mat-radio-button>
          </div>
        </mat-radio-group>

        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Add Quantity</mat-label>
          <input
            matInput
            placeholder="10"
            [(ngModel)]="data.content.quantity"
            formControlName="quantity"
          />
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Price</mat-label>
          <input
            matInput
            placeholder="10"
            [(ngModel)]="data.content.price"
            formControlName="price"
          />
        </mat-form-field>
        <div style="display: flex; gap: 5px">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;
            "
          >
            <button (click)="file.click()" mat-mini-fab color="disabled">
              <mat-icon>upload</mat-icon>

              <input
                style="display: none"
                (change)="choseImages($event)"
                #file
                (click)="$event.target.value = null"
                multiple
                type="file"
                accept="image/*"
              />
            </button>
            <p>Upload Images</p>
          </div>

          <div
            style="margin: 5px"
            class=""
            *ngFor="let item of ImageArray; let imgin = index"
          >
            <div style="position: relative">
              <img [src]="item.url" width="50" height="50" class="img-p" />
              <mat-icon
                class="icons"
                style="
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  color: white;
                  cursor: pointer;
                "
                (click)="removeImage(imgin)"
                class=""
              >
                clear
              </mat-icon>
            </div>
          </div>
        </div>

        <button
          (click)="SaveCombination()"
          [ngStyle]="
            !isLoading
              ? { 'pointer-events': 'auto' }
              : { 'pointer-events': 'none' }
          "
          mat-raised-button
          color="primary"
        >
          Save
        </button>
      </form>
    </div>
  </div>
  <div *ngSwitchDefault></div>
</span>
