import { OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { order_obj, searchparams, tags } from '../models';
import { MatDialog, Sort } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { CustomerPortalComponent } from '../customer-portal/customer-portal.component';
import { MatSnackBar } from '@angular/material';
import { CopyUrl } from '../multiple-prodcts/multiple-prodcts.component';
var param_m = /** @class */ (function () {
    function param_m() {
    }
    return param_m;
}());
export { param_m };
var Ordersv2Component = /** @class */ (function () {
    function Ordersv2Component(dataservice, dialog, route, excel, snackbar) {
        this.dataservice = dataservice;
        this.dialog = dialog;
        this.route = route;
        this.excel = excel;
        this.snackbar = snackbar;
        this.selectedTab = 0;
        this.delievryNotes = '';
        this.informCust = false;
        this.hasData = false;
        this.order_status = '0';
        this.trackid = '';
        this.Prodelivered = false;
        this.order_collection = [];
        this.checksbox = false;
        this.obj = {};
        this.selected_orders = [];
        // searchparam: searchparams;
        this.searchparam = {
            puchaser_name: '',
            storefront_collection: [],
            customer_collection: [],
            order_status: [],
            custom_date: '',
            order_date: '',
            is_archive: '',
            order_id: '',
            tags: []
        };
        this.matlist = [];
        this.multicollectionarry = [];
        this.indexs = 0;
        this.bundlemainthing = [];
        this.transformedData = [];
        this.ctype = '';
        this.otherService = '';
        this.note1 = false;
        this.selectedMode = '';
        this.isSelected = false;
        this.allchecked = false;
    }
    Ordersv2Component.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.order_collection.slice();
        if (!sort.active || sort.direction === '') {
            this.order_collection = data;
            return;
        }
        console.log(this.order_collection);
        this.order_collection = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'id':
                    return _this.compare(a.id, b.id, isAsc);
                case 'customer_name':
                    return _this.compare(a.customer_name, b.customer_name, isAsc);
                case 'total_amount':
                    return _this.compare(a.total_amount, b.total_amount, isAsc);
                case 'created_v1':
                    return _this.compare(new Date(a.created_v1).getTime(), new Date(b.created_v1).getTime(), isAsc);
                case 'storefront':
                    return _this.compare(a.storefront, b.storefront, isAsc);
                case 'status':
                    return _this.compare(a.order_tracking, b.order_tracking, isAsc);
                case 'payment_mode':
                    return _this.compare(a.payment_status, b.payment_status, isAsc);
                default:
                    return 0;
            }
        });
    };
    Ordersv2Component.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    Ordersv2Component.prototype.ngOnInit = function () {
        //  this.dataservice.orderFilterV2().subscribe(data => {
        var _this = this;
        //  },
        //  error => {
        //    console.log(error)
        //  })
        this.route.params.subscribe(function (params) {
            var custid = params['cust_id'];
            if (custid) {
                if (custid.length > 10) {
                    _this.dataservice.getSingleCustomer(custid).subscribe(function (data) {
                        _this.searchparam.customer_collection = data.json().results;
                        _this.createMatlist();
                    }, function (error) {
                        console.log(error);
                    });
                }
                else {
                    if (_this.dataservice.selected_storefront) {
                        _this.searchparam.storefront_collection.push(_this.dataservice.selected_storefront);
                        _this.createMatlist();
                    }
                    else {
                        _this.dataservice.getSingleStore(custid).subscribe(function (data) {
                            _this.searchparam.storefront_collection.push(data.json());
                            _this.createMatlist();
                        }, function (error) {
                            console.log(error);
                        });
                    }
                }
            }
        });
    };
    Ordersv2Component.prototype.openFilters = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: 'Filters', content: '', type: 'filter' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result !== 'no') {
                _this.searchparam = result;
                _this.createMatlist();
            }
        });
    };
    Ordersv2Component.prototype.createMatlist = function () {
        var _this = this;
        this.matlist = [];
        if (this.searchparam.puchaser_name) {
            var parm = new param_m();
            parm.param_key = 'purchaser_name';
            parm.param_view_value =
                'Customer name: ' + this.searchparam.puchaser_name;
            parm.param_value = this.searchparam.puchaser_name;
            this.matlist.push(parm);
        }
        if (this.searchparam.is_archive === 'true') {
            var parm = new param_m();
            parm.param_key = 'is_archived';
            parm.param_view_value = 'Show archived orders: ' + 'True';
            parm.param_value = 1;
            this.matlist.push(parm);
        }
        if (this.searchparam.is_archive === 'false') {
            var parm = new param_m();
            parm.param_key = 'is_archived';
            parm.param_view_value = 'Show archived orders: ' + 'False';
            parm.param_value = 0;
            this.matlist.push(parm);
        }
        if (this.searchparam.order_date) {
            var parm = new param_m();
            parm.param_key = 'order_date';
            parm.param_view_value = '' + this.searchparam.order_date['key'];
            parm.param_value = this.searchparam.order_date['value'];
            this.matlist.push(parm);
        }
        this.searchparam.customer_collection.forEach(function (element) {
            var parm = new param_m();
            parm.param_key = 'customer_id';
            parm.param_view_value = 'Owner: ' + element.name;
            parm.param_value = element.id;
            _this.matlist.push(parm);
        });
        this.searchparam.tags.forEach(function (element) {
            var parm = new param_m();
            parm.param_key = 'tags';
            parm.param_view_value = 'tag: ' + element.name;
            parm.param_value = element.id;
            _this.matlist.push(parm);
        });
        this.searchparam.storefront_collection.forEach(function (element) {
            var parm = new param_m();
            parm.param_key = 'store_id';
            parm.param_view_value = 'Storefront: ' + element.name;
            parm.param_value = element.id;
            _this.matlist.push(parm);
        });
        this.searchparam.order_status.forEach(function (element) {
            var parm = new param_m();
            parm.param_key = 'order_status';
            parm.param_view_value = 'Order status: ' + element.viewvalue;
            parm.param_value = element.value;
            _this.matlist.push(parm);
        });
        if (this.searchparam.custom_date) {
            var parm = new param_m();
            parm.param_key = 'custom_date';
            parm.param_view_value = 'Order date: ' + this.searchparam.custom_date;
            parm.param_value = this.searchparam.custom_date;
            this.matlist.push(parm);
        }
        if (this.searchparam.order_id) {
            var parm = new param_m();
            parm.param_key = 'order_id';
            parm.param_view_value = 'Order Id: ' + this.searchparam.order_id;
            parm.param_value = this.searchparam.order_id;
            this.matlist.push(parm);
        }
        this.getData();
    };
    Ordersv2Component.prototype.createSearchParams = function () {
        var _this = this;
        this.obj = {};
        this.matlist.forEach(function (element) {
            if (_this.obj.hasOwnProperty(element.param_key)) {
                if (_this.obj[element.param_key]) {
                    _this.obj[element.param_key] =
                        _this.obj[element.param_key] + ',' + element.param_value;
                }
            }
            else {
                _this.obj[element.param_key] = element.param_value;
            }
        });
        return this.obj;
    };
    Ordersv2Component.prototype.remove = function (i) {
        this.matlist.splice(i, 1);
        this.getData();
        // this.createSearchParams();
    };
    Ordersv2Component.prototype.getData = function () {
        var _this = this;
        // tslint:disable-next-line: curly
        if (this.matlist.length === 0)
            return;
        this.multicollectionarry = [];
        this.dataservice.orderFilterV2(this.createSearchParams()).subscribe(function (data) {
            _this.paginatingFunc(data.json().results, data.json().count);
        }, function (error) {
            console.log(error);
        });
    };
    Ordersv2Component.prototype.paginatingFunc = function (result, numberofentry) {
        console.log(result);
        var numberdividefactor = 0;
        if (result.length <= numberofentry) {
            numberdividefactor = result.length;
        }
        else {
            numberdividefactor = numberofentry;
        }
        var numberofpage = numberofentry / numberdividefactor;
        if (numberofpage > 1) {
            for (var index = 0; index < Math.trunc(numberofpage) + 1; index++) {
                var storefront_collection = [];
                this.multicollectionarry.push(storefront_collection);
            }
            this.multicollectionarry[0] = result;
            this.order_collection = this.multicollectionarry[0];
        }
        else {
            this.order_collection = result;
            console.log(this.order_collection);
        }
    };
    Ordersv2Component.prototype.paginate = function (pagenumber) {
        this.indexs = pagenumber;
        if (this.multicollectionarry[pagenumber].length > 0) {
            this.order_collection = this.multicollectionarry[pagenumber];
        }
        else {
            this.getNextResult(pagenumber + 1);
        }
    };
    Ordersv2Component.prototype.getNextResult = function (pagenumber) {
        var _this = this;
        var obj = this.createSearchParams();
        obj['page'] = pagenumber;
        this.dataservice.orderFilterV2(obj).subscribe(function (data) {
            _this.multicollectionarry[pagenumber - 1] = data.json().results;
            _this.order_collection = _this.multicollectionarry[pagenumber - 1];
            console.log(_this.order_collection);
        }, function (error) {
            console.log(error);
        });
    };
    Ordersv2Component.prototype.transformData = function (jsonData) {
        var transformedDataMap = [];
        jsonData.forEach(function (item) {
            var categoryName = item.category_name;
            var productData = item;
            // Check if category already exists in transformedDataMap
            var existingCategory = transformedDataMap.find(function (category) { return category.name === categoryName; });
            if (!existingCategory) {
                // If category doesn't exist, create a new entry
                existingCategory = { name: categoryName, products: [] };
                transformedDataMap.push(existingCategory);
            }
            // Push product_data into the products array
            existingCategory.products.push(productData);
        });
        return transformedDataMap;
    };
    Ordersv2Component.prototype.getdetails = function (i) {
        this.selectedTab = 0;
        this.cust_details = this.order_collection[i];
        // this.cust_details.bundles.forEach(element => {
        //   this.bundlemainthing.push(this.transformData(element.bundleinventory_set))
        // });
        console.log(this.bundlemainthing);
        this.order_id = this.cust_details.id;
        console.log(this.cust_details);
        if (this.cust_details.shipping_cost === null) {
            this.cust_details.shipping_cost = 0;
        }
        this.getTrackDetails();
        var parentname = this.cust_details.purchase_user.parent_first_name;
        var class_name = this.cust_details.purchase_user.class_name;
        var purchase_order = this.cust_details.purchase_user.purchase_order;
        this.customername = this.cust_details.purchase_user.name;
        this.Cemail = this.cust_details.purchase_user.email;
    };
    Ordersv2Component.prototype.getTrackDetails = function () {
        var _this = this;
        var abc = '';
        this.hasData = false;
        this.dataservice.getTrackdetails(this.order_id).then(function (res) {
            _this.estimatedD = res['delivery_date_v1'];
            _this.order_status = res['status'];
            _this.exptedD = res['departure_v1'];
            console.log(_this.exptedD);
            _this.delievryNotes = res['notes'];
            _this.informCust = res['inform_customer'];
            _this.trackid = res['tracking_id'];
            _this.couriertype = res['delivery_service'];
            abc = res['status'];
            if (abc) {
                _this.hasData = true;
            }
            else {
                _this.hasData = false;
            }
        });
    };
    Ordersv2Component.prototype.onTabchange = function ($event) {
        this.selectedTab = $event;
    };
    Object.defineProperty(Ordersv2Component.prototype, "currentOrderStatus", {
        get: function () {
            switch (this.order_status) {
                case '0':
                    return 'received';
                case '1':
                    return 'processed';
                case '2':
                    return 'dispached';
                case '3':
                    return 'delivered';
                default:
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    Ordersv2Component.prototype.close = function () {
        this.order_status = this.couriertype = this.estimatedD = this.trackid = this.delievryNotes =
            '';
        this.order_status = '0';
        this.getData();
    };
    Ordersv2Component.prototype.changeOrderStatus = function ($event) {
        this.order_status = $event.target.value;
    };
    Ordersv2Component.prototype.courierType = function ($event) {
        if ($event.target.value != 'other') {
            this.couriertype = $event.target.value;
        }
        else {
            this.couriertype = this.otherService;
        }
    };
    Ordersv2Component.prototype.otherCourierChange = function ($event) {
        this.couriertype = $event;
    };
    Ordersv2Component.prototype.downloadPdf = function () {
        var _this = this;
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(function (canvas) {
            // Few necessary setting options
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var contentDataURL = canvas.toDataURL('image/png');
            var pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            var textfilename = 'receipt_' + _this.order_id + '.pdf';
            //var obj = pdf.output('blob');
            pdf.save(textfilename); // Generated PDF
        });
    };
    Ordersv2Component.prototype.sendemail = function () {
        var _this = this;
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(function (canvas) {
            // Few necessary setting options
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var contentDataURL = canvas.toDataURL('image/png');
            var pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            var textfilename = 'receipt_' + _this.order_id + '.pdf';
            var obj = pdf.output('blob');
            _this.dataservice.sendemail(_this.order_id, obj).then(function (res) { });
            //pdf.save(textfilename); // Generated PDF
        });
    };
    Ordersv2Component.prototype.inform = function ($event) {
        this.informCust = $event.checked;
    };
    Ordersv2Component.prototype.notes = function ($event) {
        this.note1 = $event.checked;
    };
    Ordersv2Component.prototype.form1 = function () {
        console.log('form1');
    };
    // save status event
    Ordersv2Component.prototype.saveState = function () {
        console.log(this.estimatedD);
        if (!this.hasData) {
            // this.estimatedD = new Date(this.estimatedD);
            // this.exptedD = new Date(this.exptedD);
            this.dataservice
                .postTrackdetails(this.couriertype, this.order_status, new Date(this.estimatedD), this.informCust, this.trackid, new Date(this.exptedD), this.delievryNotes, this.Prodelivered, this.order_id)
                .then(function (res) {
                console.log(res);
            });
        }
        else {
            //this.informCust = this.informCust['checked'];
            // this.estimatedD = new Date(this.estimatedD);
            // this.exptedD = new Date(this.exptedD);
            this.dataservice
                .patchTrackdetails(this.couriertype, this.order_status, new Date(this.estimatedD), this.informCust, this.trackid, new Date(this.exptedD), this.delievryNotes, this.Prodelivered, this.order_id)
                .then(function (res) {
                console.log(res);
            });
        }
    };
    Ordersv2Component.prototype.selectionMode = function (btn) {
        switch (btn) {
            case 0:
                this.createExcel();
                // console.log(this.createSearchParams());
                // this.dataservice.getOrdersReport(this.createSearchParams()).subscribe(
                //   res => {
                //     console.log("report success");
                //     var FileSaver = require('file-saver');
                //     var blob = new Blob([res as BlobPart], { type: "text/plain;charset=utf-8" });
                //     FileSaver.saveAs(blob, "report.csv");
                //   }
                // )
                break;
            case 1:
                this.selectedMode = 'Bulk Update Orders';
                this.toggleSelectionMode();
                break;
            case 2:
                this.selectedMode = 'Add Tag to Orders';
                this.toggleSelectionMode();
                break;
            case 3:
                this.selectedMode = 'Archive Orders';
                this.toggleSelectionMode();
                break;
            case 4:
                this.createStampsExcel();
                break;
            default:
                break;
        }
    };
    Ordersv2Component.prototype.toggleSelectionMode = function () {
        this.checksbox = this.checksbox ? false : true;
        if (this.checksbox) {
            this.selected_orders = [];
        }
    };
    Ordersv2Component.prototype.selectItems = function (i, $event) {
        console.log(i, $event);
    };
    Ordersv2Component.prototype.createExcel = function () {
        this.excel.createExcel(this.order_collection);
    };
    Ordersv2Component.prototype.createStampsExcel = function () {
        this.excel.createStampsExcel(this.order_collection);
    };
    Ordersv2Component.prototype.onCheck = function (item) { };
    Ordersv2Component.prototype.cancelEdit = function () {
        this.checksbox = false;
        this.selected_orders = [];
    };
    Ordersv2Component.prototype.doEdit = function () {
        switch (this.selectedMode) {
            case 'Bulk Update Orders':
                this.bulkUpdate();
                break;
            case 'Add Tag to Orders':
                this.tagOrders();
                break;
            case 'Archive Orders':
                this.archiveOrders();
                break;
            default:
                break;
        }
    };
    //bulkupdate
    Ordersv2Component.prototype.bulkUpdate = function () {
        this.openBulkUpdate();
    };
    Ordersv2Component.prototype.openBulkUpdate = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Select status',
                content: 'Do you wish to update status for orders  ' +
                    this.getIds().toString() +
                    '  to ',
                type: 'bulkupdate'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result !== 'no') {
                    _this.updateOrderStatus(_this.getIds(), result);
                }
            }
        });
    };
    Ordersv2Component.prototype.updateOrderStatus = function (ids, status) {
        var _this = this;
        this.dataservice.BulkstatusUpdate(ids, status).subscribe(function (data) {
            _this.updateStatusStatic(status);
        }, function (error) {
            console.log(error);
        });
    };
    Ordersv2Component.prototype.updateStatusStatic = function (status) {
        for (var index = 0; index < this.order_collection.length; index++) {
            if (this.order_collection[index].is_checked) {
                this.order_collection[index].order_tracking = this.dataservice.orderstatuscollection[status].viewvalue;
            }
        }
        //this.deselectAll();
        this.checksbox = false;
    };
    //archive
    Ordersv2Component.prototype.archiveOrders = function () {
        this.openOrderArchive();
    };
    Ordersv2Component.prototype.openOrderArchive = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Archive/Unarchive',
                content: 'Do you wish to Archive/Unarchive orders    ' +
                    this.getIds().toString(),
                type: 'archive'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result !== 'no') {
                var state = result ? 1 : 0;
                _this.updateArchiveStatus(_this.getIds(), state);
            }
        });
    };
    Ordersv2Component.prototype.updateArchiveStatus = function (ids, status) {
        var _this = this;
        this.dataservice.addArchive(ids, status).subscribe(function (data) {
            _this.updateArchiveStatic(status);
        }, function (error) {
            console.log(error);
        });
    };
    Ordersv2Component.prototype.updateArchiveStatic = function (resukt) {
        for (var index = 0; index < this.order_collection.length; index++) {
            if (this.order_collection[index].is_checked) {
                if (resukt === 1) {
                    this.order_collection[index].is_archived = true;
                }
                else {
                    this.order_collection[index].is_archived = false;
                }
            }
        }
        //this.deselectAll();
        this.checksbox = false;
    };
    //addtag
    Ordersv2Component.prototype.tagOrders = function () {
        this.openTagOrders();
    };
    Ordersv2Component.prototype.openTagOrders = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Select tags',
                content: 'Add tag to orders ' + this.getIds().toString(),
                type: 'tags'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result.length > 0) {
                var tags_1 = result;
                var arr_1 = [];
                tags_1.forEach(function (element) {
                    arr_1.push(element.id);
                });
                _this.addTagsToOrders(arr_1, tags_1);
            }
        });
    };
    Ordersv2Component.prototype.addTagsToOrders = function (tags, tagc) {
        var _this = this;
        this.dataservice.postTagsOrder(this.getIds(), tags).subscribe(function (data) {
            _this.checksbox = false;
            _this.addTagsToOrderstatic(tagc);
        }, function (error) { });
    };
    Ordersv2Component.prototype.addTagsToOrderstatic = function (tagx) {
        var _this = this;
        var _loop_1 = function (index) {
            if (this_1.order_collection[index].is_checked) {
                tagx.forEach(function (element) {
                    _this.order_collection[index].tags.push(element);
                });
            }
        };
        var this_1 = this;
        for (var index = 0; index < this.order_collection.length; index++) {
            _loop_1(index);
        }
    };
    Ordersv2Component.prototype.selectAll = function () {
        var value = this.allchecked ? false : true;
        this.order_collection.forEach(function (order) {
            order.is_checked = value;
        });
        this.getIds();
    };
    Ordersv2Component.prototype.getIds = function () {
        var arr = [];
        this.order_collection.forEach(function (element) {
            if (element.is_checked) {
                arr.push(element.id);
            }
        });
        if (arr.length > 0) {
            this.isSelected = true;
        }
        else {
            this.isSelected = false;
        }
        return arr;
    };
    Ordersv2Component.prototype.deselectAll = function () {
        for (var index = 0; index < this.order_collection.length; index++) {
            this.order_collection[index].is_checked = false;
        }
        this.getIds();
    };
    //removeTag
    Ordersv2Component.prototype.removeTag = function (tag, order) {
        this.openRemoveTag(tag, order);
    };
    Ordersv2Component.prototype.openRemoveTag = function (tag, order) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Remove Tag',
                content: 'Do you wish to remove tag ' +
                    tag.name +
                    ' from order ' +
                    order.id +
                    ' ?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                if (result === 'yes') {
                    _this.removeTagData(tag, order);
                }
            }
        });
    };
    Ordersv2Component.prototype.removeTagData = function (tag, order) {
        var _this = this;
        var tagx = [];
        var orderx = [];
        tagx.push(tag.id);
        orderx.push(order.id);
        this.dataservice.removeTag(tagx, orderx).subscribe(function (data) {
            _this.removeTagFromLocal(tag, order);
        }, function (error) {
            console.log(error);
        });
    };
    Ordersv2Component.prototype.removeTagFromLocal = function (tag, order) {
        var arryindex = this.order_collection.indexOf(order);
        var tagindex = this.order_collection[arryindex].tags.indexOf(tag);
        this.order_collection[arryindex].tags.splice(tagindex, 1);
    };
    Ordersv2Component.prototype.copyUrlToClipboard = function () {
        var _this = this;
        var custdetails = '';
        // if(this.cust_details.purchase_user.is_shipping_address){
        // custdetails = this.cust_details.purchase_user.name.trim() + "\n" + this.cust_details.purchase_user.address_s.trim() + " " +  this.cust_details.purchase_user.address_city_s.trim()+"," + " " + this.cust_details.purchase_user.address_state_s.trim() + "," + " " +this.cust_details.purchase_user.postal_code_s.trim() + "\n"
        // + this.cust_details.purchase_user.address_country_s.trim() + "\n"
        // + this.cust_details.purchase_user.email.trim() + "\n";
        // }else{
        custdetails = this.cust_details.purchase_user.name.trim() + "\n" + this.cust_details.purchase_user.address.trim() + " " + this.cust_details.purchase_user.address_city.trim() + "," + " " + this.cust_details.purchase_user.address_state.trim() + "," + " " + this.cust_details.purchase_user.postal_code.trim() + "\n"
            + this.cust_details.purchase_user.address_country.trim() + "\n"
            + this.cust_details.purchase_user.email.trim() + "\n";
        //  }
        var listener = function (e) {
            e.clipboardData.setData('text/plain', (custdetails));
            e.preventDefault();
            _this.openSnackBar();
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    };
    Ordersv2Component.prototype.openSnackBar = function () {
        this.snackbar.openFromComponent(CopyUrl, {
            duration: 1500,
            horizontalPosition: "end",
            verticalPosition: "bottom"
        });
    };
    return Ordersv2Component;
}());
export { Ordersv2Component };
function saveAs(blob, arg1) {
    throw new Error('Function not implemented.');
}
