import { Router } from "@angular/router";
// import { CustomersComponent } from './customers/customers.component';
import { DataService } from "./data.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "./dialog/dialog.component";
var AppComponent = /** @class */ (function () {
    function AppComponent(dataservice, router, dialog) {
        var _this = this;
        this.dataservice = dataservice;
        this.router = router;
        this.dialog = dialog;
        this.list = false;
        this.val = 0;
        this.width = 50;
        this.left = 50;
        this.padding = 15;
        this.left_nav = 0;
        this.navbar_set = 0;
        this.displaytype = 'block';
        this.dataservice.subBoolShowHide$.subscribe(function (data) {
            _this.commonApp = data;
        }, function (error) { return console.log(error); }, function () { return console.log("winner winner chicken dinner"); });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            _this.urlname = event["url"];
            // console.log(this.urlname);
            // if(this.urlname == '/dashboard'){
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else if(this.urlname == '/customers')
            // {
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else if(this.urlname == '/customfilter'){
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else if(this.urlname == '/login'){
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else if(this.urlname == '/customerlist'){
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else if(this.urlname == '/multiProducts'){
            //   this.urls = true;
            //   console.log(this.urls)
            // }
            // else{
            //   this.urls = false;
            //   console.log(this.urls)
            // }
            // console.log(this.urlname);
        });
        // console.log("Hello from appComponent")
        // this.commonApp = this.dataservice.commonApp ;
    };
    AppComponent.prototype.onClickOutside = function () {
        this.width = 50;
        this.left = 50;
        this.padding = 15;
        this.left_nav = 0;
        // console.log(this.padding,'15')
        //console.log(this.padding,'15')
    };
    AppComponent.prototype.showMenu = function () {
        this.width = this.width == 250 ? 50 : 250;
        this.left = this.left == 250 ? 50 : 250;
        this.padding = this.padding == 32 ? 15 : 32;
        this.left_nav = this.left_nav = 250 ? 0 : 250;
        //console.log(this.padding,'32')
    };
    AppComponent.prototype.customer_reload = function () {
        window.location.reload();
    };
    AppComponent.prototype.logout = function () {
        this.navbar_set = 1;
        sessionStorage.removeItem("token");
        this.commonApp = "";
        this.val = 1;
        this.router.navigateByUrl("/login/admin");
    };
    AppComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Logout", content: "Are you sure?", type: "question" }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result) {
                if (result == "yes") {
                    _this.logout();
                }
            }
            // this.animal = result;
        });
    };
    return AppComponent;
}());
export { AppComponent };
