import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { DataService } from './data.service';

@Injectable()
export class AuthGuardService {

  constructor(private dataservice:DataService,private route:Router) { }
  canActivate(): boolean {
    if (!sessionStorage.getItem('token')) {
      this.route.navigate(['login/admin']);
      return false;
      
    }
    return true;
  }
}
