import { Component, Input, OnInit } from '@angular/core';
import { productinventory_set } from '../../models';

@Component({
  selector: 'app-inventory-card',
  templateUrl: './inventory-card.component.html',
  styleUrls: ['./inventory-card.component.css']
})
export class InventoryCardComponent implements OnInit {

  @Input ('inventory') inventory: any; 

  constructor() { }

  ngOnInit() {
    console.log(this.inventory);
  }

  onImgError(event){
    event.target.src = "../src/assets/images/no-image.jpg"

  }

}
