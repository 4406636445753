<div class="main">
    <mat-toolbar class="subtitle font-fmly" style="margin-bottom: 10px;">
        <span>Draft</span>
      </mat-toolbar>
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Customer">
          <table   style="width: 100%;"  mat-table [dataSource]="mainData" class="mat-elevation-z8">

            <ng-container matColumnDef="position"> 
              <th style="width: 7%;" mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element;let i=index"> {{i + 1}} </td>
            </ng-container>
          
         
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.data.name}} </td>
            </ng-container>
          
        
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number</th>
              <td mat-cell *matCellDef="let element"> {{element.data.phone_number}} </td>
            </ng-container>
          
      
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> E-mail </th>
              <td mat-cell *matCellDef="let element"> {{element.data.email}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr (click)="customerClicked(row)" style="cursor: pointer;" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          
        </mat-tab>
        <mat-tab label="Storefront"> 
          <table style="width: 100%;"  mat-table [dataSource]="mainData" class="mat-elevation-z8">

            <ng-container matColumnDef="position"> 
              <th style="width: 7%;" mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element;let i=index"> {{i + 1}} </td>
            </ng-container>
          
         
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.data.name}} </td>
            </ng-container>
          
        
            <!-- <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number</th>
              <td mat-cell *matCellDef="let element"> {{element.data.phone_number}} </td>
            </ng-container>
          
      
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> E-mail </th>
              <td mat-cell *matCellDef="let element"> {{element.data.email}} </td>
            </ng-container> -->
          
            <tr mat-header-row *matHeaderRowDef="displayedColumnsSf"></tr>
            <tr (click)="sfClicked(row)" style="cursor: pointer;" mat-row *matRowDef="let row; columns: displayedColumnsSf;"></tr>
          </table>
         </mat-tab>
        <mat-tab label="Products"> 
          <!-- <table style="width: 100%;"  mat-table [dataSource]="mainData" class="mat-elevation-z8">

            <ng-container matColumnDef="position"> 
              <th style="width: 7%;" mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element;let i=index"> {{i + 1}} </td>
            </ng-container>
          
         
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.data.name}} </td>
            </ng-container>
          
        
            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone Number</th>
              <td mat-cell *matCellDef="let element"> {{element.data.phone_number}} </td>
            </ng-container>
          
      
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> E-mail </th>
              <td mat-cell *matCellDef="let element"> {{element.data.email}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> -->
        </mat-tab>
      </mat-tab-group>
</div>
