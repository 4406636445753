import { SizeChart } from './../models';
import { OnInit } from "@angular/core";
import { DataService } from "./../data.service";
import { product_obj, userstorefront_obj, m_customers_obj, Inventory, ProductTag, Sizes } from "../models";
import { FormControl } from "@angular/forms";
import { MatDialog, MatSnackBar, MatChipInputEvent } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { CompressImageService } from "../compress.service";
import { take } from "rxjs/operators";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
var MultipleProdctsComponent = /** @class */ (function () {
    function MultipleProdctsComponent(dataservice, dialog, snackBar, compressImage, sanitizer) {
        this.dataservice = dataservice;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.compressImage = compressImage;
        this.sanitizer = sanitizer;
        this.Sizes = Sizes;
        this.selectedSizes = [];
        this.isImage = false;
        this.isPdf = false;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.inventory = {};
        this.inventoryArray = [];
        this.colorSizeCombinationArray = [];
        this.displayedColumns = ['Tag', 'Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
        this.displayedColumnsSizeChart = ['Style', 'Chart'];
        this.showCombinationList = false;
        this.showSizeChartList = true;
        this.customercollection = [];
        this.storetext = "Select Store Fronts To Add Products.";
        this.btnText = "Next";
        this.selectedstorefronts = [];
        this.numberofproducts = 0;
        this.addStoreFront = false;
        this.progressStatus = 'Adding products ....';
        this.tags = new FormControl();
        this.tagsList = [];
        this.specialTags = new FormControl();
        this.specialTagList = [];
        this.showAddProducts = false;
        this.addProduct = false;
        this.searchText = "";
        this.productName = new FormControl();
        this.productCode = new FormControl();
        this.productPrice = new FormControl();
        this.productCat = new FormControl();
        this.productColor = new FormControl();
        this.productSize = new FormControl();
        // productQuantity_new = new FormControl();
        // productSize_new = new FormControl();
        // productColor_new = new FormControl();
        this.statusArr = [];
        this.showProgress = false;
        this.prevnum = 0;
        this.isLoading = false;
        this.copiedSizes = [];
        this.dataservice.showHide(true);
    }
    MultipleProdctsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedstorefronts = this.dataservice.selected_storefronts;
        if (this.selectedstorefronts.length > 0) {
            this.tagsList = this.selectedstorefronts[0].tags;
        }
        console.log(this.tagsList);
        console.log(this.selectedstorefronts);
        //this.getStoreFrontData();
        this.addProducts();
        this.getSpecialTagList();
        // this.getTagList();
        // this.addBlankProduct();
        this.sub = this.dataservice.copiedSizesObs$.subscribe(function (sizes) {
            if (sizes.length != 0) {
                console.log(sizes);
                _this.copiedSizes = sizes;
            }
        });
    };
    MultipleProdctsComponent.prototype.ngOnDestroy = function () {
        if (this.sub != undefined) {
            console.log('on de');
            this.sub.unsubscribe();
        }
    };
    MultipleProdctsComponent.prototype.getTagList = function () {
        var _this = this;
        this.dataservice.getProductTagList().then(function (res) {
            console.log(res);
            _this.tagsList = res;
        });
    };
    MultipleProdctsComponent.prototype.getSpecialTagList = function () {
        var _this = this;
        this.dataservice.getProductSpecialTagList().then(function (res) {
            console.log(res);
            _this.specialTagList = res;
        });
    };
    MultipleProdctsComponent.prototype.toggleSize = function (size) {
        var index = this.selectedSizes.indexOf(size);
        if (index === -1) {
            this.selectedSizes.push(size);
        }
        else {
            this.selectedSizes.splice(index, 1);
        }
        this.productcollection.sizeArray = this.selectedSizes;
        console.log(this.selectedSizes);
    };
    MultipleProdctsComponent.prototype.add = function (event, type) {
        console.log('uu', event.value, type);
        var value = (event.value || '').trim().toUpperCase();
        switch (type) {
            case 'color': {
                console.log(type);
                if (value) {
                    this.productcollection.colorArray.push(value);
                }
                if (event.input) {
                    event.input.value = '';
                }
                break;
            }
            case 'size': {
                console.log(type);
                if (value) {
                    this.productcollection.sizeArray.push(value.toUpperCase());
                }
                if (event.input) {
                    event.input.value = '';
                }
                break;
            }
            case 'type': {
                console.log(type);
                // if (value) {
                //   this.productcollection[i].typeArray.push(value);
                // }
                // if (event.input) {
                //   event.input.value = '';
                // }
            }
            default: {
                //statements; 
                break;
            }
        }
    };
    MultipleProdctsComponent.prototype.removeC = function (item, type) {
        switch (type) {
            case 'color': {
                console.log(type);
                var index = this.productcollection.colorArray.indexOf(item);
                if (index >= 0) {
                    this.productcollection.colorArray.splice(index, 1);
                    this.showCombinationList = false;
                    this.productcollection.productinventory_set = [];
                }
                break;
            }
            case 'size': {
                console.log(type);
                var index = this.productcollection.sizeArray.indexOf(item);
                if (index >= 0) {
                    this.productcollection.sizeArray.splice(index, 1);
                    this.productcollection.productinventory_set = [];
                    this.showCombinationList = false;
                }
                break;
            }
            case 'type': {
                console.log(type);
                var index = this.productcollection.typeArray.indexOf(item);
                if (index >= 0) {
                    this.productcollection.typeArray.splice(index, 1);
                }
            }
            default: {
                //statements; 
                break;
            }
        }
    };
    MultipleProdctsComponent.prototype.csq = function () {
        this.inventoryArray.push(this.inventory);
        this.inventory = {};
        console.log(this.inventoryArray);
    };
    MultipleProdctsComponent.prototype.removeCombo = function (i) {
        var _this = this;
        console.log(i);
        this.productcollection.productinventory_set = this.productcollection.productinventory_set.filter(function (item, index) { return index !== i; });
        this.productcollection.colorArray = this.productcollection.colorArray.filter(function (color, index) { return _this.productcollection.productinventory_set.some(function (inv) {
            return inv.color == color;
        }); });
        this.productcollection.sizeArray = this.productcollection.sizeArray.filter(function (size, index) { return _this.productcollection.productinventory_set.some(function (inv) {
            return inv.size == size;
        }); });
        this.selectedSizes = this.productcollection.sizeArray;
        console.log(this.productcollection);
    };
    MultipleProdctsComponent.prototype.increaseQ = function () {
        this.productcollection.productinventory_set.forEach(function (inv) {
            inv.quantity += 10;
        });
    };
    MultipleProdctsComponent.prototype.decreaseQ = function () {
        this.productcollection.productinventory_set.forEach(function (inv) {
            if (inv.quantity > 0 && inv.quantity >= 10) {
                inv.quantity -= 10;
            }
            else {
                inv.quantity = 0;
            }
        });
    };
    MultipleProdctsComponent.prototype.increasePrice = function () {
        this.productcollection.productinventory_set.forEach(function (inv) {
            inv.price += 10;
        });
    };
    MultipleProdctsComponent.prototype.decreasePrice = function () {
        this.productcollection.productinventory_set.forEach(function (inv) {
            if (inv.price > 0 && inv.price >= 10) {
                inv.price -= 10;
            }
            else {
                inv.price = 0;
            }
        });
    };
    MultipleProdctsComponent.prototype.removeChart = function (i) {
        this.productcollection.size_chart[i].chart = null;
        this.productcollection.size_chart[i].charturl = null;
    };
    MultipleProdctsComponent.prototype.uploadSizeChart = function (event, i) {
        var _this = this;
        console.log(i);
        console.log(i);
        var file = event.target.files[0];
        if (file) {
            var fileType = file.type;
            var reader = new FileReader();
            reader.onload = function (e) {
                var url = _this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result);
                // Create an object with file name and sanitized URL
                var obj = {
                    name: file.name,
                    url: url
                };
                _this.productcollection.size_chart[i].chart = file;
                _this.productcollection.size_chart[i].charturl = obj;
                console.log(_this.productcollection.size_chart);
            };
            this.isImage = this.dataservice.isImage(fileType);
            this.isPdf = this.dataservice.isPDF(fileType);
            console.log(this.isImage, this.isPdf);
            if (this.dataservice.isImage(fileType) || this.dataservice.isPDF(fileType)) {
                reader.readAsDataURL(file);
            }
        }
        console.log(this.productcollection.size_chart);
    };
    MultipleProdctsComponent.prototype.onSelectionChange = function () {
        console.log('Selection changed:', this.tags.value);
    };
    MultipleProdctsComponent.prototype.onSelectionChangeCat = function (event) {
        // This method will be called whenever the selection changes
        console.log('Selection changed:', event.value);
        // if(event.value === 2){
        //   this.tags.setValue(['Paper'])
        // }else if(event.value === 3){
        //   this.tags.setValue(['Accessories'])
        // }
        // You can perform any actions you need here
        // if(event.value === 1){
        //   this.displayedColumns.unshift('Tag')
        // }else{
        //   this.displayedColumns = ['Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
        // }
        // console.log(this.displayedColumns);
    };
    MultipleProdctsComponent.prototype.generateSizeChartTable = function () {
        var _this = this;
        console.log('upload here');
        console.log(this.tags.value);
        this.productcollection.size_chart = [];
        if (this.tags.value <= 0) {
            alert("Select atleast one product style option!");
            return;
        }
        this.tags.value.forEach(function (val) {
            var sizeChart = {
                style: val,
                chart: null,
                charturl: null
            };
            _this.productcollection.size_chart.push(sizeChart);
        });
        console.log(this.productcollection.size_chart);
    };
    MultipleProdctsComponent.prototype.generateList = function () {
        var _this = this;
        var element = this.productcollection;
        element.productinventory_set = [];
        console.log(element);
        var colorArray = element.colorArray;
        var sizeArray = element.sizeArray;
        colorArray.forEach(function (color) {
            sizeArray.forEach(function (size) {
                _this.tags.value.forEach(function (tag) {
                    var inv = { tag: tag.name, color: _this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(_this.productcollection.price), files: [], imageArr: [], isChecked: false };
                    element.productinventory_set.push(inv);
                });
            });
        });
        // if(this.productcollection.categories == 1){
        //   colorArray.forEach(color => {
        //     sizeArray.forEach(size => {
        //       this.tags.value.forEach(tag => {
        //         var inv: Inventory = { tag: tag.name, color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.productcollection.price), files: [], imageArr: [], isChecked: false };
        //         element.productinventory_set.push(inv);
        //       })
        //     })
        //   })
        // }else{
        //   colorArray.forEach(color => {
        //     sizeArray.forEach(size => {
        //         var inv: Inventory = { color: this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(this.productcollection.price), files: [], imageArr: [], isChecked: false };
        //         element.productinventory_set.push(inv);
        //     })
        //   })
        // }
        element.productinventory_set.sort(function (a, b) {
            var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
            var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
            if (tagA < tagB) {
                return -1;
            }
            if (tagA > tagB) {
                return 1;
            }
            return 0; // tags are equal
        });
        this.showCombinationList = true;
    };
    MultipleProdctsComponent.prototype.deleteinv = function (index) {
        this.inventoryArray.splice(index, 1);
        console.log(this.inventoryArray);
    };
    MultipleProdctsComponent.prototype.removeImage2 = function (imgin, i) {
        console.log(imgin, i);
        this.productcollection.productinventory_set[i].imageArr.splice(imgin, 1);
        this.productcollection.productinventory_set[i].files.splice(imgin, 1);
    };
    MultipleProdctsComponent.prototype.detectFiles2 = function (event, i) {
        var _this = this;
        var images = event.target.files;
        var newFiles = [];
        if (images) {
            for (var _i = 0, images_1 = images; _i < images_1.length; _i++) {
                var image = images_1[_i];
                console.log("Image size before compressed: " + image.size + " bytes.");
                this.compressImage
                    .compress(image)
                    .pipe(take(1))
                    .subscribe(function (compressedImage) {
                    newFiles.push(compressedImage);
                    if (images.length === newFiles.length) {
                        console.log(newFiles);
                        _this.openAskDialog(i, newFiles);
                    }
                    console.log("Image size after compressed: " + (compressedImage.size) + " bytes.");
                    // now you can do upload the compressed image
                });
            }
        }
        console.log(this.productcollection);
    };
    MultipleProdctsComponent.prototype.openAskDialog = function (i, files) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to apply this product image to other color and size options?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                _this.openAskColorSizeDialog(i, files);
            }
            else {
                console.log(i);
                var _loop_1 = function (file) {
                    _this.productcollection.productinventory_set[i].files.push(file);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var obj = {
                            name: file.name,
                            url: e.target.result
                        };
                        _this.productcollection.productinventory_set[i].imageArr.push(obj);
                    };
                    reader.readAsDataURL(file);
                };
                for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                    var file = files_1[_i];
                    _loop_1(file);
                }
            }
        });
    };
    MultipleProdctsComponent.prototype.openAskColorSizeDialog = function (i, files) {
        var _this = this;
        var element = this.productcollection;
        console.log(this.tags.value);
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Choose color and size!",
                content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
                type: "colorAndsizeOptions"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            console.log(_this.productcollection.productinventory_set);
            _this.productcollection.productinventory_set.forEach(function (inv, indexx) {
                result.tags.forEach(function (tag) {
                    if (tag == inv.tag) {
                        if (result.colors.includes(inv.color) && result.sizes.includes(inv.size)) {
                            console.log('add here');
                            var _loop_2 = function (file) {
                                _this.productcollection.productinventory_set[indexx].files.push(file);
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    var obj = {
                                        name: file.name,
                                        url: e.target.result
                                    };
                                    _this.productcollection.productinventory_set[indexx].imageArr.push(obj);
                                };
                                reader.readAsDataURL(file);
                            };
                            for (var _i = 0, files_2 = files; _i < files_2.length; _i++) {
                                var file = files_2[_i];
                                _loop_2(file);
                            }
                        }
                    }
                });
            });
            // this.productcollection.productinventory_set.forEach((inv, indexx) => {
            //   result.forEach((element, ind) => {
            //     if (element.color == inv.color && element.size == inv.size) {
            //       console.log("color matched", indexx);
            // for (let file of files) {
            //   this.productcollection.productinventory_set[indexx].files.push(file);
            //   let reader = new FileReader();
            //   reader.onload = (e: any) => {
            //     let obj = {
            //       name: file.name,
            //       url: e.target.result
            //     };
            //     this.productcollection.productinventory_set[indexx].imageArr.push(obj);
            //   };
            //   reader.readAsDataURL(file);
            // }
            //     }
            //   });
            // })
        });
    };
    // detectFiles(event, index) {
    //   console.log(index);
    //   let files = event.target.files;
    //   if (files) {
    //     for (let file of files) {
    //       this.productcollection[index].files.push(file);
    //       let reader = new FileReader();
    //       reader.onload = (e: any) => {
    //         let obj = {
    //           name: file.name,
    //           url: e.target.result
    //         };
    //         this.productcollection[index].imagearry.push(obj);
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   }
    //   console.log(this.productcollection);
    // }
    MultipleProdctsComponent.prototype.addProducts = function () {
        this.addBlankProduct();
    };
    MultipleProdctsComponent.prototype.showAddStoreFront = function () {
        // this.addProduct = true;
        // this.getStoreFronts()
    };
    MultipleProdctsComponent.prototype.removeImage = function (i, index) {
        console.log(i, index);
        this.productcollection[index].imagearry.splice(i, 1);
        this.productcollection[index].files.splice(i, 1);
    };
    MultipleProdctsComponent.prototype.addBlankProduct = function () {
        var obj = new product_obj();
        obj.title = "";
        obj.size = "";
        obj.color = "";
        obj.price = "";
        obj.description = "";
        obj.multiple_colors = false;
        obj.categories = 1;
        obj.imagearry = [];
        obj.files = [];
        obj.storefront = "";
        obj.is_featured = false;
        obj.productinventory_set = [];
        this.productcollection = obj;
    };
    MultipleProdctsComponent.prototype.getStoreFrontString = function () {
        var returnstring = [];
        this.selectedstorefronts.forEach(function (element) {
            returnstring.push(element.id);
        });
        return returnstring.toString();
    };
    MultipleProdctsComponent.prototype.showStoreFronts = function () {
        if (this.addStoreFront) {
            this.addStoreFront = false;
        }
        else {
            this.addStoreFront = true;
        }
    };
    MultipleProdctsComponent.prototype.getStoreFrontData = function () {
        var _this = this;
        this.dataservice.getAllStoreFronts().subscribe(function (data) {
            var obj = data.json();
            _this.customercollection = obj["results"];
            _this.selectedstorefronts.forEach(function (element) {
                _this.checkData(element.id);
            });
            console.log(_this.customercollection);
        });
    };
    MultipleProdctsComponent.prototype.updateStoreFronts = function () {
        console.log(this.customercollection);
        this.getSelectedStoreFronts();
    };
    MultipleProdctsComponent.prototype.selectCustomerStoreFront = function (id) {
        var pos = this.customercollection.findIndex(function (element) {
            return element.id == id;
        });
        for (var index = 0; index < this.customercollection[pos].user_storefront.length; index++) {
            this.customercollection[pos].user_storefront[index].ischecked = this.customercollection[pos].ischecked;
        }
        this.getSelectedStoreFronts();
    };
    MultipleProdctsComponent.prototype.checkData = function (storefrontid) {
        for (var index = 0; index < this.customercollection.length; index++) {
            for (var indexx = 0; indexx < this.customercollection[index].user_storefront.length; indexx++) {
                if (storefrontid ===
                    this.customercollection[index].user_storefront[indexx].id) {
                    this.customercollection[index].user_storefront[indexx].ischecked = true;
                }
            }
        }
    };
    MultipleProdctsComponent.prototype.getSelectedStoreFronts = function () {
        var _this = this;
        this.selectedstorefronts = [];
        this.customercollection.forEach(function (element) {
            element.user_storefront.forEach(function (obj) {
                if (obj.ischecked) {
                    _this.selectedstorefronts.push(obj);
                }
            });
        });
        console.log(this.selectedstorefronts);
    };
    MultipleProdctsComponent.prototype.goAhead = function () {
        if (this.btnText === "Next") {
            this.btnText = "Back";
            this.showAddProducts = true;
            this.storetext = "Add Products.";
        }
        else {
            this.btnText = "Next";
            this.showAddProducts = false;
            this.storetext = "Select Store Fronts To Add Products.";
        }
    };
    MultipleProdctsComponent.prototype.paste = function () {
        if (this.copiedSizes.length != 0) {
            this.productcollection[0].sizeArray = this.copiedSizes;
        }
    };
    MultipleProdctsComponent.prototype.onSave = function () {
        var _this = this;
        console.log(this.tags.value);
        console.log(this.specialTags.value);
        if (this.productcollection.size_chart.length <= 0) {
            this.generateSizeChartTable();
        }
        if (this.selectedstorefronts.length <= 0) {
            alert("Add Store Front ");
            return;
        }
        console.log(this.productcollection);
        if (this.productcollection.productinventory_set.length <= 0) {
            alert("Generate List to add quantity!");
            return;
        }
        ;
        if (this.productcollection.productinventory_set.some(function (element) { return element.imageArr.length <= 0; })) {
            alert("Add atleast one Image to every color and size option!");
            return;
        }
        // if (!this.productcollection.size_chart) {
        //   alert("Please add size chart to the product");
        //   return
        // }
        if (this.tags.value <= 0 && this.productcollection.categories == 1) {
            alert("Select atleast one product style option!");
            return;
        }
        else {
            console.log(this.tags.value);
            this.productcollection.typeArray = this.tags.value;
        }
        this.productcollection.specialTags = this.specialTags.value;
        if (this.productName.valid &&
            this.productCode.valid &&
            this.productPrice.valid &&
            this.selectedstorefronts.length > 0) {
            this.isLoading = true;
            var element_1 = this.productcollection;
            this.statusArr.push({ title: element_1.title, status: 'In progress' });
            this.showProgress = true;
            var i_1 = 0;
            this.statusArr[i_1].status = "Working";
            element_1.storefront = this.getStoreFrontString();
            this.dataservice.createMultipleProduct(element_1).subscribe(function (data) {
                console.log(data);
                data.forEach(function (element) {
                    _this.dataservice.appendProduct_List(element);
                });
                _this.selectedstorefronts.forEach(function (elementx) {
                    _this.dataservice.appendProductObjinStore(data, elementx.user, elementx.id);
                });
                _this.isLoading = false;
                _this.statusArr[i_1].status = "Done";
                _this.dataservice.productEdited = true;
                if (i_1 === _this.statusArr.length - 1) {
                    _this.progressStatus = "Products added successfully";
                }
                // notthisbelow
                // this.openSnackBar();
            }, function (error) {
                _this.statusArr[i_1].status = "Error";
                _this.isLoading = false;
            }, function () {
                i_1++;
            });
        }
        else {
            alert("Fill All The Details");
        }
    };
    //
    MultipleProdctsComponent.prototype.openAddDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Select Storefronts",
                content: this.selectedstorefronts,
                type: "selectstorefront"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result.length > 0) {
                // let temp = this.selectedstorefronts;
                // let temp2 = result;
                _this.selectedstorefronts = result;
                _this.tagsList = _this.selectedstorefronts[0].tags;
                console.log(_this.tagsList);
                // temp.forEach(element => {
                //   this.selectedstorefronts.push(element);
                // });
                // temp2.forEach(element => {
                //   this.selectedstorefronts.push(element);
                // });
                console.log(_this.selectedstorefronts);
            }
        });
    };
    MultipleProdctsComponent.prototype.removeProduct = function (index) {
        // this.productcollection.splice(index, 1);
        // this.openSnackBar();
    };
    MultipleProdctsComponent.prototype.remove = function (index) {
        this.selectedstorefronts.splice(index, 1);
        // this.openSnackBar();
    };
    MultipleProdctsComponent.prototype.openSnackBar = function () {
        this.snackBar.openFromComponent(PizzaPartyComponent, {
            duration: 3 * 1000,
            horizontalPosition: "end",
            verticalPosition: "bottom"
        });
    };
    return MultipleProdctsComponent;
}());
export { MultipleProdctsComponent };
var PizzaPartyComponent = /** @class */ (function () {
    function PizzaPartyComponent() {
        this.lbl = "Added to cart";
    }
    return PizzaPartyComponent;
}());
export { PizzaPartyComponent };
var CopyUrl = /** @class */ (function () {
    function CopyUrl() {
        this.lbl = "Store url copied to clipboard";
    }
    return CopyUrl;
}());
export { CopyUrl };
