import { OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTableDataSource, MatSort } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
var StoresViewComponent = /** @class */ (function () {
    function StoresViewComponent(dataservice, route, router, dialog) {
        this.dataservice = dataservice;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.numberofstores = 0;
        this.numberofpage = 0;
        this.dataSource = new MatTableDataSource([]);
        this.storecust_collection = [];
        this.displayedColumns = [
            "name",
            "customer",
            "store_Group",
            "No_of_Products",
            "orders",
            "status",
            "btn1",
            "btn2",
            "btn3"
        ];
        this.multiStorefront_collection = [];
        this.storefront_collection = [];
        this.selectedVal = "grid";
        this.url = "api/v1/storefront/";
    }
    StoresViewComponent.prototype.ngOnInit = function () {
        this.dataSource.sort = this.sort;
        console.log("yo");
        // this.dataservice.getCommon('api/v1/storefront/').subscribe(data => {
        //   console.log(data.json());
        //   this.paginatingFunc(data.json().results, data.json().count);
        // },
        //   error => {
        //     console.log(error);
        //   }
        // )
    };
    StoresViewComponent.prototype.onData = function ($event) {
        var _this = this;
        this.storefront_collection = $event;
        var unique = this.storefront_collection
            .map(function (item) { return item.user; })
            .filter(function (value, index, self) { return self.indexOf(value) === index; });
        unique.forEach(function (element) {
            _this.storecust_collection.push(_this.returnStores(element));
        });
        console.log("------->", this.storefront_collection);
        this.dataSource = new MatTableDataSource(this.storefront_collection);
    };
    StoresViewComponent.prototype.returnStores = function (user) {
        var stores = [];
        var name = "";
        this.storefront_collection.forEach(function (element) {
            if (element.user === user) {
                stores.push(element);
            }
        });
        name = stores[0].user_v1;
        var obj = { cust_name: name, storefronts: stores };
        return obj;
    };
    // Edit storefront
    StoresViewComponent.prototype.editStore = function (item) {
        this.dataservice.selected_storefront = item;
        this.router.navigate(["/editstore/" + item.id + ""]);
    };
    StoresViewComponent.prototype.showOrders = function (item) {
        this.dataservice.selected_storefront = item;
        this.router.navigateByUrl("/orders/" + item.id);
    };
    StoresViewComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.storefront_collection.slice();
        if (!sort.active || sort.direction === "") {
            this.storefront_collection = data;
            return;
        }
        this.storefront_collection = data.sort(function (a, b) {
            var isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "name":
                    return _this.compare(a.name, b.name, isAsc);
                case "numberstores":
                    return _this.compare(a.storefront_product.length, b.storefront_product.length, isAsc);
                case "order":
                    return _this.compare(a.storefront_order.length, b.storefront_order.length, isAsc);
                case "status":
                    return _this.compare(a.status, b.status, isAsc);
                case "owner":
                    return _this.compare(a.user_v1, b.user_v1, isAsc);
                // case 'user_type': return this.compare(a.user_type, b.user_type, isAsc);
                // case 'protein': return compare(a.protein, b.protein, isAsc);
                default:
                    return 0;
            }
        });
    };
    StoresViewComponent.prototype.sortDataList = function (sort) {
        if (!this.dataSource)
            return;
        this.dataSource.filter = sort.trim().toLowerCase();
    };
    StoresViewComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    StoresViewComponent.prototype.onValChange = function (val) {
        this.selectedVal = val;
    };
    // Preview
    StoresViewComponent.prototype.previewStore = function (item) {
        var slug = item.slug;
        console.log(slug);
        if (!slug) {
            alert("No Url Added");
            return;
        }
        console.log(slug, "url");
        window.open(slug, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800");
    };
    StoresViewComponent.prototype.toggleStoreFrontStatus = function (item, status, index) {
        this.openArchiveDialog(item, status, index);
    };
    StoresViewComponent.prototype.openArchiveDialog = function (item, status, index) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Archive",
                content: "Are you sure, you want to archive store " + item.name + "?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result) {
                if (result == "yes") {
                    _this.toggleStoreStatusData(item, status, index);
                }
            }
            // this.animal = result;
        });
    };
    StoresViewComponent.prototype.openAddCustomerDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: "Select Customer", content: "", type: "selectcustomer" }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            // this.selectedstorefronts = result;
            if (result !== "no") {
                var selectedcustomer = result;
                _this.dataservice.selected_customer = selectedcustomer;
                _this.router.navigateByUrl("/addstorefront/" + selectedcustomer.id);
            }
            else {
                console.log(result);
            }
        });
    };
    StoresViewComponent.prototype.toggleStoreStatusData = function (item, statuss, index) {
        var _this = this;
        this.dataservice
            .toggleStoreStatus(item.id.toString(), { status: statuss })
            .subscribe(function (data) {
            _this.toggleStoreStatusStatic(item.id, statuss);
            console.log(data);
        }, function (error) {
            console.log(error);
        });
    };
    StoresViewComponent.prototype.toggleStoreStatusStatic = function (sid, status) {
        for (var index = 0; index < this.storefront_collection.length; index++) {
            if (this.storefront_collection[index].id === sid) {
                this.storefront_collection[index].status = status;
                break;
            }
        }
    };
    return StoresViewComponent;
}());
export { StoresViewComponent };
