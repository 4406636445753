<div class="main">
  <h3 class="page-heading">
    Inventory
  </h3>
  <div style="display: flex;
  justify-content: flex-end;
  margin: 0 20px;">
    <mat-button-toggle-group
    name="fontStyle"
    aria-label="Font Style"
    #group="matButtonToggleGroup"
    [value]="selectedVal"
    (change)="onValChange(group.value)"
  >
    <mat-button-toggle value="grid">
      <mat-icon class="example-icon">view_module</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="list">
      <mat-icon class="example-icon">view_list</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  </div>
 
  <div class="mainGrid" *ngIf="selectedVal === 'grid'">
  <div *ngFor="let inventory of sortedData; let i = index">
    <app-inventory-card [inventory]="inventory"></app-inventory-card>

  </div>
    
  </div>

  <div [ngClass]="selectedVal === 'list' ? 'visible':'hidden'" class="col-md-60 tables" style="margin: 20px;" >
    <table (matSortChange)="sortData($event)" mat-table [dataSource]="sortedData" matSort class="mat-elevation-z8 table-list" >

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.product_name}} </td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
        <td mat-cell *matCellDef="let element"> {{element.color}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
        <td mat-cell *matCellDef="let element"> {{element.size}} </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  
  <div class="col-sm-60 text-center paginat">
    <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
  </div>

</div>


