// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
   baseurl :'https://storedev-api.primetime.company/',
  storeUrl : 'https://storedev.primetime.company',
  version:'v2'
};

// export const environment = {
//   production: false,
//   baseurl :'https://storev3-api.primetime.company/',
//   storeUrl : 'https://storev3.primetime.company/',
//   version:'v2'

// };


// export const environment = {
//   production: false,
//   baseurl :'https://storev2-api.primetime.company/',
//   storeUrl : 'https://storev2.primetime.company/'
// };
