import { PipeTransform } from '@angular/core';
var ProductNamePipe = /** @class */ (function () {
    function ProductNamePipe() {
    }
    // transform(value: any, args?: any): any {
    //   return null;
    // }
    ProductNamePipe.prototype.transform = function (items, searchText) {
        if (!items)
            return [];
        if (!searchText)
            return items;
        searchText = searchText.toLowerCase();
        return items.filter(function (it) {
            return it.title.toLowerCase().includes(searchText);
        });
    };
    return ProductNamePipe;
}());
export { ProductNamePipe };
var ProductId = /** @class */ (function () {
    function ProductId() {
    }
    // transform(value: any, args?: any): any {
    //   return null;
    // }
    ProductId.prototype.transform = function (array, key) {
        if (!Array.isArray(array)) {
            return;
        }
        // array.sort((a: any, b: any) => {
        //   if (a[field] > b[field]) {
        //     return -1;
        //   } else if (a[field] < b[field]) {
        //     return 1;
        //   } else {
        //     return 0;
        //   }
        // });
        console.log("from pipe", key);
        if (key.field === 'is_featured') {
            array.sort(function (x, y) {
                return y[key.field] - x[key.field];
            });
        }
        if (key.field === 'id') {
            array.sort(function (a, b) {
                if (a[key.field] > b[key.field]) {
                    return -1;
                }
                else if (a[key.field] < b[key.field]) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        if (key.field === 'code') {
            array.sort(function (a, b) {
                var ax = a[key.field].match(/\d/g);
                var bx = b[key.field].match(/\d/g);
                if (ax != null) {
                    ax = ax.join('');
                }
                else {
                    ax = 0;
                }
                if (bx != null) {
                    bx = bx.join('');
                }
                else {
                    bx = 0;
                }
                console.log(ax, bx);
                if (ax < bx) {
                    return -1;
                }
                else if (ax > bx) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        if (key.field === 'price' && key.filter === 'h2l') {
            array.sort(function (a, b) {
                if (Number(a[key.field]) > Number(b[key.field])) {
                    return -1;
                }
                else if (Number(a[key.field]) < Number(b[key.field])) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        if (key.field === 'price' && key.filter === 'l2h') {
            array.sort(function (a, b) {
                if (Number(a[key.field]) < Number(b[key.field])) {
                    return -1;
                }
                else if (Number(a[key.field]) > Number(b[key.field])) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        console.log(array);
        if (key.field === 'code') {
            var temp = [];
            for (var index = 0; index < array.length; index++) {
                var element = array[index];
                var ax = element[key.field].match(/\d/g);
                if (ax == null) {
                    temp.push(element);
                    array.splice(index, 1);
                }
            }
            temp.forEach(function (element) {
                array.push(element);
            });
            return array;
        }
        else {
            return array;
        }
    };
    return ProductId;
}());
export { ProductId };
