import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inventory, m_product_obj, ProductTag, p_sf, Sizes } from '../models';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { product_obj } from '../models';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { FormControl } from '@angular/forms';
import * as _ from "lodash";
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import { CompressImageService } from '../compress.service';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
var EditProductComponent = /** @class */ (function () {
    function EditProductComponent(_sanitizer, route, dataservice, _location, dialog, _snackBar, compressImage, router, sanitizer) {
        this._sanitizer = _sanitizer;
        this.route = route;
        this.dataservice = dataservice;
        this._location = _location;
        this.dialog = dialog;
        this._snackBar = _snackBar;
        this.compressImage = compressImage;
        this.router = router;
        this.sanitizer = sanitizer;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.isImage = false;
        this.isPdf = false;
        this.isImageBackend = false;
        this.isPdfBackend = false;
        this.tempColorValue = "";
        this.tempSizeValue = "";
        this.product_obj_new = new product_obj();
        this.showForm = false;
        this.colorSizeChanged = false;
        this.showNewTable = false;
        this.imagearry = [];
        this.displayedColumns = ['Tag', 'Color', 'Size', 'Quantity', 'Price', 'Images', 'columndelete'];
        this.displayedColumnsSizeChart = ['Style', 'Chart'];
        this.colorSizeEdit = false;
        this.colorSizeIcon = "edit";
        this.files = [];
        this.selectedPreviewImage = '';
        this.disableRbutton = false;
        this.quantity$ = new Subject();
        this.price$ = new Subject();
        this.invIndex = 0;
        this.isLoading = false;
        this.tags = new FormControl();
        this.specialTags = new FormControl();
        this.tagsList = [];
        this.specialTagsList = [];
        this.productStatus = true;
        this.selectedstorefronts = [];
        this.urlindex = 0;
        this.colorArray = [];
        this.sizeArray = [];
        this.Sizes = Sizes;
        this.selectedSizes = [];
        this.tempcolorArray = [];
        this.tempsizeArray = [];
    }
    EditProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        var length = this.dataservice.inmemoryData.length;
        console.log(length);
        if (length > 0) {
            this.urlindex = this.dataservice.inmemoryData.findIndex(function (element) { return element.url === "api/v1/productv3/"; });
            console.log(this.urlindex);
            // console.log(this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0]);
        }
        // this.dataservice.productEdited = true
        // this.dataservice.customerGetFlag = true;
        this.route.params.subscribe(function (params) {
            _this.prod_id = params['prod_id'];
        });
        this.dataservice.getSingleProduct(this.prod_id).subscribe(function (data) {
            console.log(data.json(), 'is it here');
            // this.getTagList();
            _this.getSpecialTagList();
            _this.product_obj = data.json();
            if (_this.product_obj.size_chart) {
                _this.isImageBackend = _this.dataservice.isImageFromBackend(_this.product_obj.size_chart);
                _this.isPdfBackend = _this.dataservice.isPdfFromBackend(_this.product_obj.size_chart);
            }
            if (_this.product_obj.productsizechart_set.length > 0) {
                _this.product_obj.productsizechart_set.forEach(function (c) {
                    if (c.size_chart) {
                        _this.isImageBackend = _this.dataservice.isImageFromBackend(c.size_chart);
                        _this.isPdfBackend = _this.dataservice.isPdfFromBackend(c.size_chart);
                    }
                });
            }
            _this.sortProductInv();
            if (_this.product_obj.tags != null) {
                _this.tagsList = _this.product_obj.storefront_product[0].tags;
                _this.tags.setValue(_this.tagsList.filter(function (tag) { return _this.product_obj.tags.map(function (intag) { return intag.id; }).includes(tag.id); }));
            }
            // this.selectedstorefronts = this.product_obj.storefront_product as userstorefront_obj[]
            _this.tempColorValue = data.json().color;
            _this.tempSizeValue = data.json().size;
            _this.productStatus = _this.product_obj.active;
            _this.colorArray = _this.product_obj.color.split(",");
            _this.sizeArray = _this.product_obj.size.split(",");
            _this.selectedSizes = _this.sizeArray;
            _this.tempcolorArray = _this.product_obj.color.split(",");
            _this.tempsizeArray = _this.product_obj.size.split(",");
            // this.product_obj_copy = data.json();
            _this.dataservice.selected_product = data.json();
            _this.product_obj.storefront_product.forEach(function (sf) {
                var obj = { id: sf.id, user: sf.user_id, name: sf.name, customer: sf.user };
                _this.selectedstorefronts.push(obj);
            });
            _this.showForm = true;
            _this.catagories();
            console.log(_this.product_obj);
            // this.shiftObjectAtTop();
        }, function (error) { });
        this.quantitySubscription = this.quantity$.pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function () {
            _this.updateQ(_this.invIndex);
        });
        this.priceSubscription = this.price$.pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function () {
            _this.updateP(_this.invIndex);
        });
    };
    EditProductComponent.prototype.ngOnDestroy = function () {
        this.dataservice.pageNumber = 0;
        this.shiftObjectAtTop();
        this.quantitySubscription.unsubscribe();
        this.priceSubscription.unsubscribe();
    };
    EditProductComponent.prototype.openSnackBar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: 3000
        });
    };
    EditProductComponent.prototype.sortProductInv = function () {
        this.product_obj.productinventory_set.sort(function (a, b) {
            if (a.tag && b.tag) {
                var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
                var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
                if (tagA < tagB) {
                    return -1;
                }
                if (tagA > tagB) {
                    return 1;
                }
                return 0; // tags are equal
            }
        });
    };
    EditProductComponent.prototype.sortProductInvNewGeneratedList = function () {
        this.product_obj_new.productinventory_set.sort(function (a, b) {
            if (a.tag && b.tag) {
                var tagA = a.tag.toLowerCase(); // ignore upper and lowercase
                var tagB = b.tag.toLowerCase(); // ignore upper and lowercase
                if (tagA < tagB) {
                    return -1;
                }
                if (tagA > tagB) {
                    return 1;
                }
                return 0; // tags are equal
            }
        });
    };
    EditProductComponent.prototype.getTagList = function () {
        var _this = this;
        this.dataservice.getProductTagList().then(function (res) {
            console.log(res);
            _this.tagsList = res;
            console.log(_this.product_obj.tags, _this.tagsList);
            if (_this.product_obj.tags != null) {
                _this.tags.setValue(_this.tagsList.filter(function (tag) { return _this.product_obj.tags.map(function (intag) { return intag.id; }).includes(tag.id); }));
            }
            else {
                // this.tags.setValue([{id:1,name:"Mens's"}]);
                // console.log(this.tags.value);
            }
        });
    };
    EditProductComponent.prototype.getSpecialTagList = function () {
        var _this = this;
        this.dataservice.getProductSpecialTagList().then(function (res) {
            console.log(res);
            _this.specialTagsList = res;
            console.log(_this.product_obj.special_tags, _this.specialTagsList);
            if (_this.product_obj.special_tags != null) {
                _this.specialTags.setValue(_this.specialTagsList.filter(function (tag) { return _this.product_obj.special_tags.map(function (intag) { return intag.id; }).includes(tag.id); }));
            }
            else {
                // this.tags.setValue([{id:1,name:"Mens's"}]);
                // console.log(this.tags.value);
            }
        });
    };
    EditProductComponent.prototype.getSingleProduct = function () {
        var _this = this;
        this.dataservice.getSingleProduct(this.prod_id).subscribe(function (data) {
            console.log(data.json(), 'is it here');
            _this.getTagList();
            _this.product_obj = data.json();
            _this.tempColorValue = data.json().color;
            // this.product_obj_copy = data.json();
            // this.product_obj_new = data.json();
            _this.selectedPreviewImage = _this.product_obj.preview_image;
            _this.showForm = true;
            _this.catagories();
            _this.disableRbutton = false;
            console.log(_this.product_obj);
        }, function (error) { });
    };
    EditProductComponent.prototype.removeSizeChart = function () {
        this.uploadedFile = undefined;
    };
    EditProductComponent.prototype.catagories = function () {
        switch (this.product_obj.category_name) {
            case 'Clothing':
                this.product_obj.categories = 1;
                break;
            case 'Paper':
                this.product_obj.categories = 2;
                break;
            case 'Accessories':
                this.product_obj.categories = 3;
                break;
            default:
                break;
        }
    };
    EditProductComponent.prototype.cancel = function () {
        if (this.product_obj.productinventory_set.some(function (element) { return element.productinventoryimage_set.length <= 0; })) {
            alert("Add atleast one Image to every color and size option!");
            console.log("existing img err");
            return;
        }
        this._location.back();
    };
    EditProductComponent.prototype.AddComboDialogue = function () {
        var _this = this;
        console.log('ac');
        console.log(this.product_obj.categories);
        var sizes;
        if (this.product_obj.categories === 1) {
            sizes = this.Sizes;
        }
        else {
            sizes = '';
        }
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Add Combination",
                content: { id: this.prod_id, category: this.product_obj.categories, tags: this.tags.value, color: '', size: sizes, quantity: '', price: '', files: [], storefront: this.selectedstorefronts[0].id },
                type: "addcombination"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result) {
                _this.product_obj.productinventory_set.push(result);
                _this.product_obj.productinventory_set = _.filter(_this.product_obj.productinventory_set, function (item, index) {
                    return true;
                });
                if (!_this.sizeArray.includes(result.size)) {
                    _this.sizeArray.push(result.size);
                }
                if (!_this.colorArray.includes(result.color)) {
                    _this.colorArray.push(result.color);
                }
                _this.sortProductInv();
                console.log(_this.product_obj.productinventory_set);
            }
        });
    };
    EditProductComponent.prototype.openAddDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Select Storefronts",
                content: this.selectedstorefronts,
                type: "selectstorefront"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result, result.length);
            if (result.length > 0) {
                _this.selectedstorefronts.forEach(function (sf) {
                    result = result.filter(function (item, index) { return item.id != sf.id; });
                });
                console.log(result);
                result.forEach(function (element) {
                    var obj = { product: _this.product_obj.id, storefront: element.id };
                    _this.dataservice.addSftoProduct(obj).subscribe(function (res) {
                        _this.selectedstorefronts.push(element);
                        if (_this.dataservice.inmemoryData.length > 0 && _this.urlindex != -1) {
                            var ind = _this.dataservice.inmemoryData[_this.urlindex].multicollectionarry[_this.dataservice.pageNumber].findIndex(function (element) { return element.id == obj.product; });
                            console.log(ind);
                            var sfObj = { id: element.id, name: element.name, is_active: true, status: element.status };
                            _this.product_obj.storefront_product.push(sfObj);
                            _this.dataservice.inmemoryData[_this.urlindex].multicollectionarry[_this.dataservice.pageNumber][ind].storefront_product.push(sfObj);
                            // this.shiftObjectAtTop();
                        }
                        _this.openSnackBar("Storefront updated!", "close");
                    }, function (err) {
                        console.log("error adding sf");
                    });
                });
                console.log(_this.selectedstorefronts);
            }
        });
    };
    EditProductComponent.prototype.openAskDeleteSfDiaglogue = function (i) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to remove this storefront?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                var obj_1 = { product: _this.product_obj.id, storefront: _this.selectedstorefronts[i].id };
                _this.dataservice.deleteSffromProduct(obj_1).subscribe(function (res) {
                    _this.selectedstorefronts.splice(i, 1);
                    if (_this.dataservice.inmemoryData.length > 0 && _this.urlindex != -1) {
                        var ind = _this.dataservice.inmemoryData[_this.urlindex].multicollectionarry[_this.dataservice.pageNumber].findIndex(function (element) { return element.id == obj_1.product; });
                        var indx = _this.dataservice.inmemoryData[_this.urlindex].multicollectionarry[_this.dataservice.pageNumber][ind].storefront_product.findIndex(function (element) { return element.id == obj_1.storefront; });
                        console.log(indx);
                        _this.product_obj.storefront_product.splice(i, 1);
                        _this.dataservice.inmemoryData[_this.urlindex].multicollectionarry[_this.dataservice.pageNumber][ind].storefront_product.splice(indx, 1);
                        // this.shiftObjectAtTop(); 
                    }
                    _this.openSnackBar("Storefront removed!", "close");
                }, function (err) {
                    console.log("err deleting sf");
                });
            }
        });
    };
    EditProductComponent.prototype.goToSf = function (id) {
        console.log(id);
        this.router.navigate(['/editstore/' + id]);
    };
    EditProductComponent.prototype.remove = function (index) {
        this.openAskDeleteSfDiaglogue(index);
        // this.openSnackBar();
    };
    EditProductComponent.prototype.detectFiles = function (event) {
        var _this = this;
        // console.log(index);
        var files = event.target.files;
        console.log(files);
        if (files) {
            var _loop_1 = function (file) {
                this_1.files.push(file);
                var reader = new FileReader();
                reader.onload = function (e) {
                    var obj = {
                        name: file.name,
                        url: e.target.result,
                    };
                    _this.product_obj.product_images.push({
                        id: 0,
                        image: e.target.result,
                        product: _this.product_obj.id,
                        color: '',
                    });
                    _this.checkForPendingImages();
                };
                reader.readAsDataURL(file);
            };
            var this_1 = this;
            for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                var file = files_1[_i];
                _loop_1(file);
            }
        }
    };
    EditProductComponent.prototype.updateImages = function () {
        var _this = this;
        this.dataservice.UpdateImages(this.files, this.product_obj.id).subscribe(function (data) {
            console.log(data.json());
            // this.product_obj = data.json();
            _this.getSingleProduct();
            _this.confirmationDialog();
        }, function (error) {
            console.log(error);
            _this.econfirmationDialog();
        });
    };
    EditProductComponent.prototype.checkForPendingImages = function () {
        var data = this.product_obj.product_images.find(function (ele) {
            return ele.id === 0;
        });
        if (data) {
            console.log('found');
            this.disableRbutton = true;
        }
        else {
            this.disableRbutton = false;
        }
    };
    EditProductComponent.prototype.checkForPendingImages2 = function (item) {
        if (!this.disableRbutton) {
            console.log(item);
            var data = this.product_obj.product_images.find(function (ele) {
                return ele.id === 0;
            });
            if (data) {
                console.log('found');
                this.disableRbutton = true;
                this.selectedPreviewImage = '';
            }
            else {
                console.log(this.product_obj.preview_image, item);
                if (this.product_obj.preview_image !== item) {
                    this.updatePreivewImage(item, true);
                }
            }
        }
    };
    EditProductComponent.prototype.increaseQ = function (num) {
        var _this = this;
        if (num == 1) {
            this.product_obj.productinventory_set.forEach(function (inv, index) {
                inv.quantity += 10;
                _this.updateQ(index);
            });
        }
        else {
            this.product_obj_new.productinventory_set.forEach(function (inv, index) {
                inv.quantity += 10;
            });
        }
    };
    EditProductComponent.prototype.decreaseQ = function (num) {
        var _this = this;
        if (num == 1) {
            this.product_obj.productinventory_set.forEach(function (inv, index) {
                if (inv.quantity > 0 && inv.quantity >= 10) {
                    inv.quantity -= 10;
                    _this.updateQ(index);
                }
                else {
                    inv.quantity = 0;
                    _this.updateQ(index);
                }
            });
        }
        else {
            this.product_obj_new.productinventory_set.forEach(function (inv, index) {
                if (inv.quantity > 0 && inv.quantity >= 10) {
                    inv.quantity -= 10;
                }
                else {
                    inv.quantity = 0;
                }
            });
        }
    };
    EditProductComponent.prototype.onToggle = function (evt) {
        console.log(evt.checked);
        this.productStatus = evt.checked;
        this.toggleProductstaus(this.product_obj.id, evt.checked);
    };
    EditProductComponent.prototype.toggleProductstaus = function (pid, status) {
        var _this = this;
        var obj = { product: pid, is_active: status };
        this.dataservice.toggleProductStatus(obj).subscribe(function (data) {
            console.log(data);
            _this.updateStatusLocally(status);
        }, function (error) {
            _this.productStatus = !_this.productStatus;
        });
    };
    EditProductComponent.prototype.uploadSizeChart = function (event, i) {
        var _this = this;
        console.log(i);
        var file = event.target.files[0];
        console.log(file);
        if (file) {
            var fileType = file.type;
            var reader = new FileReader();
            reader.onload = function (e) {
                var obj = { style: _this.product_obj.productsizechart_set[i].style, size_chart: file };
                // const url = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
                // this.uploadedFile = {
                //   name: file.name,
                //   type: fileType,
                //   url: url,
                //   file: file
                // }
                // this.product_obj.size_chart = file
                console.log(obj);
                _this.updatesinglesizechart(_this.product_obj.productsizechart_set[i].id, obj, i);
            };
            reader.readAsDataURL(file);
            // this.isImage = this.dataservice.isImage(fileType)
            // this.isPdf = this.dataservice.isPDF(fileType)
            // if (this.dataservice.isImage(fileType) || this.dataservice.isPDF(fileType)) {
            //   reader.readAsDataURL(file);
            // }
        }
    };
    EditProductComponent.prototype.updatesinglesizechart = function (i, obj, localid) {
        var _this = this;
        this.dataservice.addNewSizeChartToStyle(i, obj).then(function (val) {
            console.log(val);
            _this.product_obj.productsizechart_set[localid].size_chart = val.size_chart;
            if (_this.product_obj.productsizechart_set.length > 0) {
                _this.product_obj.productsizechart_set.forEach(function (c) {
                    _this.isImageBackend = _this.dataservice.isImageFromBackend(c.size_chart);
                    _this.isPdfBackend = _this.dataservice.isPdfFromBackend(c.size_chart);
                });
            }
        });
    };
    EditProductComponent.prototype.updateStatusLocally = function (status) {
        var _this = this;
        if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
            var ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(function (element) { return element.id == _this.product_obj.id; });
            console.log(ind);
            this.product_obj.active = status;
            this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind] = this.product_obj;
        }
    };
    EditProductComponent.prototype.add = function (event, i, type) {
        var value = this.dataservice.capitalizeFirstLetter(event.value || '').trim();
        switch (type) {
            case 'color': {
                console.log(type, value, 'as');
                if (value) {
                    this.colorArray.push(value);
                    // this.colorSizeChanged = true
                }
                if (event.input) {
                    event.input.value = '';
                }
                break;
            }
            case 'size': {
                console.log(type);
                if (value) {
                    this.sizeArray.push(value.toUpperCase());
                    // this.colorSizeChanged = true
                }
                if (event.input) {
                    event.input.value = '';
                }
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
    };
    EditProductComponent.prototype.removeC = function (item, i, type) {
        switch (type) {
            case 'color': {
                console.log(type);
                var index = this.colorArray.indexOf(item);
                if (index >= 0) {
                    this.colorArray.splice(index, 1);
                    // this.colorSizeChanged = true
                    this.showNewTable = false;
                    this.product_obj_new.productinventory_set = [];
                }
                break;
            }
            case 'size': {
                console.log(type);
                var index = this.sizeArray.indexOf(item);
                if (index >= 0) {
                    this.sizeArray.splice(index, 1);
                    this.product_obj_new.productinventory_set = [];
                    // this.colorSizeChanged = true
                    this.showNewTable = false;
                }
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
    };
    EditProductComponent.prototype.generateList = function () {
        var _this = this;
        this.colorSizeChanged = true;
        this.product_obj_new.productinventory_set = [];
        // const colorArray = this.product_obj.color.split(',').filter(c => c != "");
        // const sizeArray = this.product_obj.size.split(',').filter(c => c != "");
        var colorArray = this.colorArray;
        var sizeArray = this.sizeArray;
        colorArray.forEach(function (color) {
            sizeArray.forEach(function (size) {
                console.log(color, size);
                _this.tags.value.forEach(function (tag) {
                    var inv = { tag: tag.name, color: _this.dataservice.transform(color.trim()), size: size.trim().toUpperCase(), quantity: 10, price: Number(_this.product_obj.price), files: [], imageArr: [], isChecked: false };
                    _this.product_obj_new.productinventory_set.push(inv);
                });
                _this.sortProductInvNewGeneratedList();
                // var inv: Inventory = {tag: ,color: color.trim(), size: size.trim(), quantity: 0, files: [], imageArr: [], isChecked: false };
                // this.product_obj_new.productinventory_set.push(inv);
                // console.log(inv);
            });
        });
        this.showNewTable = true;
        console.log(this.product_obj_new);
    };
    EditProductComponent.prototype.uploadImageNew = function (event, i) {
        var _this = this;
        console.log(i);
        var images = event.target.files;
        var newFiles = [];
        if (images) {
            for (var _i = 0, images_1 = images; _i < images_1.length; _i++) {
                var image = images_1[_i];
                console.log("Image size before compressed: " + image.size + " bytes.");
                this.compressImage
                    .compress(image)
                    .pipe(take(1))
                    .subscribe(function (compressedImage) {
                    newFiles.push(compressedImage);
                    if (images.length === newFiles.length) {
                        console.log(newFiles);
                        _this.openAskDialog(i, newFiles);
                    }
                    console.log("Image size after compressed: " + (compressedImage.size) + " bytes.");
                    // now you can do upload the compressed image
                });
            }
        }
    };
    EditProductComponent.prototype.uploadImageExisting = function (event, i) {
        var _this = this;
        console.log(i);
        var newFiles = [];
        var images = event.target.files;
        if (images) {
            if (this.colorSizeEdit) {
                console.log("edit");
                for (var _i = 0, images_2 = images; _i < images_2.length; _i++) {
                    var image = images_2[_i];
                    console.log("Image size before compressed: " + image.size + " bytes.");
                    this.compressImage
                        .compress(image)
                        .pipe(take(1))
                        .subscribe(function (compressedImage) {
                        newFiles.push(compressedImage);
                        if (images.length === newFiles.length) {
                            console.log(newFiles);
                            _this.openAskDialogForExisting(i, newFiles);
                        }
                        console.log("Image size after compressed: " + (compressedImage.size) + " bytes.");
                        // now you can do upload the compressed image
                    });
                }
            }
        }
        return;
        this.openAskDialogForExisting(i, images);
    };
    // not in use currently 
    EditProductComponent.prototype.setNewImage = function (image) {
        if (image !== null) {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + image + ")");
        }
        else {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + 'https://t4.ftcdn.net/jpg/02/15/84/43/240_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg' + ")");
        }
    };
    // alldialogues
    EditProductComponent.prototype.openAskDialog = function (i, files) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to apply this product image to other color and size options?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                _this.openAskColorSizeDialog(i, files);
            }
            else {
                var _loop_2 = function (file) {
                    _this.product_obj_new.productinventory_set[i].files.push(file);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var obj = {
                            name: file.name,
                            url: e.target.result
                        };
                        _this.product_obj_new.productinventory_set[i].imageArr.push(obj);
                    };
                    reader.readAsDataURL(file);
                };
                for (var _i = 0, files_2 = files; _i < files_2.length; _i++) {
                    var file = files_2[_i];
                    _loop_2(file);
                }
            }
        });
    };
    EditProductComponent.prototype.openAskDialogForExisting = function (i, files) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to apply this product image to other color and size options?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                _this.openAskColorSizeDialogForExisting(i, files);
            }
            else {
                for (var _i = 0, files_3 = files; _i < files_3.length; _i++) {
                    var file = files_3[_i];
                    _this.addNewImageToCombo(_this.product_obj.productinventory_set[i].id, file);
                }
            }
        });
    };
    EditProductComponent.prototype.openAskColorSizeDialog = function (i, files) {
        var _this = this;
        var element = this.product_obj_new;
        element.colorArray = this.colorArray;
        element.sizeArray = this.sizeArray;
        console.log(element.colorArray, element.sizeArray);
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Choose color and size!",
                content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
                type: "colorAndsizeOptions"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            console.log(_this.product_obj_new.productinventory_set);
            _this.product_obj_new.productinventory_set.forEach(function (inv, indexx) {
                result.tags.forEach(function (tag) {
                    if (tag == inv.tag) {
                        if (result.colors.includes(inv.color) && result.sizes.includes(inv.size)) {
                            console.log('add here');
                            var _loop_3 = function (file) {
                                _this.product_obj_new.productinventory_set[indexx].files.push(file);
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    var obj = {
                                        name: file.name,
                                        url: e.target.result
                                    };
                                    _this.product_obj_new.productinventory_set[indexx].imageArr.push(obj);
                                };
                                reader.readAsDataURL(file);
                            };
                            for (var _i = 0, files_4 = files; _i < files_4.length; _i++) {
                                var file = files_4[_i];
                                _loop_3(file);
                            }
                        }
                    }
                });
            });
        });
    };
    EditProductComponent.prototype.openAskColorSizeDialogForExisting = function (i, files) {
        var _this = this;
        var element = this.product_obj_new;
        element.colorArray = this.colorArray;
        element.sizeArray = this.sizeArray;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "Choose color and size!",
                content: { ca: element.colorArray, sa: element.sizeArray, ta: this.tags.value },
                type: "colorAndsizeOptions"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            // return
            console.log(_this.product_obj.productinventory_set);
            console.log(_this.product_obj.productinventory_set.length);
            _this.product_obj.productinventory_set.forEach(function (inv, indexx) {
                result.tags.forEach(function (element, ind) {
                    console.log(element, inv.tag);
                    if (element == inv.tag) {
                        if (result.colors.map(function (color) { return color.toLowerCase(); }).includes(inv.color.toLowerCase()) &&
                            result.sizes.map(function (size) { return size.toLowerCase(); }).includes(inv.size.toLowerCase())) {
                            console.log("color matched", indexx);
                            for (var _i = 0, files_5 = files; _i < files_5.length; _i++) {
                                var file = files_5[_i];
                                console.log(file);
                                var id = _this.product_obj.productinventory_set[indexx].id;
                                _this.addNewImageToCombo(id, file);
                            }
                        }
                    }
                });
            });
        });
    };
    EditProductComponent.prototype.addNewImageToCombo = function (id, file) {
        var _this = this;
        this.isLoading = true;
        this.dataservice.addInvImage(id, file).then(function (res) {
            console.log(res);
            _this.isLoading = false;
            var index = _.findIndex(_this.product_obj.productinventory_set, function (e) {
                return e.id == id;
            }, 0);
            console.log(index);
            _this.product_obj.productinventory_set[index].productinventoryimage_set.push(res);
            console.log(_this.product_obj.productinventory_set[index].productinventoryimage_set);
            _this.openSnackBar("Image Updated", "close");
        }, function (err) {
            console.log(err);
            _this.isLoading = false;
            _this.openSnackBar("Image Updated error", "close");
        });
    };
    EditProductComponent.prototype.inputChanged = function () {
        console.log("gotcha");
        this.colorSizeChanged = true;
    };
    EditProductComponent.prototype.toggleColorSizeEdit = function () {
        this.colorSizeEdit = !this.colorSizeEdit;
        if (this.colorSizeEdit) {
            this.colorSizeIcon = "close";
        }
        else {
            this.showNewTable = false;
            this.product_obj_new.productinventory_set = [];
            this.resetCSInputValues();
            this.colorSizeChanged = false;
            this.colorSizeIcon = "edit";
        }
    };
    EditProductComponent.prototype.toggleSize = function (size) {
        var index = this.selectedSizes.indexOf(size);
        if (index === -1) {
            this.selectedSizes.push(size);
        }
        else {
            this.selectedSizes.splice(index, 1);
        }
        this.sizeArray = this.selectedSizes;
        console.log(this.selectedSizes);
    };
    EditProductComponent.prototype.resetCSInputValues = function () {
        var _this = this;
        this.product_obj.color = this.tempColorValue;
        this.product_obj.size = this.tempSizeValue;
        this.colorArray = [];
        this.sizeArray = [];
        this.tempcolorArray.forEach(function (col) {
            _this.colorArray.push(col);
        });
        this.tempsizeArray.forEach(function (siz) {
            _this.sizeArray.push(siz);
        });
        // console.log(this.tempColorValue, this.colorArray, this.tempcolorArray);
    };
    EditProductComponent.prototype.removeCombo = function (i) {
        this.product_obj_new.productinventory_set = _.filter(this.product_obj_new.productinventory_set, function (item, index) {
            return index != i;
        });
    };
    EditProductComponent.prototype.openAskDeleteComboDiaglogue = function (i) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to delete this combination?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                _this.isLoading = true;
                _this.dataservice.deleteInvRow(_this.product_obj.productinventory_set[i].id).then(function (res) {
                    console.log(res);
                    _this.isLoading = false;
                    _this.product_obj.productinventory_set = _.filter(_this.product_obj.productinventory_set, function (item, index) {
                        return index != i;
                    });
                    _this.colorArray = _this.colorArray.filter(function (color, index) { return _this.product_obj.productinventory_set.some(function (inv) {
                        return inv.color.toLowerCase() == color.toLowerCase();
                    }); });
                    console.log(_this.colorArray);
                    _this.sizeArray = _this.sizeArray.filter(function (size, index) { return _this.product_obj.productinventory_set.some(function (inv) {
                        return inv.size.toLowerCase() == size.toLowerCase();
                    }); });
                    _this.selectedSizes = _this.sizeArray;
                    _this.openSnackBar("Combination Removed", "close");
                }, function (err) {
                    console.log(err);
                    _this.isLoading = false;
                    _this.openSnackBar("Combination Remove error", "close");
                });
            }
        });
    };
    EditProductComponent.prototype.removeComboExisting = function (i) {
        this.openAskDeleteComboDiaglogue(i);
    };
    EditProductComponent.prototype.removeImageNewTable = function (imgin, i) {
        console.log(imgin, i);
        this.product_obj_new.productinventory_set[i].imageArr.splice(imgin, 1);
        this.product_obj_new.productinventory_set[i].files.splice(imgin, 1);
    };
    EditProductComponent.prototype.openAskDeleteImageDiaglogue = function (imgin, i) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: "",
                content: "Do you want to delete this image?",
                type: "question"
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("The dialog was closed", result);
            if (result == 'yes') {
                _this.deleteInvImage(imgin, i);
            }
        });
    };
    EditProductComponent.prototype.removeImageExistingTable = function (imgin, i) {
        console.log(imgin, i);
        this.openAskDeleteImageDiaglogue(imgin, i);
    };
    EditProductComponent.prototype.deleteInvImage = function (imgin, i) {
        var _this = this;
        console.log(imgin, this.product_obj.productinventory_set[i].productinventoryimage_set[imgin].id);
        var imgId = this.product_obj.productinventory_set[i].productinventoryimage_set[imgin].id;
        this.dataservice.deleteImage(imgId).then(function (res) {
            console.log("image delete success", res);
            _this.product_obj.productinventory_set[i].productinventoryimage_set.splice(imgin, 1);
            _this.openSnackBar("Image Removed", "close");
        }, function (err) {
            console.log(err);
        });
    };
    EditProductComponent.prototype.updateInv = function () {
        var _this = this;
        if (this.showNewTable && this.colorSizeChanged && this.product_obj_new.productinventory_set.every(function (element) { return element.imageArr.length > 0; })) {
            console.log("new inv");
            var sfid = this.selectedstorefronts[0].id;
            console.log(sfid);
            this.dataservice.updateNewInventory(this.product_obj.id, this.product_obj_new.productinventory_set, sfid).then(function (res) {
                console.log("new inv response", res);
                _this.openSnackBar("Inventory Updated", "close");
                // location.reload()
                _this.isLoading = false;
                _this.InventoryconfirmationDialog();
            }, function (err) {
                console.log(err);
            });
        }
    };
    EditProductComponent.prototype.updateQ = function (i) {
        var _this = this;
        var invObj = {
            color: this.product_obj.productinventory_set[i].color,
            size: this.product_obj.productinventory_set[i].size,
            quantity: this.product_obj.productinventory_set[i].quantity,
        };
        this.isLoading = true;
        this.dataservice.updateInventory(this.product_obj.productinventory_set[i].id, invObj).then(function (res) {
            _this.isLoading = false;
            console.log("update quantity res", res);
            _this.openSnackBar("quantity updated", "close");
        }, function (err) {
            _this.isLoading = false;
            _this.openSnackBar("quantity update error", "close");
        });
    };
    EditProductComponent.prototype.updateP = function (i) {
        var _this = this;
        var invObj = {
            color: this.product_obj.productinventory_set[i].color,
            size: this.product_obj.productinventory_set[i].size,
            price: this.product_obj.productinventory_set[i].price,
        };
        this.isLoading = true;
        this.dataservice.updateInventory(this.product_obj.productinventory_set[i].id, invObj).then(function (res) {
            _this.isLoading = false;
            console.log("update price res", res);
            _this.openSnackBar("price updated", "close");
        }, function (err) {
            _this.isLoading = false;
            _this.openSnackBar("price update error", "close");
        });
    };
    EditProductComponent.prototype.updateQuantity = function (q, i) {
        this.invIndex = i;
        this.quantity$.next(q);
    };
    EditProductComponent.prototype.updatePrice = function (p, i) {
        console.log(p, i);
        this.invIndex = i;
        this.price$.next(p);
    };
    EditProductComponent.prototype.shiftObjectAtTop = function () {
        var _this = this;
        if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
            var ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(function (element) { return element.id == _this.product_obj.id; });
            console.log(ind);
            this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].splice(ind, 1);
            this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0].unshift(this.product_obj);
        }
    };
    EditProductComponent.prototype.update2 = function () {
        var _this = this;
        var obj = {
            title: this.product_obj.title,
            code: this.product_obj.code,
            description: this.product_obj.description,
            product_background_color: this.product_obj.product_background_color,
            product_text_color: this.product_obj.product_text_color,
            price: this.product_obj.price,
            categories: this.product_obj.categories,
            tags: this.tags.value,
            special_tags: this.specialTags.value,
            is_featured: this.product_obj.is_featured,
            storefront: this.selectedstorefronts[0].id
        };
        if (this.uploadedFile) {
            obj['size_chart'] = this.uploadedFile.file;
        }
        if (obj.title.length <= 0 || obj.code.length <= 0 || obj.price == null) {
            alert("Fill All The Details");
            return;
        }
        if (this.showNewTable && this.colorSizeChanged) {
            obj['color'] = this.dataservice.makeColorStr(this.colorArray);
            obj['size'] = this.sizeArray.toString();
            if (this.product_obj_new.productinventory_set.some(function (element) { return element.imageArr.length <= 0; })) {
                alert("Add atleast one Image to every color and size option!");
                console.log("new img err");
                return;
            }
        }
        else {
            if (this.product_obj.productinventory_set.some(function (element) { return element.productinventoryimage_set.length <= 0; })) {
                alert("Add atleast one Image to every color and size option!");
                console.log("existing img err");
                return;
            }
        }
        if (this.tags.value == null) {
            alert("Select atleast one product type option!");
            return;
        }
        console.log(this.tags.value, obj);
        console.log(this.product_obj.id, obj["color"], obj["size"]);
        this.isLoading = true;
        this.dataservice.updateProduct2(this.product_obj.id, obj).then(function (res) {
            console.log(res);
            _this.updateProductLocally(res);
            // this.updateProductInselectedStore(res as any);
            _this.updateInv();
            // this.openSnackBar("Product Updated","close");
            if (!_this.showNewTable && !_this.colorSizeChanged) {
                _this.isLoading = false;
                _this.confirmationDialog();
            }
        }, function (err) {
            console.error(err);
            _this.econfirmationDialog();
        });
    };
    // update() {
    //   let obj = {
    //     title: this.product_obj.title,
    //     code: this.product_obj.code,
    //     description: this.product_obj.description,
    //     price: this.product_obj.price,
    //     categories: this.product_obj.categories,
    //     color: this.product_obj.color,
    //     size: this.product_obj.size,
    //     is_featured: this.product_obj.is_featured,
    //   };
    //   this.dataservice.updateProduct(obj, this.product_obj.id).subscribe(
    //     (data) => {
    //       console.log(data);
    //       this.updateProductInselectedStore(data.json());
    //       if (this.files.length > 0) {
    //         this.updateImages();
    //       } else {
    //         this.confirmationDialog();
    //       }
    //     },
    //     (error) => {
    //       console.log(error);
    //       this.econfirmationDialog();
    //     }
    //   );
    // }
    EditProductComponent.prototype.updateProductLocally = function (productObj) {
        if (this.dataservice.inmemoryData.length > 0 && this.urlindex != -1) {
            var ind = this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].findIndex(function (element) { return element.id == productObj.id; });
            console.log(ind, this.dataservice.inmemoryData[this.urlindex].multicollectionarry);
            if (ind != -1) {
                this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber][ind] = productObj;
                this.dataservice.inmemoryData[this.urlindex].multicollectionarry[this.dataservice.pageNumber].splice(ind, 1);
                this.dataservice.inmemoryData[this.urlindex].multicollectionarry[0].unshift(productObj);
            }
        }
    };
    EditProductComponent.prototype.updatePreivewImage = function (url, showC) {
        var _this = this;
        this.dataservice
            .updateProduct({ preview_image: url }, this.product_obj.id)
            .subscribe(function (data) {
            console.log(data);
            _this.product_obj = data.json();
            if (showC) {
                _this.confirmationDialogPreviewImage();
            }
        });
    };
    EditProductComponent.prototype.updateProductInselectedStore = function (data) {
        var _this = this;
        console.log(data);
        console.log(this.dataservice.selected_storefront);
        if (this.dataservice.selected_storefront) {
            var index = this.dataservice.selected_storefront.storefront_product.findIndex(function (element) { return element.id === _this.product_obj.id; });
            console.log(this.dataservice.selected_storefront);
            console.log(data, index, 'idk');
            if (index !== -1) {
                this.dataservice.selected_storefront.storefront_product[index].title = this.product_obj.title;
                this.dataservice.selected_storefront.storefront_product[index].code = this.product_obj.code;
                this.dataservice.selected_storefront.storefront_product[index].description = this.product_obj.description;
                this.dataservice.selected_storefront.storefront_product[index].price = this.product_obj.price;
                this.dataservice.selected_storefront.storefront_product[index].categories = this.product_obj.categories;
                this.dataservice.selected_storefront.storefront_product[index].color = this.product_obj.color;
                this.dataservice.selected_storefront.storefront_product[index].size = this.product_obj.size;
                this.dataservice.selected_storefront.storefront_product[index].is_featured = this.product_obj.is_featured;
                this.dataservice.selected_storefront.storefront_product[index].category_name = data.category_name;
            }
        }
    };
    EditProductComponent.prototype.InventoryconfirmationDialog = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Success',
                content: 'Product has been successfully updated',
                type: 'confirmation',
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            location.reload();
        });
    };
    EditProductComponent.prototype.confirmationDialog = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Success',
                content: 'Product has been successfully updated',
                type: 'confirmation',
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
        });
    };
    EditProductComponent.prototype.confirmationDialogPreviewImage = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Success',
                content: 'Preivew Image has been successfully updated',
                type: 'confirmation',
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
        });
    };
    EditProductComponent.prototype.econfirmationDialog = function () {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: 'Error', content: 'Something went wrong!', type: 'error' },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            location.reload();
        });
    };
    EditProductComponent.prototype.copySizes = function () {
        this.dataservice.copiedSizes$.next(this.sizeArray);
        this.openSnackBar("Sizes Copied Successfully.", "");
    };
    return EditProductComponent;
}());
export { EditProductComponent };
