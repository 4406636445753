<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="z-index: 50;
position: fixed;
top: 51px;"></mat-progress-bar>
<div class="main">
  <mat-toolbar style="justify-content: space-between;">
    <h3 class="page-heading">Customers / Stores</h3>

    <!-- <span class="example-fill-remaining-space"></span> -->
    <div>
      <input class="input-style" [(ngModel)]="searchText" placeholder="Search" />
      <mat-form-field style="margin-right: 20px">
        <mat-label style="font-size: 16px">Sort By</mat-label>
        <mat-select [(ngModel)]="selectedSort" style="font-size: 16px">
          <mat-option *ngFor="let item of sortParams" [value]="item.value" (click)="sortDataDropDown()">
            {{ item.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup"
        [value]="selectedVal" (change)="onValChange(group.value)">
        <mat-button-toggle value="grid">
          <mat-icon class="example-icon">view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
          <mat-icon class="example-icon">view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </mat-toolbar>

  <div class="mess">
    <!-- Grid -->
    <div class="col-sm-60" *ngIf="selectedVal === 'grid'">
      <div style="width: 100%;" class="col-sm-15" *ngFor="
          let Item of (customer_collection | filter: searchText);
          let i = index
        ">
        <div>
          <fieldset style="border: 1px solid #c0c0c0;
          margin: 0 2px;
          padding: 0.35em 0.625em 0.75em;">
            <!-- customer-card -->
            <legend style="border: 0; width: auto">
              <mat-card class="customer-card" [ngStyle]="Item.status == 'Active'? {} : {'background-color':'#999999','opacity':'0.5'}">
                <!-- <a mat-stroked-button class="edit-btn" (click)="editCustomer(Item)">Edit</a>
                <button type="button" class="btn btn-default btn-edits-delet1" (click)="editCustomer(Item)">EDIT</button>
                <button type="button"class="btn btn-default btn-edits-delet" (click)="deleteCustomer(Item)">DELETE</button> -->
                <div class="flex delete ">


                  <mat-icon class="icon-black" (click)="editCustomer(Item)" matTooltip="Edit"
                    [matTooltipPosition]="'above'">
                    edit
                  </mat-icon>
                  <mat-icon class="icon-black" (click)="deleteCustomer(Item)" matTooltip="Delete"
                    [matTooltipPosition]="'above'">
                    delete
                  </mat-icon>
                </div>
                <mat-card-header>
                  <div mat-card-avatar class="example-header-image" [style.background-image]="getBackground(Item.logo)">
                  </div>
                  <mat-card-title class="title font-fmly" style="width: 150px;margin-top: 5px;"
                    matTooltip="{{ Item.name }}" [matTooltipPosition]="'above'">
                    {{ Item.name }}
                  </mat-card-title>

                  <mat-card-subtitle>{{ Item.user_type }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content style="margin: 0">
                  <div class="tab2">
                    <div *ngIf="item?.address_city">
                      <span>City :</span>
                      <span class="Cityname">{{ Item.address_city }}</span>
                    </div>
                    <div>
                      <span>Status :</span>
                      <span class="Cityname">{{ Item.status }}</span>
                    </div>
                  </div>

                  <!-- <div class="text-center">
                <p class="stores">No. of Stores</p>
                <p class="nums num1" (click)="viewStores(item)">
                  {{ item.user_storefront.length }}
                </p>
              </div> -->
                </mat-card-content>
                <mat-card-actions class="text-center">
                  <!-- <button *ngIf="Item.user_storefront.length > 0"  mat-button  class="bt2"  (click)="viewStores(Item)">  Stores</button>
                  <button mat-button class="bt2"routerLink="/orders/{{ Item.id }}">Orders</button> -->
                  <div class="flex space-evenly">
                    <mat-icon *ngIf="Item.user_storefront.length > 0" class="bt2" (click)="viewStores(Item)"
                      matTooltip="View Stores" [matTooltipPosition]="'above'">
                      store
                    </mat-icon>

                    <mat-icon *ngIf="Item.user_storefront.length > 0" class="bt2" routerLink="/orders/{{ Item.id }}"
                      matTooltip="Orders" [matTooltipPosition]="'above'">
                      local_grocery_store
                    </mat-icon>
                  </div>
                </mat-card-actions>
              </mat-card>
            </legend>
            <!-- stores-card -->

            <div class="flex" style="flex-wrap: wrap">
              <ng-container *ngFor="let item of Item.user_storefront; let i = index">
                <mat-card class="store-card  flex space-evenly" [ngStyle]="item.status == 'active'? {} : {'background-color':'#999999','opacity':'0.5'}">

                  <mat-icon *ngIf="item.passcode" class="icon-black" matTooltip="Password Protected"
                    [matTooltipPosition]="'above'">
                    lock
                  </mat-icon>
                  <div class="delete">
                    <mat-icon class="icon-black" (click)="copyUrlToClipboard(Item.name_slug,item)" matTooltip="Copy store url"
                      [matTooltipPosition]="'above'">
                      file_copy
                    </mat-icon>
                    <mat-icon class="icon-black" (click)="editStore(item)" matTooltip="Edit"
                      [matTooltipPosition]="'above'">
                      edit
                    </mat-icon>
                    <mat-icon class=" icon-black" (click)="deleteStore(Item, i, item.slug,item.id)" matTooltip="Delete"
                      [matTooltipPosition]="'above'">
                      delete
                    </mat-icon>
                  </div>
                  <mat-card-header>
                    <mat-card-title class="title font-fmly" matTooltip="{{ item.name }}" [matTooltipPosition]="'above'">
                      {{ item.name }}
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="tap-height">
                      <!-- <div class="taps">
                        Status :
                        <span class="Cityname">{{ item.status }}</span>
                      </div> -->
                      <div *ngIf="item.location" class="taps title" matTooltip="{{ item.location }}"
                        [matTooltipPosition]="'above'">
                        Location :
                        <span class="Cityname">{{ item.location }}</span>
                      </div>

                      <div>
                        <div *ngIf="item.chain_name" class="row-height">
                          <div class="taps">
                            <span>Store Group :</span>
                            <span class="contests" matTooltip="{{ item.chain_name }}" [matTooltipPosition]="'above'">
                              <p style="margin-left: 5px;">{{ item.chain_name }}</p>
                            </span>
                          </div>
                        </div>
                        <div class="row-height">
                          <div class="taps" style="margin: 0 16px;">
                            <div>Customer :</div>
                            <div class="contests" matTooltip="{{ item.user_v1 }}" [matTooltipPosition]="'above'">
                              <p style="margin-left: 5px;"> {{ item.user_v1 }}</p>
                            </div>
                          </div>

                          <div class="taps" style="margin: 0 16px;">Copy Store :
                            <mat-icon class="icon-black" (click)="copyStore(item)" matTooltip="Copy store"
                              [matTooltipPosition]="'above'">
                              file_copy
                            </mat-icon>
                          </div>
                        </div>

                        <div class="row-height" *ngIf="item.passcode">
                          <div class="taps" >
                            <div>Passcode :</div>
                            <div class="contests">
                              <p style="margin-left: 5px;">{{ item.passcode }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex space-evenly text-center">
                      <div>
                        <div>No. of Products</div>
                        <div (click)="editStoreP(item)" class="nums num1">
                          {{ item.storefront_product }} / {{item.storefront_product_total}}
                        </div>
                      </div>
                      <div>
                        <div>No. of Orders</div>
                        <div (click)="showOrders(item)" class="nums num2">
                          {{ item.storefront_order }}
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                  <mat-card-actions class="text-center" style="display: flex;
                  flex-wrap: wrap;
                  justify-content: space-evenly;">
                    <!-- <button mat-button class="bt1" (click)="editStore(item)">Edit Store</button> -->
                    <!-- < <button mat-button class="bt1" (click)="previewStore(item)">  Preview Store</button> -->

                    <div class="preview-container" (click)="previewStore(item, Item)" matTooltip="Preview Store"
                      [matTooltipPosition]="'above'">
                      <mat-icon class="preview-content-store-icon">
                        store
                      </mat-icon>
                      <mat-icon class="preview-content-preview-icon">
                        chevron_left
                      </mat-icon>
                    </div>

                    <div>
                      <p>Status : {{item.status}}</p>
                      <mat-slide-toggle [matTooltip]="item.status == 'active' ? 'Archive' : 'Active'"
                        [matTooltipPosition]="'above'" color="primary" [checked]="item.status == 'active'"
                        (change)="onToggle($event,Item,item,i)">
                      </mat-slide-toggle>
                    </div>


                    <!-- <mat-icon class="store-archive-activate" *ngIf="item.status === 'active'"
                      (click)="toggleStoreFrontStatus(Item, item, 'archive', i)" matTooltip="Archive"
                      [matTooltipPosition]="'above'">
                      archive
                    </mat-icon>
                    
                    <mat-icon class="store-archive-activate" *ngIf="item.status !== 'active'"
                      (click)="toggleStoreStatusData(Item, item, 'active', i)" matTooltip="Activate"
                      [matTooltipPosition]="'above'">
                      check_circle
                    </mat-icon> -->
                  </mat-card-actions>
                </mat-card>
              </ng-container>

              <!-- addStore -->

              <div class="store-card mat-card flex align-items justify-content add-store-front"
                (click)="addStore(Item)">
                <!-- <a><i class="fa fa-plus" aria-hidden="true" style="font-size: 35px;"></i></a> -->
                <mat-icon class="add-store-front-icon" matTooltip="Add Store Fronts" [matTooltipPosition]="'above'">
                  exposure_plus_1
                </mat-icon>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- List -->

    <div class="" *ngIf="selectedVal === 'list'">
      <table class="table-list table-striped " matSort (matSortChange)="sortData($event)">
        <tr class="row-header font-fmly">
          <!-- <th mat-sort-header="id">ID</th> -->

          <th style="width: 150px;" mat-header-row mat-sort-header="customer_image"><b>Customer Logo</b></th>

          <th mat-header-row mat-sort-header="name"><b>Name</b></th>
          <th mat-sort-header="user_type">Type</th>

          <th mat-sort-header="nstorefront">No. of Storefronts</th>
          <th mat-sort-header="location">City</th>
          <th mat-sort-header="status">Status</th>
          <th mat-sort-header="storefronts">Storefronts</th>
          <th mat-sort-header="viewStore">View Stores</th>
          <th mat-sort-header="vieworders">View Orders</th>
          <th mat-sort-header="delete">Delete</th>
          <th></th>
        </tr>
        <tr *ngFor="let item of (customer_collection | filter: searchText)" style="height: 80px;">
          <!-- <td>{{ item.id }}</td> -->

          <td (click)="editCustomer(item)" style="text-align: center;"><img class="customer-logo" src="{{item.logo}}"
              onError="this.src='assets/images/deaault_store_img.png';" alt=""></td>
          <td (click)="editCustomer(item)" style="cursor: pointer;">{{ item.name }}</td>

          <td (click)="editCustomer(item)">{{ item.user_type }}</td>

          <td (click)="editCustomer(item)">{{ item.user_storefront.length }}</td>
          <td (click)="editCustomer(item)">{{ item.address_city }}</td>
          <td (click)="editCustomer(item)">{{ item.status }}</td>
          <!-- chiplist  -->
          <td style="max-width: 200px;">
            <mat-chip-list #chipList id="style-3">
              <mat-chip (click)="editStore(itemx)" style="flex: none;align-self: center;cursor: pointer;"
                *ngFor="let itemx of item.user_storefront;let i = index" (removed)="deleteStore(item, i, itemx.slug,itemx.id)"
                [ngStyle]="itemx.status == 'active'? {} : {'background-color':'#999999','opacity':'0.5'}">
                {{ itemx.name }}

                <button style="background-color: transparent;border:none;display: flex;
                justify-content: center;" (click)="previewStore(itemx,item,$event)">
                  <mat-icon style="width: 20px;
                  height: 20px;
                  font-size: 20px;
                  margin: 0 5px;" class="product-card-table-i product-card-table-delete-i" matTooltip="Preview store"
                    [matTooltipPosition]="'above'">
                    store
                  </mat-icon>
                </button>
                <div [ngClass]="itemx.status == 'active'? 'activeInStore' : 'inActiveInStore'"
                  [matTooltip]="itemx.status == 'active' ? 'Store is active' : 'Store is archive'"
                  [matTooltipPosition]="'above'"></div>
                <mat-icon class="icon-black" (click)="editStore(itemx)" matTooltip="Edit"
                  [matTooltipPosition]="'above'">
                  edit
                </mat-icon>
                <mat-icon matTooltip="Delete store" [matTooltipPosition]="'above'" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>

          </td>

          <!-- vs -->
          <td>
            <mat-icon *ngIf="item.user_storefront.length > 0" class="product-card-table-i product-card-table-view-i"
              (click)="viewStores(item)" matTooltip="View Stores" [matTooltipPosition]="'above'">
              store
            </mat-icon>
            <div *ngIf="item.user_storefront.length == 0" style="height: 35px;" (click)="addStore(item)"
              matTooltip="Add Store" [matTooltipPosition]="'above'" class="product-card-table-add-i">
              <mat-icon class="product-card-table-icon-store">
                store
              </mat-icon>
              <mat-icon class="product-card-table-icon-plus">
                exposure_plus_1
              </mat-icon>
            </div>
          </td>
          <td>
            <mat-icon class="product-card-table-i product-card-table-orders-i" routerLink="/orders/{{ item.id }}"
              matTooltip="Orders" [matTooltipPosition]="'above'">
              local_grocery_store
            </mat-icon>
          </td>

          <td>
            <mat-icon class="product-card-table-i product-card-table-delete-i" (click)="deleteCustomer(item)"
              matTooltip="Delete" [matTooltipPosition]="'above'">
              delete
            </mat-icon>
          </td>



        </tr>
      </table>
    </div>
    <!-- pagination -->
    <div class="col-sm-60 text-center paginat">
      <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
    </div>
    <button mat-fab color="primary" class="btn-cust" (click)="addCustomer()" matTooltip="Add Customer"
      [matTooltipPosition]="'left'">
      <mat-icon class="icons">add</mat-icon>
    </button>
  </div>
</div>

<!--
  mat table
<table class="table-list table-striped" *ngIf="selectedVal === 'list'" mat-table [dataSource]="dataSource" matSort
  class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="row-header font-fmly ">
      Name
    </th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="user_type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="row-header font-fmly ">
      Type
    </th>
    <td mat-cell *matCellDef="let element">{{ element.user_type }}</td>
  </ng-container>

  <ng-container matColumnDef="user_storefront">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="row-header font-fmly  ">
      No of storefronts
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.user_storefront.length }}
    </td>
  </ng-container>


  <ng-container matColumnDef="address_city">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="row-header font-fmly  ">
      City
    </th>
    <td mat-cell *matCellDef="let element">{{ element.address_city }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="row-header font-fmly ">
      Status
    </th>
    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
  </ng-container>


  <ng-container matColumnDef="btn1">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div style="    justify-content: space-evenly;
                        display: flex;">
        <mat-icon *ngIf="element.user_storefront.length > 0" class="product-card-table-i product-card-table-view-i"
          (click)="viewStores(element)" matTooltip="View Stores" [matTooltipPosition]="'above'">
          store
        </mat-icon>
        <div *ngIf="element.user_storefront.length == 0" style="height: 35px;" (click)="addStore(element)"
          matTooltip="Add Store" [matTooltipPosition]="'above'" class="product-card-table-add-i">
          <mat-icon class="product-card-table-icon-store">
            store
          </mat-icon>
          <mat-icon class="product-card-table-icon-plus">
            exposure_plus_1
          </mat-icon>
        </div>

        <mat-icon class="product-card-table-i product-card-table-orders-i" routerLink="/orders/{{ element.id }}"
          matTooltip="Orders" [matTooltipPosition]="'above'">
          local_grocery_store
        </mat-icon>
        <mat-icon class="product-card-table-i product-card-table-delete-i" (click)="deleteCustomer(element)"
          matTooltip="Delete" [matTooltipPosition]="'above'">
          delete
        </mat-icon>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table> -->

<!-- ----normal table -->

<!-- <table class="table-list table-striped" *ngIf="selectedVal === 'list'" matSort (matSortChange)="sortData($event)">
  <tr class="row-header font-fmly">
    <th mat-header-row mat-sort-header="name"><b>Name</b></th>
    <th mat-sort-header="user_type">Type</th>

    <th mat-sort-header="nstorefront">No of storefronts</th>
    <th mat-sort-header="location">City</th>
    <th mat-sort-header="status">Status</th>
    <th></th>
  </tr>
  <tr *ngFor="let item of (customer_collection | filter: searchText)">
    <td>{{ item.name }}</td>
    <td>{{ item.user_type }}</td>

    <td>{{ item.user_storefront.length }}</td>
    <td>{{ item.address_city }}</td>
    <td>{{ item.status }}</td>
    <td>
      <div style="    justify-content: space-evenly;
            display: flex;">
        <mat-icon *ngIf="item.user_storefront.length > 0" class="product-card-table-i product-card-table-view-i"
          (click)="viewStores(item)" matTooltip="View Stores" [matTooltipPosition]="'above'">
          store
        </mat-icon>
        <div *ngIf="item.user_storefront.length == 0" style="height: 35px;" (click)="addStore(item)"
          matTooltip="Add Store" [matTooltipPosition]="'above'" class="product-card-table-add-i">
          <mat-icon class="product-card-table-icon-store">
            store
          </mat-icon>
          <mat-icon class="product-card-table-icon-plus">
            exposure_plus_1
          </mat-icon>
        </div>

        <mat-icon class="product-card-table-i product-card-table-orders-i" routerLink="/orders/{{ item.id }}"
          matTooltip="Orders" [matTooltipPosition]="'above'">
          local_grocery_store
        </mat-icon>
        <mat-icon class="product-card-table-i product-card-table-delete-i" (click)="deleteCustomer(item)"
          matTooltip="Delete" [matTooltipPosition]="'above'">
          delete
        </mat-icon>
      </div>
    </td>
  </tr>
</table> -->