import { OnInit, OnDestroy } from '@angular/core';
import { DataService } from './../data.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { ProductTag, m_customers_obj, userstorefront_obj } from '../models';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import readXlsxFile from 'read-excel-file';
import { CompressImageService } from '../compress.service';
import { environment } from '../../environments/environment';
var AddstorefrontComponent = /** @class */ (function () {
    function AddstorefrontComponent(dataservice, route, router, dialog, _location, compressImage) {
        this.dataservice = dataservice;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this._location = _location;
        this.compressImage = compressImage;
        this.tagsList = [];
        this.selectedTags = [];
        this.onlinePay = true;
        this.net30Pay = false;
        this.auto_fill = false;
        this.show_banner_images = false;
        this.generatedUrl = false;
        this.isPasscode = false;
        this.bannerColor = '#3f51b5';
        this.titleColor = '#ffffff';
        this.desBgColor = '#3f51b5';
        this.descTextColor = '#ffffff';
        this.show_customer_name = true;
        this.class = "widths";
        this.description = '';
        this.passcode = '';
        this.archive_message = '';
        //baseurl = 'https://store.primetime.company/';
        this.baseurl = 'https://preview.primetime.company/';
        this.storefront_status = [
            { value: 'active', viewValue: 'Active' },
            { value: 'archive', viewValue: 'Archive' }
        ];
        this.showForm = false;
        this.SFname = new FormControl('', [Validators.required]);
        this.SFurlHint = new FormControl('', [Validators.required]);
        this.passcodefc = new FormControl('', [Validators.required, Validators.maxLength(8)]);
        this.xlsErrors = [];
        this.xlsData = [];
        this.showRecords = false;
        this.buttonShowRecords = "View Records";
        this.bannerImages = [];
        this.bannerImagesFiles = [];
    }
    AddstorefrontComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.SFstatus = 'active';
        this.getTagList();
        if (!this.dataservice.selected_customer) {
            //  this.route.navigateByUrl('/customers');
            this.router.params.subscribe(function (params) {
                _this.cust_id = params['cust_id'];
                _this.getSingleCustomer(_this.cust_id);
            });
        }
        else {
            this.showForm = true;
            this.setupEventLisnter();
            this.cust_id = this.dataservice.selected_customer.id;
        }
        this.SFurlHint.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(function (term) {
            _this.checkforIllegalChar(term);
        });
        if (this.dataservice.draftStorefront) {
            console.log(this.dataservice.draftStorefront);
            var obj = this.dataservice.draftStorefront.data;
            this.SFname.patchValue(obj.name);
            this.description = obj.description;
            this.bannerColor = obj.banner_color_code;
            this.titleColor = obj.title_color;
            this.desBgColor = obj.desBgColor;
            this.descTextColor = obj.descTextColor;
            this.tagsList = obj.tags;
            this.free_shipping_amount = obj.free_shipping_amount;
            this.SFstatus = obj.status;
            this.archive_message = obj.archive_message;
            this.onlinePay = obj.online_payment;
            this.net30Pay = obj.net30;
            this.SFurlHint.patchValue(obj.slug);
            this.show_banner_images = obj.show_banner_images;
        }
    };
    AddstorefrontComponent.prototype.ngOnDestroy = function () {
        console.log("des");
        this.dataservice.draftStorefront = null;
    };
    AddstorefrontComponent.prototype.getTagList = function () {
        var _this = this;
        this.dataservice.getProductTagList().then(function (res) {
            console.log(res);
            _this.tagsList = res;
        });
    };
    AddstorefrontComponent.prototype.goToDraft = function () {
        this.route.navigate(['/draft']);
    };
    AddstorefrontComponent.prototype.setupEventLisnter = function () {
        var _this = this;
        setTimeout(function () {
            _this.inputxls = document.getElementById('input');
            _this.inputxls.addEventListener('change', _this.onFielLoad.bind(_this));
        }, 500);
    };
    AddstorefrontComponent.prototype.fileupload = function (event) {
        var _this = this;
        console.log(event.target.files);
        var files = event.target.files;
        // this.bannerImages = files;
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            this.bannerImagesFiles.push(file);
            this.compressImage
                .compress(file)
                .pipe(take(1))
                .subscribe(function (compressedImage) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var obj = {
                        name: compressedImage.name,
                        url: e.target.result
                    };
                    _this.bannerImages.push(obj);
                };
                reader.readAsDataURL(compressedImage);
            });
        }
    };
    AddstorefrontComponent.prototype.removeBannerImage = function (i) {
        this.bannerImages.splice(i, 1);
        this.bannerImagesFiles.splice(i, 1);
    };
    AddstorefrontComponent.prototype.onFielLoad = function () {
        var _this = this;
        var schema = {
            'PROPERTY_NAME': {
                prop: 'PROPERTY_NAME',
                type: String,
                required: true
            },
            'ADDRESS': {
                prop: 'ADDRESS',
                type: String
            },
            'COUNTRY': {
                prop: 'COUNTRY',
                type: String
            },
            'STATE': {
                prop: 'STATE',
                type: String
            },
            'CITY': {
                prop: 'CITY',
                type: String
            },
            'ZIPCODE': {
                prop: 'ZIPCODE',
                type: String
            },
            'SHIPPING_INSTRUCTIONS': {
                prop: 'SHIPPING_INSTRUCTIONS',
                type: String
            }
        };
        readXlsxFile(this.inputxls['files'][0], { schema: schema }).then(function (rows, errors) {
            _this.xlsData = rows.rows;
            _this.xlsErrors = rows.errors;
            console.log(_this.xlsData, _this.xlsErrors);
            // `rows` is an array of rows
            // each row being an array of cells.
        });
    };
    AddstorefrontComponent.prototype.checkforIllegalChar = function (term) {
        var splChars = '*|,":<>[]{}`\';()@&$#%,\' \'';
        for (var i = 0; i < this.SFurlHint.value.length; i++) {
            if (splChars.indexOf(this.SFurlHint.value.charAt(i)) !== -1) {
                this.SFurlHint.setErrors({ containsillegalchar: true });
                return;
            }
        }
        this.checkforduplicatestoreurl(term, this.cust_id);
    };
    AddstorefrontComponent.prototype.checkforduplicatestoreurl = function (term, id) {
        var _this = this;
        this.dataservice.veriftyURl(term, id).then(function (res) {
            console.log(res);
            _this.statuss = res['status'];
            console.log(_this.statuss);
            if (!_this.statuss) {
                _this.SFurlHint.setErrors({ alredyexist: true });
                return;
            }
            else {
                _this.url = _this.baseurl + _this.SFurlHint.value + '';
            }
        });
    };
    AddstorefrontComponent.prototype.getSingleCustomer = function (id) {
        var _this = this;
        this.dataservice.getSingleCustomer(id).subscribe(function (data) {
            console.log(data.json());
            var result = data.json();
            var selectedCustomer = result['results'][0];
            _this.dataservice.setSelectedCustomer(selectedCustomer);
            console.log(_this.dataservice.getSelectedCustomer());
            _this.showForm = true;
            _this.setupEventLisnter();
        }, function (err) {
            console.log(err);
        });
    };
    // generates the Url
    AddstorefrontComponent.prototype.generateUrl = function () {
        var _this = this;
        this.generatedUrl = true;
        this.SFurlHint.setValue(this.SFurlHint.value.toLowerCase());
        var splChars = '*|,":<>[]{}`\';()@&$#%,\' \'';
        if (!this.SFurlHint) {
            alert('url is Empty');
            return;
        }
        if (this.SFurlHint.valid) {
            this.dataservice.veriftyURl(this.SFurlHint.value, this.cust_id).then(function (res) {
                console.log(res);
                _this.statuss = res['status'];
                console.log(_this.statuss);
                if (!_this.statuss) {
                    //alert("Url Already Exist");
                    // this.SFurlHint.setValue('');
                    _this.SFurlHint.setErrors({ alredyexist: true });
                    return;
                }
                else {
                    for (var i = 0; i < _this.SFurlHint.value.length; i++) {
                        if (splChars.indexOf(_this.SFurlHint.value.charAt(i)) != -1) {
                            alert('Illegal characters detected!');
                            return;
                        }
                    }
                    _this.url = _this.baseurl + _this.SFurlHint.value + '';
                    console.log(_this.url);
                }
            });
        }
    };
    AddstorefrontComponent.prototype.cancel = function () {
        this._location.back();
        //this.route.navigate(['/customers']);
    };
    AddstorefrontComponent.prototype.CreateStorefront = function (create) {
        var _this = this;
        console.log(this.selectedTags);
        if (this.SFname.invalid || this.SFurlHint.invalid) {
            this.SFname.markAsTouched();
            this.SFurlHint.markAsTouched();
            return;
        }
        if (this.auto_fill && this.xlsErrors.length != 0) {
            return;
        }
        if (!this.isPasscode) {
            this.passcode = '';
        }
        else {
            if (this.passcodefc.valid) {
                this.passcode = this.passcodefc.value;
            }
            else {
                return;
            }
        }
        var obj = {
            name: this.SFname.value,
            banner_color_code: this.bannerColor,
            title_color: this.titleColor,
            desc_background_color: this.desBgColor,
            desc_text_color: this.descTextColor,
            tags: this.selectedTags,
            status: this.SFstatus,
            online_payment: this.onlinePay,
            net30: this.net30Pay,
            slug: this.SFurlHint.value,
            user: this.cust_id,
            passcode: this.passcode,
            description: this.description,
            free_shipping_amount: this.free_shipping_amount,
            auto_fill: this.auto_fill,
            auto_fill_data: this.xlsData,
            show_customer_name: this.show_customer_name,
            archive_message: this.archive_message,
            show_banner_images: this.show_banner_images,
            version: environment.version
        };
        if (this.dataservice.draftStorefront) {
            obj['draft_id'] = this.dataservice.draftStorefront.id;
        }
        console.log(obj);
        // return
        this.dataservice.createSf_v2(obj, create).subscribe(function (response) {
            console.log(response);
            var obj = response;
            obj['storefront_product'] = 0;
            obj['storefront_product_total'] = 0;
            console.log(obj);
            var storeobj = obj;
            _this.uploadBannerImages(storeobj.id);
            _this.store_obj = storeobj;
            _this.dataservice.appendStoreObjInCustomer(storeobj);
            _this.openDialog();
        }, function (error) { }, function () { });
    };
    AddstorefrontComponent.prototype.saveAsDraftStorefront = function (draft) {
        var _this = this;
        if (this.SFname.invalid || this.SFurlHint.invalid) {
            this.SFname.markAsTouched();
            this.SFurlHint.markAsTouched();
            return;
        }
        if (this.auto_fill && this.xlsErrors.length != 0) {
            return;
        }
        if (!this.isPasscode) {
            this.passcode = '';
        }
        else {
            if (this.passcodefc.valid) {
                this.passcode = this.passcodefc.value;
            }
            else {
                return;
            }
        }
        var obj = {
            name: this.SFname.value,
            banner_color_code: this.bannerColor,
            title_color: this.titleColor,
            desc_background_color: this.desBgColor,
            desc_text_color: this.descTextColor,
            status: this.SFstatus,
            online_payment: this.onlinePay,
            net30: this.net30Pay,
            slug: this.SFurlHint.value,
            user: this.cust_id,
            passcode: this.passcode,
            description: this.description,
            free_shipping_amount: this.free_shipping_amount,
            auto_fill: this.auto_fill,
            auto_fill_data: this.xlsData,
            show_customer_name: this.show_customer_name,
            archive_message: this.archive_message,
            show_banner_images: this.show_banner_images
        };
        console.log(obj);
        this.dataservice.createSf_v2(obj, draft).subscribe(function (response) {
            console.log(response);
            var obj = response;
            var storeobj = obj;
            _this.uploadBannerImages(storeobj.id);
            _this.openActionDialogue('Success', 'Storefront is succesfully saved as a draft!', 'Go to Draft');
        }, function (error) { }, function () { });
    };
    AddstorefrontComponent.prototype.uploadBannerImages = function (sfId) {
        var _this = this;
        if (this.bannerImagesFiles.length > 0) {
            this.bannerImagesFiles.forEach(function (image) {
                _this.dataservice.uploadBannerImages(sfId, image).then(function (res) {
                    console.log(res);
                }).catch(function (err) {
                    console.log(err);
                });
            });
        }
    };
    AddstorefrontComponent.prototype.updateDraft = function () {
        var _this = this;
        var obj = {
            name: this.SFname.value,
            banner_color_code: this.bannerColor,
            title_color: this.titleColor,
            desc_background_color: this.desBgColor,
            desc_text_color: this.descTextColor,
            status: this.SFstatus,
            online_payment: this.onlinePay,
            net30: this.net30Pay,
            slug: this.SFurlHint.value,
            user: this.cust_id,
            passcode: this.passcode,
            description: this.description,
            free_shipping_amount: this.free_shipping_amount,
            auto_fill: this.auto_fill,
            auto_fill_data: this.xlsData,
            show_customer_name: this.show_customer_name,
            archive_message: this.archive_message,
            show_banner_images: this.show_banner_images
        };
        this.dataservice.updateStorefrontDraft(obj, this.dataservice.draftStorefront.id).subscribe(function (res) {
            console.log(res);
            _this.openDialogError('Success', 'Storefront draft is succesfully updated!');
        });
    };
    AddstorefrontComponent.prototype.openDialogError = function (title, content) {
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: title, content: content, type: 'error' }
        });
    };
    AddstorefrontComponent.prototype.openActionDialogue = function (title, content, action) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: { title: title, content: { content: content, action: action }, type: 'action' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("go to draft now");
            _this.route.navigate(['/draft']);
        });
    };
    AddstorefrontComponent.prototype.openDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Success',
                content: "Storefront <b>" + this.SFname.value + "</b> is created.",
                type: 'addproduct'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === 'no') {
                console.log('The dialog was closed', result);
                _this.route.navigateByUrl('/');
            }
            if (result === 'yes') {
                // //this.dataservice.setCustomer(this.returnCustomer);
                _this.dataservice.selected_storefronts.push(_this.store_obj);
                _this.route.navigateByUrl('/addproducts');
            }
        });
    };
    AddstorefrontComponent.prototype.returnRandomString = function (length) {
        this.passcode = Math.random()
            .toString(36)
            .substr(2, length);
        this.passcodefc.setValue(this.passcode);
        console.log(this.passcode);
    };
    return AddstorefrontComponent;
}());
export { AddstorefrontComponent };
