import { OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { m_customers_obj, userstorefront_obj } from '../models';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
var ViewstorefrontComponent = /** @class */ (function () {
    function ViewstorefrontComponent(dataservice, router, route, dialog) {
        var _this = this;
        this.dataservice = dataservice;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.storefrontlist = [];
        this.showForm = false;
        this.router.params.subscribe(function (params) {
            _this.customerId = params['cust_id'];
        });
    }
    ViewstorefrontComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataservice.showHide(true);
        console.log("yo");
        this.customer_obj = this.dataservice.getSelectedCustomer();
        if (this.dataservice.getSelectedCustomer()) {
            this.showForm = true;
            if (this.customer_obj) {
                this.storefrontlist = this.customer_obj.user_storefront;
            }
        }
        else {
            this.router.params.subscribe(function (params) {
                _this.customerId = params['cust_id'];
                _this.getSingleCustomer(params['cust_id']);
            });
        }
    };
    ViewstorefrontComponent.prototype.getSingleCustomer = function (id) {
        var _this = this;
        this.dataservice.getSingleCustomer(id).subscribe(function (data) {
            var result = data.json();
            var selectedCustomer = result['results'][0];
            _this.dataservice.setSelectedCustomer(selectedCustomer);
            _this.customer_obj = _this.dataservice.getSelectedCustomer();
            _this.storefrontlist = _this.customer_obj.user_storefront;
            _this.showForm = true;
        }, function (err) {
            console.log(err);
        });
    };
    ViewstorefrontComponent.prototype.gotoAddStoreFront = function () {
        this.route.navigateByUrl('/addstorefront/' + this.customerId);
    };
    ViewstorefrontComponent.prototype.gotoAddProduct = function (item) {
        this.dataservice.selected_storefronts = [];
        this.dataservice.selected_storefronts.push(item);
        this.route.navigateByUrl('/addproducts/' + this.customerId);
    };
    ViewstorefrontComponent.prototype.prev = function (i, listItem) {
        var _this = this;
        var slug = this.storefrontlist[i].slug;
        console.log(slug);
        if (!slug) {
            alert('No Url Added');
            return;
        }
        // let status = ;
        if (this.customer_obj['status'].toLocaleLowerCase() == 'archive') {
            var dialogRef = this.dialog.open(DialogComponent, {
                data: {
                    title: 'Archived customer',
                    content: "Customer status is archived, press okay to view store",
                    type: 'confirmation'
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    console.log("", result);
                    if (result === 'okay') {
                        slug = slug + "/?is_admin=true";
                        _this.openStoreFront(_this.customer_obj.name_slug, slug);
                    }
                }
            });
        }
        else if (listItem.status.toLocaleLowerCase() == 'archive') {
            var dialogRef = this.dialog.open(DialogComponent, {
                data: {
                    title: 'Archived store',
                    content: "Store is archived, press okay to view store",
                    type: 'confirmation'
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    console.log("", result);
                    if (result === 'okay') {
                        slug = slug + "/?is_admin=true";
                        _this.openStoreFront(_this.customer_obj.name_slug, slug);
                    }
                }
            });
        }
        else {
            this.openStoreFront(this.customer_obj.name_slug, slug);
        }
    };
    ViewstorefrontComponent.prototype.openStoreFront = function (customer, url) {
        window.open(this.dataservice.storeUrl + "/" + customer + "/" + url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=300,left=300,width=1200,height=800');
    };
    // edit storefront
    ViewstorefrontComponent.prototype.edit = function (item) {
        this.dataservice.selected_storefront = item;
        this.route.navigateByUrl('/liststorefronts/editstore/' + this.customerId + '/' + item.id);
    };
    // Get storefront Name
    ViewstorefrontComponent.prototype.toggleStoreFrontStatus = function (item, status, index) {
        this.openArchiveDialog(item, status, index);
    };
    ViewstorefrontComponent.prototype.openArchiveDialog = function (item, status, index) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            data: {
                title: 'Archive',
                content: 'Are you sure, you want to archive store ' + item.name + '?',
                type: 'question'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('The dialog was closed', result);
            if (result) {
                if (result == 'yes') {
                    _this.toggleStoreStatusData(item, status, index);
                }
            }
            // this.animal = result;
        });
    };
    ViewstorefrontComponent.prototype.toggleStoreStatusData = function (item, statuss, index) {
        var _this = this;
        this.dataservice
            .toggleStoreStatus(item.id.toString(), { status: statuss })
            .subscribe(function (data) {
            _this.storefrontlist[index].status = statuss;
            console.log(data);
        }, function (error) {
            console.log(error);
        });
    };
    return ViewstorefrontComponent;
}());
export { ViewstorefrontComponent };
