import { DataService } from "./../data.service";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from "@angular/material";
// @Component({
//   selector: 'app-dialog',
//   templateUrl: './dialog.component.html',
//   styleUrls: ['./dialog.component.css']
// })
// export class DialogComponent implements OnInit {
//   constructor() { }
//   ngOnInit() {
//   }
// }
import { MatDialogRef } from "@angular/material";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
var DialogComponent = /** @class */ (function () {
    function DialogComponent(dataservice, dialogRef, data, datepipe, fb) {
        // console.log(data);
        var _this = this;
        this.dataservice = dataservice;
        this.dialogRef = dialogRef;
        this.data = data;
        this.datepipe = datepipe;
        this.fb = fb;
        this.selectedOwnerCol = new FormControl();
        this.selectedTags = new FormControl();
        this.order_date = new FormControl();
        this.is_archive = new FormControl(false);
        this.dates = ["Last 7 days", "Last 15 days", "Last 30 days"];
        this.quickdates = [
            { id: 0, key: "Last 7 days", value: "", viewvalue: "" },
            { id: 1, key: "Last 15 days", value: "", viewvalue: "" },
            { id: 2, key: "Last 30 days", value: "", viewvalue: "" }
        ];
        this.selectedStoreCol = new FormControl();
        this.selectedOrderStatus = new FormControl();
        this.date = new FormControl();
        this.orderstatuscollection = [
            {
                value: "0",
                viewvalue: "Order Received"
            },
            {
                value: "1",
                viewvalue: "Order Processing"
            },
            {
                value: "2",
                viewvalue: "Order Dispatch"
            },
            {
                value: "3",
                viewvalue: "Order Delivered"
            }
        ];
        this.customercollection = [];
        this.selectedstorefronts = [];
        this.orderId = '';
        this.searchparams = {
            puchaser_name: "",
            storefront_collection: [],
            customer_collection: [],
            order_status: [],
            custom_date: "",
            order_date: "",
            is_archive: "",
            order_id: "",
            tags: []
        };
        this.reorderData = [];
        this.allColorChecked = false;
        this.allSizeChecked = false;
        this.allTagsChecked = false;
        this.selectedColors = [];
        this.selectedSizes = [];
        this.selectedStyleTags = [];
        this.mainInvArray = [];
        this.uniqueColorArray = [];
        this.uniqueTagsArray = [];
        this.uniqueSizeArray = [];
        //add Tags
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.tagCtrl = new FormControl();
        this.tags = [];
        this.allTags = [];
        this.comboForm = this.fb.group({
            id: this.data.content.id,
            storefront: [this.data.content.storefront, Validators.required],
            tag: ['', Validators.required],
            color: ['', Validators.required],
            size: ['', Validators.required],
            quantity: ['', Validators.required],
            price: ['', Validators.required],
            files: [[], Validators.required],
        });
        this.ImageArray = [];
        this.isLoading = false;
        this.ProductInventory = [];
        if (data.type === "selectstorefront") {
            this.getStoreFrontData(data.content);
        }
        if (data.type === "selectcustomer") {
            this.getStoreFrontData(data.content);
        }
        if (data.type === "filter") {
            this.getStoreFrontData(data.content);
            this.getTags();
        }
        if (data.type === "tags") {
            this.getTags();
        }
        if (data.type === "reorder") {
            this.reorderData = data.content;
            console.log(this.reorderData);
        }
        if (data.type === "colorAndsizeOptions") {
            console.log(data);
            var content = data.content;
            this.uniqueColorArray = content.ca;
            this.uniqueSizeArray = content.sa;
            this.uniqueTagsArray = content.ta;
            return;
            this.uniqueColorArray = _.uniqBy(data.content, 'color');
            this.uniqueColorArray.forEach(function (color) {
                var arr = [];
                data.content.forEach(function (element) {
                    if (element.color == color.color) {
                        console.log(element.size);
                        arr.push({ size: element.size, isChecked: false });
                    }
                });
                var inv = { color: color.color, isChecked: false, size: arr };
                // console.log(inv);
                _this.mainInvArray.push(inv);
            });
            console.log(this.mainInvArray);
        }
        if (data.type === "showInventory") {
            console.log(data.content);
            this.getSingleInventory(data.content);
        }
        if (data.type === "copystorefront") {
            console.log(data.content);
            this.getCustomerList();
        }
        if (data.type === 'addcombination') {
            this.comboForm.get('tag').valueChanges.subscribe(function (value) {
                console.log('Selected season:', value);
                // Perform any other actions based on the selected season
            });
            this.comboForm.get('size').valueChanges.subscribe(function (value) {
                console.log('Selected season:', value);
                // Perform any other actions based on the selected season
            });
        }
    }
    DialogComponent.prototype.selectColor = function (index, isChecked) {
        this.selectedColors[index] = isChecked;
        this.allColorChecked = this.selectedColors.every(function (color) { return color; });
    };
    DialogComponent.prototype.selectAllColors = function () {
        for (var i = 0; i < this.uniqueColorArray.length; i++) {
            this.selectedColors[i] = this.allColorChecked;
        }
    };
    DialogComponent.prototype.selectAllSizes = function () {
        for (var i = 0; i < this.uniqueSizeArray.length; i++) {
            this.selectedSizes[i] = this.allSizeChecked;
        }
    };
    DialogComponent.prototype.selectAllTags = function () {
        for (var _i = 0, _a = this.uniqueTagsArray; _i < _a.length; _i++) {
            var tag = _a[_i];
            tag.isChecked = this.allTagsChecked;
        }
    };
    // yag
    DialogComponent.prototype.selectTag = function (index, isChecked) {
        this.uniqueTagsArray[index].isChecked = isChecked;
        this.allTagsChecked = this.uniqueTagsArray.every(function (tag) { return tag.isChecked; });
    };
    // size
    DialogComponent.prototype.selectSize = function (index, isChecked) {
        this.selectedSizes[index] = isChecked;
        this.allSizeChecked = this.selectedSizes.every(function (size) { return size; });
    };
    DialogComponent.prototype.getSelectedItems = function () {
        var _this = this;
        var selectedTags = this.uniqueTagsArray.filter(function (tag) { return tag.isChecked; }).map(function (tag) { return tag.name; });
        var selectedColors = this.uniqueColorArray.filter(function (_, index) { return _this.selectedColors[index]; });
        var selectedSizes = this.uniqueSizeArray.filter(function (_, index) { return _this.selectedSizes[index]; });
        console.log("Selected tags:", selectedTags);
        console.log("Selected colors:", selectedColors);
        console.log("Selected sizes:", selectedSizes);
        var obj = { tags: selectedTags, colors: selectedColors, sizes: selectedSizes };
        return obj;
    };
    DialogComponent.prototype.confirmClick = function () {
        console.log("hii");
        if (!this.dataservice.isAtLeastOneChecked(this.uniqueTagsArray, 'isChecked') &&
            !this.dataservice.isAtLeastOneChecked(this.selectedColors, '') &&
            !this.dataservice.isAtLeastOneChecked(this.selectedSizes, '')) {
            alert("Please select at least one tag, color, and size.");
            return;
        }
        // this.mainInvArray = _.filter(this.mainInvArray, function (o) { return o.isChecked; })
        // this.mainInvArray.forEach((color) => {
        //   color.size.forEach(element => {
        //     if (element.isChecked) {
        //       var inv = { color: color.color, size: element.size }
        //       arr.push(inv)
        //     }
        //   });
        // })
        this.dialogRef.close(this.getSelectedItems());
        console.log(this.getSelectedItems());
    };
    DialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    DialogComponent.prototype.getTags = function () {
        var _this = this;
        this.dataservice.getCommon("api/v1/tagslist/").subscribe(function (data) {
            _this.allTags = data.json().results;
        }, function (error) { });
    };
    DialogComponent.prototype.calculateTotal = function (data) {
        var total = 0;
        data.forEach(function (element) {
            total = element.sub_total + total;
        });
        return total;
    };
    DialogComponent.prototype.getCustomerList = function () {
        var _this = this;
        this.dataservice.getAllStoreFronts().subscribe(function (data) {
            var obj = data.json();
            var filCusts = obj["results"];
            _this.customercollection = filCusts;
            console.log(_this.customercollection);
        });
    };
    DialogComponent.prototype.getStoreFrontData = function (datax) {
        var _this = this;
        console.log(datax);
        if (this.dataservice.customer_list.length === 0) {
            this.dataservice.getAllStoreFronts().subscribe(function (data) {
                var obj = data.json();
                _this.customercollection = obj["results"];
                _this.dataservice.customer_list = _this.customercollection;
                // this.consumeSelectedOptions(datax);
                console.log(_this.customercollection);
                if (datax.length != 0) {
                    _this.consumeSelectedOptions(datax);
                }
            });
        }
        else {
            this.customercollection = this.dataservice.customer_list;
            this.consumeSelectedOptions(datax);
        }
    };
    DialogComponent.prototype.consumeSelectedOptions = function (data) {
        var _this = this;
        if (this.data.type == "filter") {
            if (data.length === 0) {
                return;
            }
        }
        this.selectedstorefronts = [];
        this.unCheckEverything();
        console.log(data.length, "hello");
        data.forEach(function (elementx) {
            var index = _this.customercollection.findIndex(function (element) {
                console.log(element.id, elementx.user);
                return element.id === elementx.user;
            });
            console.log(index);
            var userstoreindex = _this.customercollection[index].user_storefront.findIndex(function (element) {
                return element.id === elementx.id;
            });
            _this.customercollection[index].user_storefront[userstoreindex].ischecked = true;
            _this.selectedstorefronts.push(_this.customercollection[index].user_storefront[userstoreindex]);
        });
    };
    DialogComponent.prototype.unCheckEverything = function () {
        this.customercollection.forEach(function (element) {
            element.ischecked = false;
            element.user_storefront.forEach(function (element) {
                element.ischecked = false;
            });
        });
    };
    DialogComponent.prototype.updateStoreFronts = function () {
        console.log(this.customercollection);
        this.getSelectedStoreFronts();
    };
    DialogComponent.prototype.selectCustomerStoreFront = function (id) {
        var pos = this.customercollection.findIndex(function (element) {
            return element.id == id;
        });
        for (var index = 0; index < this.customercollection[pos].user_storefront.length; index++) {
            this.customercollection[pos].user_storefront[index].ischecked = this.customercollection[pos].ischecked;
        }
        this.getSelectedStoreFronts();
    };
    DialogComponent.prototype.getSelectedStoreFronts = function () {
        this.selectedstorefronts = [];
        this.selectedstorefronts.push(this.selectedStoreFront);
        return;
        this.customercollection.forEach(function (element) {
            element.user_storefront.forEach(function (obj) {
                console.log();
                // if (obj.ischecked) {
                //   this.selectedstorefronts.push(obj);
                // }
            });
        });
        console.log(this.selectedstorefronts);
    };
    DialogComponent.prototype.checkData = function () {
        // console.log(this.selectedOwnerCol.value,this.selectedStoreCol.value,this.selectedOrderStatus.value);
        // console.log(this.searchText);
        this.searchparams.puchaser_name = this.searchText;
        this.searchparams.order_id = this.orderId;
        if (this.selectedOwnerCol.value !== null) {
            this.searchparams.customer_collection = this.selectedOwnerCol.value;
        }
        if (this.selectedStoreCol.value !== null) {
            this.searchparams.storefront_collection = this.selectedStoreCol.value;
        }
        if (this.selectedOrderStatus.value !== null) {
            this.searchparams.order_status = this.selectedOrderStatus.value;
        }
        if (this.selectedTags.value !== null) {
            this.searchparams.tags = this.selectedTags.value;
        }
        if (this.date.value) {
            this.searchparams.custom_date = this.datepipe.transform(this.date.value, "dd/MM/yyyy");
        }
        console.log(this.is_archive);
        if (this.is_archive.value) {
            this.searchparams.is_archive = "true";
        }
        else {
            if (this.is_archive.touched) {
                this.searchparams.is_archive = "false";
            }
        }
        if (this.order_date.value) {
            switch (this.order_date.value["id"]) {
                case 0:
                    console.log(this.getDateSub(7));
                    this.quickdate = this.quickdates[0];
                    this.quickdate.value = this.getDateSub(7);
                    this.searchparams.order_date = this.quickdate;
                    break;
                case 1:
                    this.quickdate = this.quickdates[1];
                    this.quickdate.value = this.getDateSub(15);
                    this.searchparams.order_date = this.quickdate;
                    break;
                case 2:
                    this.quickdate = this.quickdates[2];
                    this.quickdate.value = this.getDateSub(30);
                    this.searchparams.order_date = this.quickdate;
                    break;
                default:
                    break;
            }
        }
        console.log(this.searchparams);
    };
    DialogComponent.prototype.getDateSub = function (days) {
        // Days you want to subtract
        var date = new Date();
        var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
        return (this.datepipe.transform(last, "dd/MM/yyyy") +
            "," +
            this.datepipe.transform(date, "dd/MM/yyyy"));
    };
    DialogComponent.prototype.add = function (event) {
        // Add fruit only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            var input = event.input;
            var value = event.value;
            // Add our fruit
            if ((value || "").trim()) {
                this.addTag(value.trim(), input);
            }
            // Reset the input value
        }
    };
    DialogComponent.prototype.remove = function (fruit) {
        var index = this.tags.indexOf(fruit);
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    };
    DialogComponent.prototype.selected = function (event) {
        this.tags.push(event.option.value);
        this.tagCtrl.setValue(null);
        console.log(this.tags);
    };
    DialogComponent.prototype.addTag = function (tag, input) {
        var _this = this;
        this.dataservice.addTag(tag).subscribe(function (data) {
            console.log();
            _this.tags.push(data.json());
            if (input) {
                input.value = "";
            }
            _this.tagCtrl.setValue(null);
        }, function (error) {
            console.log(error);
        });
    };
    DialogComponent.prototype.choseImages = function (evt) {
        var _this = this;
        console.log();
        var _loop_1 = function (file) {
            this_1.data.content.files.push(file);
            var reader = new FileReader();
            reader.onload = function (e) {
                var obj = {
                    name: file.name,
                    url: e.target.result
                };
                _this.ImageArray.push(obj);
            };
            reader.readAsDataURL(file);
        };
        var this_1 = this;
        for (var _i = 0, _a = evt.target.files; _i < _a.length; _i++) {
            var file = _a[_i];
            _loop_1(file);
        }
        this.comboForm.get('files').setValue(this.data.content.files);
    };
    DialogComponent.prototype.removeImage = function (i) {
        this.ImageArray.splice(i, 1);
        this.data.content.files.splice(i, 1);
        this.comboForm.get('files').setValue([]);
    };
    DialogComponent.prototype.SaveCombination = function () {
        var _this = this;
        console.log(this.comboForm.value);
        if (this.comboForm.valid) {
            this.isLoading = true;
            this.dataservice.addInvCombination(this.comboForm.value).then(function (res) {
                _this.dataservice.openSnackBar('Combination added', 'close');
                _this.isLoading = false;
                _this.dialogRef.close(res);
            })
                .catch(function (err) {
                console.log(err);
                _this.dataservice.openSnackBar(err, 'close');
                _this.isLoading = false;
                _this.dialogRef.close();
            });
        }
        else {
            this.dataservice.openSnackBar('Please fill out all the fields', 'close');
        }
    };
    DialogComponent.prototype.getSingleInventory = function (prodId) {
        var _this = this;
        console.log(prodId);
        this.dataservice.getInventory(prodId).then(function (res) {
            console.log(res);
            _this.ProductInventory = res.productinventory_set;
        });
    };
    return DialogComponent;
}());
export { DialogComponent };
