<div class="main">
  <mat-toolbar class="subtitle font-fmly" style="margin-bottom: 10px;">
    <span> {{isInEditMode ? "Edit ":""}}Coupons </span>
  </mat-toolbar>
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChange($event)" #matgroup>
    <mat-tab label="{{tabLabel}}">

      <mat-card class="cards" style="text-align: center;">


        <div class="formflex">

          <div>
            <label style="margin-bottom: 15px;">Coupon Type</label><br>
            <mat-radio-group aria-label="Coupon Type" [(ngModel)]="coupon_type">
              <mat-radio-button value="flat_discount">Flat Discount</mat-radio-button>
              <mat-radio-button style="margin-left: 5px;" value="percentage_discount">Percentage Based
                Discount</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field>
            <mat-label>Name Of Coupon</mat-label>
            <input matInput placeholder="Ex. FLAT15" value="" [formControl]="coupon_name">
            <mat-error *ngIf="coupon_name.hasError('required')">
              Name is <strong>required</strong>
            </mat-error>

            <mat-error *ngIf="coupon_name.hasError('couponInuse')">
              Coupon name is <strong>already in use</strong>
            </mat-error>
          </mat-form-field>


          <mat-form-field>
            <mat-label>Storefronts</mat-label>
            <mat-select [formControl]="selectedStoreCol" multiple>
              <mat-select-trigger>
                {{
                selectedStoreCol.value ? selectedStoreCol.value[0]?.name : ""
                }}
                <span *ngIf="selectedStoreCol.value?.length > 1" class="example-additional-selection">
                  (+{{ selectedStoreCol.value.length - 1 }}
                  {{
                  selectedStoreCol.value?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>

              <mat-optgroup *ngFor="let group of customercollection" [label]="group.name" [disabled]="group.disabled">
                <mat-option *ngFor="let item of group.user_storefront" [value]="item">
                  {{ item.name }}
                </mat-option>
              </mat-optgroup>


            </mat-select>
            <mat-error *ngIf="selectedStoreCol.hasError('required')">
              Storefronts are <strong>required</strong>
            </mat-error>
          </mat-form-field>


          <mat-form-field *ngIf="coupon_type === 'flat_discount'">
            <mat-label>Flat Discount Value </mat-label>
            <input matInput placeholder="Ex. 5$ , 10$" type="number" [formControl]="flatdiscount">
            <span matSuffix>$</span>

            <mat-error *ngIf="flatdiscount.hasError('required')">
              Flat discount value is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="coupon_type === 'percentage_discount'">
            <mat-label>Flat Percentage Value </mat-label>
            <input matInput placeholder="Ex. 5$ , 10$" type="number" [formControl]="percentagediscount">
            <span matSuffix>%</span>

            <mat-error *ngIf="percentagediscount.hasError('required')">
              Percentage discount value is <strong>required</strong>
            </mat-error>
          </mat-form-field>



          <!-- <div>
            <label style="margin-bottom: 15px;">Coupoun Validity Type</label><br>
            <mat-radio-group aria-label="Coupoun Validity Type" [(ngModel)]="validity_type" style="margin-top: 5px;">
              <mat-radio-button value="default">Default</mat-radio-button>
              <mat-radio-button value="numberofusage">Number of Usage</mat-radio-button>
              <mat-radio-button value="expirydate">Expiry Date</mat-radio-button>

            </mat-radio-group>
          </div> -->



          <mat-form-field>
            <mat-label>Number of usage limit </mat-label>
            <input matInput placeholder="Ex. 50, 100" type="number" [formControl]="usagelimit">

            <mat-error *ngIf="usagelimit.hasError('required')">
              Limit value is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Expiry Date</mat-label>
            <input matInput placeholder="Ex. 50, 100" type="date" [formControl]="expirydate">

            <mat-error *ngIf="expirydate.hasError('invalidDate')">
              Expiry date is <strong>invalid</strong>
            </mat-error>

          </mat-form-field>

          <mat-checkbox class="example-margin" [(ngModel)]="is_valid">Is Valid</mat-checkbox>

        </div>


        <button mat-raised-button color="primary" style="margin: auto; margin-top: 10px;"
          (click)="onCreateCoupon()">{{isInEditMode ? "Edit Coupon" : "Create Coupon"}}</button>

        <button *ngIf="isInEditMode" mat-raised-button color="primary"
          style="margin: auto; margin-top: 10px; margin-left: 20px;" (click)="setToCreateCoupon()"> Create New
          Coupon</button>


      </mat-card>




    </mat-tab>
    <mat-tab label="View Coupons"> <mat-card class="cards" style="text-align: center;">
        <mat-toolbar class="subtitle font-fmly" style="margin-bottom: 10px;">
          <span>View Coupons</span>
        </mat-toolbar>

        <table>
          <tr style="pointer-events: none;" class="row-header font-fmly">
            <th><b>No.</b></th>
            <th><b>Name</b></th>
            <th>Type</th>

            <th>Discount Value</th>
            <th>Status</th>
            <th>Edit</th>
            <th>Delete</th>

          </tr>
          <tr *ngFor="let item of coupon_list;let i = index">
            <td>{{ i+1 }}</td>
            <td>{{ item.coupon_name }}</td>
            <td>

              <ng-container *ngIf="item.coupon_type === 'flat_discount'; else elseTemplate">
                Flat Discount $
              </ng-container>
              <ng-template #elseTemplate>
                Percentage Discount %
              </ng-template>

            </td>

            <td>

              <ng-container *ngIf="item.coupon_type === 'flat_discount'; else elseTemplate1">

                {{item.discount_value}} $
              </ng-container>
              <ng-template #elseTemplate1>

                {{item.percent_discount_value}} %

              </ng-template>


            </td>

            <td>

              <ng-container *ngIf="item.coupon_valid; else elseTemplate2">
                <button mat-raised-button="success" style="background: green;
              color: white !important;" (click)="openCouponStatusChangeDialog(item)">Active</button>

              </ng-container>
              <ng-template #elseTemplate2>
                <button mat-raised-button color="warn" (click)="openCouponStatusChangeDialog(item)">Deactive</button>


              </ng-template>


            </td>

            <td>
              <button mat-raised-button color="primary"
                (click)="matgroup.selectedIndex = 0;editCoupon(item);">Edit</button>


            </td>
            <td>
              <button style="color: white !important;" mat-raised-button color="warn"
                (click)="openCouponDeleteDialog(item);">Delete</button>


            </td>
          </tr>
        </table>
      </mat-card>
    </mat-tab>
    <mat-tab label="Styles">

      <mat-form-field class="addtagfield">
        <mat-label>Add Style</mat-label>
        <input matInput placeholder="New Style" [(ngModel)]="newStyle">
      </mat-form-field>

      <button (click)="addTag()" mat-raised-button color="primary" class="btn-save">Add Style</button>
      <table>
        <tr style="pointer-events: none;" class="row-header font-fmly">
          <th>
            No
          </th>
          <th>
            Name
          </th>
          <th>
            Remove Style
          </th>
        </tr>

        <tr *ngFor="let item of tags;let i = index">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>
          <button (click)="removeStyle(item.id)" mat-fab color="warn">
            <mat-icon  class="warnbutton">delete</mat-icon>
          </button>

          </td>
        </tr>
      </table>
    </mat-tab>
    <mat-tab label="Tags">

      <mat-form-field class="addtagfield">
        <mat-label>Add Tags</mat-label>
        <input matInput placeholder="New Style" [(ngModel)]="newSpecialTag">
      </mat-form-field>

      <button (click)="addspecialTag()" mat-raised-button color="primary" class="btn-save">Add Tag</button>
      <table>
        <tr style="pointer-events: none;" class="row-header font-fmly">
          <th>
            No
          </th>
          <th>
            Name
          </th>
          <th>
            Remove Tag
          </th>
        </tr>

        <tr *ngFor="let item of specialTags;let i = index">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>
          <button (click)="removeSpecialTag(item.id)" mat-fab color="warn">
            <mat-icon  class="warnbutton">delete</mat-icon>
          </button>

          </td>
        </tr>
      </table>
    </mat-tab>
    <mat-tab label="Bundle Category">

      <mat-form-field class="addtagfield">
        <mat-label>Add Category</mat-label>
        <input matInput placeholder="New Category" [(ngModel)]="bundleCategory">
      </mat-form-field>

      <button (click)="addCategory()" mat-raised-button color="primary" class="btn-save">Add Category</button>
      <table>
        <tr style="pointer-events: none;" class="row-header font-fmly">
          <th>
            No
          </th>
          <th>
            Name
          </th>
          <th>
            Remove Category
          </th>
        </tr>

        <tr *ngFor="let item of bundleCategories;let i = index">
          <td>{{i + 1}}</td>
          <td>{{item.name}}</td>
          <td>
          <button (click)="removeBundleCategory(item.id)" mat-fab color="warn">
            <mat-icon  class="warnbutton">delete</mat-icon>
          </button>

          </td>
        </tr>
      </table>
    </mat-tab>
  </mat-tab-group>

</div>