<div class="main">
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <mat-toolbar>
    <h1 class="page-heading">Orders</h1>
    <span class="example-fill-remaining-space"></span>
    <ng-container *ngIf="!checksbox">
      <button class="btnss" mat-raised-button [disabled]="order_collection.length === 0" (click)="selectionMode(0)">
        <img src="https://img.icons8.com/metro/50/333333/ms-excel.png" height="20" />&nbsp; Export to Excel
      </button>
      <button class="btnss" mat-raised-button [disabled]="order_collection.length === 0" (click)="selectionMode(4)">
        <img src="https://img.icons8.com/metro/50/333333/ms-excel.png" height="20" />&nbsp; Export Stamps Data To Excel
      </button>
      <button class="btnss" mat-raised-button [disabled]="order_collection.length === 0" (click)="selectionMode(1)">
        <i class="material-icons">all_inbox</i>&nbsp; Bulk update
      </button>
      <button class="btnss" mat-raised-button [disabled]="order_collection.length === 0" (click)="selectionMode(2)">
        <i class="material-icons">add_comment</i>&nbsp; Add Tag
      </button>
      <button class="btnss" mat-raised-button [disabled]="order_collection.length === 0" (click)="selectionMode(3)">
        <i class="material-icons">archive</i>&nbsp; Archive
      </button>
      <mat-icon class="example-icon" (click)="openFilters()">filter_list</mat-icon>
    </ng-container>
    <ng-container *ngIf="checksbox">
      <span class="" style="margin-right: 10px;">{{ selectedMode }}</span>
      <button class="doneCancel" mat-raised-button [disabled]="!isSelected" (click)="doEdit()">
        Done
      </button>
      <button class="" mat-raised-button (click)="cancelEdit()">Cancel</button>
    </ng-container>
  </mat-toolbar>

  <div class="chips">
    <div class="chips" *ngIf="matlist.length > 0">
      <mat-chip-list #chipList class="">
        <mat-chip class="gap" *ngFor="let item of matlist; let i = index" (removed)="remove(i)">
          {{ item.param_view_value }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>



  <div class="col-md-60 tables mess">
    <table class="table-list table-striped sticky-header" matSort (matSortChange)="sortData($event)">
      <thead>
        <tr class="row-header">

          <th *ngIf="checksbox">
            <mat-checkbox [(ngModel)]="allchecked" (click)="selectAll()"></mat-checkbox>
          </th>
          <th mat-sort-header="id" class="margin-r mat-sort-header-content">
            ID
          </th>
          <th mat-sort-header="customer_name" class="margin-r">
            Customer Name
          </th>
          <th mat-sort-header="storefront" class="margin-r">Storefront</th>
          <th class="margin-r">Product Name</th>
          <th mat-sort-header="created_v1" class="margin-r">Order Date</th>

          <th mat-sort-header="payment_mode" class="margin-r">Payment Mode</th>
          <th mat-sort-header="total_amount" class="margin-r">Total Amount</th>
          <th mat-sort-header="shipping_instructions" class="margin-r">Shipping instructions</th>

          <th mat-sort-header="status" class="margin-r">Status</th>

          <th class="margin-r">Tags</th>
        </tr>
      </thead>
      <tr *ngFor="let orders of order_collection; let i = index">
        <td class="text-left" *ngIf="checksbox" style="padding: 5px;">
          <mat-checkbox [(ngModel)]="orders.is_checked" (change)="getIds()"></mat-checkbox>
        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{ orders.id }}
        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{ orders.purchase_user.name }}
        </td>

        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{orders.storefront_owner}} - {{orders.storefront }}
        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          <div class="" *ngFor="let name of orders.purchase_set">
            {{ name.title }}
          </div>
        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{ orders.created_v1 }}
        </td>

        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          <div class="" *ngIf="!orders.payment_status && orders.total_amount !== 0">Net30</div>
          <div class="" *ngIf="orders.payment_status">Credit Card</div>
          <div class="" *ngIf="orders.total_amount === 0">NA</div>

        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          ${{ orders.total_amount / 100 }}
        </td>

        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{ orders.purchase_user.shipping_instructions }}
        </td>
        <td (click)="getdetails(i)" data-toggle="modal" data-target="#myModal">
          {{ orders.order_tracking }}
        </td>
        <td style="width: 325px;">
          <!-- <mat-chip-list #chipList>
                        <mat-chip *ngFor="let tags of orders.tags" selected>
                            {{tags.name}}
                        </mat-chip>
                    </mat-chip-list> -->
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let tags of orders.tags" (removed)="removeTag(tags, orders)">
              {{ tags.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </td>
      </tr>
    </table>

    <div style="display: flex; justify-content: center">
      <ul class="pagination">
        <ng-container *ngFor="let item of multicollectionarry; let i = index">
          <li>
            <a class="paginates font-fmly" [ngClass]="{ highlight: indexs === i }" (click)="paginate(i)">{{ i + 1 }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- <div class="col-sm-60 text-center paginat">
        <app-paginator [url]="url" (data)="onData($event)"></app-paginator>
    </div> -->

  <!--  -->
  <!-- The Modal -->
  <div class="modal modal-report" id="myModal" data-backdrop="static" *ngIf="cust_details">
    <div class="modal-dialog modal-dialog-centered" style="width: 800px;height: 700px;">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="onTabchange($event)">
            <mat-tab label="Order Details">
              <div class="modal-body" id="contentToConvert">
                <div class="">
                  <div class="col-sm-30 font-fmly" style="padding-left: 0px;">
                    <h6 class="modal-title">Order ID: {{ cust_details.id }}</h6>
                    <h6 class="modal-title">
                      Order Date: {{ cust_details.created | date }}
                    </h6>

                    <h6 class="modal-title">
                      Storefront: {{cust_details.storefront_owner}} - {{ cust_details.storefront }}
                    </h6>
                  </div>
                  <div class="col-sm-30 text-right">
                    <img src="assets/images/logo.png" height="50" />
                  </div>
                </div>

                <div class="tabs">
                  <!-- bundle table  -->
                  <table class="table" *ngIf="cust_details.bundles.length > 0" style="background-color: #f2f2f2;
                    border-radius: 14px;
                    padding: 10px;
                    margin: 8px 0;">
                    <thead class="thead-light">
                      <tr class="font-fmly">
                        <th>Bundle Name</th>
                        <th>Bundle Code</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let bundle of cust_details.bundles">
                        <tr>
                          <td>{{ bundle.title }}</td>
                          <td>{{ bundle.code }}</td>
                          <td>${{ bundle.price }}</td>
                        </tr>

                        <ng-container *ngFor="let inv of transformData(bundle.products)">
                          <tr>
                            <h4 style="background-color: lightgray;
                            padding: 8px 18px;">
                              {{inv.name}}
                            </h4>
                            <table class="table">
                              <thead class="thead-light">
                                <tr class="font-fmly">
                                  <th>Product Name</th>
                                  <th>Product Code</th>
                                  <th>Style</th>
                                  <th>Color</th>
                                  <th>Quantity</th>
                                  <th>Size</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let prod of inv.products">
                                <tr>
                                  <td>{{prod.title}}</td>
                                  <td>{{prod.code}}</td>
                                  <td>{{ prod.tag }}</td>
                                  <td>{{ prod.color }}</td>
                                  <td>{{ prod.quantity }}</td>
                                  <td>{{ prod.size }}</td>
                                </tr>
                                </ng-container>
  
  
                              </tbody>
                            </table>
                          </tr>
                          
                        </ng-container>

                      </ng-container>
                    </tbody>
                  </table>

                  <!-- <table class="table" *ngIf="cust_details.bundles.length > 0">
                    <thead class="thead-light">
                      <tr>
                        <th>Bundle Name</th>
                        <th>Code</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let bundle of cust_details.bundles" class="trcolor">
                        <td>{{ bundle.title }}</td>
                        <td>{{ bundle.code }}</td>
                        <td>
                          ${{ bundle.price }}
                        </td>
                      </tr>
                      
                    </tbody>
                  </table> -->
                  <table class="table">
                    <thead class="thead-light">
                      <tr class="font-fmly">
                        <th>Product Name</th>
                        <th>Code</th>
                        <th>Style</th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>Size</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let prods of cust_details.products" class="trcolor">
                        <td>{{ prods.title }}</td>
                        <td>{{ prods.code }}</td>
                        <td>{{ prods.tag }}</td>
                        <td>{{ prods.color }}</td>
                        <td>{{ prods.quantity }}</td>
                        <td>{{ prods.size }}</td>
                        <td>
                          ${{ prods.unit_price }}
                          <span *ngIf="prods.quantity > 1">X {{ prods.quantity }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="subtotal">Tax Amount</td>
                        <td class="subtotal">${{ cust_details.sub_total }}</td>

                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="subtotal">Service Fee</td>
                        <td class="subtotal">${{ cust_details.shipping_cost }}</td>
                      </tr>

                      <tr *ngIf="cust_details.is_coupon_applied">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="totalam">Coupon Applied</td>
                        <td class="totalam">
                          <b> {{ cust_details.coupon_data.coupon_name }} - {{cust_details.coupon_data.coupon_type ===
                            'flat_discount' ? cust_details.coupon_data.discount_value :
                            cust_details.coupon_data.percent_discount_value}}{{cust_details.coupon_data.coupon_type ===
                            'flat_discount' ? '$' : '%'}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td> </td>
                        <td class="totalam">Total Amount:</td>
                        <td class="totalam">
                          ${{ cust_details.total_amount / 100 }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div class="row">
                    <div class="col-sm-30">
                      <div class="">
                        <p style="font-size: 22px;
                        font-weight: 500;" class="inlins">Customers Name:</p>
                        <span class="custname">
                          {{ cust_details.purchase_user.first_name }}
                          {{ cust_details.purchase_user.last_name }}</span>
                      </div>
                      <div class="" *ngIf="parentname">
                        <p class="inlins">Parent's Name:</p>
                        <span class="custname">
                          {{ cust_details.purchase_user.parent_first_name }}
                          {{
                          cust_details.purchase_user.parent_last_name
                          }}</span>
                      </div>
                      <div class="" *ngIf="class_name">
                        <p class="inlins">Class Name:</p>
                        <span class="custname">
                          {{ cust_details.purchase_user.class_name }}</span>
                      </div>
                      <div class="" *ngIf="purchase_order">
                        <p class="inlins">Purchase Order:</p>
                        <span class="custname">
                          {{ cust_details.purchase_user.purchase_order }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user.company_name">
                        <p class="addr">Company Name:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.company_name }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user.po_number">
                        <p class="addr">PO Number:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.po_number }}</span>
                      </div>
                      <div class="">
                        <p class="addr">Email id:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.email }}</span>
                      </div>
                      <div class="">
                        <p class="addr">Contact No:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.phone_number }}</span>
                      </div>
                      <br />

                      <div class="" *ngIf="cust_details.purchase_user?.class_name">
                        <p class="addr">Class Name:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.class_name }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user?.club_name">
                        <p class="addr">Club Name:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.club_name }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user?.team_name">
                        <p class="addr">Team Name:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.team_name }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user?.player_first_name">
                        <p class="addr">Player Name:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.player_first_name }}
                          {{cust_details.purchase_user.player_last_name}}</span>
                      </div>
                    </div>
                    <div class="col-sm-30">

                      <div class="customer">
                        <p class="text-left" style="font-size: 18px;"
                          *ngIf="cust_details.purchase_user.is_shipping_address ">
                          Shipping Adddress: {{cust_details.is_international_shipping ? "International" : "Local"}}
                        </p>

                        <p class="text-left" style="font-size: 18px;"
                          *ngIf="!cust_details.purchase_user.is_shipping_address ">
                          Shipping Adddress & Billing Address: {{cust_details.is_international_shipping ?
                          "International" : "Local"}}
                        </p>

                        <div class="" *ngIf="cust_details.purchase_user.property_name">
                          <p class="addr">Property Name:</p>
                          <span class="grays">{{
                            cust_details.purchase_user.property_name
                            }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Address:</p>
                          <span class="grays">{{
                            cust_details.purchase_user.address
                            }}</span>
                        </div>
                        <div class="">
                          <p class="addr">City:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_city }}</span>
                        </div>
                        <div class="">
                          <p class="addr">State:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_state }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Country:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_country }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Postal Code:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.postal_code }}</span>
                        </div>

                      </div>
                      <br>
                      <div class="customer" *ngIf="cust_details.purchase_user.is_shipping_address ">
                        <p class="text-left" style="font-size: 18px;">
                          Billing Adddress: {{cust_details.is_international_shipping ? "International" : "Local"}}

                        </p>
                        <div class="">
                          <p class="addr">Address:</p>
                          <span class="grays">{{
                            cust_details.purchase_user.address_s
                            }}</span>
                        </div>
                        <div class="">
                          <p class="addr">City:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_city_s }}</span>
                        </div>
                        <div class="">
                          <p class="addr">State:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_state_s }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Country:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.address_country }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Postal Code:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.postal_code_s }}</span>
                        </div>

                        <div class="">
                          <p class="addr">Contact No:</p>
                          <span class="grays">
                            {{ cust_details.purchase_user.phone_number_s }}</span>
                        </div>

                      </div>

                      <div class="" *ngIf="cust_details.purchase_user.shipping_instructions">
                        <p class="addr">Shipping Instructions:</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.shipping_instructions }}</span>
                      </div>
                      <div class="" *ngIf="cust_details.purchase_user.post_order">
                        <p class="addr">PO#</p>
                        <span class="grays">
                          {{ cust_details.purchase_user.shipping_instructions }}</span>
                      </div>


                      <div *ngIf="cust_details.stripe_charge_id">
                        <div class="">
                          <p class="addr">Stripe Charge_id:</p>
                          <span class="grays">
                            {{ cust_details.stripe_charge_id }}</span>
                        </div>
                        <div class="">
                          <p class="addr">Stripe Receipt:</p>
                          <span class="grays">
                            <a href="{{cust_details.stripe_receipt_url}}" target="_blank">view receipt</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Order Status">
              <div class="modal-body">
                <div class="row">
                  <section>
                    <div class="wizard">
                      <div class="wizard-inner">
                        <div class="connecting-line"></div>
                        <ul class="nav nav-tabs" role="tablist">
                          <li role="presentation" class="">
                            <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" title="Step 1">
                              <span class="round-tab">
                                <i class="glyphicon glyphicon-shopping-cart" style="color:#5bc0de;"></i>
                              </span>
                            </a>
                          </li>

                          <li role="presentation" class="disabled">
                            <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab" title="Step 2">
                              <span class="round-tab">
                                <img src="https://img.icons8.com/metro/26/5bc0de/process.png" style="margin-top: -5px;"
                                  *ngIf="order_status >= 1" />
                              </span>
                            </a>
                          </li>
                          <li role="presentation" class="disabled">
                            <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab" title="Step 3">
                              <span class="round-tab">
                                <!-- <i class="glyphicon glyphicon-picture"></i> -->
                                <img src="https://img.icons8.com/windows/48/5bc0de/in-transit.png"
                                  style="margin-top: -5px;" *ngIf="order_status >= 2" />
                              </span>
                            </a>
                          </li>

                          <li role="presentation" class="disabled">
                            <a href="#complete" data-toggle="tab" aria-controls="complete" role="tab" title="Complete">
                              <span class="round-tab">
                                <!-- <i class="glyphicon glyphicon-ok"></i> -->
                                <img src="https://img.icons8.com/ios/48/5bc0de/shipped.png" style="margin-top: -5px;"
                                  *ngIf="order_status == 3" />
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
                <br />
                <br />
                <!--  -->

                <div class="form-group">
                  <label for="sel1">Select Status</label>
                  <select (change)="changeOrderStatus($event)" class="form-control" id="sel1"
                    [(ngModel)]="order_status">
                    <option value="0">Order Received</option>
                    <option value="1">Order Processing</option>
                    <option value="2">Order Dispatch</option>
                    <option value="3">Order Delivery</option>
                  </select>
                </div>

                <div [ngSwitch]="order_status">
                  <!--  -->
                  <div *ngSwitchCase="0">
                    <h4 class="modal-title">Order Id: {{ cust_details.id }}</h4>
                    <h4 class="modal-title">
                      Storefront: {{cust_details.storefront_owner}} - {{cust_details.storefront }}
                    </h4>
                    <h4 class="modal-title">
                      Order Date: {{ cust_details.created | date }}
                    </h4>



                    <!-- product table  -->
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th>Product Name</th>
                          <th>Code</th>
                          <th>Color</th>
                          <th>Quantity</th>
                          <th>Size</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let prods of cust_details.purchase_set" class="trcolor">
                          <td>{{ prods.title }}</td>
                          <td>{{ prods.code }}</td>
                          <td>{{ prods.color }}</td>
                          <td>{{ prods.quantity }}</td>
                          <td>{{ prods.size }}</td>
                          <td>
                            ${{ prods.unit_price }}
                            <span *ngIf="prods.quantity > 1">X {{ prods.quantity }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="subtotal">Tax Amount</td>
                          <td class="subtotal">
                            ${{ cust_details.sub_total }}
                          </td>
                        </tr>

                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="subtotal">Service Fee</td>
                          <td class="subtotal">${{ cust_details.shipping_cost }}</td>

                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="totalam">Total Amount:</td>
                          <td class="totalam">
                            ${{ cust_details.total_amount / 100 }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />

                  <div class="Orderprocessing" *ngSwitchCase="1">
                    <!-- <label>
                      Select Estimated Delivery Date &nbsp;
                      <input type="date" value="" [ngModel]="estimatedD | date:'yyyy-MM-dd'" (ngModelChange)="estimatedD = $event"  />
                    </label> -->
                    <br />
                    <mat-checkbox matTooltip="Info about the action" [matTooltipPosition]="'above'"
                      (change)="notes($event)" (change)="inform($event)" [(ngModel)]="informCust">
                      Inform Customer
                    </mat-checkbox>
                  </div>
                  <!--  -->
                  <div class="OrderDisp" *ngSwitchCase="2">
                    <div class="form-group">
                      <label for="sel1">Courier Type</label>
                      <select class="form-control" (change)="courierType($event)" [(ngModel)]="ctype">
                        <option value="ups">UPS</option>
                        <option value="delivery">Delivery</option>
                        <option value="fedex">Fed-Ex</option>
                        <option value="usps">USPS</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <mat-form-field style="margin-right: 4px;" class="example-full-width" *ngIf="ctype === 'other'">
                      <input matInput placeholder="Other Service" [(ngModel)]="otherService"
                        (ngModelChange)="otherCourierChange($event)" />
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Tracking Id" value="" [(ngModel)]="trackid" />
                    </mat-form-field>
                    <br />
                    <label>
                      Select Expected Delivery Date &nbsp;
                      <input type="date" value="" [(ngModel)]="exptedD" />
                    </label>
                    <br />
                    <mat-form-field class="example-full-width">
                      <textarea matInput placeholder="Delivery Notes" [(ngModel)]="delievryNotes"></textarea>
                    </mat-form-field>
                    <mat-checkbox matTooltip="Info about the action" [matTooltipPosition]="'above'"
                      (change)="notes($event)" (change)="inform($event)" [(ngModel)]="informCust">
                      Inform Customer
                    </mat-checkbox>
                  </div>
                  <!--  -->
                  <div class="delivery" *ngSwitchCase="3">
                    <mat-checkbox [(ngModel)]="informCust">Inform Customer</mat-checkbox>
                  </div>
                  <br />
                  <div class="" *ngIf="informCust">
                    <p class="" style="font-weight: 300;">
                      Following customer <b>{{ customername }}</b> will receive
                      email at <b>{{ Cemail }}</b>, regarding order status being {{currentOrderStatus}} and estimated
                      time
                      of delivery.
                    </p>
                  </div>
                </div>

                <!--  -->
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- Modal body -->

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" *ngIf="selectedTab == 0" (click)="copyUrlToClipboard()">
            Copy to Clipboard
          </button>
          <button type="button" class="btn btn-primary" *ngIf="selectedTab == 0" (click)="downloadPdf()">
            Generate Receipt
          </button>

          <button type="button" class="btn btn-primary" *ngIf="selectedTab == 0" (click)="sendemail()">
            Send Email
          </button>
          <button type="button" class="btn btn-primary" *ngIf="selectedTab == 1" (click)="saveState()">
            Save State
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
</div>