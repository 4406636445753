import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { m_customers_obj, customer_obj } from '../models';
import { Location } from '@angular/common';
import { ENTER, COMMA, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {

  constructor(private route: ActivatedRoute, private dataservice: DataService, private _location: Location, public dialog: MatDialog,private router: Router,) { }

  cust_id: any;
  Custlogo: any;
  array1: any;
  customerDetails: any;


  customer_obj: m_customers_obj;
  showForm = false;

  teams = [];


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, TAB];
  allEmailId: string[] = [];

  selectedStorefront = "";






  types = [
    { value: 'school', viewValue: 'Schools' },
    { value: 'business', viewValue: 'Business' },
    { value: 'corporate', viewValue: 'Corporate' },
    { value: 'restaurant', viewValue: 'Restaurant' },
    { value: 'sports', viewValue: 'Sports' },
    // { value: 'volleyball', viewValue: 'Volleyball' },
    { value: 'other', viewValue: 'Other' }
  ];











  states = [
    { value: 'AL', viewValue: ' AL' },
    { value: 'AK', viewValue: 'AK' },
    { value: 'AZ', viewValue: 'AZ' },
    { value: 'AR', viewValue: 'AR' },
    { value: 'CA', viewValue: 'CA' },
    { value: 'CO', viewValue: 'CO' },
    { value: 'CT', viewValue: 'CT' },
    { value: 'DE', viewValue: 'DE' },
    { value: 'FL', viewValue: 'FL' },
    { value: 'GA', viewValue: 'GA' },
    { value: 'HI', viewValue: 'HI' },
    { value: 'ID', viewValue: 'ID' },
    { value: 'IL', viewValue: 'IL' },
    { value: 'IN', viewValue: 'IN' },
    { value: 'IA', viewValue: 'IA' },
    { value: 'KY', viewValue: 'KY' },
    { value: 'LA', viewValue: 'LA' },
    { value: 'ME', viewValue: 'ME' },
    { value: 'MD', viewValue: 'MD' },
    { value: 'MA', viewValue: 'MA' },
    { value: 'MI', viewValue: 'MI' },
    { value: 'MN', viewValue: 'MN' },
    { value: 'MS', viewValue: 'MS' },
    { value: 'MO', viewValue: 'MO' },
    { value: 'MT', viewValue: 'MT' },
    { value: 'NE', viewValue: 'NE' },
    { value: 'NV', viewValue: 'NV' },
    { value: 'NH', viewValue: 'NH' },
    { value: 'NJ', viewValue: 'NJ' },
    { value: 'NM', viewValue: 'NM' },
    { value: 'NY', viewValue: 'NY' },
    { value: 'NC', viewValue: 'NC' },
    { value: 'ND', viewValue: 'ND' },
    { value: 'OH', viewValue: 'OH' },
    { value: 'OK', viewValue: 'OK' },
    { value: 'OR', viewValue: 'OR' },
    { value: 'PA', viewValue: 'PA' },
    { value: 'RI', viewValue: 'RI' },
    { value: 'SC', viewValue: 'SC' },
    { value: 'SD', viewValue: 'SD' },
    { value: 'TN', viewValue: 'TN' },
    { value: 'TX', viewValue: 'TX' },
    { value: 'UT', viewValue: 'UT' },
    { value: 'VT', viewValue: 'VT' },
    { value: 'VA', viewValue: 'VA' },
    { value: 'WA', viewValue: 'WA' },
    { value: 'WV', viewValue: 'WV' },
    { value: 'WI', viewValue: 'WI' },
    { value: 'WY', viewValue: 'WY' },


  ];
  status = [
    { value: 'Potential', viewValue: 'Potential' },
    { value: 'Active', viewValue: 'Active' },
    { value: 'Archive', viewValue: 'Archive' }
  ];


  ngOnInit() {


    if (this.dataservice.selected_customer) {

      this.customer_obj = this.dataservice.selected_customer;
      console.log(this.customer_obj);
      this.getteams();


      this.showForm = true;

    }
    else {

      this.route.params.subscribe(params => {
        this.cust_id = params['cust_id'];
        console.log(this.cust_id, 'ss');
      });

      this.dataservice.getSingleCustomer(this.cust_id).subscribe(
        data => {

          this.customer_obj = data.json().results[0];
          this.getteams();
          this.showForm = true;

          console.log(this.customer_obj);

        },
        error => {

        }

      )

    }

  }

  sfSelected(id){
    console.log(id);
    this.router.navigate(['/editstore/' + id]);

  }

  getteams() {

    if(this.customer_obj.secondary_emails != null) {
    this.allEmailId = this.customer_obj.secondary_emails.split(',');
    }

    if (this.customer_obj.user_sub_type.length !== 0) {
      let team = this.customer_obj.user_sub_type[0].split(',');

      let i = 0;
      team.forEach(element => {
        this.teams[i] = element;
        i++;
      });
      console.log(this.teams);
    }


  }

  cancel() {
    this._location.back();
  }






  // logo upload
  fileupload(event) {
    console.log(event);
    var files = event.srcElement.files;
    this.customer_obj.logo = files[0];
    this.Custlogo = files[0].name;
    console.log(this.Custlogo);
    // this.customer_obj.logo = this.Custlogo.name;
    //console.log(this.Custlogo, 'logo');
  }
  addClubRow(){
    this.teams.push('');
  }

  removeClubRow(i){
    this.teams.splice(i,1);
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  // customer update
  update() {

    var tempTeam = [];
    console.log(this.teams);
    this.teams.forEach(team => {
      console.log(team);
      if(team != ''){
        tempTeam.push(team);
      }
    })
    console.log(tempTeam);

    if(this.Custlogo != undefined){
      console.log('logo');
    }else{
      console.log('no logo');
    }

  // return
    let obj = {
      'name': this.customer_obj.name,
      'username': this.customer_obj.username,
      'user_storefront': this.customer_obj.user_sub_type[0],
      'password_text': this.customer_obj.password_text,
      'phone_number': this.customer_obj.phone_number,
      'contact_name': this.customer_obj.contact_name,
      'address_street': this.customer_obj.address_street,
      'address_city': this.customer_obj.address_city,
      'address_state': this.customer_obj.address_state,
      'postal_code': this.customer_obj.postal_code,
      'user_type': this.customer_obj.user_type,
      'user_sub_type': tempTeam.toString(),
      'designation': this.customer_obj.designation,
      'status': this.customer_obj.status,
      'contact_title': this.customer_obj.contact_title,
      'email': this.customer_obj.email,

      'secondary_emails': this.allEmailId.toString(),
      'logo': this.customer_obj.logo,
      'shipping_instructions': this.customer_obj.shipping_instructions,
      'sales_tax': this.customer_obj.sales_tax > 0 ? this.customer_obj.sales_tax : 0,
      'shipping_cost': this.customer_obj.shipping_cost > 0 ? this.customer_obj.shipping_cost : 0,
      'is_international_shipping': this.customer_obj.is_international_shipping,
      'shipping_cost_international': this.customer_obj.shipping_cost_international > 0 ? this.customer_obj.shipping_cost_international : 0


    }

    console.log(obj);

    this.dataservice.updatesCustomers(this.customer_obj.id, obj).subscribe(
      res => {
        console.log(res.json());
        this.openDialog();
        this.dataservice.customerUpdated$.next(res.json());
      },
      error => {
        const keys = Object.keys(error.json());
        const errorarry = [];
        keys.forEach(element => {
          //  console.log(error.json()[element]);
          const obj = {
            title: element,
            desc: error.json()[element].toString()
          };

          errorarry.push(obj);

        });



        console.log(errorarry);


        let errorconent = '';

        errorarry.forEach(element => {

          errorconent += `<p><b style="text-transform: capitalize;">${element.title}</b> ${element.desc}</p>`;

        });

        this.openDialogError('Error', errorconent);
      });
  }



  openDialogError(title: string, content: string) {





    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: title, content: content, type: 'error' }
    });
  }





  addSecondaryEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim() && this.validateEmail(value)) {
      this.allEmailId.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.customerDetails.controls['s_email'].setValue(null);
  }



  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: "Success", content: `Customer <b>${this.customer_obj.name}</b> is updated.`, type: 'confirmation' }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }



  validateEmail(email) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  remove(fruit: string): void {
    const index = this.allEmailId.indexOf(fruit);

    if (index >= 0) {
      this.allEmailId.splice(index, 1);
    }
  }

}
