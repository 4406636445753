import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
var ProductNewComponent = /** @class */ (function () {
    function ProductNewComponent(_formBuilder) {
        this._formBuilder = _formBuilder;
    }
    ProductNewComponent.prototype.ngOnInit = function () {
    };
    return ProductNewComponent;
}());
export { ProductNewComponent };
