<mat-progress-bar *ngIf="isLoading" mode="indeterminate" style="z-index: 50;
position: fixed;
top: 51px;"></mat-progress-bar>

<div class="main" *ngIf="showForm">

  <app-breadcrumb></app-breadcrumb>


  <mat-card class="cards">
    <br />
    <mat-toolbar class="subtitle font-fmly" style="margin-bottom:12px;">Edit Product - {{product_obj.title}}
    </mat-toolbar>
    <h4 style="padding: 0 20px;">Status: <span>{{productStatus? 'Active':'Inactive'}}</span></h4>
    <mat-slide-toggle style="margin: 0 20px;" color="primary" [(ngModel)]="productStatus" (change)="onToggle($event)">
    </mat-slide-toggle>
    <div class="">
      <mat-card style="margin: 0; margin-bottom: 20px;">
        <!-- <div class="subtitle font-fmly" *ngFor="let item of selectedstorefronts">
           Storefront: {{item.name}}
        
        </div> -->
        <mat-chip-list #chipList>
          <mat-chip style="cursor: pointer;" [matTooltip]="item.customer" (click)="goToSf(item.id)"
            [matTooltipPosition]="'above'" *ngFor="let item of selectedstorefronts; let i = index"
            (removed)="remove(i)">
            {{ item.name }}
            <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
          </mat-chip>

          <!-- <mat-chip color="primary" selected (click)="openAddDialog()">
            Copy Product to another Storefront
            <mat-icon>add</mat-icon>
          </mat-chip> -->
        </mat-chip-list>
      </mat-card>
    </div>

    <mat-form-field class="widths">
      <input matInput placeholder="Product Name" [(ngModel)]="product_obj.title" required />
    </mat-form-field>
    <mat-form-field class="widths">
      <input matInput placeholder="Product Code" [(ngModel)]="product_obj.code" />
    </mat-form-field>
    <mat-form-field class="example-full-width widths">
      <textarea matInput placeholder="Product Description" [(ngModel)]="product_obj.description"></textarea>
    </mat-form-field>
    <mat-form-field style="width: auto;margin: 0 8px;" class="example-full-width widths">
      <input matInput type="color" placeholder="Product Background Color"
        [(ngModel)]="product_obj.product_background_color">
    </mat-form-field>
    <mat-form-field style="width: auto;" class="example-full-width widths">
      <input matInput type="color" placeholder="Product Text Color" [(ngModel)]="product_obj.product_text_color">
    </mat-form-field>
    <br>
    <mat-checkbox [(ngModel)]="product_obj.is_featured"><b>Featured</b></mat-checkbox>

    <div class="img-logos row">

    </div>
    <table class="example-full-width custlogo widths hidden" cellspacing="0">
      <tr>
        <td>
          <label class="custom-file-upload">
            <!-- <input id="file-input" (change)="prodUpdateImg($event)" type="file" /> -->
            <input (change)="detectFiles($event)" multiple type="file" accept="image/*" />
            <i class="fa fa-cloud-upload"></i> Add Product Images
          </label>
        </td>
      </tr>
    </table>

    <div class="row" style="margin-top: 15px;">

      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedPreviewImage">


        <div class="col-sm-8" *ngFor="let item of product_obj.product_images; let i = index"
          style="text-align: center;">
          <mat-icon class="icons" (click)="removeImage(i)" class="closes">
            <!--delete highlight_off clear -->
            clear
          </mat-icon>
          <img [src]="item.image" width="100" height="100" class="img-p" /><br>
          <mat-radio-button [value]="item.image" [disabled]="disableRbutton"
            (click)="checkForPendingImages2(item.image)"></mat-radio-button>

        </div>
      </mat-radio-group>

    </div>
    <p *ngIf="disableRbutton" style="color:red">In order to select preview image please upload newly added images by
      clicking update</p>

    <br />
    
   
    <mat-form-field class="example-full-width widths">
      <mat-select placeholder="Product Type" [(ngModel)]="product_obj.categories">
        <mat-option [value]="1">Clothing</mat-option>
        <mat-option [value]="2">Paper</mat-option>
        <mat-option [value]="3">Accessories</mat-option>
      </mat-select>
    </mat-form-field>
    <br />

    <mat-form-field class="example-full-width widths">
      <mat-select placeholder="Product Tags" [formControl]="specialTags" multiple>
        <mat-option *ngFor="let tag of specialTagsList" [value]="tag">{{tag.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>

    <div>
      <!-- <button (click)="sizechartinput.click()" mat-raised-button color="primary" class="btn-save">Update size
        chart <i class="fa fa-cloud-upload"></i></button>
      <input type="file" #sizechartinput (change)="uploadSizeChart($event)" style="display: none;"> -->



      <!-- <div *ngIf="!uploadedFile" style="margin: 8px 0;">

        <img *ngIf="isImageBackend" [src]="product_obj.size_chart" alt="Uploaded Image" width="200">
        <a *ngIf="isPdfBackend" [href]="product_obj.size_chart" target="_blank" rel="noopener noreferrer">{{
          product_obj.size_chart }}</a>

      </div>

      <div *ngIf="uploadedFile" style="margin: 8px 0;display: flex;
      align-items: flex-start;">

        <img *ngIf="isImage" [src]="uploadedFile.url" alt="Uploaded Image" width="200">
        <a *ngIf="isPdf" [href]="uploadedFile.url" target="_blank" rel="noopener noreferrer">{{ uploadedFile.name }}</a>
        <mat-icon (click)="removeSizeChart()" style="cursor: pointer;">close</mat-icon>
      </div> -->

    </div>
    <table style="width: 50% !important;"  mat-table matSort
      [dataSource]="product_obj.productsizechart_set" class="mat-elevation-z8">
      <ng-container matColumnDef="Style">
        <th mat-header-cell *matHeaderCellDef> Style </th>
        <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.style |
          titlecase}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Chart">
        <th mat-header-cell *matHeaderCellDef> Chart </th>
        <td style="display: flex;align-items: center;height: 100px;" mat-cell *matCellDef="let element,let i = index">
          <button (click)="sizechartinput.click()" mat-mini-fab color="disabled"
                aria-label="Example icon button with a menu icon" style="margin: 0 10px;">
                <input type="file" #sizechartinput (change)="uploadSizeChart($event,i)" style="display: none;">

                <mat-icon>upload</mat-icon>

              </button>

          <img *ngIf="isImageBackend" [src]="element.size_chart" alt="Uploaded Image"  width="50" height="50" class="img-p" >
          <a *ngIf="isPdfBackend" [href]="element.size_chart" target="_blank" rel="noopener noreferrer">{{
            element.size_chart }}</a>


          <!-- 

              <div *ngIf="element.charturl"  style="position: relative;margin: 0 10px" >
                
                <img *ngIf="isImage" [src]="element.charturl.url"  width="50" height="50" class="img-p">
                
                <a *ngIf="isPdf" [href]="element.charturl.url" target="_blank" rel="noopener noreferrer">{{
                  element.charturl.name }}</a>
                  <mat-icon class="icons"
                    style="position: absolute;top: 0px;right: -20px; color: rgb(64, 64, 64);cursor: pointer;"
                     class="" (click)="removeChart(i)">

                    clear
                  </mat-icon>
              </div> -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsSizeChart"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsSizeChart;"></tr>
    </table>

    <br>

    <div class="widths">
      <!-- <mat-form-field class="color-chip-list" appearance="fill" >
        <mat-label>Product Type Options</mat-label>
        <mat-chip-list #chipListType aria-label="Type selection">
          <mat-chip *ngFor="let type of obj.typeArray" [selectable]="selectable" [removable]="removable"
            (removed)="removeC(type,index,'type')">
            {{type}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="New Type..." [matChipInputFor]="chipListType"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event,index,'type')">
        </mat-chip-list>
      </mat-form-field> -->

      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Product Style Options</mat-label>
        <mat-select [formControl]="tags" multiple>
          <mat-option *ngFor="let tag of tagsList" [value]="tag">{{tag.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="gLDiv" style="display: flex;flex-direction: column;">
      <mat-form-field class="widths">
        <input type="number" matInput placeholder="Price" value="0" [(ngModel)]="product_obj.price" />
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
      <div class="colorSizeEdit">
        <!-- color chips  -->
        <mat-form-field class="color-chip-list" appearance="fill">
          <mat-label>Color Options</mat-label>
          <mat-chip-list [disabled]="!colorSizeEdit" #chipListColor aria-label="Fruit selection">
            <mat-chip *ngFor="let color of colorArray" [selectable]="selectable" [removable]="removable"
              (removed)="removeC(color,index,'color')">
              {{color}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New color..." [matChipInputFor]="chipListColor"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event,index,'color')">
          </mat-chip-list>
        </mat-form-field>

        <section class="color-chip-list">
          <mat-label>Size Options</mat-label>
          
          <mat-chip-list *ngIf="product_obj.categories != 1"  [disabled]="!colorSizeEdit" #chipListSize aria-label="Fruit selection">
            <mat-chip *ngFor="let size of sizeArray" [selectable]="selectable" [removable]="removable"
              (removed)="removeC(size,index,'size')">
              {{size}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New size..." [matChipInputFor]="chipListSize"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event,index,'size')">
          </mat-chip-list>
          
          <div *ngIf="product_obj.categories === 1" class="sizescheckbox">
            <p *ngFor="let item of Sizes">
              <mat-checkbox [disabled]="!colorSizeEdit" (change)="toggleSize(item)"
                [checked]="selectedSizes.includes(item)">{{item}}</mat-checkbox>
            </p>
          </div>

        </section>
        <div class="flex-col" style="gap: 8px; justify-items:center;align-items: center;">
          <button (click)="toggleColorSizeEdit()" mat-mini-fab color="primary"
            aria-label="Example icon button with a menu icon">
            <mat-icon>{{colorSizeIcon}}</mat-icon>
          </button>

          <!-- <div (click)="copySizes()" style="cursor: pointer;display: flex;flex-direction: column;align-items: center;">
            <span class="material-icons">content_copy</span>
            <p>copy sizes</p>
          </div> -->
        </div>



      </div>

      <!-- new table -->

      <div style="display: flex; gap: 10px;margin: 10px 0;">
        <table *ngIf="showNewTable" style="width: 50% !important; margin: 15px 0;" mat-table
          [dataSource]="product_obj_new.productinventory_set" class="mat-elevation-z8">

          <ng-container matColumnDef="Tag">
            <th mat-header-cell *matHeaderCellDef> Style </th>
            <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.tag}}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Color">
            <th mat-header-cell *matHeaderCellDef> Color </th>
            <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element;let i = index"> {{element.color |
              titlecase}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Size">
            <th mat-header-cell *matHeaderCellDef> Size </th>
            <td style="width: 7%;" mat-cell *matCellDef="let element;let i = index"> {{element.size.toUpperCase()}}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity
              <button class="stepperButton" (click)="increaseQ(0)" style="margin: 0 10px;">+</button>
              <button class="stepperButton" (click)="decreaseQ(0)">-</button>
            </th>
            <td style="width: 22%;" mat-cell *matCellDef="let element;let i = index">
              <div style="margin: 10px;">
                <input type="number" min="0" step="10" class="age-input" [(ngModel)]="element.quantity">
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Price">

            <th mat-header-cell *matHeaderCellDef>Price</th>

            <td style="width: 22%;" mat-cell *matCellDef="let element">
              <div style="margin: 10px;">
                <input type="number" min="0" step="10" class="age-input" [(ngModel)]="element.price">
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Images">
            <th mat-header-cell *matHeaderCellDef> Images </th>
            <td style="display: flex;align-items: center;height: 100px;" mat-cell
              *matCellDef="let element,let i = index">


              <button (click)="fileN.click()" mat-mini-fab color="disabled"
                aria-label="Example icon button with a menu icon">
                <input (change)="uploadImageNew($event,i)" #fileN (click)="$event.target.value=null" multiple
                  type="file" accept="image/*" />
                <mat-icon>upload</mat-icon>

              </button>





              <div class="row " style="overflow-x: auto;margin: 0 10px;max-width: 400px;display: flex;">
                <div style="margin:5px;" *ngFor="let item of element.imageArr; let imgin = index">
                  <div style="position: relative;">
                    <img [src]="item.url" width="50" height="50" class="img-p" />
                    <button class="cancelImage" (click)="removeImageNewTable(imgin,i)">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="columndelete">
            <th style="width:15%;" mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let element;let i = index">
              <button (click)="removeCombo(i)" mat-mini-fab color="warn"
                aria-label="Example icon button with a menu icon">
                <mat-icon>delete</mat-icon>
              </button>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <button *ngIf="colorSizeEdit && !colorSizeChanged" style="height: 50px;" mat-raised-button color="primary"
          (click)="AddComboDialogue()">Add Combination</button>
        <button *ngIf="colorSizeEdit" style="height: 50px;" mat-raised-button color="primary"
          (click)="generateList()">Generate New List</button>
      </div>


      <!-- existing table  -->
      <div style="display: flex; gap: 10px;">
        <table [ngStyle]="!colorSizeEdit || colorSizeChanged ? {'background-color':'#cccccc'}:{}"
          style="width: 50% !important;" mat-table [dataSource]="product_obj.productinventory_set"
          class="mat-elevation-z8">

          <ng-container matColumnDef="Tag">
            <th mat-header-cell *matHeaderCellDef> Style </th>
            <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element"> {{element.tag | titlecase}}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Color">
            <th mat-header-cell *matHeaderCellDef> Color </th>
            <td style="width: 10%;padding: 20px;" mat-cell *matCellDef="let element;let i = index"> {{element.color |
              titlecase}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Size">
            <th mat-header-cell *matHeaderCellDef> Size </th>
            <td style="width: 7%;" mat-cell *matCellDef="let element;let i = index"> {{element.size.toUpperCase()}}
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity (+10)
              <button class="stepperButton"
                [ngStyle]="!colorSizeEdit || colorSizeChanged ? {'background-color':'#cccccc'}:{}"
                [disabled]="!colorSizeEdit || colorSizeChanged" (click)="increaseQ(1)"
                style="margin: 0 10px;">+</button>
              <button class="stepperButton"
                [ngStyle]="!colorSizeEdit || colorSizeChanged ? {'background-color':'#cccccc'}:{}"
                [disabled]="!colorSizeEdit || colorSizeChanged" (click)="decreaseQ(1)">-</button>
            </th>
            <td style="width: 22%;" mat-cell *matCellDef="let element;let i = index">
              <div style="margin: 10px;">
                <input [disabled]="!colorSizeEdit || colorSizeChanged" type="number" min="0" step="10" class="age-input"
                  (input)="updateQuantity(element.quantity,i)" [(ngModel)]="element.quantity">
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Price">

            <th mat-header-cell *matHeaderCellDef> Price</th>

            <td style="width: 22%;" mat-cell *matCellDef="let element;let i = index">
              <div style="margin: 10px;">
                <input [disabled]="!colorSizeEdit || colorSizeChanged" (input)="updatePrice(element.price,i)"
                  type="number" min="0" step="10" class="age-input" [(ngModel)]="element.price">
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Images">
            <th mat-header-cell *matHeaderCellDef> Images </th>
            <td style="display: flex;align-items: center;height: 100px;" mat-cell
              *matCellDef="let element,let i = index">

              <button [disabled]="!colorSizeEdit || colorSizeChanged" (click)="fileE.click()" mat-mini-fab
                color="disabled" aria-label="Example icon button with a menu icon">

                <input [disabled]="!colorSizeEdit || colorSizeChanged" #fileE (change)="uploadImageExisting($event,i)"
                  (click)="$event.target.value=null" multiple type="file" accept="image/*" />
                <mat-icon>upload</mat-icon>
              </button>


              <div class="row " style="overflow-x: auto;margin: 0 10px;max-width: 400px;display: flex;">
                <div style="margin:5px;" *ngFor="let item of element.productinventoryimage_set; let imgin = index">
                  <div style="position: relative;">
                    <img [src]="item.image" width="50" height="50" class="img-p" />
                    <button [disabled]="!colorSizeEdit || colorSizeChanged" class="cancelImage"
                      (click)="removeImageExistingTable(imgin,i)">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </div>

                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="columndelete">
            <th style="width:15%;" mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let element;let i = index">
              <button [disabled]="!colorSizeEdit || colorSizeChanged" (click)="removeComboExisting(i)" mat-mini-fab
                color="warn" aria-label="Example icon button with a menu icon">
                <mat-icon>delete</mat-icon>
              </button>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>


        <!-- <button  *ngIf="colorSizeEdit" style="height: 50px;" mat-raised-button color="primary" (click)="generateList()">Generate New List</button> -->

      </div>

    </div>

    <!-- <div class="gLDiv">
      <div class="colorSizeEdit">
        <mat-form-field class="widths">
          <input [disabled]="!colorSizeEdit" (input)="inputChanged()" matInput placeholder="Color Options" value=""
            [(ngModel)]="product_obj.color" />
        </mat-form-field>
        <mat-form-field class="widths">
          <input [disabled]="!colorSizeEdit" (input)="inputChanged()" matInput placeholder="Size Options" value=""
            [(ngModel)]="product_obj.size" (ngModelChange)="product_obj.size = $event.toUpperCase()" />
        </mat-form-field>

        <button (click)="toggleColorSizeEdit()" mat-mini-fab color="primary"
          aria-label="Example icon button with a menu icon">
          <mat-icon>{{colorSizeIcon}}</mat-icon>
        </button>
      </div>

      <button *ngIf="colorSizeEdit && colorSizeChanged" mat-raised-button color="primary" style="margin: 10px 0;"
        (click)="generateList()">
        Generate New List
      </button>
      
    </div> -->


    <div class="text-center">
      <!-- <button
        mat-raised-button
        color="primary"
        class="btn-save"
        (click)="generateList()"
        style="margin: 10px;"
      >
        Generate List
      </button> -->
      <button mat-raised-button color="primary" class="btn-save" (click)="update2()" style="margin: 10px;">
        Update
      </button>
      <button mat-raised-button color="primary" class="btn-save" (click)="cancel()" style="margin: 10px;">
        Cancel
      </button>
    </div>
  </mat-card>
</div>