import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"]
})
export class BreadcrumbComponent implements OnInit {
  logUrlPath: object;
  ngOnInit() {}
  constructor(private route: ActivatedRoute, private router: Router) {
    let copyUrl = Object.assign([], this.route.routeConfig["data"]["url"]);
    this.logUrlPath = copyUrl.map(url => {
      let deleteUrl = Object.assign({}, url);
      if (deleteUrl["isId"]) {
        let index: number = 2;
        index = this.router["url"].split("/").findIndex(val => /-/.test(val));
        deleteUrl["url"] =
          deleteUrl["url"] + this.router["url"].split("/")[index];
        return deleteUrl;
      } else return deleteUrl;
    });
  }
}
