<mat-card class="inventory_card">
  <img *ngIf="inventory.productinventoryimage_set.length > 0" style="height: 300px;object-fit: cover;min-width: 350px;" (error)="onImgError($event)" mat-card-image src="{{inventory.productinventoryimage_set[0].image}}" alt="Photo of a Shiba Inu">
  <img *ngIf="inventory.productinventoryimage_set.length === 0" style="height: 300px;object-fit: cover;min-width: 350px;" (error)="onImgError($event)" mat-card-image src="assets/images/deaault_store_img.png" alt="Photo of a Shiba Inu">
  <mat-card-content>
    <mat-card-title>{{inventory.product_name}}</mat-card-title>
    <div style="display: flex;justify-content: space-between; font-size:16px;">
      <p>Color</p>
      <p style="margin-right: 30px;">{{inventory.color}}</p>
    </div>
    <div style="display: flex;justify-content: space-between; font-size:16px;">
      <p>Size</p>
      <p style="margin-right: 30px;">{{inventory.size}}</p>
    </div>
    <div style="display: flex;justify-content: space-between; font-size:16px;">
      <p>Quantity</p>
      <p style="margin-right: 30px;">{{inventory.quantity}}</p>
    </div>

    
  </mat-card-content>
  
</mat-card>